<p-toast position="top-center" key="confirm" (onClose)="cancelarMensajeConfirmacion()" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="flex flex-column" style="flex: 1">
            <div class="text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{message.summary}}</h4>
                <p>{{message.detail}}</p>
            </div>
            <div class="grid p-fluid">
                <div class="col-6">
                    <button type="button" pButton (click)="cancelarMensajeConfirmacion()" label="Cancelar"
                        class="p-button-secondary"></button>
                </div>
                <div class="col-6">
                    <button type="button" pButton (click)="confirmarMensaje()" label="Aceptar"
                        class="p-button-success"></button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>
<p-toast position="top-right" key="msj"></p-toast>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="Aceptar" rejectLabel="Cancelar"
    rejectButtonStyleClass="p-button-text">
</p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
    rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar"
    [rejectVisible]="false"></p-confirmDialog>
<div class="d-flex justify-content-center">
    <div class="container-fluid" style="width: 98%;">
        <div class="row mt-5">
            <div class="col-md-12">
                <div class="card card-shadow sinborde">
                    <div class="card-header sinborde" style="background: none; ">
                        <div class="cardicono rounded">
                            <i class="pi pi-calendar-plus mt-3" style="font-size: 2rem; color: #fff;"></i>
                        </div>
                        <header style="left: 11%; top: 5%;">
                            <div style="display:inline">Gestión de</div>
                            <div style="color:blueviolet;display:inline;" class="ml-3">Bono Semanal</div>
                        </header>
                        <div class="row d-flex justify-content-around mt-4">
                            <div class="col-md-8">
                                <div class="row d-flex justify-content-around">
                                    <div class="col-md-4">
                                        <p-dropdown [options]="lstSemanas" autoWidth="false" [style]="{'width':'100%'}"
                                            placeholder="Seleccione Semana*" [(ngModel)]="selectedSemana"
                                            optionLabel="nom_semana" [showClear]="true">
                                            <ng-template let-target pTemplate="item">
                                                <div class="ui-helper-clearfix" style="position: relative;height:25px;"
                                                    pTooltip="{{getTargetDescription(target)}}" tooltipPosition="top">
                                                    <span
                                                        style="font-size:14px;float:left;margin-top:4px">{{target.nom_semana}}</span>
                                                </div>
                                            </ng-template>
                                        </p-dropdown>
                                        <div *ngIf="selectedSemana!=null">
                                            <div class="row">
                                                <div class="col-sm-6 text-left texto_verde">Inicio:
                                                    {{selectedSemana.fec_inicio | date: 'dd/MM/yyyy' }}</div>
                                                <div class="col-sm-6 text-right texto_verde">Fin:
                                                    {{selectedSemana.fec_fin | date: 'dd/MM/yyyy' }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <p-dropdown [options]="lstCiudadesPrincipales" autoWidth="false"
                                            [style]="{'width':'100%'}" placeholder="Seleccione Ciudad*"
                                            [(ngModel)]="selectedCiudad" optionLabel="nombre" [showClear]="true">
                                        </p-dropdown>
                                    </div>
                                    <div class="col-md-4">
                                        <button pButton pRipple label="Filtrar" class="p-button-success"
                                            icon="pi pi-filter" (click)="cargarInformacionPersonalPorSemana();"
                                            pTooltip="Cargar Información"></button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 d-flex justify-content-end">
                                <div>
                                    <p-button label="Consultar Historico" icon="pi pi-list" iconPos="right"
                                        (click)="consultaSemanas();" pTooltip="Consultar Semanas"
                                        tooltipPosition="left">
                                    </p-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-content">
                        <div class="row mt-3">
                            <p-table #dt1 [value]="lstColaboradores" [tableStyle]="{'min-width': '50rem'}"
                                styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true" scrollHeight="480px"
                                responsiveLayout="scroll"
                                [globalFilterFields]="['nombres','apellido_paterno','apellido_materno','desc_puesto']"
                                [paginator]="true" [rows]="10" [rowsPerPageOptions]="[10,15,20,30,50]"
                                [showCurrentPageReport]="true" [resizableColumns]="false"
                                currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros"
                                [loading]="loading">
                                <ng-template pTemplate="caption">
                                    <div class="flex justify-content-end">
                                        <span class="p-input-icon-left p-ml-auto">
                                            <i class="pi pi-search"></i>
                                            <input pInputText type="text" size="40"
                                                (input)="applyFilterGlobal($event, 'contains')"
                                                placeholder="Buscar Nombre,Puesto" />
                                        </span>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="idx" class="text-center"># Chofer<p-sortIcon
                                                field="idx"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="nombres">Nombres<p-sortIcon field="nombres"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="apellido_paterno">Apellido Paterno<p-sortIcon
                                                field="apellido_paterno"></p-sortIcon></th>
                                        <th pSortableColumn="apellido_materno">Apellido Materno<p-sortIcon
                                                field="apellido_materno"></p-sortIcon></th>
                                        <th pSortableColumn="desc_puesto">Puesto<p-sortIcon
                                                field="desc_puesto"></p-sortIcon></th>
                                        <th pSortableColumn="nombre_ciudad_hm">Ciudad<p-sortIcon
                                                field="nombre_ciudad_hm"></p-sortIcon></th>
                                        <th class="text-center">¿Bono?</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-data let-index>
                                    <tr>
                                        <td class="text-center texto_chofer_lila">
                                            {{data.cod_chofer}}
                                        </td>
                                        <td class="texto_resaltado">
                                            {{data.nombres }}
                                        </td>
                                        <td class="texto_resaltado"> {{data.apellido_paterno}}</td>
                                        <td class="texto_resaltado"> {{data.apellido_materno}}</td>
                                        <td
                                            [ngClass]="{'texto_chofer': data.id_puesto==1,'texto_auxiliar':data.id_puesto==2,'texto_bk':data.id_puesto==3}">
                                            {{ data.desc_puesto}}
                                        </td>
                                        <td class=""> {{data.nombre_ciudad_hm}}</td>
                                        <td>
                                            <div class="flex justify-content-center">
                                                <p-checkbox [(ngModel)]="data.clv_bono_cast" [binary]="true"
                                                    inputId="binary"></p-checkbox>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="summary">
                                    <div class="flex align-items-center justify-content-center mb-2 mt-2">
                                        <div class="col-md-3">
                                            <button class="btn btn-success w-100" pTooltip="Guardar Asignación de Bono"
                                                (click)="guardarTodo()" [disabled]="dsbGuardarTodo">
                                                <span class="text-white">Guardar Todo</span>
                                                <i class="pi pi-save text-white"></i>
                                            </button>
                                        </div>
                                    </div>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>