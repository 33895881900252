import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Ibonoaguinaldo } from '../models/bonoaguinaldo';
import { ICiudadesPrincipales } from '../models/CiudadesPrincipales';
import { CiudadesprincipalesService } from './ciudadesprincipales.service';  // Importado
import { ICiudadPrincipalHM } from '../models/ciudadprincipal';
import { IVacaciones } from '../models/vacaciones';
import { IVacacionesPost } from '../models/vacacionesPost';
import { IResultadoGeneral } from '../models/resultadogeneral';
import { IputVacaciones } from '../models/putVacaciones';

@Injectable({
  providedIn: 'root'
})
export class VacacionesService {
  private apiUrl: string = environment.sUrlNomina + 'Vacaciones';

  constructor(private http: HttpClient) { }  // Inyección de CiudadesprincipalesService

  getListadoVacaciones( id_ciudad_hm: number,num_anio: number, cod_usuario:string): Observable<IVacaciones[]> {
    const valores = {
      params: new HttpParams()
       .set('id_ciudad_hm', id_ciudad_hm)
        .set('num_anio', num_anio)
        .set('cod_usuario', cod_usuario)
    };
    return this.http.get<IVacaciones[]>(this.apiUrl, valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  // getListadoCiudadesPrincipales(): Observable<ICiudadPrincipalHM[]> {
  //   // Uso del método del servicio importado
  //   return this.ciudadesPrincipalesService.getListadoCiudadesPrincipales();
  // }

  rptVacaciones(num_anio: number, id_ciudad_principal_hm: number): Observable<any> {
    const valores = {
      params: new HttpParams()
        .set('num_anio', num_anio)
        .set('id_ciudad_hm', id_ciudad_principal_hm)
    };
    return this.http.get<any>(this.apiUrl + '/rptVacaciones', valores)
      .pipe(retry(1), catchError(this.handleError));
  }
  postVacaciones(data: IVacacionesPost): Observable<IResultadoGeneral> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let data_enviar =JSON.stringify(data);
    return this.http.post<IResultadoGeneral>(this.apiUrl +'/AddEditVacaciones', data_enviar, { headers: headers })
      .pipe(catchError(this.handleError));
  }
  autNoVacaciones(data: IVacacionesPost): Observable<IResultadoGeneral> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let data_enviar =JSON.stringify(data);
    return this.http.put<IResultadoGeneral>(this.apiUrl +'/AutorizadoNoVacaciones', data_enviar, { headers: headers })
      .pipe(catchError(this.handleError));
  }

  AprobarVacaciones(data: IVacacionesPost): Observable<IResultadoGeneral> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let data_enviar =JSON.stringify(data);
    return this.http.put<IResultadoGeneral>(this.apiUrl +'/AutorizadoVacaciones', data_enviar, { headers: headers })
      .pipe(catchError(this.handleError));
  }

  finalizarPeriodo(data: IputVacaciones): Observable<IResultadoGeneral> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let data_enviar =JSON.stringify(data);
    return this.http.put<IResultadoGeneral>(this.apiUrl +'/finalizarPeriodo', data_enviar, { headers: headers })
      .pipe(catchError(this.handleError));
  }


  DeletePeriodoVacaciones(data: IVacacionesPost): Observable<IResultadoGeneral> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let data_enviar={headers:headers, body : JSON.stringify(data)};
    return this.http.delete<IResultadoGeneral>(this.apiUrl +'/DeletePeriodo', data_enviar)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(() => new Error(errorMessage));
  }
}
