import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, catchError, retry, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { IBonoPermanenciaEnviar } from '../models/bono_enviar_permanencia';
import { IrespondBonoPermanencia } from '../models/respondBonoPermanencia';
import IBonoPermanenciaPost from '../models/postBonoPermanencia';

@Injectable({
  providedIn: 'root'
})
export class BonopermanenciaService {
  private apiUrl: string ='';
  constructor(private _http: HttpClient) {
    this.apiUrl = environment.sUrlNomina + 'BonoPermanencia';
  }

  getBonoPorSemanayCiudad(id_semana:number,anio:number,id_ciudad_principal_hm:number, cod_usuario: string): Observable<any> {
    const valores = {
      params: new HttpParams().
        set('num_semana', id_semana).
        set('anio', anio).
        set('id_ciudad_principal_hm', id_ciudad_principal_hm).
        set('cod_usuario', cod_usuario)

    };
    return this._http
      .get<any>(this.apiUrl, valores)
      .pipe(retry(1), catchError(this.handleError));
  }

// terminar de ditar
getListChoferesCiudad(id_semana: number, id_ciudad_hm:number): Observable<IrespondBonoPermanencia[]> {
    const valores = {
      params: new HttpParams().

         set('id_semana',id_semana).
         set('id_ciudad_principal_hm',id_ciudad_hm)


    }

    return this._http
        .get<IrespondBonoPermanencia[]>(this.apiUrl +'/getListChoferesCiudad',valores)
        .pipe(retry(1), catchError(this.handleError));

   }


  postBono(data: IBonoPermanenciaEnviar[] ):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this._http.post<any>(this.apiUrl + '/',body,{'headers':headers});
   }


  //  postAgregar(data: IBonoPermanenciaPost): Observable<any> {
  //   const headers = { 'content-type': 'application/json'};
  //   let body = JSON.stringify(data);
  //   return this._http.post<any>(this.apiUrl + '/', body, { 'headers': headers })
  //   .pipe(catchError(this.handleError));
  // }

  handleError(error:any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
        return errorMessage;
    });
  }
}
