import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Ibonoaguinaldo } from '../models/bonoaguinaldo';

import { CiudadesprincipalesService } from './ciudadesprincipales.service';  // Importado
import { ICiudadPrincipalHM } from '../models/ciudadprincipal';
import { IPostPrimaVacacional } from '../models/postprimavacacional';
import { IPostInfonavit } from '../models/postinfonavit';
import { IInfonavit } from '../models/infonavit';
import { ICorSemInfonavit } from '../models/postcortseminfonavit';
import { IhisInfonavit } from '../models/hisinfonavit';

@Injectable({
  providedIn: 'root'
})
export class InfonavitService {
  private apiUrl: string = environment.sUrlNomina + 'Infonavit';

  constructor(private http: HttpClient, private ciudadesPrincipalesService: CiudadesprincipalesService) { }  // Inyección de CiudadesprincipalesService

  getListadodeducciones( id_ciudad_hm: number, cod_usuario:string): Observable<IInfonavit[]> {
    const valores = {
      params: new HttpParams()
        // .set('num_anio', num_anio)
        .set('id_ciudad_hm', id_ciudad_hm)
        // .set('id_semana', id_semana)
        .set('cod_usuario',cod_usuario)
    };
    return this.http.get<[IInfonavit]>(this.apiUrl, valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  getListadoCiudadesPrincipales(): Observable<ICiudadPrincipalHM[]> {
    // Uso del método del servicio importado
    return this.ciudadesPrincipalesService.getListadoCiudadesPrincipales();
  }


  public postinfonavit(data: IPostInfonavit):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this.http.post<any>(this.apiUrl + '/postInfonavit/',body,{'headers':headers});
   }

   public cortsem(data: ICorSemInfonavit):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this.http.post<any>(this.apiUrl + '/infcortesem/',body,{'headers':headers});
   }


   gethisifonavit(cod_chofer: number): Observable<IhisInfonavit[]> {
    const valores = {
      params: new HttpParams()
        .set('cod_chofer', cod_chofer)
    };
    return this.http.get<IhisInfonavit[]>(this.apiUrl + '/hisinfonavit', valores)
      .pipe(retry(1), catchError(this.handleError));
  }
  //  cortsem(id_ciudad_hm:number, id_semana: number, clv_his: number,num_anio: number, cod_usuario: string): Observable<any> {
  //   const valores = {

  //     id_ciudad_hm:id_ciudad_hm,
  //     id_semana: id_semana,
  //     clv_his: clv_his,
  //     num_anio: num_anio,
  //     cod_usuario: cod_usuario

  //   };

  //   return this.http.post<any>(this.apiUrl + '/infcortesem', valores)
  //     .pipe(retry(1), catchError(this.handleError));
  // }



  rptInfonavit(num_anio_rpt: number, id_semana_rpt: number,id_ciudad_hm_rpt: number): Observable<any> {
    const valores = {
      params: new HttpParams()
        .set('num_anio', num_anio_rpt)
        .set('id_semana', id_semana_rpt)
        .set('id_ciudad_hm', id_ciudad_hm_rpt)
    };
    return this.http.get<any>(this.apiUrl + '/rptInfonavit', valores)
      .pipe(retry(1), catchError(this.handleError));
  }








  private handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(() => new Error(errorMessage));
  }
}
