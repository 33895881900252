import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ICiudad } from '../models/ciudad';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { IConfiguracionUnidad } from '../models/confUnidad';
import { IDiaFestivo, IDiaFestivoColabDisp, IMovDiasFestivos, IPostColabDiaFestivo, IUltRutasDiaFestivo } from '../models/diaFestivoColab';
import { IResultadoGeneral } from '../models/resultadogeneral';
@Injectable({
  providedIn: 'root'
})

export class DiafestivoColabServiceService {

  private apiUrl: string = environment.sUrlNomina + 'DiasFestivosColab';

  constructor(private http: HttpClient) { }

  getListDiaFestivo(num_anio:number): Observable<IDiaFestivo[]> {
    const valores = {
      params: new HttpParams().
         set('num_anio',num_anio)
    }
    return this.http.get<IDiaFestivo[]>(this.apiUrl+'/ObtenDiasFestivos' , valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  getListMovDiaFestivoColab(id_ciudad_hm:number, num_anio:number,cod_usuario: string): Observable<IMovDiasFestivos[]> {
    const valores = {
      params: new HttpParams().
        set('id_ciudad_hm',id_ciudad_hm).
        set('num_anio',num_anio).
        set('cod_usuario',cod_usuario)
    }
    return this.http.get<IMovDiasFestivos[]>(this.apiUrl+'/ObtenMovimientosDiasFestivos' , valores)
    .pipe(retry(1), catchError(this.handleError));
  }

  getListColabDispDiaFestivo(id_diafestivo:number, num_anio:number): Observable<IDiaFestivoColabDisp[]> {
    const valores = {
      params: new HttpParams().
        set('id_diafestivo',id_diafestivo).
        set('num_anio',num_anio)
    }
    return this.http.get<IDiaFestivoColabDisp[]>(this.apiUrl+'/ObtenColaboradoresDiasFestivos', valores)
    .pipe(retry(1), catchError(this.handleError));
  }

  getListRutasColabDiaFestivo(cod_chofer:number): Observable<IUltRutasDiaFestivo[]> {
    const valores = {
      params: new HttpParams().
        set('cod_chofer',cod_chofer)
    }
    return this.http.get<IUltRutasDiaFestivo[]>(this.apiUrl+'/ObtenUltimasRutasChofer', valores)
    .pipe(retry(1), catchError(this.handleError));
  }


  // Utiliza el modelo IRecursosMaster
  PostDiaFestivoColab(data: IPostColabDiaFestivo): Observable<IResultadoGeneral> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let data_enviar =JSON.stringify(data);
    return this.http.post<IResultadoGeneral>(this.apiUrl +'/PostMoviminetoDiaFestivo', data_enviar, { headers: headers })
      .pipe(catchError(this.handleError));
  }

 deleteDiaFestivoColab(idx: number, cod_chofer:number, cod_usuario:string): Observable<IResultadoGeneral> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let data_enviar=
    {
      headers:headers
      , body : JSON.stringify({ idx: idx, cod_chofer: cod_chofer, cod_usuario: cod_usuario})
    };

    return this.http.delete<IResultadoGeneral>(this.apiUrl +'/DeleteMovimientoDiaFestivo', data_enviar)
      .pipe(catchError(this.handleError));
  }
  
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}
