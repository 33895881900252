<p-toast position="top-right" key="msj"></p-toast>

<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="Aceptar" rejectLabel="Cancelar"
  rejectButtonStyleClass="p-button-text">
</p-confirmDialog>

<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
  rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar" [rejectVisible]="false">

</p-confirmDialog>
<div class="container-fluid" style="border-color: blue; border: 5px ; width: 95%;">
  <div class="row">
    <div class="col-md-12">
      <div class="card card-shadow sinborde">
        <div class="card-header sinborde" style="background: none; ">
          <div class="cardicono rounded">
            <i class="pi pi-briefcase mt-3" style="font-size: 2rem; color: #fff;"></i>
          </div>
          <header style="left: 11%; top: 5%;">Incapacidad
            <div style="color:rgb(33, 111, 228); display: inline;">de Colaboradores</div>
          </header>
          <div class="row d-flex justify-content-end">
            <div class="col-md-12">

              <!-- -------------fecha del filtro---------------- -->
              <div class="form-group row mt-5">
                <div class="col-md-3">
                  <div class="row d-flex justify-content-start">
                    <div class="col-md-5">
                      <label class="col-form-label color-font text-right"
                        style="font-weight: 600; font-family: 'Poppins', sans-serif;">
                        <span class="input-group-addon ml-5"> <i class="pi pi-calendar color-icon mr-4"
                            style="font-size: 2rem; color: #3B82F6;"></i>
                        </span>
                        Año
                      </label>
                    </div>
                    <div class="col-sm-7 mt-3">
                      <select class="form-control" [(ngModel)]="num_anio">
                        <option *ngFor="let year of years" [value]="year">{{ year }}</option>
                      </select>

                    </div>
                  </div>
                </div>

                <div class="col-md-2 mt-2">
                  <p-dropdown [options]="meses" autoWidth="false" [style]="{'width':'100%'}"
                    placeholder="Seleccione el mes*" optionValue="num_mes" [(ngModel)]="id_mes" optionLabel="nombre"
                    [showClear]="false">
                  </p-dropdown>

                </div>

                <!-- filtro de ciudades -->
                <div class="col-md-2 mr-3 mt-2">
                  <p-dropdown [(ngModel)]="id_ciudad_hm" [options]="lstCiudad" optionLabel="nombre" optionValue="id"
                    placeholder="Filtrar por Ciudad" [showClear]="false">
                  </p-dropdown>

                </div>

                <!-- ----------boton principal para el filtro  inicial------------------- -->
                <div class="col-md-1 ">
                  <button pButton pRipple type="button" (click)="FiltrarInformacion();" label="Filtrar"
                    icon="pi pi-filter" iconPos="left"></button>
                </div>
                <!-- ----------boton principal de los reportes en excel------------------- -->
                <div class="  col-md-3 ml-6 ">
                  <!-- <button type="button" pButton pRipple class="p-button-success " pTooltip="Exportar Catalogo a EXCEL"
                    tooltipPosition="right" (click)="abrirModalReportes()">
                    <i class="pi pi-file-excel"></i> Exportar
                  </button> -->
                </div>

              </div>

            </div>
          </div>

        </div>
        <div class="card-body">
          <p-table #dt1 [value]="lstIncapacidades" [scrollable]="true" scrollHeight="flex" scrollDirection="both"
            [paginator]="true" [rows]="150" [rowsPerPageOptions]="[50,75,100,200]" [showCurrentPageReport]="true"
            currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros"
            [globalFilterFields]="['nom_chofer','desc_ciudad_hm','desc_puesto']" [loading]="loading">
            <ng-template pTemplate="caption">
              <div class="flex justify-content-between">
                <div>
                  <button pButton pRipple type="button" (click)="crearNuevo();" *ngIf="habilitaragregar"
                    label="Nueva Incapacidad" icon="pi pi-plus" [style]="{'width':'100%'}" iconPos="left" class="info">
                  </button>
                </div>
                <div class="p-input-icon-left p-ml-auto" *ngIf="habilitaragregar">
                  <i class="pi pi-search"></i>
                  <input pInputText type="text" size="40" (input)="applyFilterGlobal($event, 'contains')"
                    placeholder="Buscar" />
                </div>
                <div class="p-input-icon-left p-ml-auto" *ngIf="!habilitaragregar">
                  <i class="pi pi-search"></i>
                  <input pInputText type="text" size="40" (input)="applyFilterGlobal($event, 'contains')"
                    placeholder="Buscar" />
                </div>
              </div>

            </ng-template>
            <ng-template pTemplate="header">
              <tr>

                <th class="text-center">#Chofer</th>
                <th class="text-start" pSortableColumn="nom_chofer">Colaborador<p-sortIcon
                    field="nom_chofer"></p-sortIcon></th>
                <th class="text-center" pSortableColumn="desc_puesto">Puesto<p-sortIcon
                    field="desc_puesto"></p-sortIcon></th>
                <th class="text-center" pSortableColumn="id_ciudad_hm">Ciudad<p-sortIcon
                    field="id_ciudad_hm"></p-sortIcon></th>
                <th class="text-start">Incapacidad</th>
                <th class="text-start">Subincapcidad</th>
                <th class="text-center" pSortableColumn="fec_inicio">Fecha de Inicio <p-sortIcon
                    field="fec_inicio"></p-sortIcon> </th>
                <th class="text-center" pSortableColumn="fec_fin">Fecha Fin<p-sortIcon field="fec_fin"></p-sortIcon>
                </th>
                <th class="text-center">#Dias</th>
                <th class="text-center" style="width: 200px; min-width: 200px;">Descripción</th>
                <th class="text-center">Importe Total</th>
                <th class="text-center" pSortableColumn="clv_estatus">Estatus<p-sortIcon
                    field="clv_estatus"></p-sortIcon></th>

                <th class="text-center">Opciones</th>

              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
              <tr>
                <td class="text-center"><b>{{data.cod_chofer}}</b> </td>
                <td class="text-left"><b>{{data.nom_chofer}}</b></td>
                <td class="text-center"
                  [ngStyle]="{'color': data.desc_puesto === 'DRIVER' ? 'rgb(13,110,253)' : 'orange'}">
                  <b>{{data.desc_puesto}}</b>
                </td>
                <td class="text-center">{{data.desc_ciudad_hm}}</td>
                <td class="text-start" style="color: rgb(22, 79, 212);"><b>{{data.desc_opcion_incapacidad}}</b></td>
                <td class="text-start" style="color: rgb(58, 181, 239);"><b>{{data.desc_tipo_sub_incapacidad}}</b></td>
                <td class="text-center">{{data.fec_inicio | date: 'dd/MM/yyyy'}}</td>
                <td class="text-center">{{data.fec_fin | date: 'dd/MM/yyyy'}}</td>
                <td class="text-center" style="color: rgb(218, 162, 8);"><b>{{data.num_dias}}</b></td>
                <td class="text-justify" style="width: 200px; min-width: 200px; font-size:13px;">{{data.desc_incapacidad
                  | slice : 0:80 }}<button type="button" class="btn p-button-text" (click)="verMas(data);"
                    pTooltip="ver mas" tooltipPosition="top">
                    <i class="pi pi-comment color-icon mr-3" style="font-size: 1rem; color: #3B82F6;"></i>
                  </button></td>


                <td class="text-center" style="min-width:200px ;max-width: 200px; color: rgb(19, 161, 19);"
                  [ngClass]="{'ajustarcelda':true}">
                  <p class="my-0"><b>{{data.imp_incapacidad | currency: 'USD'}}</b>
                    <span class="input-group-addon ml-2 my-0" (click)="mostrardesgloce(data)"
                      pTooltip="Desgloce de la Incapacidad">
                      <i class="pi pi-info-circle color-icon mr-3" style="font-size: 1rem; color: #3B82F6;">
                      </i>
                    </span>
                  </p>
                </td>






                <td class="text-center">
                  <p-tag *ngIf="data.clv_estatus==1" severity="warning" value="REQUIERE AUTORIZACION"></p-tag>
                  <p-tag *ngIf="data.clv_estatus==2" severity="success" value="VIGENTE"></p-tag>

                </td>
                <td>
                  <div class="d-flex justify-content-around ">


                    <div class="d-flex">
                      <div class="ml-2">
                        <button class="btn btn-success" tooltipPosition="top" pTooltip="Aprobar Incapcidad"
                          (click)="AprobarIncapacidad(data)">
                          <i class="pi pi-check"></i>
                        </button>
                      </div>

                    </div>


                    <div class="ml-2">
                      <div class="ml-2">
                        <button [disabled]="data.clv_activo === 2" tooltipPosition="top" class="btn btn-danger"
                          pTooltip="Eliminar Incapacidad" (click)="EliminarIncapacidad(data)">
                          <i class="pi pi-trash"></i>
                        </button>
                      </div>
                    </div>

                    <!-- *ngIf="data.clv_operacion ===3" -->
                    <div class="ml-2">
                      <div class="ml-2">
                        <button class="btn btn-primary"      tooltipPosition="top"
                          pTooltip="Editar Incapacidad" (click)="EditarIncapacidad(data)">
                          <i class="pi pi-pencil"></i>
                        </button>
                      </div>

                    </div>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>

          <div class=" mt-3 d-flex justify-content-center">
            <p class="text-left informativo"
              style="display: inline; padding-left: 10px; border-left: 5px solid rgb(67, 192, 35); font-weight: normal; font-size: 0.8rem;">
              <b>VIGENTE</b> Ya ah sido aprobada y se contempla en nomina
            </p>
            <p class="text-left informativo"
              style="display: inline; padding-left: 10px; border-left: 5px solid rgb(224, 137, 38); font-weight: normal; font-size: 0.8rem;">
              <b>REQUIERE AUTORIZACION</b> se encuentra espera de autorizacion
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--AGREGAR Incapacidad-->
  <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspAgregar" [style]="{width: '55vw','height':'700px'}"
    (onHide)="cancelarAgregar()" [transitionOptions]="'0ms'" styleClass="card-shadow">
    <ng-template pTemplate="header">
      Registro de Incapacidad
    </ng-template>
    <ng-template pTemplate="content">
      <form class="form_cliente" [formGroup]="reactiveincapacidades" (ngSubmit)="GuardarIncapacidad()">
        <div class="row  pb-2">
          <div class="row mt-1 d-flex justify-content-start">
            <div class="col-sm-6">
              <span class="title-light">Agrege la informacion solicitada
                <i class="pi pi-cog" style="size: 2rem;">
                </i>
              </span>
            </div>
          </div>
          <div class="form-group col-md-6 mt-2">

            <div class="row g-0">

              <!-- <div class="col-sm-1">
                <span class="input-group-addon"> <i class="pi pi-align-justify color-icon" style="font-size: 2rem"></i>
                </span>
              </div> -->
              <div class="col-sm-10  ml-4">
                <label style="color: rgb(22, 22, 176); " [ngClass]="{'texto_sm':true}"
                  class="label-header"><b>Colaborador</b></label>
                <p-dropdown [options]="lstChoferes" optionLabel="nombre_completo" formControlName="cod_chofer"
                  autoWidth="false" [showClear]="false" [filter]="true" [filterBy]="'nombre_completo'"
                  placeholder="Seleccione Colaborador" [style]="{'width':'95%' , 'margin-top': '5px'}"
                  optionValue="cod_chofer" (onChange)="changedp($event)">
                </p-dropdown>

              </div>
            </div>
          </div>
          <!-- fecha inicio y fin -->
          <div class="form-group col-md-6 mt2">
            <div class="row g-0">
              <div class="col-sm-{{ id_editar === 1 ? '4' : '6' }} d-flex flex-column justify-content-around">
                <label style="color: rgb(22, 22, 176);" [ngClass]="{'texto_sm':true}" class="label-header"><b>Fecha
                    Inicio</b></label>
                <input formControlName="fec_inicio" type="date" class="form-control sinborde"
                  pTooltip="Seleccione o teclee fecha Inicio" tooltipPosition="top">
              </div>
              <div class="col-sm-4 d-flex flex-column justify-content-around" *ngIf="id_editar === 1">
                <label style="color: rgb(22, 22, 176);" [ngClass]="{'texto_sm':true}" class="label-header"><b>Fecha
                    Fin</b></label>
                <input formControlName="fec_fin" type="date" class="form-control sinborde"
                  pTooltip="Seleccione o teclee fecha Fin" tooltipPosition="top">
              </div>
              <div class="col-sm-{{ id_editar === 1 ? '4' : '6' }} d-flex flex-column justify-content-around">
                <label style="color: rgb(22, 22, 176);" [ngClass]="{'texto_sm':true}" class="label-header"><b>Días de
                    Duración</b></label>
                <input formControlName="num_duracion" type="number" placeholder="0" class="form-control sinborde"
                  pTooltip="Ingrese los días de duración de la incapacidad" tooltipPosition="top" max="28" min="0">
              </div>

            </div>
          </div>
        </div>

        <!-- tipo de incapacuidad -->
        <div class="row d-flex">

          <div class="col-md-6">
            <div div class="row mt-4 ml-4  p-ml-auto">
              <label style="color: rgb(22, 22, 176); " [ngClass]="{'texto_sm':true}" class="label-header"><b>Tipo de
                  incapacidad</b></label>
              <p-dropdown [options]="lstIncapacidad" optionLabel="desc_opcion_incapacidad"
                formControlName="id_incapacidad" autoWidth="false" [showClear]="false"
                placeholder="seleccione el tipo de incapacidad" optionValue="id_incapacidad"
                [style]="{'width':'80%' , 'margin-top': '5px'}"> </p-dropdown>
            </div>




            <!-- Dropdown condicional basado en el valor seleccionado -->
            <div class="row mt-2 ml-4 p-ml-auto" *ngIf="reactiveincapacidades.controls['id_incapacidad'].value === 2">
              <div class="col-md-6 p-input-icon-left">
                <i class="pi pi-percentage ml-3"></i>
                <input pInputText type="text" formControlName="porcentaje_capturado"
                  pTooltip="Ingrese el porcentaje capturado" />
              </div>
            </div>

            <!-- Fin del dropdown condicional -->

            <div div class="row mt-3  ml-4">
              <label style="color: rgb(22, 22, 176); " [ngClass]="{'texto_sm':true}" class="label-header"><b>Tipo de
                  sub incapacidad</b></label>
              <p-dropdown [options]="lstSubIncapacidad" optionLabel="desc_opcion_sub_incapacidad"
                formControlName="id_sub_incapacidad" autoWidth="false" [showClear]="false"
                placeholder="seleccione el tipo de incapacidad" optionValue="id_sub_incapacidad"
                [style]="{'width':'80%' , 'margin-top': '5px'}"> </p-dropdown>
            </div>


            <div class="row mt-3  ml-4">
              <label style="color: rgb(22, 22, 176); " [ngClass]="{'texto_sm':true}"
                class="label-header mt-2"><b>Ciudad</b></label>
              <div class="col-sm-10">
                <p-dropdown [options]="lstCiudad2" optionLabel="nombre" formControlName="id_ciudad_hm" autoWidth="false"
                  [showClear]="false" placeholder="Seleccione Ciudad del colaborador acreedor"
                  [style]="{'width':'100%' , 'margin-top': '5px'}" optionValue="id">
                </p-dropdown>
              </div>


            </div>
          </div>
          <div class="col-md-6">
            <label style="color:  rgb(22, 22, 176); font-weight: bold;">Descripcion de Incapacidad</label>
            <textarea rows="5" cols="63" pInputTextarea formControlName="desc_incapacidad"></textarea>
          </div>
        </div>

        <!-- <div class="row">
          <div class="col-md-12">
            <label style="color:  rgb(22, 22, 176); font-weight: bold;">ANEXOS</label>
            <p-fileUpload name="demo[]" url="https://www.primefaces.org/cdn/api/upload.php"
              (onUpload)="onUpload($event)" [multiple]="true" accept=".pdf" [maxFileSize]="1000000">
              <ng-template pTemplate="content">
                <ul *ngIf="uploadedFiles.length">
                  <li *ngFor="let file of uploadedFiles">{{ file.name }} - {{ file.size }} bytes</li>
                </ul>
              </ng-template>
            </p-fileUpload>
          </div>
        </div> -->
        <div class="mt-7 d-flex justify-content-center">
          <button type="submit" class="btn btn-lg btn-success" [disabled]="
        !reactiveincapacidades.controls['id_incapacidad'].valid || !reactiveincapacidades.controls['desc_incapacidad'].valid
          || !reactiveincapacidades.controls['fec_inicio'].valid " pTooltip="Registrar Incapacidad">Guardar</button>
        </div>
      </form>
    </ng-template>
    <ng-template pTemplate="footer">
      <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
        (click)="cancelarAgregar();"></button>
    </ng-template>
  </p-dialog> <!--FIN AGREGAR PRESTAMO-->


  <!-- pdialog de comentario  -->
  <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspVerMas" [style]="{width: '35vw','height':'430px'}"
    (onHide)="verMenos()" [transitionOptions]="'0ms'" styleClass="card-shadow">
    <ng-template pTemplate="header">
      Descripcion de la incapacidad
    </ng-template>
    <ng-template pTemplate="content">
      <form class="form_cliente" [formGroup]="reactiveincapacidades" (ngSubmit)="verMenos()">
        <div class="row  pb-2">

          <!-- tipo de incapacuidad -->

          <div class="row d-flex mt-3">
            <label style=" font-weight: bold;" class="title-light">Colaborador: {{this.nombre_chofer}}</label>
            <textarea rows="5" cols="50" pInputTextarea formControlName="desc_incapacidad"></textarea>
          </div>
        </div>
        <div class="mt-2 d-flex justify-content-center">
          <button type="submit" class="btn btn-lg btn-success" pTooltip="Registrar Incapacidad">ACEPTAR</button>
        </div>
      </form>
    </ng-template>
    <ng-template pTemplate="footer">
      <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="verMenos();"></button>
    </ng-template>
  </p-dialog>




  <!-- pdialog desgloce  -->
  <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspDesgloce" [style]="{width: '40vw','height':'585px'}"
    (onHide)="verMenosdesgloce()" [transitionOptions]="'0ms'" styleClass="card-shadow">
    <ng-template pTemplate="header">
      Desgloce de la incapacidad
    </ng-template>
    <ng-template pTemplate="content">
      <form class="form_cliente" [formGroup]="reactiveincapacidades" (ngSubmit)="verMenosdesgloce()">
        <div class="row ml-2 pb-2">

          <!-- tipo de incapacuidad -->

          <div class="row d-flex mt-3">
            <label style=" font-weight: bold;" class="title-light">Colaborador:
              {{this.nombre_chofer}}({{this.des_puesto}})</label>
          </div>
          <div class="row d-flex mt-2">
            <label>Tip de incapacidad:
              <span style="color: rgb(231, 154, 22); font-weight: bold;">{{this.desc_tipo_sub_incapacidad}}</span></label>
          </div>

          <div class="row d-flex mt-2">
            <label>Sueldo diario del colaborador:
              <span style="color: green; font-weight: bold;">{{this.imp_salario_base |
                currency}}</span></label>

          </div>

          <div class="row d-flex mt-2">
            <label>Sueldo ante el IMMS:
              <span style="color: green; font-weight: bold;">{{this.imp_salario_ims |
                currency}}</span></label>

          </div>

          <div class="row d-flex mt-2">
            <label>Porcentaje que paga el IMMS de la incapacidad: <span
                style="color: green; font-weight: bold;">{{(this.porcentaje * 100) | number:'1.2-2'}}%</span>
            </label>
          </div>
          <div class="row d-flex mt-2">
            <label>Numero de dias: <span style="color: green; font-weight: bold;">{{this.num_dias }}</span>
            </label>
          </div>

          <div class="row d-flex mt-2">
            <label>Importe total: <span style="color: green; font-weight: bold;">{{this.imp_total |
                number:'1.2-2'}}</span>
            </label>
          </div>
          <hr style="width: 100%; color: black; height: 1px; background-color:black; margin-top: 5px;">


          <div class="row d-flex mt-1" *ngIf="id_sub_incapacidad == 1 && num_dias <= 3">
            <label style="font-weight: normal; font-style: italic; text-decoration: none; color: #e66262;">
              *Nota: las incapacidades por enfermedad general se pagan después del tercer día*
            </label>

          </div>


          <div class="row d-flex mt-1" *ngIf="id_sub_incapacidad == 1  && num_dias >= 3">
            <label style="font-weight: normal; font-style: italic; text-decoration: none; color: #e66262;">
              *Nota: las incapacidades por enfermedad general se pagan después del tercer día*
            </label>

            <label style="font-weight: bold;" class="mt-3">Desgloce del calculo</label>
            <label >
              Importe total = ((( {{this.imp_salario_base | currency}} - {{this.imp_salario_ims |currency}}) *
              {{this.num_dias-3}}) * {{this.porcentaje * 100 |number:'1.2-2'}}%)
              <span style="color: green; font-weight: bold;">{{' = ' + '$' + this.imp_total }}</span>
            </label>
          </div>


          <div class="row d-flex mt-1" *ngIf="id_sub_incapacidad != 1">
            <label style="font-weight: bold;" class="mt-3">Desgloce del calculo</label>
            <label >
              Importe total = ((( {{this.imp_salario_base | currency}} - {{this.imp_salario_ims |currency}}) *
              {{this.num_dias}}) * {{this.porcentaje * 100 |number:'1.2-2'}}%)
              <span style="color: green; font-weight: bold;">{{' = ' + '$' + this.imp_total }}</span>
            </label>
          </div>

          <hr style="width: 100%; color: black; height: 1px; background-color:black;">
        </div>
        <div class="mt-2 d-flex justify-content-center">
          <button type="submit" class="btn btn-lg btn-success" pTooltip="Registrar Incapacidad">ACEPTAR</button>
        </div>
      </form>
    </ng-template>
    <ng-template pTemplate="footer">
      <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
        (click)="verMenosdesgloce();"></button>
    </ng-template>
  </p-dialog> <!--FIN AGREGAR PRESTAMO-->
</div>
