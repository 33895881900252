import { Component } from '@angular/core';
import { IResultadoGeneral } from './../models/resultadogeneral';
import { ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { ConfirmEventType, ConfirmationService, MessageService } from 'primeng/api';
import { saveAs } from 'file-saver';
import ICredencialUsuario from '../models/credencial';
import { ICiudad } from '../models/ciudad';
import { InombreCompletoChofer } from '../models/nombreCompletoChofer';
import { IMes } from '../models/mes';
import { IrptEstatusForaneo } from '../models/rptEstatusForaneo';
import { IEstatusForaneo } from '../models/estatusforaneo';
import { ISemanasHappyMile } from '../models/semanas';
import { BonoRecomendacionService } from '../services/bonorecomendacion.service';
import { IBonoRecomendacion } from '../models/bonorecomendacion';
import { IpostBonoRecomendacion } from '../models/postbonorecomendacion';
import { CiudadhmService } from './../services/ciudadhm.service';
import { ICiudadHM } from '../models/ciudadhm';
import { SemanaService } from '../services/semana.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { min } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-bonorecomendacion',
  templateUrl: './bonorecomendacion.component.html',
  styleUrls: ['./bonorecomendacion.component.scss']
})
export class BonorecomendacionComponent {

  @ViewChild('dt1') dt!: Table;
  lstCiudad: ICiudadHM[] = [];
  lstCiudad2: ICiudadHM[] = [];
  lstCiudades: ICiudadHM[] = [];
  lstChoferes: InombreCompletoChofer[] = [];
  lstchofCopy: InombreCompletoChofer[] = [];
  // lstCiudadesForanea: ICiudad[] = [];
  lstEstatusForaneo: IEstatusForaneo[] = [];
  //lstBonoRecomendacion: IPrima [] = [];
  lstBonoRecomendacion: IBonoRecomendacion[] = [];
  lstSemanas: ISemanasHappyMile[] = [];


  mssgListaCiudades: string = '';
  selectedSemana: ISemanasHappyMile = {
    idx: 0,
    id_semana: 0,
    nom_semana: '',
    anio: 0,
    fec_inicio: '',
    fec_fin: '',
    clv_activo: 0
  };

  lstrptDescansoFaltas: IrptEstatusForaneo[] = [];
  selectedCiudad: ICiudad = {
    id: 0,
    nombre: '',
    clave_Estado: '',
    id_ciudad_principal: 0
  };


  years: number[] = [];
  loading: boolean = false;

  //variables usadas para la fecha, fueron casteadas ya que presentaban errorees
  fecha_hoy: any = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
  dia: any = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
  fecha_filtrada: any = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
  fecha_dia: any = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
  anio: any = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
  fecha_fin: any = null;

  //hacer un reactive form  --modelo que voy a enviar
  nom_chofer: string = '';
  // cod_chofer:number=0;
  id_ciudad_hm: number = 0;
  num_anio: number = 0;
  id_mes: number = 0;
  // id_semana : number= 0;
  // cod_chofer_recomendado:number=0;
  // nom_chofer_recomendado: string = '';
  id_ciudad_principal: number = 0;
  dspCliente: boolean = false;
  texto_operacion: string = '';
  texto_operacion_pago: string = '';
  num_operacion: number = 0;
  clv_operacion: number = 0;
  habilitar_agregar: boolean = false;
  imp_aguinaldo_total: number = 0;
  imp_dias_extra_total: number = 0;
  imp_total_genral: number = 0;
  mostraragregar: boolean = false;
  resultado: IResultadoGeneral = {
    clv_estatus: 0,
    messageDetails: ''
  };

  dsblstCiudades: boolean = true;
  dsblstColaboradores: boolean = true;
  dsblstCiudadesAdscritas: boolean = true;
  // variables de reportes--------------------------
  dbsReportes: boolean = false;
  fecha_fin_filtro: any = null;
  ButtonExportExcel: boolean = false;
  //Fecha de Dias Atras
  fecha_del_dia: any = null;
  btnHabilitar: boolean = false;
  mostrar_texto_fechas: number = 0;
  fecha_final_dia!: Date;
  //Fecha para restar 3 dias
  fecha_minus3 !: Date;
  fecha_dia_real: any = null;
  checked: boolean = false;
  selectedMesReporte1: IMes = {
    num_mes: -1,
    nombre: ""
  }
  clv_activo: number = 0;
  anioReporte1: any;
  loading_reporte1: boolean = false;
  meses: IMes[] = [
    { num_mes: 0, nombre: "TODOS LOS MESES" },
    { num_mes: 1, nombre: "Enero" },
    { num_mes: 2, nombre: "Febrero" },
    { num_mes: 3, nombre: "Marzo" },
    { num_mes: 4, nombre: "Abril" },
    { num_mes: 5, nombre: "Mayo" },
    { num_mes: 6, nombre: "Junio" },
    { num_mes: 7, nombre: "Julio" },
    { num_mes: 8, nombre: "Agosto" },
    { num_mes: 9, nombre: "Septiembre" },
    { num_mes: 10, nombre: "Octubre" },
    { num_mes: 11, nombre: "Noviembre" },
    { num_mes: 12, nombre: "Diciembre" }];




  reactivebonoRecomendacion: FormGroup = new FormGroup({
    nom_chofer: new FormControl(''),
    cod_chofer: new FormControl(0, [Validators.min(1), Validators.required]),
    id_ciudad_hm: new FormControl(0),
    num_anio: new FormControl(0),
    id_semana: new FormControl(0, [Validators.min(1), Validators.required]),
    cod_chofer_recomendado: new FormControl(0, [Validators.min(1), Validators.required]),
    nom_chofer_recomendado: new FormControl(''),
    id_ciudad_recomendado: new FormControl(0),
    selectedSemana: new FormControl([], [Validators.min(1), Validators.required]),
    semana_hm_inicio: new FormControl(null),
    semana_hm_fin: new FormControl(null),
    nombreCiudad: new FormControl(''),
    fec_inicio: new FormControl(''),
    fec_fin: new FormControl(''),
  });



  //Permisos
  user: ICredencialUsuario = {};
  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private semanaService: SemanaService,
    private bonoRecomendacionService: BonoRecomendacionService,
    private CiudadhmService: CiudadhmService,
    private auth: AuthService
  ) {
    this.cargarInformacionUsuario();
    this.initializeYears();
    this.num_anio = new Date().getFullYear();

    this.reactivebonoRecomendacion.controls['selectedSemana'].valueChanges.subscribe(data => {
      if (data == null) {
        //this.resetearFormularios();
        this.reactivebonoRecomendacion.controls['fec_inicio'].setValue('');
        this.reactivebonoRecomendacion.controls['fec_fin'].setValue('');
      } else {
        this.reactivebonoRecomendacion.controls['fec_inicio'].setValue(data.fec_inicio);
        this.reactivebonoRecomendacion.controls['fec_fin'].setValue(data.fec_fin);
      }
    });

  }

  ngOnInit(): void {
    this.cargaInicial();
    this.cargarListadoDeSemanasAnio();
    this.cargaListaChoferes();
  }

  cargarInformacionUsuario() {
    this.user = this.auth.user;
  }

  initializeYears(): void {
    const currentYear = new Date().getFullYear();
    for (let year = currentYear; year >= currentYear - 5; year--) {
      this.years.push(year);
    }
  }


  // metodos para el filtro para fechas
  getTargetDescription(ruta: ISemanasHappyMile) {
    if (ruta !== null && ruta !== undefined) {
      let fec_inicio = new Date(ruta.fec_inicio);
      let fec_fin = new Date(ruta.fec_fin);
      return this.formatearFechas(fec_inicio) + ' | ' + this.formatearFechas(fec_fin);
    } else {
      return '';
    }

  }

  getTargetDescriptioninicio(ruta: ISemanasHappyMile) {
    if (ruta !== null && ruta !== undefined) {
      let fec_inicio = new Date(ruta.fec_inicio);
      let fec_fin = new Date(ruta.fec_fin);
      return this.formatearFechas(fec_inicio);
    } else {
      return '';
    }

  }
  getTargetDescriptionfin(ruta: ISemanasHappyMile) {
    if (ruta !== null && ruta !== undefined) {
      let fec_inicio = new Date(ruta.fec_inicio);
      let fec_fin = new Date(ruta.fec_fin);
      return this.formatearFechas(fec_fin);
    } else {
      return '';
    }

  }

  formatearFechas(fecha: Date): string {
    let fecha_Cast = '';
    var getYear = new Date(fecha).toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date(fecha).toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date(fecha).toLocaleDateString('es-MX', { day: '2-digit' });
    fecha_Cast = getDay + '/' + getMonth + '/' + getYear;
    return fecha_Cast;
  }

  cargarListadoDeSemanasAnio() {
    this.lstSemanas = [];
    this.semanaService.listarSemanas(this.anio).subscribe((resp) => {
      this.lstSemanas = resp;
      setTimeout(() => {
      },
        600);
    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> al cargar Información. Contacte al Administrador.');
      });
  }


  changedSemana(event: any) {
    let id_semana = event.value;
    // Encuentra la semana seleccionada en la lista de semanas
    let semanaSeleccionada = this.lstSemanas.find(semana => semana.id_semana === id_semana);
    if (semanaSeleccionada) {
      // Actualiza los valores en el formulario reactivo
      this.reactivebonoRecomendacion.controls['fec_inicio'].setValue(semanaSeleccionada.fec_inicio);
      this.reactivebonoRecomendacion.controls['fec_fin'].setValue(semanaSeleccionada.fec_fin);
    }
  }

  // -----------filtra y carga la informacion ----
  FiltrarFechaDiariaRuta() {
    this.loading = true;
    if (this.id_ciudad_hm == null || typeof this.id_ciudad_hm === 'undefined' || this.selectedSemana == null || typeof this.selectedSemana === 'undefined') {
      this.mostrarDialogoInformativoSinInformacion('<b>Informativo</b> Debe de seleccionar una semana para poder filtrar');
      this.loading = false;
    } else {
      this.mostraragregar = true;
      this.bonoRecomendacionService.getListadoRecomendado(this.num_anio, this.id_mes, this.id_ciudad_hm, this.user.cod == undefined ? '0999' : this.user.cod).subscribe(
        (resp) => {
          this.lstBonoRecomendacion = resp;
          if (this.lstBonoRecomendacion.length === 0) {
            this.loading = false;
            this.mostrarDialogoInformativoSinInformacion('<b>No Existe</b> Registros para la Fecha ' + this.fecha_filtrada + ' seleccionada');
          } else {
            this.imp_total_genral = 0;
            // Iterar sobre la lista y sumar los valores de los campos
            this.lstBonoRecomendacion.forEach((item) => {
              this.imp_total_genral += item.bono_recomendacion;
            });
            // Finalizar el loading después de 800 ms2
            setTimeout(() => {
              this.loading = false;
            }, 800);
          }
        },
        (error) => {
          this.mostrarDialogoInformativoSinInformacion('<b>ERROR</b> en Cargar Movimientos ');
          this.loading = false;
        }
      );
    }
  }


  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }



  // cargar lista inicial de las ciudades para los dropdown  get
  cargaInicial() {
    this.dsblstCiudades = true;
    this.loading = true;
    this.CiudadhmService.getListadoCiudades().subscribe((resp) => {
      this.lstCiudad = resp.slice(); // Crear una copia independiente
      this.lstCiudad2 = resp.slice(); // Crear una copia independiente
      const index = this.lstCiudad.findIndex(item => item.nombre === 'TODAS LAS CIUDADES');
      if (index !== -1) {
        this.lstCiudad.splice(index, 1);
      }
      this.lstCiudad.push({ id: 0, nombre: 'TODAS LAS CIUDADES', clave_estado: '', id_ciudad_principal: 0, clave_tipoTransporte: 0 });
      setTimeout(() => {
        this.loading = false;
        this.dsblstCiudades = false;
      }, 1000);
    },
      (error) => {
        this.loading = false;
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Ciudades');
      });
  }

  cargaListaChoferes() {
    this.dsblstColaboradores = true;
    this.lstChoferes = [];
    this.bonoRecomendacionService.getListadoChoferesGeneral(this.id_ciudad_hm).subscribe((resp) => {
      this.lstChoferes = resp;
      setTimeout(() => {
        this.dsblstColaboradores = false;
      }, 100);
    },
      (error) => {
        this.dsblstColaboradores = false;
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Colaboradores');
      });
  }


  saveAsExcelFile(buffer: any, fileName: string): void {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + "_exportar_" + dateFormat + EXCEL_EXTENSION);
  }



  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '. <b>Contacte al Administrador.</b>';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }

  changedp(event: any) {
    let eve = event.value;
    this.lstCiudades = [];
    let choferSeleccionado = this.lstChoferes.find(chofer => chofer.cod_chofer === eve);
    if (choferSeleccionado) {
      this.reactivebonoRecomendacion.controls['id_ciudad_hm'].setValue(choferSeleccionado.id_ciudad_hm);
      this.reactivebonoRecomendacion.controls['nombreCiudad'].setValue(choferSeleccionado.desc_ciudad_hm);
      this.reactivebonoRecomendacion.controls['nom_chofer'].setValue(choferSeleccionado.nombre_completo);
      // this.reactivebonoRecomendacion.controls['nombreChofer'].setValue(choferSeleccionado.nombre_completo);
    } else {
      this.mssgListaCiudades = 'El colaborador no cuenta con ciudad favor de verificar';
    }
  }


  changedprecomendado(event: any) {
    let eve = event.value;
    this.lstCiudades = [];
    let choferSeleccionado = this.lstChoferes.find(chofer => chofer.cod_chofer === eve);
    if (choferSeleccionado) {
      this.reactivebonoRecomendacion.controls['id_ciudad_recomendado'].setValue(choferSeleccionado.id_ciudad_hm);
      this.reactivebonoRecomendacion.controls['nombreCiudad'].setValue(choferSeleccionado.desc_ciudad_hm);
      this.reactivebonoRecomendacion.controls['nom_chofer_recomendado'].setValue(choferSeleccionado.nombre_completo);
      // this.reactivebonoRecomendacion.controls['nombreChofer'].setValue(choferSeleccionado.nombre_completo);
    } else {
      this.mssgListaCiudades = 'El colaborador no cuenta con ciudad favor de verificar';
    }
  }


  // ----------------reportes  ------------
  abrirModalReportes() {
    this.dbsReportes = true;
    this.inicializarFechasFormateadas();
  }


  cancelarModalReportes() {
    this.dbsReportes = false;
    // Inicio Reporte 1
    this.selectedMesReporte1 = {
      num_mes: 0,
      nombre: ''
    }
    this.anioReporte1 = null;
    this.selectedCiudad = {
      id: -1,
      nombre: '',
      clave_Estado: '',
      id_ciudad_principal: 0
    };
    this.fecha_fin = null;
  }



  cancelarVentanaModalPdialog() {
    //variables del primero
    this.reactivebonoRecomendacion.controls['cod_chofer_recomendado'].setValue('');
    this.reactivebonoRecomendacion.controls['cod_chofer'].setValue('');
    this.reactivebonoRecomendacion.controls['selectedSemana'].setValue('');
    this.reactivebonoRecomendacion.controls['id_ciudad_hm'].setValue('');
    this.reactivebonoRecomendacion.controls['id_ciudad_recomendado'].setValue('');

    this.dspCliente = false;
  }





  guardarBono() {
    let choferSeleccionado = this.lstChoferes.find(chofer => chofer.cod_chofer === this.reactivebonoRecomendacion.controls['cod_chofer'].value);
    let nombreChofer = choferSeleccionado ? choferSeleccionado.nombre_completo : '';
    let data_enviar: IpostBonoRecomendacion = {
      cod_chofer: this.reactivebonoRecomendacion.controls['cod_chofer'].value,
      id_ciudad_hm: this.reactivebonoRecomendacion.controls['id_ciudad_hm'].value,
      num_anio: this.reactivebonoRecomendacion.controls['selectedSemana'].value.anio,
      // id_mes: this.reactivebonoRecomendacion.controls['selectedSemana'].value.id_mes,
      id_semana: this.reactivebonoRecomendacion.controls['selectedSemana'].value.id_semana,
      cod_chofer_recomendado: this.reactivebonoRecomendacion.controls['cod_chofer_recomendado'].value,
      cod_usuario: this.user.cod == undefined ? '0999' : this.user.cod,
      clv_activo: this.clv_activo = 1,
    };

    this.confirmationService.confirm({
      message: '¿Está seguro de aprobar el <b>BONO DE RECOMENDACIÓN</b>  del Colaborador <b>' + nombreChofer + '</b>?',
      header: 'Confirmación de edición de estatus',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.bonoRecomendacionService.postBonoRecomendacion(data_enviar).subscribe((resp) => {
          // const resultado = Array.isArray(resp) ? resp[0] : resp;
          if (resp.clv_estatus == 1) {
            this.messageService.add({ key: 'msj', severity: 'success', summary: 'Exito', detail: 'Se registro correctamente' });
            this.dspCliente = false;
            this.FiltrarFechaDiariaRuta();
            this.cancelarVentanaModalPdialog();
          }
          else if (resp.clv_estatus == -2) {
            this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: resp.messageDetails });
            // this.dspCliente = false;
          }
        });
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
        }
      }
    });
  }



  AprobarBono(data: IBonoRecomendacion) {
    const fechaActual = new Date();
    if (data.clv_activo == 2) {
      this.messageService.add({
        key: 'msj',
        severity: 'warn',
        summary: 'Cancelado',
        detail: 'El colaborador ya fue autorizado'
      });
    } else {
      this.nom_chofer = data.nom_chofer;
      let data_enviar: IpostBonoRecomendacion = {
        //  idx: 0,  checar lo de las fechas que mando
        cod_chofer: data.cod_chofer,
        id_ciudad_hm: data.id_ciudad_hm,
        num_anio: data.num_anio,
        //id_mes : data.id_mes,
        id_semana: data.id_semana,
        cod_chofer_recomendado: data.cod_chofer_recomendado,
        cod_usuario: this.user.cod == undefined ? '0999' : this.user.cod,
        clv_activo: this.clv_activo = 2,
      };
      this.confirmationService.confirm({
        message: '¿Está seguro de aprobar el <b>BONO POR RECOMENDACION </b>  del Colaborador <b>' + this.nom_chofer + '</b>?',
        header: 'Confirmación de edición de estatus',
        icon: 'pi pi-info-circle',
        accept: () => {
          this.bonoRecomendacionService.postBonoRecomendacion(data_enviar).subscribe((resp) => {
            // Verificamos si la respuesta es una lista y tomamos el primer elemento
            // const resultado = Array.isArray(resp) ? resp[0] : resp;
            if (resp.clv_estatus == 1) {
              this.messageService.add({ key: 'msj', severity: 'success', summary: 'Exito', detail: 'Se aprobo correctamente' });
              this.FiltrarFechaDiariaRuta();
            } else if (resp.clv_estatus == 2) { this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'El colaborador ya fue recomendado anteriormente' }); }
            else if (resp.clv_estatus == -2) {
              this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: resp.messageDetails });
            }
          });
        },
        reject: (type: any) => {
          switch (type) {
            case ConfirmEventType.REJECT:
              this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
              break;
            case ConfirmEventType.CANCEL:
              this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
              break;
          }
        }
      });
    }
  }


  EliminarRegistro(data: IBonoRecomendacion) {
    this.nom_chofer = data.nom_chofer;
    let data_enviar: IpostBonoRecomendacion = {
      cod_chofer: data.cod_chofer,
      id_ciudad_hm: data.id_ciudad_hm,
      num_anio: data.num_anio,
      id_semana: data.id_semana,
      cod_chofer_recomendado: data.cod_chofer_recomendado,
      cod_usuario: this.user.cod == undefined ? '0999' : this.user.cod,
      clv_activo: this.clv_activo = 3,
    };
    this.confirmationService.confirm({
      message: '¿Está seguro de eliminar el  <b>BONO POR RECOMENDACION </b>  del Colaborador <b>' + this.nom_chofer + '</b>?',
      header: 'Confirmación de edición de estatus',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.bonoRecomendacionService.postBonoRecomendacion(data_enviar).subscribe((resp) => {
          // Verificamos si la respuesta es una lista y tomamos el primer elemento
          //const resultado = Array.isArray(resp) ? resp[0] : resp;
          if (resp.clv_estatus == 1) {
            this.messageService.add({ key: 'msj', severity: 'success', summary: 'Exito', detail: 'Se elimino correctamente' });
            this.FiltrarFechaDiariaRuta();
          } else if (resp.clv_estatus == 2) { this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'El colaborador ya fue recomendado anteriormente' }); }
          else if (resp.clv_estatus == -2) {
            this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: resp.messageDetails });
          }
        });
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
        }
      }
    });
  }


  //botones de agregar
  mostrarModalAgregar() {
    this.reactivebonoRecomendacion.controls['id_ciudad_hm'].disable();
    this.reactivebonoRecomendacion.controls['id_ciudad_recomendado'].disable();
    this.dspCliente = true
    this.texto_operacion = 'Agregar Nuevo Bono Por Recomendacion';
  }





  inicializarFechasFormateadas() {
    //hacer globales
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.fecha_dia = dateFormat;
    this.fecha_del_dia = new Date(dateFormat + 'T00:00:00');
    //Fecha que no se modifica
    this.fecha_final_dia = new Date(dateFormat + 'T00:00:00');
    //Fecha para restar los 3 dias
    let minus3days = new Date(this.fecha_final_dia);
    this.fecha_minus3 = new Date(minus3days.setDate(new Date(minus3days).getDate() - 4));
    //Fecha del dia real, no se mueve
    this.fecha_dia_real = dateFormat;
    //Reportes
    let num_mes: number = +getMonth;
    this.meses.map((mes) => {
      if (mes.num_mes == num_mes) {
        this.selectedMesReporte1.nombre = mes.nombre;
        this.selectedMesReporte1.num_mes = mes.num_mes;
      }
    });
    this.anioReporte1 = getYear;
  }


  // metodo para exportar a excel
  exportarExcel(num_anio: number, id_ciudad_principal: number) {
    //  // this.num_anio = this.fecha_filtrada;
    //   this.loading_reporte1 = true;
    //   if ( num_anio == null || id_ciudad_principal == null) {
    //     this.mostrarDialogoInformativo('<b>Aviso</b> Es necesario seleccionar <b>Fecha</b> de Operación a Filtrar');
    //   } else {
    //     this.bonoaguinaldoService.rptBonoAguinaldo(this.num_anio, this.id_ciudad_principal)
    //       .subscribe((resp: IPrima[]) => {
    //         if (resp.length === 0 || typeof resp === 'undefined') {
    //           this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion del Catalogo para exportar(Excel)');
    //           this.loading_reporte1 = false;
    //         } else {
    //           import("xlsx").then(xlsx => {
    //             const worksheet = xlsx.utils.json_to_sheet(resp);
    //             const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
    //             const excelBuffer: any = xlsx.write(workbook, {
    //               bookType: "xlsx",
    //               type: "array"
    //             });

    //             this.saveAsExcelFile(excelBuffer, "ReporteAguinaldo" + '_' + num_anio );
    //           });
    //           setTimeout(() => {
    //             this.ButtonExportExcel = false;
    //             this.loading_reporte1 = false;
    //           }, 1000);
    //         }
    //       },
    //         (error) => {
    //           this.ButtonExportExcel = false;
    //           this.loading_reporte1 = false;
    //           this.fecha_fin = null;
    //           this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: 'Error en emitir el reporte de Estatus de Facturación.' });
    //         });
    //   }
  }



  mostrarDialogoInformativo(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '.';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
        this.messageService.clear();
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
        this.messageService.clear();
      },
      key: "sinInformacionDialog"
    });
    this.loading = false;
    this.messageService.clear();
  }


}
