import { PrestamoviaticosService } from './../services/prestamoviaticos.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import ICredencialUsuario from '../models/credencial';
import { AuthService } from '../services/auth.service';
import { ConfirmEventType, ConfirmationService, MessageService } from 'primeng/api';
import { IPrestamosViaticos } from '../models/prestamoviaticos';
import { IColaboradoresQuinceDias } from '../models/prest_viaticos';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IPrevisualizarViaticos } from '../models/previsualizar_viaticos';
import { IPrestamoEnviar } from '../models/prestamo_enviar';
import { IPrestamoDispersion } from '../models/prestamo_dispersion';
import { IResultadoGeneral } from '../models/resultadogeneral';
import { IPrestamoGuardar } from '../models/prestamo_guardar';
import { IViaticoPagadoEstatus } from '../models/prestamo_viatico_estatus';

@Component({
  selector: 'app-prestamoviaticos',
  templateUrl: './prestamoviaticos.component.html',
  styleUrls: ['./prestamoviaticos.component.scss']
})
export class PrestamoviaticosComponent implements OnInit {
  @ViewChild('dt1') dt!: Table;
  user: ICredencialUsuario = {};
  loading: boolean = false;
  fecha_dia: any = null;
  anio: any = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
  dspDialogo: boolean = false;
  texto_operacion: string = 'Dispersión de Préstamo de Viáticos';
  lstColaboradores: IPrestamosViaticos[] = [];
  clv_operacion: number = 0;
  nombre_registro: string = '';
  lstTodosColaboradores: IColaboradoresQuinceDias[] = [];
  selectedColaborador: IColaboradoresQuinceDias = {
    cod_chofer: 0,
    fec_alta: '',
    desc_ciudad: '',
    nombre_completo: ''
  };
  lstprevisualizar: IPrevisualizarViaticos[] = [];
  data_enviar_previsualizar: IPrestamoEnviar = {
    imp_total: 0,
    cod_chofer: 0,
    fecha_registro: '',
    cod_usuario_registro: ''
  };
  dataForm: FormGroup = new FormGroup({
    cod_chofer: new FormControl(null, [Validators.required]),
    imp_total: new FormControl(null, [Validators.required])
  });
  lstDispersionPrestamo: IPrestamoDispersion[] = [];
  dsbCambiarAutorizado: boolean = true;
  resultado_general: IResultadoGeneral = {
    clv_estatus: 0,
    messageDetails: ''
  };
  prestamo_enviar: IPrestamoGuardar = {
    idx: 0,
    id_prestamo: 0,
    id_prestamo_disp: 0,
    cod_chofer: 0,
    num_semana_inicio: 0,
    imp_prestamo_total: 0,
    imp_abono: 0,
    num_semana_abono: 0,
    num_anio_abono: 0,
    clv_pagado: 0,
    clv_autorizado: 0,
    clv_finalizado: 0,
    clv_activo: 0,
    num_anio_registro: 0,
    fecha_registro: '',
    cod_usuario_registro: '',
    cod_usuario_autorizo: ''
  };
  lstPagadoViatico: IViaticoPagadoEstatus[] = [];
  dspDialogoPrestamoEstatus: boolean = false;
  constructor(private auth: AuthService, private confirmationService: ConfirmationService, private messageService: MessageService,
    private prestamoviaticosService: PrestamoviaticosService) {
    this.cargarInformacionUsuario();
  }
  ngOnInit(): void {
    this.inicializarFechas();
    this.cargarInformacionColaboradoresQuinceDias();
  }

  cargarInformacionUsuario() {
    this.user = this.auth.user;
  }

  guardarPrestamo() {
    if (this.lstDispersionPrestamo.length > 0) {
      this.prestamoviaticosService.guardarPrestamo(this.lstDispersionPrestamo).subscribe((resp) => {
        this.resultado_general = resp;
        this.messageService.add({ key: 'msj', severity: 'success', summary: 'Actualizado', detail: 'La información se almaceno correctamente' });
        this.cargarInformacion(this.anio);
        this.cancelarModal();
      },
        (error) => {
          this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Guardar Prestamo Viaticos>', detail: 'Contacte al Administrador del Sitio' });
        });
    }
  }

  calcularDispersionPrestamo() {
    let imp_total = this.dataForm.get('imp_total')?.value;
    let cod_chofer = this.dataForm.get('cod_chofer')?.value;
    let cod_usuario_registro = this.user.cod!;
    this.selectedColaborador = this.lstTodosColaboradores.filter((x) => x.cod_chofer == cod_chofer)[0];
    this.prestamoviaticosService.obtenerDispersionDePrestamo(imp_total, cod_chofer, this.fecha_dia, cod_usuario_registro).subscribe((resp) => {
      this.lstDispersionPrestamo = resp;
      if (this.lstDispersionPrestamo.length > 0) {
        this.prestamo_enviar.idx = 0;
        this.prestamo_enviar.id_prestamo = 0;
        this.prestamo_enviar.id_prestamo_disp = this.lstDispersionPrestamo[0].id_prestamo_disp;
        this.prestamo_enviar.cod_chofer = this.lstDispersionPrestamo[0].cod_chofer;
        this.prestamo_enviar.num_semana_inicio = this.lstDispersionPrestamo[0].num_semana_inicio;
        this.prestamo_enviar.imp_prestamo_total = this.lstDispersionPrestamo[0].imp_prestamo_total;
        this.prestamo_enviar.imp_abono = this.lstDispersionPrestamo[0].imp_abono;
        this.prestamo_enviar.num_semana_abono = this.lstDispersionPrestamo[0].num_semana_abono;
        this.prestamo_enviar.num_anio_abono = this.lstDispersionPrestamo[0].num_anio_abono;
        this.prestamo_enviar.clv_pagado = this.lstDispersionPrestamo[0].clv_pagado;
        this.prestamo_enviar.clv_autorizado = this.lstDispersionPrestamo[0].clv_autorizado;
        this.prestamo_enviar.clv_finalizado = 0;
        this.prestamo_enviar.clv_activo = 1;
        this.prestamo_enviar.num_anio_registro = this.lstDispersionPrestamo[0].num_anio_registro;
        this.prestamo_enviar.fecha_registro = this.lstDispersionPrestamo[0].fecha_registro;
        this.prestamo_enviar.cod_usuario_registro = this.lstDispersionPrestamo[0].cod_usuario_registro;
        this.prestamo_enviar.cod_usuario_autorizo = this.lstDispersionPrestamo[0].cod_usuario_autorizo;
      }
    },
      (error) => {
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Cargar Dispersión>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  inicializarFechas() {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.fecha_dia = dateFormat;
  }

  cargarInformacionPrestamo(id_prestamo: number) {
    if (id_prestamo != 0) {
      this.prestamoviaticosService.cargarInformacionPrestamoPagado(id_prestamo).subscribe((resp) => {
        this.lstPagadoViatico = resp;
        this.dspDialogoPrestamoEstatus = true;
      },
        (error) => {
          this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Cargar Informacion del Prestamo>', detail: 'Contacte al Administrador del Sitio' });
        });
    }
  }

  cancelarModalEstatus() {
    this.dspDialogoPrestamoEstatus = false;
    this.lstPagadoViatico = [];
  }

  cargarInformacionColaboradoresQuinceDias() {
    this.prestamoviaticosService.cargarInformacionQuinceDias().subscribe((resp) => {
      this.lstTodosColaboradores = resp;
    },
      (error) => {
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Cargar Listado Colaboradores>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  cargarInformacion(num_anio: any) {
    let anio_cast: any = 0;
    if (num_anio instanceof Date) {
      // will execute
      anio_cast = num_anio.getFullYear();
    } else {
      anio_cast = num_anio;
    }
    if (anio_cast == null || typeof anio_cast == 'undefined') {
      let mensaje = 'Seleccione un Año para mostrar Información';
      this.mostrarDialogoInformativoSinInformacion(mensaje);
    } else {
      //this.anio = num_anio;
      let cod_usuario = this.user.cod!;
      this.loading = true;
      this.prestamoviaticosService.cargarInformacion(anio_cast, cod_usuario).subscribe((resp) => {
        this.lstColaboradores = resp;
        this.loading = false;
      },
        (error) => {
          this.loading = false;
          this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Cargar Prestamos Viaticos>', detail: 'Contacte al Administrador del Sitio' });
        });
    }
  }

  consultaHistorico() {

  }

  registrarAhorro(num_opcion: number) {
    this.clv_operacion = 1;
    this.dspDialogo = true;
    this.texto_operacion = 'Registrar Préstamo';
  }

  rechazarPrestamo(data: IPrestamosViaticos) {
    let texto_desactivar_header = 'Descartar Prestamo';
    let nombre_completo = (data.nombres + ' ' + data.apellido_paterno + ' ' + data.apellido_materno);
    let texto_desactivar = 'Descartar el Prestamo de <b>' + nombre_completo + '</b> Por un Monto de <b>$' + data.imp_prestamo_total + '</b>';
    this.confirmationService.confirm({
      message: 'Esta seguro que desea ' + texto_desactivar + '?',
      header: texto_desactivar_header,
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Descartado', detail: 'Se ha Descartado el Prestamo de ' + nombre_completo });
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Rechazado', detail: 'Se ha Rechazado la operación' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Se ha Cancelado la operación' });
            break;
        }
      }
    });
  }

  autorizarPrestamo(data: IPrestamosViaticos) {
    let texto_desactivar_header = 'Autorizar Prestamo';
    let nombre_completo = (data.nombres + ' ' + data.apellido_paterno + ' ' + data.apellido_materno);
    let texto_desactivar = 'Autorizar el Prestamo de <b>' + nombre_completo + '</b> Por un Monto de <b>$' + data.imp_prestamo_total + '</b>';
    this.confirmationService.confirm({
      message: 'Esta seguro que desea ' + texto_desactivar + '?',
      header: texto_desactivar_header,
      icon: 'pi pi-check-square',
      accept: () => {
        //mandar llamar api
        this.autorizar(data);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Se ha Cancelado la operación' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Se ha Cancelado la operación' });
            break;
        }
      }
    });
  }

  autorizar(data: IPrestamosViaticos) {
    let cod_usuario = this.user.cod!;
    this.prestamoviaticosService.guardarAutorizacionPrestamo(data.id_prestamo, 1, data.clv_finalizado, cod_usuario).subscribe((resp) => {
      this.resultado_general = resp;
      this.messageService.add({ key: 'msj', severity: 'success', summary: 'Actualizado', detail: 'La información se almaceno correctamente' });
      this.cargarInformacion(this.anio);
    },
      (error) => {
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Autorizar Prestamo Viaticos>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  mostrarModalEditar(num_opcion: number, data: IPrestamosViaticos) {

  }

  confirmarEliminar(id_caja_ahorro: number, data: IPrestamosViaticos) {

  }

  cancelarModal() {
    this.dspDialogo = false;
    this.dataForm.reset();
    this.selectedColaborador = {
      cod_chofer: 0,
      fec_alta: '',
      desc_ciudad: '',
      nombre_completo: ''
    };
    this.texto_operacion = '';
    this.resultado_general = {
      clv_estatus: 0,
      messageDetails: ''
    };
    this.prestamo_enviar = {
      idx: 0,
      id_prestamo: 0,
      id_prestamo_disp: 0,
      cod_chofer: 0,
      num_semana_inicio: 0,
      imp_prestamo_total: 0,
      imp_abono: 0,
      num_semana_abono: 0,
      num_anio_abono: 0,
      clv_pagado: 0,
      clv_autorizado: 0,
      clv_finalizado: 0,
      clv_activo: 0,
      num_anio_registro: 0,
      fecha_registro: '',
      cod_usuario_registro: '',
      cod_usuario_autorizo: ''
    };
    this.lstDispersionPrestamo = [];
  }

  cancelarMensajeConfirmacion() {
    this.messageService.clear('confirm');
    this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });

  }

  confirmarMensaje() {
    this.messageService.clear('confirm');
    this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
  }

  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario;
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }

  /*getTargetDescription(data: IColaboradoresQuinceDias) {
    return this.lstCiudad.filter((x)=> x.id==data.id_ciudad_hm)[0].nombre;
  }*/
}
