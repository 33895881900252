import { Component,OnInit } from '@angular/core';
import { Router,NavigationExtras} from '@angular/router';
import { MenuItem } from 'primeng/api';
import ICredencialUsuario from '../models/credencial';
import { AuthService } from '../services/auth.service';
import { HttpService } from '../services/http.service';
import { Subscription } from 'rxjs';
import Module from '../models/Module';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent {
menuItems: MenuItem [] = [];
user: ICredencialUsuario = {};
displaySidebar: boolean = false;
userSubscriber?: Subscription;
blockedPanel:boolean=false;
constructor(private router: Router,private auth: AuthService, private http: HttpService){
  this.user.name = '';
  this.user.profession = '';
}


  /*ngOnInit(): void {
    this.mapPermissionsToMenuItems();
  }*/

  ngOnInit(): void {
    this.blockedPanel=true;
    if (this.auth.user) this.user = this.auth.user;
    if (!this.auth.user || !this.auth.user.permissions) {
      this.http.getPermissions().then((permissions: Module[]) => {
        this.auth.permissions = permissions;
        this.user = this.auth.user;
        this.mapPermissionsToMenuItems2(permissions);
      });
    }else{
      this.mapPermissionsToMenuItems2(this.auth.user.permissions);
    }
    this.userSubscriber = this.auth.$user.subscribe((user: any) => {
      this.user = user;
      if (!this.auth.user || !this.auth.user.permissions) {
        this.mapPermissionsToMenuItems2(this.auth.permissions);
      }
    });
  }

  mapPermissionsToMenuItems2(modules: Module[]){
    this.menuItems = modules.map(m =>{
      return {
        label:m.description,
        items:m.options.map(o =>{
          return {
            label:o.description,
            icon:`pi pi-fw pi-${o.icon}`,
            id:o.id,
            routerLink:o.route,
            command:()=>{
              this.handleNavigate(o.route,o.id);
            },
           // queryParams:{test:"hola"}
          } as MenuItem
        })
      } as MenuItem;
    });
  }

  mapPermissionsToMenuItems(){
    this.menuItems = [];
    //Modulo Empleados
    this.menuItems.push({
      label:"Abastecimiento",
      items:[
        /*{
          label:"Nueva Unidad",
          routerLink:"unidad",
          icon: 'pi pi-plus',
          command:()=>{
            this.handleNavigate("principal/unidad","TESTID");
          },
        },*/
        {
          label:"Listado de Unidades",
          routerLink:"listado",
          icon: 'pi pi-align-justify',
          command:()=>{
            this.handleNavigate("principal/listado","TESTID");
          }
        }
      ]
    });

    //Planeacion
    this.menuItems.push({
      label:"Planeacion",
      items:[
        {
          label:"PAO",
          routerLink:"proyeccion",
          icon: 'pi pi-calendar',
          command:()=>{
            this.handleNavigate("principal/proyeccion","TESTID");
          },
        },
        {
          label:"Operación Diaria",
          routerLink:"opediaria",
          icon: 'pi pi-calendar',
          command:()=>{
            this.handleNavigate("principal/opediaria","TESTID");
          },
        }
      ]
    });
     
    
  //Modulo Catalogos
  this.menuItems.push({
    label:"Catalogos",
    items:[
      {
        label:"Clientes",
        routerLink:"clientes",
        icon: 'pi pi-users',
        command:()=>{
          this.handleNavigate("principal/clientes","TESTID");
        },
      },
      {
        label:"Servicios",
        routerLink:"servicios",
        icon: 'pi pi-tablet',
        command:()=>{
          this.handleNavigate("principal/servicios","TESTID");
        },
      },
      {
        label:"Rutas",
        routerLink:"rutas",
        icon: 'pi pi-truck',
        command:()=>{
          this.handleNavigate("principal/rutas","TESTID");
        },
      }, 
      {
        label:"Choferes",
        routerLink:"drivers",
        icon: 'pi pi-users',
        command:()=>{
          this.handleNavigate("principal/drivers","TESTID");
        },
      }
    ]
   });

    //Modulo Mantenimiento
    /*this.menuItems.push({
      label:"Mantenimiento",
      items:[
        {
          label:"Unidad",
          routerLink:"mantenimiento",
          icon: 'pi pi-pencil',
          command:()=>{
            this.handleNavigate("principal/mantenimiento","TESTID");
          },
        }
      ]
    });*/
    
    //Modulo Movimientos
    /*this.menuItems.push({
      label:"Seguridad",
      items:[
        {
          label:"Nuevo Usuario",
          routerLink:"nuevou",
          icon: 'pi pi-plus',
          command:()=>{
            this.handleNavigate("principal/nuevou","TESTID");
          },
        },
        {
          label:"Listado de Usuarios",
          routerLink:"usuarios",
          icon: 'pi pi-sort-amount-down',
          command:()=>{
            this.handleNavigate("principal/usuarios","TESTID");
          }
        }
      ]
    });*/
    
  }

  handleNavigate(route:string,optionId: string) {
    this.tgSidebar();
    const extras: NavigationExtras = {
      state:{
        optionId
      }
    }
    this.router.navigate([route],extras);
  }

  tgSidebar(): void {
    this.displaySidebar = !this.displaySidebar;
  }

  stop(ev:any){
    ev.stopPropagation();
  }

  salir() {
    this.router.navigateByUrl("/login");
  }
}
