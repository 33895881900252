<p-toast [style]="{marginTop: '80px'}" position="top-right" key="msj"></p-toast>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="Aceptar" rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text">
</p-confirmDialog>
<div class="root-login">
    <div class="logo">
        <!--<img src="../../../assets/images/logo_centrado.png" alt="LogoHM">-->
    </div>
    <div>
        <div class="title text-white text-center text-3xl">
            <b>Sistema Gestor de Nomina<p style="color:#7db2e1 ;">(SGDN)</p>
                <div>
                    <img src="../../../assets/images/logo_centrado.png" alt="LogoHM" style="width: 400px;">
                </div>
            </b>
        </div>
        <div class="form-card shadow-6">
            <div class="formulary" [formGroup]="form" *ngIf="form">
                <div class="title t-h6 tc-b75">Autentifiquese para iniciar su sesión</div>
                <div class="p-field">
                    <span class="p-input-icon-left">
                        <i class="pi pi-user"></i>
                        <i class="pi pi-spin pi-spinner" *ngIf="loading"></i>
                        <input type="text" formControlName="UserName" pInputText placeholder="Usuario"
                            (keyup.enter)="inicioSesionUsuario()" />
                    </span>
                    <small class="p-error p-invalid"
                        [ngClass]="{'err-msg-active':form.controls['UserName'].invalid && form.controls['UserName'].touched}">
                        {{getErrors(error_email,'UserName')}}
                    </small>
                </div>
                <div class="p-field">
                    <span class="p-input-icon-left">
                        <i class="pi pi-lock"></i>
                        <input type="text" formControlName="Password" pInputText placeholder="Contraseña"
                            type="password" (keyup.enter)="inicioSesionUsuario()" />
                    </span>
                    <small class="p-error p-invalid"
                        [ngClass]="{'err-msg-active':form.controls['Password'].invalid && form.controls['Password'].touched}">
                        {{getErrors(error_contrasenia,'Password')}}
                    </small>
                </div>
                <div class="form-controls pt-2">
                    <!--<p-captcha siteKey="6LcQRGokAAAAAA1nzajfpUvNDU3w1y5T4ofzyAOi"
                        (onResponse)="onCaptchaResponse($event)" initCallback="loadCaptcha"
                        ></p-captcha>-->
                    <small class="captchaErrorMessage"
                        [ngClass]="{'err-msg-active':form.controls['Cookie'].invalid && form.controls['Cookie'].touched}">
                        {{getErrors(error_captcha,'Cookie')}}
                    </small>
                    <p-button class="p-mt-3" label="Iniciar sesión" styleClass="p-button-raised p-button-secondary"
                        [disabled]="loading" (click)="inicioSesionUsuario()"></p-button>
                </div>
            </div>
        </div>
    </div>
</div>