<p-toast position="top-right" key="msj"></p-toast>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="Aceptar" rejectLabel="Cancelar"
    rejectButtonStyleClass="p-button-text">
</p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
    rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar"
    [rejectVisible]="false"></p-confirmDialog>

<div class="d-flex justify-content-center">
    <div class="container-fluid" style="width: 98%;">
        <div class="row mt-5">
            <div class="col-md-12">
                <div class="card card-shadow sinborde">
                    <div class="card-header sinborde" style="background: none; ">
                        <div class="cardicono rounded">
                            <i class="pi pi-calendar-plus mt-3" style="font-size: 2rem; color: #fff;"></i>
                        </div>
                        <header style="left: 11%; top: 5%;">
                            <div style="display:inline">Listado de</div>
                            <div style="color:blueviolet;display:inline;" class="ml-3">Tabulado Sueldo por Ciudad</div>
                        </header>
                        <div class="row d-flex justify-content-around mt-4">
                            <div class="col-md-6">
                                <div class="row d-flex justify-content-around">
                                    <div class="col-md-5">
                                        <p-calendar [(ngModel)]="anio" view="year" [showIcon]="true" dateFormat="yy"
                                            inputId="yearpicker" [style]="{'width':'100%','height':'80%'}"
                                            pTooltip="Seleccione Año"></p-calendar>
                                    </div>
                                    <div class="col-md-3">
                                        <button pButton pRipple label="Filtrar" class="p-button-success"
                                            icon="pi pi-filter" (click)="cargarInformacionTabulado(anio);"
                                            pTooltip="Cargar Información"></button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 d-flex justify-content-end">
                                <div>
                                    <p-button label="Agregar" icon="pi pi-plus-circle" iconPos="right"
                                        (click)="mostrarModalAgregar();" pTooltip="Registrar Tabulado de Sueldo"
                                        tooltipPosition="left">
                                    </p-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-content">
                        <div class="row mt-3">
                            <!--<p-table #dt1 [value]="lstTabulado" [tableStyle]="{'min-width': '50rem'}"
                                styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true" scrollHeight="480px"
                                responsiveLayout="scroll" [globalFilterFields]="['nombre_tipo_puesto','nombre_ruta']"
                                [paginator]="true" [rows]="30" [rowsPerPageOptions]="[10,15,20,30,50]"
                                [showCurrentPageReport]="true" [resizableColumns]="false"
                                currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros"
                                [loading]="loading">
                                <ng-template pTemplate="caption">
                                    <div class="flex justify-content-end">
                                        <p-columnFilter field="id_servicio" matchMode="equals" [showMenu]="false"
                                            tooltip="Filtrar por Cliente" tooltipPosition="top">
                                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                <p-dropdown [ngModel]="value" [options]="lstServicioBrindado"
                                                    optionLabel="nom_servicio" optionValue="id_servicio"
                                                    (onChange)="filter($event.value)"
                                                    placeholder="Filtrar por ServicioBrindado" [showClear]="true">
                                                </p-dropdown>
                                            </ng-template>
                                        </p-columnFilter>
                                        <p-columnFilter field="id_cliente" matchMode="equals" [showMenu]="false"
                                            tooltip="Filtrar por Cliente" tooltipPosition="top">
                                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                <p-dropdown [ngModel]="value" [options]="lstClientes"
                                                    optionLabel="nombre" optionValue="id_cliente"
                                                    (onChange)="filter($event.value)" placeholder="Filtrar por Cliente"
                                                    [showClear]="true">
                                                </p-dropdown>
                                            </ng-template>
                                        </p-columnFilter>
                                        <p-columnFilter field="id_ciudad_HM" matchMode="equals" [showMenu]="false"
                                            tooltip="Filtrar por Ciudad" tooltipPosition="top">
                                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                <p-dropdown [ngModel]="value" [options]="lstCiudad" optionLabel="nombre"
                                                    optionValue="id" (onChange)="filter($event.value)"
                                                    placeholder="Filtrar por Ciudad" [showClear]="true">
                                                </p-dropdown>
                                            </ng-template>
                                        </p-columnFilter>
                                        <span class="p-input-icon-left p-ml-auto">
                                            <i class="pi pi-search"></i>
                                            <input pInputText type="text" size="40"
                                                (input)="applyFilterGlobal($event, 'contains')"
                                                placeholder="Buscar Puesto,Nombre de Ruta" />
                                        </span>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="idx" class="text-center">#<p-sortIcon
                                                field="idx"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="nombre_servicio">Servicio<p-sortIcon
                                                field="nombre_servicio"></p-sortIcon></th>
                                        <th pSortableColumn="nombre_ruta">Ruta<p-sortIcon
                                                field="nombre_ruta"></p-sortIcon></th>
                                        <th pSortableColumn="nombre_tipo_puesto">Puesto<p-sortIcon
                                                field="nombre_tipo_puesto"></p-sortIcon></th>
                                        <th pSortableColumn="nom_cliente">Cliente<p-sortIcon
                                                field="nom_cliente"></p-sortIcon></th>
                                        <th pSortableColumn="nombre_proyecto">Proyecto<p-sortIcon
                                                field="nombre_proyecto"></p-sortIcon></th>
                                        <th pSortableColumn="nombre_configuracionunidad">Unidad<p-sortIcon
                                                field="nombre_configuracionunidad"></p-sortIcon></th>
                                        <th class="text-center texto_principal">Sueldo</th>
                                        <th pSortableColumn="nombre_ciudad_HM">Ciudad<p-sortIcon
                                                field="nombre_ciudad_HM"></p-sortIcon></th>
                                        <th pSortableColumn="num_anio" class="text-center">Año<p-sortIcon
                                                field="num_anio"></p-sortIcon>
                                        </th>
                                        <th>Estatus</th>
                                        <th></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-data let-index>
                                    <tr>
                                        <td>
                                            <p class="font-weight-bold text-center">{{data.idx}}</p>
                                        </td>
                                        <td class="texto_azul" [ngClass]="{'color-operacion': data.id_servicio==8}">
                                            {{data.nombre_servicio }}
                                        </td>
                                        <td class="texto_sm text-justify">
                                            {{data.nombre_ruta}}
                                        </td>
                                        <td class="texto_resaltado"> {{data.nombre_tipo_puesto}}</td>
                                        <td>
                                            {{data.nom_cliente}}
                                        </td>
                                        <td>
                                            {{ data.nombre_proyecto}}
                                        </td>
                                        <td>
                                            {{data.nombre_configuracionunidad}}
                                        </td>
                                        <td class="text-center texto_principal">{{data.imp_sueldo | currency }}</td>
                                        <td>
                                            {{data.nombre_ciudad_HM}}
                                        </td>
                                        <td class="texto_principal">
                                            {{data.num_anio}}
                                        </td>
                                        <td><p-tag *ngIf="data.clv_activo==1" severity="success" value="Activo"></p-tag>
                                            <p-tag *ngIf="data.clv_activo==0" [style]="{ 'background': '#D9D9D9'}"
                                                value="Inactivo"></p-tag>
                                        </td>
                                        <td>
                                            <div class="d-flex justify-content-around">
                                                <button class="btn btn-warning" pTooltip="Editar Informacion de Salario"
                                                    tooltipPosition="left" (click)="mostrarModalEditar(data);">
                                                    <i class="pi pi-pencil"></i>
                                                </button>
                                                <button class="btn btn-info" pTooltip="Estatus de Salario"
                                                    tooltipPosition="left"
                                                    (click)="confirmarEliminar(data.id_anio,data);">
                                                    <i class="pi pi-delete-left"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>-->
                            <p-table #dt1 [value]="lstTabuladoN" [tableStyle]="{'min-width': '50rem'}"
                                styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true" scrollHeight="480px"
                                responsiveLayout="scroll" [globalFilterFields]="['desc_ruta','clave_ruta','id_ruta']"
                                [paginator]="true" [rows]="30" [rowsPerPageOptions]="[10,15,20,30,50]"
                                [showCurrentPageReport]="true" [resizableColumns]="false"
                                currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros"
                                [loading]="loading">
                                <ng-template pTemplate="caption">
                                    <div class="flex justify-content-end">
                                        <p-columnFilter field="id_servicio" matchMode="equals" [showMenu]="false"
                                            tooltip="Filtrar por Cliente" tooltipPosition="top">
                                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                <p-dropdown [ngModel]="value" [options]="lstServicioBrindado"
                                                    optionLabel="nom_servicio" optionValue="id_servicio"
                                                    (onChange)="filter($event.value)"
                                                    placeholder="Filtrar por ServicioBrindado" [showClear]="true">
                                                </p-dropdown>
                                            </ng-template>
                                        </p-columnFilter>
                                        <!--<p-columnFilter field="id_cliente" matchMode="equals" [showMenu]="false"
                                            tooltip="Filtrar por Cliente" tooltipPosition="top">
                                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                <p-dropdown [ngModel]="value" [options]="lstClientes"
                                                    optionLabel="nombre" optionValue="id_cliente"
                                                    (onChange)="filter($event.value)" placeholder="Filtrar por Cliente"
                                                    [showClear]="true">
                                                </p-dropdown>
                                            </ng-template>
                                        </p-columnFilter>-->
                                        <p-columnFilter field="id_ciudad_HM" matchMode="equals" [showMenu]="false"
                                            tooltip="Filtrar por Ciudad" tooltipPosition="top">
                                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                <p-dropdown [ngModel]="value" [options]="lstCiudad" optionLabel="nombre"
                                                    optionValue="id" (onChange)="filter($event.value)"
                                                    placeholder="Filtrar por Ciudad" [showClear]="true">
                                                </p-dropdown>
                                            </ng-template>
                                        </p-columnFilter>
                                        <span class="p-input-icon-left p-ml-auto">
                                            <i class="pi pi-search"></i>
                                            <input pInputText type="text" size="40"
                                                (input)="applyFilterGlobal($event, 'contains')"
                                                placeholder="Buscar Clave,Nombre de Ruta" />
                                        </span>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="idx" class="text-center">#<p-sortIcon
                                                field="idx"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="desc_servicio">Servicio<p-sortIcon
                                                field="desc_servicio"></p-sortIcon></th>
                                        <th pSortableColumn="desc_ruta">Ruta<p-sortIcon
                                                field="desc_ruta"></p-sortIcon></th>
                                        <th>SueldoDriver</th>
                                        <th >SueldoAux</th>
                                        <th>AlimentoDriver</th>
                                        <th >AlimentoAux</th>
                                        <th class="text-center texto_principal">Info(?)</th>
                                        <th pSortableColumn="desc_ciudad_HM">Ciudad<p-sortIcon
                                                field="desc_ciudad_HM"></p-sortIcon></th>
                                        <th pSortableColumn="num_anio" class="text-center">Año<p-sortIcon
                                                field="num_anio"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="clv_activo">Estatus<p-sortIcon
                                            field="clv_activo"></p-sortIcon></th>
                                        <th></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-data let-index>
                                    <tr>
                                        <td>
                                            <p class="font-weight-bold text-center">{{data.idx}}</p>
                                        </td>
                                        <td class="texto_azul" [ngClass]="{'texto_principal': data.id_servicio==8,
                                        'texto_azul': data.id_servicio==1,'texto_resaltado': data.id_servicio==2,
                                        'color-operacion': data.id_servicio==3,'texto_tinto': data.id_servicio==4}">
                                            {{data.desc_servicio }}
                                        </td>
                                        <td class="texto_sm text-justify texto_resaltado">
                                            <!--<p *ngIf="data.id_servicio==8" style="display:inline;">({{data.id_ruta}})</p>-->
                                            {{data.desc_ruta}}
                                        </td>
                                        <td>
                                            <p *ngIf="data.num_dias_mas<=1" class="my-0">{{data.imp_sueldo_diver |
                                                currency}}</p>
                                            <p *ngIf="data.num_dias_mas>1" class="my-0">{{data.imp_ext_est_sueldo_diver
                                                | currency}}</p>

                                        </td>
                                        <td>
                                            <p *ngIf="data.num_dias_mas<=1" class="my-0">{{data.imp_sueldo_auxiliar |
                                                currency}}</p>
                                            <p *ngIf="data.num_dias_mas>1" class="my-0">
                                                {{data.imp_ext_est_sueldo_auxiliar | currency}}</p>
                                        </td>
                                        <td>
                                            <p *ngIf="data.id_servicio==8 && data.num_dias_mas<=1" class="my-0">{{
                                                data.imp_alimento_diver |
                                                currency}}</p>
                                            <p *ngIf="data.id_servicio==8 && data.num_dias_mas>1" class="my-0">{{
                                                data.imp_ext_est_alimento_diver |
                                                currency}}</p>
                                        </td>
                                        <td>
                                            <p *ngIf="data.id_servicio==8 && data.num_dias_mas<=1" class="my-0">{{
                                                data.imp_alimento_auxiliar |
                                                currency}}</p>
                                            <p *ngIf="data.id_servicio==8 && data.num_dias_mas>1" class="my-0">{{
                                                data.imp_ext_est_alimento_auxiliar |
                                                currency}}</p>
                                        </td>
                                        <td class="text-center texto_principal">
                                            <div *ngIf="data.id_servicio==8 && data.num_dias_mas>1">
                                                <button class="btn btn-warning" pTooltip="Extensión de Ruta"
                                                    tooltipPosition="left" (click)="mostrarModalExtension(data);">
                                                    <i class="pi pi-info-circle"></i>
                                                </button>
                                            </div>
                                            <div
                                                *ngIf="data.id_servicio==1 || data.id_servicio==2 || data.id_servicio==3 ||data.id_servicio==4">
                                                {{data.imp_no_ordinario | currency}}
                                            </div>
                                        </td>
                                        <td>
                                            {{data.desc_ciudad_HM}}
                                        </td>
                                        <td class="texto_principal">
                                            {{data.num_anio}}
                                        </td>
                                        <td><p-tag *ngIf="data.clv_activo==1" severity="success" value="Activo"></p-tag>
                                            <p-tag *ngIf="data.clv_activo==0" [style]="{ 'background': '#D9D9D9'}"
                                                value="Inactivo"></p-tag>
                                        </td>
                                        <td>
                                            <div class="d-flex justify-content-around">
                                                <button class="btn btn-warning"
                                                    pTooltip="Editar Informacion de Tabulador" tooltipPosition="left"
                                                    (click)="mostrarModalEditarReestructura(data);">
                                                    <i class="pi pi-pencil"></i>
                                                </button>
                                                <button class="btn btn-info" pTooltip="Estatus de Tabulador"
                                                    tooltipPosition="left"
                                                    (click)="confirmarEliminar(data.id_anio,data);">
                                                    <i class="pi pi-delete-left"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--AGREGAR / EDITAR TABULADOR DE SUELDO-->
        <!--<p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspDialogo"
            [style]="{width: '75vw','height':'680px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
            (onHide)="cancelarModal()">
            <ng-template pTemplate="header">
                {{ texto_operacion }}
                <div *ngIf="" style="color:black;">
                    {{nombre_registro }}
                </div>
            </ng-template>
            <ng-template pTemplate="content">
                <div class="mt-1">
                    <form [formGroup]="dataForm" (ngSubmit)="guardarInformacion()">
                        <span class="title-light mt-3">Información de Tabulador <i class="pi pi-cog"
                                style="size: 2rem;"></i>
                        </span>
                        <div class="row mt-1">
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-1 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-bars color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                        <p-dropdown [options]="lstServicioBrindado" optionLabel="nom_servicio"
                                            autoWidth="false" [style]="{'width':'100%'}" [showClear]="true"
                                            formControlName="id_servicio" optionValue="id_servicio"
                                            placeholder="Seleccione Servicio" tooltip="Servicio Brindado"></p-dropdown>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-1 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-bars color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                        <p-button icon="pi pi-search" 
                                        label="Buscar Ruta" styleClass="p-button-raised p-button-success p-button-text"
                                        (click)="mostrarDiaologoRutas()" 
                                        pTooltip="Mostrar catalogo de Rutas"></p-button>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-12 color-operacion d-flex align-items-center">
                                        <p>RUTA: {{ desc_ruta }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-1 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-bars color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                        <p-dropdown [options]="lstProyectos" optionLabel="nombre" autoWidth="false"
                                            [style]="{'width':'100%'}" [showClear]="true" formControlName="id_proyecto"
                                            optionValue="id_proyecto" placeholder="Seleccione Proyecto*"
                                            tooltip="Proyecto"></p-dropdown>
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-1 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-bars color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                        <p-dropdown [options]="lstClientes" optionLabel="nombre" autoWidth="false"
                                            [style]="{'width':'100%'}" [showClear]="true" formControlName="id_cliente"
                                            optionValue="id_cliente" placeholder="Seleccione Cliente*"
                                            tooltip="Cliente"></p-dropdown>
                                        <hr class="my-0 mt-1">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-1 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-bars color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                        <p-calendar formControlName="num_anio" view="year" [showIcon]="true"
                                            dateFormat="yy" inputId="yearpicker"
                                            [style]="{'width':'100%','height':'80%'}"></p-calendar>
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-1 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                        <input type="number" class="form-control sinborde" pTooltip="Importe de Salario"
                                            formControlName="imp_sueldo">
                                        <hr class="my-0 mt-1">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-1 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-bars color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                        <p-dropdown [options]="lstPuestosChofer" optionLabel="nombre" autoWidth="false"
                                            [style]="{'width':'100%'}" [showClear]="true"
                                            formControlName="id_tipo_puesto" optionValue="id"
                                            placeholder="Seleccione Puesto*" tooltip="Puesto"></p-dropdown>
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-1 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-bars color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                        <p-dropdown [options]="lstCiudad" optionLabel="nombre" autoWidth="false"
                                            [style]="{'width':'100%'}" [showClear]="true" formControlName="id_ciudad_HM"
                                            optionValue="id" placeholder="Seleccione Ciudad*"
                                            tooltip="Ciudad"></p-dropdown>
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-1 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-bars color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                        <p-dropdown [options]="lstConfUnidad" optionLabel="nombre" autoWidth="false"
                                            [style]="{'width':'100%'}" [showClear]="true"
                                            formControlName="clave_configuracionUnidad"
                                            optionValue="clave_configuracionUnidad"
                                            placeholder="Seleccione Conf.Unidad*" tooltip="Conf.Unidad"></p-dropdown>
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-1 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-comment color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                        <textarea rows="3" cols="50" pInputTextarea formControlName="observacion"
                                            pTooltip="Ingrese Alguna Observación sobre el Tabulado"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-center mt-1">
                            <div class="col-2">
                                <button type="submit" class="btn btn-success w-100" [disabled]="!dataForm.valid">
                                    <span class="text-white">Guardar</span>
                                    <i class="pi pi-save text-white"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                    (click)="cancelarModal();"></button>
            </ng-template>
        </p-dialog>-->
        <!--REESTRUCTURA DE TABULADOR-->
        <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspDialogo"
            [style]="{width: '75vw','height':'680px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
            (onHide)="cancelarModal()">
            <ng-template pTemplate="header">
                {{ texto_operacion }}
                <div *ngIf="" style="color:black;">
                    {{nombre_registro }}
                </div>
            </ng-template>
            <ng-template pTemplate="content">
                <div class="mt1">
                    <form [formGroup]="gestionForm" (ngSubmit)="gestionPantallas()">
                        <div class="row">
                            <div class="col-md-12">
                                <span class="title-light mt-3">Tipo de Servicio - Imputable a Ruta<i class="pi pi-cog"
                                        style="size: 2rem;"></i>
                                </span>
                            </div>
                        </div>
                        <!--Servicio Principal-->
                        <div class="row">
                            <div class="row form-group col-md-5">
                                <label for="lblligero" class="col-sm-2 col-form-label color-font text-right">
                                    <span class="input-group-addon"> <i class="pi pi-bars color-icon mr-3"
                                            style="font-size: 2rem; color: #3B82F6;"></i>
                                    </span>
                                </label>
                                <div class="col-sm-10">
                                    <p-dropdown [options]="lstServicioBrindado_form" optionLabel="nom_servicio"
                                        autoWidth="false" [style]="{'width':'100%'}" [showClear]="true"
                                        formControlName="id_servicio" optionValue="id_servicio"
                                        placeholder="Seleccione Servicio" tooltip="Servicio Brindado"></p-dropdown>
                                    <hr class="my-0">
                                </div>
                            </div>
                            <div class="form-group col-md-2">
                                <div class="row g-0">
                                    <div class="col-sm-12 w-100">
                                        <p-button label="Aceptar" icon="pi pi-check-square"
                                            [disabled]="!gestionForm.valid" type="submit"></p-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="mt-2">
                    <div class="row" *ngIf="clv_ope">
                        <form [formGroup]="operacionForm" (ngSubmit)="guardarInformacion()">
                            <span class="title-light mt-3">Información de Tabulador <i class="pi pi-cog"
                                    style="size: 2rem;"></i>
                            </span>
                            <div class="row mt-2"> <!--Ruta-->
                                <div class="form-group col-md-3">
                                    <div class="row">
                                        <div class="col-sm-2 my-0">
                                            <span class="input-group-addon"> <i class="pi pi-bars color-icon"
                                                    style="font-size: 1.5rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-10">
                                            <p-button icon="pi pi-search" label="Buscar Ruta"
                                                styleClass="p-button-raised p-button-success p-button-text w-100"
                                                (click)="mostrarDiaologoRutas()"
                                                pTooltip="Mostrar catalogo de Rutas"
                                                [disabled]="editarRuta"></p-button>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-9">
                                    <div class="row g-0">
                                        <div class="col-sm-12 color-operacion d-flex align-items-center">
                                            <p>RUTA: {{ desc_ruta }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2"><!--partes de Ruta-->
                                <div class="form-group col-md-3">
                                    <div class="row g-0">
                                        <div class="col-sm-2 d-flex align-items-center">
                                            <span class="input-group-addon"> <i class="pi pi-bars color-icon"
                                                    style="font-size: 1.5rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-10">
                                            <p-dropdown [options]="lstProyectos" optionLabel="nombre" autoWidth="false"
                                                [style]="{'width':'100%'}" [showClear]="true"
                                                formControlName="id_proyecto" optionValue="id_proyecto"
                                                placeholder="Seleccione Proyecto*" tooltip="Proyecto"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <div class="row g-0">
                                        <div class="col-sm-1 d-flex align-items-center">
                                            <span class="input-group-addon"> <i class="pi pi-bars color-icon"
                                                    style="font-size: 1.5rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11">
                                            <p-dropdown [options]="lstClientes" optionLabel="nombre" autoWidth="false"
                                                [style]="{'width':'100%'}" [showClear]="true"
                                                formControlName="id_cliente" optionValue="id_cliente"
                                                placeholder="Seleccione Cliente*" tooltip="Cliente"></p-dropdown>
                                            <hr class="my-0 mt-1">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <div class="row g-0">
                                        <div class="col-sm-2 d-flex align-items-center">
                                            <span class="input-group-addon"> <i class="pi pi-bars color-icon"
                                                    style="font-size: 1.5rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-10">
                                            <p-dropdown [options]="lstConfUnidad" optionLabel="nombre" autoWidth="false"
                                                [style]="{'width':'100%'}" [showClear]="true"
                                                formControlName="clave_configuracionUnidad"
                                                optionValue="clave_configuracionUnidad"
                                                placeholder="Seleccione Conf.Unidad*"
                                                tooltip="Conf.Unidad"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <div class="row g-0">
                                        <div class="col-sm-2 d-flex align-items-center">
                                            <span class="input-group-addon"> <i class="pi pi-bars color-icon"
                                                    style="font-size: 1.5rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-10">
                                            <p-dropdown [options]="lstCiudad" optionLabel="nombre" autoWidth="false"
                                                [style]="{'width':'100%'}" [showClear]="true"
                                                formControlName="id_ciudad_HM" optionValue="id"
                                                placeholder="Seleccione Ciudad*" tooltip="Ciudad"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2"><!--Numero Dias-->
                                <div class="form-group col-md-3">
                                    <div class="row g-0">
                                        <div class="col-sm-6 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-hashtag color-icon"
                                                    style="font-size: 1.5rem"></i>
                                            </span>
                                            <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;font-size: 11px;">Numero
                                                Dias</p>
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="number" class="form-control" placeholder="Dias"
                                                formControlName="num_dias_mas" min="0">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-1"><!--Entregas,Maniobras y Hospedaje-->
                                <div class="form-group col-md-3">
                                    <div class="row g-0">
                                        <div class="col-sm-6 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                                                    style="font-size: 1.5rem"></i>
                                            </span>
                                            <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;font-size: 11px;">
                                                Entregas</p>
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="number" class="form-control sinborde" pTooltip="Entregas"
                                                formControlName="imp_apoyo_entregas" min="0">
                                            <hr class="my-0 mt-1">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <div class="row g-0">
                                        <div class="col-sm-6 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                                                    style="font-size: 1.5rem"></i>
                                            </span>
                                            <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;font-size: 11px;">
                                                Maniobras</p>
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="number" class="form-control sinborde" pTooltip="Maniobras"
                                                formControlName="imp_apoyo_maniobra" min="0">
                                            <hr class="my-0 mt-1">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <div class="row g-0">
                                        <div class="col-sm-6 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-hashtag color-hospedaje"
                                                    style="font-size: 1.5rem"></i>
                                            </span>
                                            <p class="color-hospedaje mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;font-size: 11px;">Dias Hospedaje</p>
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="number" class="form-control" placeholder="# Dias"
                                                formControlName="num_dias_hospedaje" min="0">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <div class="row g-0">
                                        <div class="col-sm-6 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-dollar color-hospedaje"
                                                    style="font-size: 1.5rem"></i>
                                            </span>
                                            <p class="color-hospedaje mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;font-size: 11px;">
                                                Hospedaje</p>
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="number" class="form-control sinborde"
                                                pTooltip="Importe de Hospedaje" formControlName="imp_hospedaje" min="0">
                                            <hr class="my-0 mt-1">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-1"><!--RUTA NORMAL-->
                                <span class="texto-lila mt-3">Ruta Normal
                                </span>
                                <div class="form-group col-md-3">
                                    <div class="row g-0">
                                        <div class="col-sm-6 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                                                    style="font-size: 1.5rem"></i>
                                            </span>
                                            <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;font-size: 11px;">
                                                SueldoDriver</p>
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="number" class="form-control sinborde" pTooltip="Sueldo Driver"
                                                formControlName="imp_sueldo_diver" min="0">
                                            <hr class="my-0 mt-1">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <div class="row g-0">
                                        <div class="col-sm-6 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                                                    style="font-size: 1.5rem"></i>
                                            </span>
                                            <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;font-size: 11px;">
                                                SueldoAuxiliar</p>
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="number" class="form-control sinborde" pTooltip="Sueldo Aux"
                                                formControlName="imp_sueldo_auxiliar" min="0">
                                            <hr class="my-0 mt-1">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <div class="row g-0">
                                        <div class="col-sm-6 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                                                    style="font-size: 1.5rem"></i>
                                            </span>
                                            <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold; font-size: 11px;">
                                                Alimento Driver</p>
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="number" class="form-control sinborde"
                                                pTooltip="Alimento Driver" formControlName="imp_alimento_diver">
                                            <hr class="my-0 mt-1" min="0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <div class="row g-0">
                                        <div class="col-sm-6 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                                                    style="font-size: 1.5rem"></i>
                                            </span>
                                            <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;  font-size: 11px;">
                                                Alimento Aux</p>
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="number" class="form-control sinborde" pTooltip="Alimento Aux"
                                                formControlName="imp_alimento_auxiliar" min="0">
                                            <hr class="my-0 mt-1">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-1"><!--RUTA LARGA 12HRS-->
                                <span class="texto-lila mt-3">Ruta Larga(+12hrs)
                                </span>
                                <div class="form-group col-md-3">
                                    <div class="row g-0">
                                        <div class="col-sm-6 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                                                    style="font-size: 1.5rem"></i>
                                            </span>
                                            <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;font-size: 11px;">
                                                SueldoDriver</p>
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="number" class="form-control sinborde" pTooltip="Sueldo Driver"
                                                formControlName="imp_ext_est_sueldo_diver" min="0">
                                            <hr class="my-0 mt-1">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <div class="row g-0">
                                        <div class="col-sm-6 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                                                    style="font-size: 1.5rem"></i>
                                            </span>
                                            <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;font-size: 11px;">
                                                SueldoAuxiliar</p>
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="number" class="form-control sinborde" pTooltip="Sueldo Aux"
                                                formControlName="imp_ext_est_sueldo_auxiliar" min="0">
                                            <hr class="my-0 mt-1">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <div class="row g-0">
                                        <div class="col-sm-6 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                                                    style="font-size: 1.5rem"></i>
                                            </span>
                                            <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold; font-size: 11px;">
                                                Alimento Driver</p>
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="number" class="form-control sinborde"
                                                pTooltip="Alimento Driver" formControlName="imp_ext_est_alimento_diver">
                                            <hr class="my-0 mt-1" min="0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <div class="row g-0">
                                        <div class="col-sm-6 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                                                    style="font-size: 1.5rem"></i>
                                            </span>
                                            <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;  font-size: 11px;">
                                                Alimento Aux</p>
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="number" class="form-control sinborde" pTooltip="Alimento Aux"
                                                formControlName="imp_ext_est_alimento_auxiliar" min="0">
                                            <hr class="my-0 mt-1">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr class="style-two">
                            <div class="row mt-2" ><!--AÑO, ESTATUS,OBSERVACIÓN-->
                                <div class="form-group col-md-3">
                                    <div class="row g-0">
                                        <div class="col-sm-4 mx-0">
                                            <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;font-size: 11px;">Año
                                            </p>
                                        </div>
                                        <div class="col-sm-8">
                                            <p-calendar formControlName="num_anio" view="year" [showIcon]="true"
                                                dateFormat="yy" inputId="yearpicker"
                                                [style]="{'width':'100%','height':'80%'}"></p-calendar>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <div class="row g-0">
                                        <div class="col-sm-4 mx-0">
                                            <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;font-size: 11px;">
                                                Estatus</p>
                                        </div>
                                        <div class="col-sm-8">
                                            <p-dropdown [options]="lstEstatus" optionLabel="nombre" autoWidth="false"
                                                [style]="{'width':'100%'}" [showClear]="true"
                                                formControlName="clv_activo" optionValue="id"
                                                placeholder="Estatus Tabulador*"></p-dropdown>
                                            <hr class="my-0 mt-1">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-comment color-icon"
                                                    style="font-size: 1.5rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11">
                                            <textarea rows="3" cols="50" pInputTextarea formControlName="observacion"
                                                pTooltip="Ingrese Alguna Observación sobre el Tabulado"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-center mt-1">
                                <div class="col-2">
                                    <button type="submit" class="btn btn-success w-100"
                                        [disabled]="!operacionForm.valid || btnHabilitar">
                                        <span class="text-white">Guardar</span>
                                        <i class="pi pi-save text-white"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="row" *ngIf="clv_noordinario">
                        <form [formGroup]="noOrdinarioForm" (ngSubmit)="guardarInformacionNoOrdinarios()">
                            <span class="title-light mt-3">Información de Tabulador - No Ordinarios <i class="pi pi-cog"
                                    style="size: 2rem;"></i>
                            </span>
                            <div class="row mt-1"><!--No Ordinario-->
                                <div class="form-group col-md-4">
                                    <div class="row g-0">
                                        <div class="col-sm-3 d-flex align-items-center">
                                            <span class="input-group-addon"> <i class="pi pi-bars color-icon"
                                                    style="font-size: 1.5rem"></i>
                                            </span>
                                            <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;font-size: 11px;">Ciudad
                                            </p>
                                        </div>
                                        <div class="col-sm-9">
                                            <p-dropdown [options]="lstCiudad" optionLabel="nombre" autoWidth="false"
                                                [style]="{'width':'100%'}" [showClear]="true"
                                                formControlName="id_ciudad_HM" optionValue="id"
                                                placeholder="Seleccione Ciudad*" tooltip="Ciudad"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-4">
                                    <div class="row g-0">
                                        <div class="col-sm-3 d-flex align-items-center">
                                            <span class="input-group-addon"> <i class="pi pi-bars color-icon"
                                                    style="font-size: 1.5rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-9">
                                            <p-dropdown [options]="lstConfUnidad" optionLabel="nombre" autoWidth="false"
                                                [style]="{'width':'100%'}" [showClear]="true"
                                                formControlName="clave_configuracionUnidad"
                                                optionValue="clave_configuracionUnidad"
                                                placeholder="Seleccione Conf.Unidad*"
                                                tooltip="Conf.Unidad"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <div class="row g-0">
                                        <div class="col-sm-3 mx-0">
                                            <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;font-size: 11px;">Año
                                            </p>
                                        </div>
                                        <div class="col-sm-9">
                                            <p-calendar formControlName="num_anio" view="year" [showIcon]="true"
                                                dateFormat="yy" inputId="yearpicker"
                                                [style]="{'width':'100%','height':'80%'}"></p-calendar>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3"><!--AÑO, ESTATUS-->
                                <div class="form-group col-md-4">
                                    <div class="row g-0">
                                        <div class="col-sm-3 d-flex align-items-center">
                                            <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                                                    style="font-size: 1.5rem"></i>
                                            </span>
                                            <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold; font-size: 11px;">
                                                Sueldo Driver</p>
                                        </div>
                                        <div class="col-sm-9">
                                            <input type="number" class="form-control sinborde"
                                                pTooltip="Sueldo Driver" formControlName="imp_sueldo_diver">
                                            <hr class="my-0 mt-1" min="0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-4">
                                    <div class="row g-0">
                                        <div class="col-sm-3 d-flex align-items-center">
                                            <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                                                    style="font-size: 1.5rem"></i>
                                            </span>
                                            <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold; font-size: 11px;">
                                                Sueldo Auxiliar</p>
                                        </div>
                                        <div class="col-sm-9">
                                            <input type="number" class="form-control sinborde"
                                                pTooltip="Sueldo Auxiliar" formControlName="imp_sueldo_auxiliar">
                                            <hr class="my-0 mt-1" min="0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-4">
                                    <div class="row g-0">
                                        <div class="col-sm-3 d-flex align-items-center">
                                            <span class="input-group-addon"> <i class="pi pi-bars color-icon"
                                                    style="font-size: 1.5rem"></i>
                                            </span>
                                            <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;font-size: 11px;">Estatus
                                            </p>
                                        </div>
                                        <div class="col-sm-9">
                                            <p-dropdown [options]="lstEstatus" optionLabel="nombre" autoWidth="false"
                                                [style]="{'width':'100%'}" [showClear]="true"
                                                formControlName="clv_activo" optionValue="id"
                                                placeholder="Estatus Tabulador*"
                                                tooltip="Estatus"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2"><!--OBSERVACION-->
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-comment color-icon"
                                                    style="font-size: 1.5rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11">
                                            <textarea rows="3" cols="50" pInputTextarea formControlName="observacion"
                                                pTooltip="Ingrese Alguna Observación sobre el Tabulado"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-center mt-1">
                                <div class="col-2">
                                    <button type="submit" class="btn btn-success w-100"
                                        [disabled]="!noOrdinarioForm.valid">
                                        <span class="text-white">Guardar</span>
                                        <i class="pi pi-save text-white"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                    (click)="cancelarModal();"></button>
            </ng-template>
        </p-dialog>
        <!--CATALOGO DE RUTAS-->
        <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dsbRutas"
            [style]="{width: '55vw','height':'550px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
            (onHide)="cancelarDialogoRutas()">
            <ng-template pTemplate="header">
                Catalogo de Rutas a Tabular
            </ng-template>
            <ng-template pTemplate="content">
                <div class="mt-1">
                    <p-table #dt2 dataKey="code" [value]="lstRutas" [tableStyle]="{ 'min-width': '30rem' }"
                        styleClass="p-datatable-sm" [scrollable]="true" scrollHeight="280px"
                        [globalFilterFields]="['clave_ruta','desc_ruta','desc_ciudad','desc_cliente']">
                        <ng-template pTemplate="caption">
                            <div class="flex justify-content-end">
                                <span class="p-input-icon-left p-ml-auto">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text" size="60"
                                        (input)="applyFilterGlobal2($event, 'contains')"
                                        placeholder="Buscar Clave Ruta,Nombre Ruta,Cliente,Ciudad" />
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Clave</th>
                                <th>Ruta</th>
                                <th>Ciudad</th>
                                <th></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-data>
                            <tr>
                                <td>{{data.clave_ruta}}</td>
                                <td>{{ data.desc_ruta }}</td>
                                <td>{{ data.desc_ciudad }}</td>
                                <td><p-button label="seleccionar" icon="pi pi-check" styleClass="p-button-sm"
                                        (click)="onRowSelectRuta(data)"></p-button></td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                    (click)="cancelarDialogoRutas();"></button>
            </ng-template>
        </p-dialog>
        <!--VENTANA DE EXTENSION DE RUTA-->
        <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspDialogoExtra"
            [style]="{width: '58vw','height':'600px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
            (onHide)="cancelarMostrarExtension()">
            <ng-template pTemplate="header">
                Información del Tabulador
            </ng-template>
            <ng-template pTemplate="content">
                <div class="mt-1">
                    <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th colspan="2" class="texto_azul">Detalle de la Extensión de Ruta</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="texto_resaltado">Ruta</td>
                                <td class="text-justify" style="color: blue; font-weight: bold;">{{ data_extension.desc_ruta}}</td>
                            </tr>
                            <tr>
                                <td class="texto_resaltado">Dias de Ruta</td>
                                <td class="color-operacion">{{ data_extension.num_dias_mas | number}}</td>
                            </tr>
                            <tr>
                                <td class="texto_resaltado">Sueldo Ext.Driver</td>
                                <td>{{ data_extension.imp_ext_est_sueldo_diver | currency}}</td>
                            </tr>
                            <tr>
                                <td class="texto_resaltado">Sueldo Ext.Auxiliar</td>
                                <td>{{ data_extension.imp_ext_est_sueldo_auxiliar | currency}}</td>
                            </tr>
                            <tr>
                                <td class="texto_resaltado">Alimento Ext.Driver</td>
                                <td>{{ data_extension.imp_ext_est_alimento_diver | currency}}</td>
                            </tr>
                            <tr>
                                <td class="texto_resaltado">Alimento Ext.Auxiliar</td>
                                <td>{{ data_extension.imp_ext_est_alimento_auxiliar | currency}}</td>
                            </tr>
                            <tr>
                                <td class="texto_resaltado">Apoyo Entregas</td>
                                <td>{{ data_extension.imp_apoyo_entregas | currency}}</td>
                            </tr>
                            <tr>
                                <td class="texto_resaltado">Apoyo Maniobras</td>
                                <td>{{ data_extension.imp_apoyo_maniobra | currency}}</td>
                            </tr>
                            <tr>
                                <td class="texto_resaltado">Dias de Hospedaje</td>
                                <td class="color-operacion">{{ data_extension.num_dias_hospedaje | number}}</td>
                            </tr>
                            <tr>
                                <td class="texto_resaltado">Hospedaje</td>
                                <td>{{ data_extension.imp_hospedaje | currency}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                    (click)="cancelarMostrarExtension();"></button>
            </ng-template>
        </p-dialog>
    </div>
</div>