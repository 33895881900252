import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Ibonoaguinaldo } from '../models/bonoaguinaldo';

import { CiudadesprincipalesService } from './ciudadesprincipales.service';  // Importado
import { ICiudadPrincipalHM } from '../models/ciudadprincipal';
import { IPostPrimaVacacional } from '../models/postprimavacacional';
import { IResultadoGeneral } from '../models/resultadogeneral';
import { IPrima } from '../models/primavacacional';
import { IDeletePrimaVacacional } from '../models/deleteprimavacaciona';

@Injectable({
  providedIn: 'root'
})
export class PrimavacacionalService {
  private apiUrl: string = environment.sUrlNomina + 'PrimaVacacional';

  constructor(private http: HttpClient, private ciudadesPrincipalesService: CiudadesprincipalesService) { }  // Inyección de CiudadesprincipalesService

  getListadoPrimaVacacional(num_anio: number, id_ciudad_principal: number, id_semana: number): Observable<IPrima[]> {
    const valores = {
      params: new HttpParams()
        .set('num_anio', num_anio)
        .set('id_ciudad_principal', id_ciudad_principal)
        .set('id_semana', id_semana)
    };
    return this.http.get<[IPrima]>(this.apiUrl, valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  getListadoCiudadesPrincipales(): Observable<ICiudadPrincipalHM[]> {
    // Uso del método del servicio importado
    return this.ciudadesPrincipalesService.getListadoCiudadesPrincipales();
  }

  /*postPrimaVacacional(data: IPostPrimaVacacional): Observable<IResultadoGeneral> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let data_enviar =JSON.stringify(data);
    return this.http.post<IResultadoGeneral>(this.apiUrl +'/opcPrimaVacacional', data_enviar, { headers: headers })
      .pipe(catchError(this.handleError));
  }*/

  public postPrimaVacacional(data: IPostPrimaVacacional):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this.http.post<any>(this.apiUrl + '/opcPrimaVacacional/',body,{'headers':headers});
   }


  // deletePrima(data: IPostPrimaVacacional): Observable<IResultadoGeneral> {
  //   const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  //   let data_enviar={headers:headers, body : JSON.stringify(data)};

  //   return this.http.delete<IResultadoGeneral>(this.apiUrl +'/deleteApoyoPrimaVacacional', data_enviar)
  //     .pipe(catchError(this.handleError));
  // }
  rptPrimaVacacional(num_anio: number, id_ciudad_principal: number): Observable<any> {
    const valores = {
      params: new HttpParams()
        .set('num_anio', num_anio)
        .set('id_ciudad_principal', id_ciudad_principal)
    };
    return this.http.get<any>(this.apiUrl + '/rptAguinaldo', valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  private handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(() => new Error(errorMessage));
  }
}
