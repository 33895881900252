import { Component, OnInit, ViewChild } from '@angular/core';
import { IDiasFestivos } from '../models/diasfestivos';
import { Table } from 'primeng/table';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DiasService } from '../services/dias.service';
import { ConfirmEventType, ConfirmationService, MessageService } from 'primeng/api';
import { IErrorCode } from '../models/errorcode';

@Component({
  selector: 'app-diasfestivos',
  templateUrl: './diasfestivos.component.html',
  styleUrls: ['./diasfestivos.component.scss']
})
export class DiasfestivosComponent implements OnInit {
  @ViewChild('dt1') dt!: Table;
  dspDialogo:boolean=false;
  texto_operacion:string='';
  clv_editar:boolean=false;
  nombre_registro:string='';
  lstDiasFestivos:IDiasFestivos[]=[];
  loading:boolean=false;
  dataForm: FormGroup = new FormGroup({
    nombre: new FormControl(null, [Validators.required]),
    fecha: new FormControl('', [Validators.required]),
    idx: new FormControl()
  });
  clv_operacion:number=0;
  error_captura!: IErrorCode;
  fecha_dia:any=null;
  fecha_dia_festivo:any=null;
  constructor(private diasfestivosService: DiasService,private confirmationService: ConfirmationService, private messageService: MessageService){

  }
  ngOnInit(): void {
    this.cargarListado();
  }

  cargarListado(){
    this.loading=true;
    this.diasfestivosService.listarDiasFestivos(2023).subscribe((resp)=> {
      this.lstDiasFestivos=resp;
      setTimeout(() => {
        this.loading=false;
      },
        600);
    },
    (error)=>{
      this.loading=false;
      this.mostrarDialogoInformativoSinInformacion('<b>Error</b> al cargar Información. Contacte al Administrador.');
    });
  }

  mostrarModalAgregar(){
    this.clv_operacion=1;
    this.texto_operacion='Nuevo Registro';
    this.dspDialogo=true;
  }

  guardarInformacion(){
    let data: IDiasFestivos = {
      idx: 0,
      nombre: '',
      fecha: '',
      id_semana: 0,
      nom_semana: '',
      anio: 0,
      clv_activo: 0
    };
    if(this.clv_operacion==1){
      data.nombre=this.dataForm.get('nombre')?.value;
      data.fecha=this.dataForm.get('fecha')?.value;
      this.diasfestivosService.agregarDiaFestivo(data).subscribe((resp)=> {
        this.cancelarModal();
        this.messageService.add({ key:'msj', severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se registro de forma correcta' });
        this.cargarListado();
      },
      (error)=> {
          if(error.status==409){
            this.error_captura = error.error;
            this.mostrarDialogoInformativoSinInformacion(this.error_captura.message);
          } else {
            this.messageService.add({key:'msj', severity: 'error', summary: 'Error en <Agregar>', detail: 'Contacte al Administrador del Sitio' });
          }
      });
    } else if(this.clv_operacion==2){
      data.nombre=this.dataForm.get('nombre')?.value;
      data.fecha=this.dataForm.get('fecha')?.value;
      data.idx=this.dataForm.get('idx')?.value;
      this.diasfestivosService.editarDiaFestivo(data).subscribe((resp)=> {
        this.cancelarModal();
        this.messageService.add({ key:'msj', severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se registro de forma correcta' });
        this.cargarListado();
      },
      (error)=> {
        if(error.status==409){
          this.error_captura = error.error;
          this.mostrarDialogoInformativoSinInformacion(this.error_captura.message);
        } else {
          this.messageService.add({key:'msj', severity: 'error', summary: 'Error en <Editar>', detail: 'Contacte al Administrador del Sitio' });
        }
      });
    }

  }

  cancelarModal(){
    this.clv_operacion=0;
    this.texto_operacion='';
    this.dspDialogo=false;
    this.dataForm.reset();
    this.fecha_dia_festivo = '';
    this.fecha_dia ='';
  }

  mostrarModalEditar(data: IDiasFestivos){
    this.clv_operacion=2;
    this.texto_operacion='Editar Registro';
    this.dataForm.controls['idx'].setValue(data.idx);
    this.dataForm.controls['nombre'].setValue(data.nombre);
    this.dataForm.controls['fecha'].setValue(data.fecha.substring(0,10));
    this.fecha_dia_festivo=data.fecha.substring(0,10);
    this.fecha_dia = this.inicializarFechas();
    this.dspDialogo=true;
  }

  inicializarFechas():string{
    var getYear = new Date().toLocaleDateString('es-MX',{ year: 'numeric'});   
    var getMonth = new Date().toLocaleDateString('es-MX',{ month: '2-digit'});
    var getDay = new Date().toLocaleDateString('es-MX',{day: '2-digit'});
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    return dateFormat;
  }

  confirmarCambiarEstatus(id:number,data:IDiasFestivos){
    let clv_estatus_original: number = data.clv_activo;
    let texto_desactivar: string = data.clv_activo == 1 ? 'DESACTIVAR' : 'ACTIVAR';
    let texto_desactivar_header: string = data.clv_activo == 1 ? 'Desactivar' : 'Activar';
    this.confirmationService.confirm({
      message: 'Esta seguro que desea <b>' + texto_desactivar + '</b> el registro de Dia Festivo <b>' + data.nombre + '</b>('+data.fecha.substring(0,10)+') ?',
      header: 'Confirmación de ' + texto_desactivar_header,
      icon: 'pi pi-info-circle',
      accept: () => {
        data.clv_activo = data.clv_activo == 1 ? 0 : 1;
        this.guardarInformacionDesactivar(data);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            data.clv_activo = clv_estatus_original;
            //this.messageService.add({ key: 'msj', severity: 'error', summary: 'Rechazado', detail: 'Se ha Rechazado la operación' });
            break;
          case ConfirmEventType.CANCEL:
            data.clv_activo = clv_estatus_original;
            //this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Se ha Cancelado la operación' });
            break;
        }
      }
    });
  }

  guardarInformacionDesactivar(data:IDiasFestivos){
    this.diasfestivosService.estatusDiaFestivo(data).subscribe((resp)=> {
      this.cargarListado();
      this.messageService.add({ key:'msj', severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se registro de forma correcta' });
    },
    (error)=> {
      this.messageService.add({key:'msj', severity: 'error', summary: 'Error en <Editar>', detail: 'Contacte al Administrador del Sitio' });
    });
  }

  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario;
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }
}
