import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CiudadesprincipalesService } from './ciudadesprincipales.service';
import { IRecibopdf } from '../models/reciboPdf';

@Injectable({
  providedIn: 'root'
})
export class RecibodigitalService {
  private apiUrl_Recibo: string ='';
  private apiUrl_activities: string ='';
  private apiUrl: string = environment.sUrlNomina + 'ReciboDigital';
  constructor(private http: HttpClient, private ciudadesPrincipalesService: CiudadesprincipalesService) {
    this.apiUrl_Recibo = environment.sUrlRecibo+ 'nomina';
    this.apiUrl_activities = environment.sUrlRecibo + 'activities';
  }  // Inyección de CiudadesprincipalesService

  getListadodeducciones(num_anio: number, id_ciudad_hm: number, id_semana: number): Observable<IRecibopdf[]> {
    const valores = {
      params: new HttpParams()
        .set('num_anio', num_anio)
        .set('id_ciudad_hm', id_ciudad_hm)
        .set('id_semana', id_semana)

    };
    return this.http.get<[IRecibopdf]>(this.apiUrl, valores)
      .pipe(retry(1), catchError(this.handleError));
  }


  getRecibo(num_anio: number, id_semana: number, cod_chofer:number): Observable<Blob> {
    const params={
      anio:num_anio,
      week:id_semana,
      employee:cod_chofer
    }

    return this.http.get(this.apiUrl_Recibo, {
      responseType: 'blob',
      params
    }).pipe(
      catchError(this.handleError)
    );
  }
  getactivities(num_anio: number, id_semana: number, cod_chofer:number): Observable<Blob> {
    const params={
      anio:num_anio,
      week:id_semana,
      employee:cod_chofer
    }

    return this.http.get(this.apiUrl_activities, {
      responseType: 'blob',
      params
    }).pipe(
      catchError(this.handleError)
    );
  }

  // getListadoCiudadesPrincipales(): Observable<ICiudadPrincipalHM[]> {
  //   // Uso del método del servicio importado
  //   return this.ciudadesPrincipalesService.getListadoCiudadesPrincipales();
  // }



  // rptInfonavit(num_anio_rpt: number, id_semana_rpt: number,id_ciudad_hm_rpt: number): Observable<any> {
  //   const valores = {
  //     params: new HttpParams()
  //       .set('num_anio', num_anio_rpt)
  //       .set('id_semana', id_semana_rpt)
  //       .set('id_ciudad_hm', id_ciudad_hm_rpt)
  //   };
  //   return this.http.get<any>(this.apiUrl + '/rptInfonavit', valores)
  //     .pipe(retry(1), catchError(this.handleError));
  // }


  private handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(() => new Error(errorMessage));
  }
}
