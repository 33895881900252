import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IpostEstatusForaneo } from '../models/postEstatusForaneo';
import { IResultadoGeneral } from '../models/resultadogeneral';
import { IdeleteEstatusForaneo } from '../models/deleteEstatusForaneo';
import { InombreCompletoChofer } from '../models/nombreCompletoChofer';
import { IEstatusForaneo } from '../models/estatusforaneo';

@Injectable({
  providedIn: 'root'
})
export class ApoyoestatusforaneoService {

  private apiUrl: string = environment.sUrlNomina + 'ApoyoEstatusForaneo';


  constructor(private http: HttpClient) { }

 getListadoEstatusForaneo(fec_inicio: string, fecha_fin_filtro: string,id_ciudad_hm:number ,cod_usuario : string): Observable<IEstatusForaneo[]> {
  const valores = {
    params: new HttpParams().
       set('fec_inicio',fec_inicio).
       set('fec_fin',fecha_fin_filtro).
       set('id_ciudad_hm',id_ciudad_hm).
       set('cod_usuario',cod_usuario)
  }
  return this.http
      .get<IEstatusForaneo[]>(this.apiUrl,valores)
      .pipe(retry(1), catchError(this.handleError));

 }

  // Utiliza el modelo IRecursosMaster
  postRecursos(data: IpostEstatusForaneo): Observable<IResultadoGeneral> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let data_enviar =JSON.stringify(data);
    return this.http.post<IResultadoGeneral>(this.apiUrl +'/guardaApoyoEstForaneo', data_enviar, { headers: headers })
      .pipe(catchError(this.handleError));
  }
  // putRecursos(data: IpostEstatusForaneo): Observable<IResultadoGeneral> {
  //   const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  //   let data_enviar =JSON.stringify(data);
  //   return this.http.post<IResultadoGeneral>(this.apiUrl +'/editaApoyoEstForaneo', data_enviar, { headers: headers })
  //     .pipe(catchError(this.handleError));
  // }

 deleteRecursos(data: IdeleteEstatusForaneo): Observable<IResultadoGeneral> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let data_enviar={headers:headers, body : JSON.stringify(data)};

    return this.http.delete<IResultadoGeneral>(this.apiUrl +'/deleteApoyoEstatusForaneo', data_enviar)
      .pipe(catchError(this.handleError));
  }
  cambioEstatus(data: IdeleteEstatusForaneo): Observable<IResultadoGeneral> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let data_enviar=JSON.stringify(data);

    return this.http.put<IResultadoGeneral>(this.apiUrl +'/editaApoyoEstForaneo', data_enviar , { headers: headers })
      .pipe(catchError(this.handleError));
  }
  editaApoyoFinForaneo(data: IdeleteEstatusForaneo): Observable<IResultadoGeneral> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let data_enviar=JSON.stringify(data);

    return this.http.put<IResultadoGeneral>(this.apiUrl +'/editaApoyoFinForaneo', data_enviar , { headers: headers })
      .pipe(catchError(this.handleError));
  }


  getListadoChoferesGeneral(Date: any , ciudad:number  ): Observable<InombreCompletoChofer[]> {
    const valores = {
      params: new HttpParams().
         set('fecha', Date).
         set('id_ciudad_hm', ciudad)

        }
    return this.http.get<InombreCompletoChofer[]>(this.apiUrl+'/getChoferesGenerales' , valores)
    .pipe(retry(1), catchError(this.handleError));
  }
//  agregar los dos ultimos
// Method: PUT - editEstatusApoyoForaneo
//     idx: number

// Method: GET - rptApoyoEstatusForaneo
//     Any --Sin Modelo

  // const insuranceData = {
  //   id : insuranceId
  // }
  // var reqHeader = new HttpHeaders({
  //         "Content-Type": "application/json",
  //     });
  //     const httpOptions = {
  //         headers: reqHeader,
  //         body: insuranceData,
  //     };
  // return this.http.delete<any>(this.url + "users/insurance", httpOptions);
  // }


  rptApoyoEstatusForaneo(fec_inicio: string, fecha_fin_filtro: string,id_ciudad_hm:number ): Observable<any> {
  const valores = {
    params: new HttpParams().
       set('fec_inicio',fec_inicio).
       set('fec_fin',fecha_fin_filtro).
       set('id_ciudad_hm',id_ciudad_hm)
      }
  return this.http
      .get<any>(this.apiUrl +'/rptApoyoEstatusForaneo',valores)
      .pipe(retry(1), catchError(this.handleError));

 }

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }

}
