import { Component } from '@angular/core';

@Component({
  selector: 'app-aplicapuntualidad',
  templateUrl: './aplicapuntualidad.component.html',
  styleUrls: ['./aplicapuntualidad.component.scss']
})
export class AplicapuntualidadComponent {

}
