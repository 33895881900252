import { Component } from '@angular/core';
import { IResultadoGeneral } from './../models/resultadogeneral';
import { ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { ConfirmEventType, ConfirmationService, MessageService } from 'primeng/api';
import { saveAs } from 'file-saver';
import ICredencialUsuario from '../models/credencial';
import { ICiudad } from '../models/ciudad';
import { InombreCompletoChofer } from '../models/nombreCompletoChofer';
import { IMes } from '../models/mes';
import { IrptEstatusForaneo } from '../models/rptEstatusForaneo';
import { IEstatusForaneo } from '../models/estatusforaneo';
import { BonoaguinaldoService } from '../services/bonoaguinaldo.service';
import { ISemanasHappyMile } from '../models/semanas';
import { SemanaService } from '../services/semana.service';
import { AuthService } from '../services/auth.service';
import { ICiudadHM } from '../models/ciudadhm';
import { CiudadhmService } from './../services/ciudadhm.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BonoRecomendacionService } from '../services/bonorecomendacion.service';
import { INomina } from '../models/nomina';
import { IJsonDia } from '../models/json_dia';
import { IEchamelaMano } from '../models/echamelamano';
import { IPostEchameLaMano } from '../models/postechamelamano';
import { EchamelamanoService } from '../services/echamelamano.service';
import { IDescuentosVariosCalculoDispersion } from '../models/descuentosVariosCalculoDispersion';
import { IDescuentosVarios } from '../models/DescuentosVariosModel';
import { IPostDescuentosVarios } from '../models/postDescuentosVariosModel';
import { IDescuentosVariosDispersion } from '../models/DescuentosVariosDispersion';
import { IEstatus } from '../models/estatus';
import { IPostprestamos } from '../models/IPostprestamos';
import { IPrestamos } from '../models/prestamosModel';

@Component({
  selector: 'app-prestamo',
  templateUrl: './prestamo.component.html',
  styleUrls: ['./prestamo.component.scss']
})
export class PrestamoComponent {

  @ViewChild('dt1') dt!: Table;
  lstCiudad: ICiudadHM[] = [];
  lstCiudad2: ICiudadHM[] = [];
  lstChoferes: InombreCompletoChofer[] = [];
  lstchofCopy: InombreCompletoChofer[] = [];
  lstEstatusForaneo: IEstatusForaneo[] = [];
  lstEchamelaMano: IPrestamos [] = [];
  lstSemanas: ISemanasHappyMile[] = [];
  lstNominaPorCiudad: INomina[] = [];
  lstJsonPorDia: IJsonDia[]=[];
  lstDispersion: IDescuentosVariosCalculoDispersion[]=[];
  total_dispersion:number= 0;

  imp_restante:number = 0;
  nombre_completo: string = '';
  cod_chofer: number = 0;
  imp_total:number = 0;
  clv_activo: number= 0;

  mssgListaCiudades: string = '';
  selectedSemana: ISemanasHappyMile = {
    idx: 0,
    id_semana: 0,
    nom_semana: '',
    anio: 0,
    fec_inicio: '',
    fec_fin: '',
    clv_activo: 0
  };

  selectedSemanaReporte: ISemanasHappyMile = {
    idx: 0,
    id_semana: 0,
    nom_semana: '',
    anio: 0,
    fec_inicio: '',
    fec_fin: '',
    clv_activo: 0
  };

  lstrptDescansoFaltas: IrptEstatusForaneo[] = [];
  selectedCiudad: ICiudad = {
    id: 0,
    nombre: '',
    clave_Estado: '',
    id_ciudad_principal: 0
  };

  //num_anio: number= 0;
  years: number[] = [];
  loading: boolean = false;
  loading_Disp: boolean = false;
  anio_filtro:any;
  dspDialogoDispercion: boolean =false;

  //variables usadas para la fecha, fueron casteadas ya que presentaban errorees
  dia: any = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
  fecha_dia: any = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
  anio: any = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
  fecha_fin: any = null;
  id_ciudad_hm: number = 0;
  id_ciudad_principal: number = 0;
  texto_operacion: string = '';
  num_operacion: number = 0;
  clv_operacion: number = 0;
  habilitar_agregar: boolean = false;
  resultado: IResultadoGeneral = {
    clv_estatus: 0,
    messageDetails: ''
  };
  habilitaragregar :boolean=false;
  dspDeduccion:boolean = false;
  dsblstCiudades: boolean = true;
  dsblstColaboradores: boolean = true;
  dsblstCiudadesAdscritas: boolean = true;
  // variables de reportes--------------------------
  dbsReportes: boolean = false;
  fecha_fin_filtro: any = null;
  ButtonExportExcel: boolean = false;
  //Fecha de Dias Atras
  fecha_del_dia: any = null;
  mostrar_texto_fechas: number = 0;
  fecha_final_dia!: Date;
  //Fecha para restar 3 dias
  fecha_minus3 !: Date;
  fecha_dia_real: any = null;
  selectedMesReporte1: IMes = {
    num_mes: -1,
    nombre: ""
  }
  lstEstatus: IEstatus[] = [{
    id: 1,
    nombre: 'ACTIVO'
  },
  {
    id: 2,
    nombre: 'FINALIZADO'

  },


];

  anioReporte1: any;
  loading_reporte1: boolean = false;
  lstTableMostrarDispersion: IDescuentosVarios = {
    id_descuento: 0,
    cod_chofer: 0,
    nombre_completo: '',
    id_categoria_descuento: 0,
    desc_categoria: '',
    id_sub_categoria_descuento: 0,
    desc_sub_categoria: '',
    imp_total: 0,
    id_semana_inicial: 0,
    id_semana_final: 0,
    id_ciudad_hm: 0,
    desc_ciudad: '',
    num_pagos: 0,
    desc_estatus: '',
    fec_registro: new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)),
    jsnDescDisp: [],
    clv_btn_eliminar: 0,
    clv_operacion: 0,
    cod_usuario: ''
  };
  meses: IMes[] = [
    { num_mes: 1, nombre: "Enero" },
    { num_mes: 2, nombre: "Febrero" },
    { num_mes: 3, nombre: "Marzo" },
    { num_mes: 4, nombre: "Abril" },
    { num_mes: 5, nombre: "Mayo" },
    { num_mes: 6, nombre: "Junio" },
    { num_mes: 7, nombre: "Julio" },
    { num_mes: 8, nombre: "Agosto" },
    { num_mes: 9, nombre: "Septiembre" },
    { num_mes: 10, nombre: "Octubre" },
    { num_mes: 11, nombre: "Noviembre" },
    { num_mes: 12, nombre: "Diciembre" }];

    reactiveforprestamo: FormGroup = new FormGroup({
      idx: new FormControl(0),
      cod_chofer: new FormControl(0, [Validators.min(1),Validators.required]),
      id_ciudad_hm: new FormControl(0),
      num_anio: new FormControl(new Date().getFullYear()),
      //id_semana: new FormControl(0, [Validators.min(1),Validators.required]),
      selectedSemana : new FormControl([],[Validators.min(1),Validators.required]),
      nombreCiudad: new FormControl(''),
      fec_inicio : new FormControl(''),
      fec_fin : new FormControl(''),
      imp_deduccion: new FormControl(0,[Validators.min(1),Validators.required]),
      clv_activo: new FormControl(0),
      num_pagos: new FormControl(0,[Validators.required,Validators.min(1)]),
    });

    dataFormCalc: FormGroup = new FormGroup({
      imp_total: new FormControl(null, [Validators.required,Validators.min(1),Validators.max(5000)]),
      num_pagos: new FormControl(12, [Validators.required,Validators.min(1),Validators.max(12)]),
      num_anio: new FormControl(new Date().getFullYear()),
      selectedSemana : new FormControl([],[Validators.min(1),Validators.required]),
      fec_inicio : new FormControl(''),
      fec_fin : new FormControl(''),
      imp_percent: new FormControl({ value: 30,disabled:true }),
      desc_prestamo: new FormControl('', [Validators.maxLength(500), Validators.required]),

    });

    dataFormChofer: FormGroup = new FormGroup({
      idx: new FormControl(0),
      cod_chofer: new FormControl(null, [Validators.required]),
      nom_chofer: new FormControl(null, [Validators.required]),
      id_ciudad_hm: new FormControl(0),
      nombreCiudad: new FormControl(''),
      clv_activo: new FormControl(0),
      cod_usuario: new FormControl('')
    });

  //Permisos
  user: ICredencialUsuario = {};
  constructor(

    private bonoaguinaldoService: BonoaguinaldoService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private semanaService: SemanaService,
    private echamelamanoService:EchamelamanoService,
    private auth: AuthService,
    private CiudadhmService: CiudadhmService,
    private bonoRecomendacionService: BonoRecomendacionService
    ) {

    this.initializeYears();
   // this.num_anio = new Date().getFullYear();
    this.cargarInformacionUsuario();

    this.dataFormCalc.controls['selectedSemana'].valueChanges.subscribe(data => {
      if (data==null) {
        //this.resetearFormularios();
        this.dataFormCalc.controls['fec_inicio'].setValue('');
        this.dataFormCalc.controls['fec_fin'].setValue('');
      } else {
        this.dataFormCalc.controls['fec_inicio'].setValue(data.fec_inicio);
        this.dataFormCalc.controls['fec_fin'].setValue(data.fec_fin);
      }
    });

    this.dataFormChofer.controls['cod_chofer'].valueChanges.subscribe(data => {
      let choferSeleccionado = this.lstChoferes.find(chofer => chofer.cod_chofer === data);
      if (choferSeleccionado) {
          this.dataFormChofer.controls['id_ciudad_hm'].setValue(choferSeleccionado.id_ciudad_hm);
          this.dataFormChofer.controls['nombreCiudad'].setValue(choferSeleccionado.desc_ciudad_hm);
          this.dataFormChofer.controls['nom_chofer'].setValue(choferSeleccionado.nombre_completo);
         // this.reactiveforprestamo.controls['nombreChofer'].setValue(choferSeleccionado.nombre_completo);
      } else {
        this.mssgListaCiudades = 'El colaborador no cuenta con ciudad favor de verificar';
      }
    });
  }

  ngOnInit(): void {
    this.cargaInicial();
    this.cargarListadoDeSemanasAnio();
    this.inicializarFechas();
  }

  cargarInformacionUsuario() {
    this.user = this.auth.user;
  }

  initializeYears(): void {
    const currentYear = new Date().getFullYear();
    for (let year = currentYear; year >= currentYear - 5; year--) {
      this.years.push(year);
    }
  }

  cargarListadoDeSemanasAnio_cast(num_anio:any) {
    let anio_cast: any = 0;
    if (num_anio instanceof Date) {
      // will execute
      anio_cast = num_anio.getFullYear();
    } else {
      anio_cast = num_anio;
    }
    this.lstSemanas = [];
    this.semanaService.listarSemanas(anio_cast).subscribe((resp) => {
      this.lstSemanas = resp;
      //reiniciar
      this.lstNominaPorCiudad = [];
      this.lstJsonPorDia=[];
      setTimeout(() => {
      },
        600);
    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>Error al cargar Semanas HM</b>. Contacte al Administrador.');
      });
  }

  consultaSemanas() {
    alert('Historico');
  }

  inicializarFechas() {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.anio = getYear;
    this.anio_filtro = getYear;
  }






// metodos para eñ filtro para fechas
  getTargetDescription(ruta: ISemanasHappyMile) {
    let fec_inicio = new Date(ruta.fec_inicio);
    let fec_fin = new Date(ruta.fec_fin);
    return this.formatearFechas(fec_inicio) + ' | ' + this.formatearFechas(fec_fin);
  }

  formatearFechas(fecha: Date): string {
    let fecha_Cast = '';
    var getYear = new Date(fecha).toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date(fecha).toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date(fecha).toLocaleDateString('es-MX', { day: '2-digit' });
    fecha_Cast = getDay + '/' + getMonth + '/' + getYear;
    return fecha_Cast;
  }

  cargarListadoDeSemanasAnio() {
    this.lstSemanas = [];
    this.semanaService.listarSemanas(this.anio).subscribe((resp) => {
      this.lstSemanas = resp;
      setTimeout(() => {
      },
        600);
    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> al cargar Información. Contacte al Administrador.');
      });
  }
  imp_total_genral:number=0;
  // -----------filtra y carga la informacion ----
  Filtrar() {

    this.loading = true;
    if (this.id_ciudad_hm == null || typeof this.id_ciudad_hm === 'undefined' || !this.selectedSemana || this.selectedSemana.id_semana == null || typeof this.selectedSemana.id_semana === 'undefined') {
      this.mostrarDialogoInformativoSincontacteadmi('<b>Informativo</b> Debe seleccionar una semana para poder filtrar');
      this.loading = false;
    } else   {
      this.habilitaragregar= true;
      this.echamelamanoService.getListaprestamo( this.selectedSemana.id_semana,this.selectedSemana.anio, this.id_ciudad_hm,this.user.cod != null ? this.user.cod:'0999').subscribe(
        (resp) => {
          this.lstEchamelaMano = resp;
          if (this.lstEchamelaMano.length === 0) {
            this.loading = false;
            this.mostrarDialogoInformativoSincontacteadmi('<b>No Existe</b> Registros para la Fecha seleccionada');
          }
          setTimeout(() => {
            this.loading = false;
          }, 500);
          // else {
          //   this.imp_total_genral = 0;
          //     // Iterar sobre la lista y sumar los valores de los campos
          //     this.lstEchamelaMano.forEach((item) => {
          //       this.imp_total_genral += item.imp_deduccion;
          //     });
          //   setTimeout(() => {
          //     this.loading = false;
          //   }, 800);
          // }
        },
        (error) => {
          this.mostrarDialogoInformativoSincontacteadmi('<b>ERROR</b> en Cargar Movimientos ');
          this.loading = false;
        }
      );
    }
  }

  mostrarDialogoInformativoSincontacteadmi(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario ;
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }

  crearNuevo(){
    this.texto_operacion = 'Agregar Descuento'
    this.dspDeduccion=true;
    this.cargaListaChoferes();
    // this.dataFormCalc.controls['clv_activo'].setValue(1);
    this.dataFormCalc.get('clv_activo')?.setValue(1);
    this.texto_operacion = 'Agregar Nueva Deduccion ';
    this.dataFormChofer.controls['id_ciudad_hm'].disable();
    this.dataFormChofer.controls['cod_chofer'].enable();
    this.dataFormCalc.controls['num_anio'].enable();
    this.dataFormCalc.controls['selectedSemana'].enable();
    this.clv_operacion=1;
  }

  pdialogeditarDeduccion(){
    this.dspDeduccion=true;
    this.texto_operacion = '';
  }


  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  cargaListaChoferes() {
    this.dsblstColaboradores = true;
    this.lstChoferes = [];
    this.bonoRecomendacionService.getListadoChoferesGeneral(this.id_ciudad_hm).subscribe((resp) => {
      this.lstChoferes = resp;
      setTimeout(() => {
        this.dsblstColaboradores = false;
      }, 100);
    },
      (error) => {
        this.dsblstColaboradores = false;
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Colaboradores');
      });
  }



  cargaInicial() {
    this.dsblstCiudades = true;
    this.loading = true;
    this.CiudadhmService.getListadoCiudades().subscribe((resp) => {
      this.lstCiudad = resp.slice(); // Crear una copia independiente
      this.lstCiudad2 = resp.slice(); // Crear una copia independiente
      const index = this.lstCiudad.findIndex(item => item.nombre === 'TODAS LAS CIUDADES');
      if (index !== -1) {
        this.lstCiudad.splice(index, 1);
      }
      this.lstCiudad.push({ id: 0, nombre: 'TODAS LAS CIUDADES', clave_estado: '', id_ciudad_principal: 0, clave_tipoTransporte: 0 });
      setTimeout(() => {
        this.loading = false;
        this.dsblstCiudades = false;
      }, 1000);
    },
    (error) => {
      this.loading = false;
      this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Ciudades');
    });
  }

  cancelarAgregar() {
    this.dataFormChofer.controls['cod_chofer'].setValue(0);
    this.dataFormCalc.controls['selectedSemana'].setValue([]);
    this.dataFormChofer.controls['id_ciudad_hm'].setValue(0);
    this.dataFormCalc.controls['imp_total'].setValue(0);
    this.dataFormCalc.controls['num_anio'].setValue(new Date().getFullYear());
    this.dataFormCalc.controls['num_pagos'].setValue(12);
    this.dataFormCalc.controls['desc_prestamo'].setValue('');

    this.lstDispersion = [];

    this.dspDeduccion = false;
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + "_exportar_" + dateFormat + EXCEL_EXTENSION);
  }



  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '. <b>Contacte al Administrador.</b>';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }


  // ----------------reportes  ------------
  abrirModalReportes() {
    this.dbsReportes = true;
    this.inicializarFechasFormateadas();
  }


  cancelarModalReportes() {
    this.dbsReportes = false;
    // Inicio Reporte 1
    this.selectedMesReporte1 = {
      num_mes: 0,
      nombre: ''
    }
    this.anioReporte1 = null;
    this.selectedCiudad = {
      id: -1,
      nombre: '',
      clave_Estado: '',
      id_ciudad_principal: 0
    };
    this.fecha_fin = null;

    this.selectedSemanaReporte = {
      idx: 0,
      id_semana: 0,
      nom_semana: '',
      anio: 0,
      fec_inicio: '',
      fec_fin: '',
      clv_activo: 0
    };
    this.id_ciudad_hm_rpt =0;
  }


  editarDeduccion(data: IEchamelaMano) {
    const selectedWeek = this.lstSemanas.find(semana => semana.id_semana === data.id_semana);

    this.texto_operacion = 'Editar Deduccion ';
    this.dspDeduccion = true;

    this.dataFormChofer.controls['idx'].setValue(data.idx);
    this.dataFormChofer.controls['cod_chofer'].setValue(data.cod_chofer);
    this.dataFormChofer.controls['id_ciudad_hm'].setValue(data.id_ciudad_hm);
    this.dataFormCalc.controls['num_anio'].setValue(data.num_anio);
    this.dataFormCalc.controls['imp_total'].setValue(data.imp_deduccion);
    this.dataFormCalc.controls['selectedSemana'].setValue(selectedWeek);
    this.dataFormChofer.controls['clv_activo'].setValue(2);

     this.dataFormChofer.controls['id_ciudad_hm'].disable();
     this.dataFormChofer.controls['cod_chofer'].disable();
     this.dataFormCalc.controls['num_anio'].disable();
     this.dataFormCalc.controls['selectedSemana'].disable();
  }



  dspVerMas:boolean=false;
  verMas(data:IPrestamos ) {
     this.dspVerMas = true;
     this.nombre_completo = data.nombre_completo;
    this.dataFormCalc.controls['desc_prestamo'].setValue(data.desc_prestamo);
    this.dataFormCalc.controls['desc_prestamo'].disable();
  }
  verMenos(){
    this.dspVerMas=false;
    this.nombre_completo = '';
    this.dataFormCalc.controls['desc_prestamo'].setValue('');
    this.dataFormCalc.controls['desc_prestamo'].enable();
  }

  deleteDescuento(id_descuento: number,nombre: string)
  {
    this.confirmationService.confirm({
      message: '¿Está seguro de eliminar la <b>DISPERSION</b>  del Colaborador <b>' + nombre + ' </b>?',
      header: 'Confirmación antes de eliminar dispersion',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.echamelamanoService.deleteRecursos(id_descuento).subscribe((resp)=>
          {
            if(resp.clv_estatus != -2)
              setTimeout(() => {
                this.cancelarModal();
                this.cargarDescuentosVarios();
                this.messageService.add({ severity: 'success', summary: 'Eliminacion Exitosa', detail: 'La informacion del descuento se elimino de manera correcta' });
              }, 800);
            else
            setTimeout(() => {
              this.cancelarModal();
              this.cargarDescuentosVarios();
              this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Eliminar Descuento>', detail: resp.messageDetails});
            }, 800);
          },
            (error) => {
              this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Eliminar Descuento>', detail: 'Contacte al Administrador del Sitio' });
        });
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
        }
      }
    });
  }

  // EliminarRegistro(data: IEchamelaMano){

  // let resultado: IResultadoGeneral;
  //   let data_enviar:  IPostEchameLaMano = {
  //     idx: data.idx,
  //     cod_chofer: data.cod_chofer,
  //     id_ciudad_hm: data.id_ciudad_hm,
  //     id_semana: data.id_semana,
  //     num_anio: data.num_anio,
  //     imp_deduccion: data.imp_deduccion,
  //     cod_usuario: this.user.cod == undefined ? '0999' : this.user.cod,
  //     clv_activo: this.clv_activo = 3,

  //   }
  //   this.confirmationService.confirm({
  //     message: '¿Está seguro que eliminar el <b>PRESTAMO</b>  del Colaborador <b>' + data.nom_chofer + '</b>?',
  //     header: 'Confirmación de edición de estatus',
  //     icon: 'pi pi-info-circle',
  //     accept: () => {
  //       this.echamelamanoService.postprestamo(data_enviar).subscribe((resp) => {
  //         // Verificamos si la respuesta es una lista y tomamos el primer elemento
  //         const resultado = Array.isArray(resp) ? resp[0] : resp;
  //         if (resultado.clv_estatus == 1) {
  //             this.messageService.add({ key: 'msj', severity: 'success', summary: 'Exito', detail: 'Se registro correctamente' });
  //             this.Filtrar();
  //         } else if (resultado.clv_estatus == -2) {
  //             this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: resultado.messageDetails });
  //         }
  //     });
  //     },
  //     reject: (type: any) => {
  //       switch (type) {
  //         case ConfirmEventType.REJECT:
  //           this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
  //           break;
  //         case ConfirmEventType.CANCEL:
  //           this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
  //           break;
  //       }
  //     }
  //   });
  // }



  inicializarFechasFormateadas() {
    //hacer globales
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.fecha_dia = dateFormat;
    this.fecha_del_dia = new Date(dateFormat + 'T00:00:00');
    //Fecha que no se modifica
    this.fecha_final_dia = new Date(dateFormat + 'T00:00:00');
    //Fecha para restar los 3 dias
    let minus3days = new Date(this.fecha_final_dia);
    this.fecha_minus3 = new Date(minus3days.setDate(new Date(minus3days).getDate() - 4));
    //Fecha del dia real, no se mueve
    this.fecha_dia_real = dateFormat;
    //Reportes
    let num_mes: number = +getMonth;
    this.meses.map((mes) => {
      if (mes.num_mes == num_mes) {
        this.selectedMesReporte1.nombre = mes.nombre;
        this.selectedMesReporte1.num_mes = mes.num_mes;
      }
    });
    this.anioReporte1 = getYear;
  }



  id_ciudad_hm_rpt: number=0;
deshabilitarbtnexportar:boolean=false;
  // metodo para exportar a excel
  exportarExcel( ) {
    this.deshabilitarbtnexportar = true;
    if (this.id_ciudad_hm_rpt == null || typeof this.id_ciudad_hm_rpt === 'undefined' || !this.selectedSemanaReporte || this.selectedSemanaReporte.id_semana == null || typeof this.selectedSemanaReporte.id_semana === 'undefined') {
      this.mostrarDialogoInformativoSinInformacion('<b>Informativo</b> Debe seleccionar una semana para poder filtrar');
      this.deshabilitarbtnexportar = false;
    } else  {
      this.echamelamanoService.rpteEchamelaMano(this.selectedSemanaReporte.anio, this.selectedSemanaReporte.id_semana,this.id_ciudad_hm_rpt,)
        .subscribe((resp: IEchamelaMano[]) => {
          if (resp.length === 0 || typeof resp === 'undefined') {
            this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> debes de seleccionar una semana existe para poder exportar la informacion del Catalogo(Excel)');

            this.deshabilitarbtnexportar = false;
          } else {
            import("xlsx").then(xlsx => {
              const worksheet = xlsx.utils.json_to_sheet(resp);
              const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
              const excelBuffer: any = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array"
              });

              this.saveAsExcelFile(excelBuffer, "Reporte_Colaboradores_Descuento_Prestamo"+ '_'+ 'semana'+'_'+this.selectedSemanaReporte.id_semana+  '_' + 'añio'+'_'+ this.selectedSemanaReporte.anio );
            });
            setTimeout(() => {
              this.ButtonExportExcel = false;
              this.deshabilitarbtnexportar = false;
              //this.cancelarModalReportes()
            }, 1000);
          }
        },
          (error) => {
            this.ButtonExportExcel = false;
            this.deshabilitarbtnexportar = false;
            this.fecha_fin = null;
            this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: 'Error en emitir el reporte de Estatus de Facturación.' });
          });
    }
  }


  mostrarDialogoInformativo(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '.';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
        this.messageService.clear();
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
        this.messageService.clear();
      },
      key: "sinInformacionDialog"
    });
    this.loading = false;
    this.messageService.clear();
  }

  formatearFechasCod(fecha: Date): string {
    let fecha_Cast = '';
    var getYear = new Date(fecha).toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date(fecha).toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date(fecha).toLocaleDateString('es-MX', { day: '2-digit' });
    fecha_Cast = getYear+'-'+getMonth+'-'+getDay;
    return fecha_Cast;
  }

  calcularDispersion()
  {

    let imp_total = this.dataFormCalc.get('imp_total')?.value;
    let num_pagos = this.dataFormCalc.get('num_pagos')?.value;
    let id_ciudad_hm = this.dataFormChofer.get('id_ciudad_hm')?.value;
    let fec_registro = this.dataFormCalc.get('selectedSemana')?.value;
    this.total_dispersion = 0;
    this.echamelamanoService.getListadoDescuentosDispersion(imp_total,num_pagos,fec_registro.id_semana,fec_registro.anio).subscribe((resp) => {
      if(resp.length != 0)
      {
        this.lstDispersion = resp;
        this.total_dispersion = this.lstDispersion[0].imp_pago_total;
      }
      else
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: "No se se pudo obtener la dispersion" });
    },(error) =>{
      this.mostrarDialogoInformativoSinInformacion('<b>Error</b> al cargar Información. Contacte al Administrador.');
    });
  }

  guardarDispersion()
  {
    let nom_completo = this.lstChoferes.find(x => x.cod_chofer == this.dataFormChofer.get('cod_chofer')?.value);
    let nombre = nom_completo == undefined ? '':nom_completo?.nombre_completo
    let data_enviar: IPostprestamos = {
      cod_chofer: this.dataFormChofer.get('cod_chofer')?.value,
      nombre_completo: nombre,
      id_ciudad_hm: this.dataFormChofer.get('id_ciudad_hm')?.value,
      id_categoria_descuento: 0,
      desc_descuento: '',
      id_sub_categoria_descuento:0,
      desc_sub_descuento: '',
      id_semana_inicial: this.lstDispersion[0].id_semana_inicial,
      num_anio_inicial: this.lstDispersion[0].num_anio_inicial,
      fec_inicial: this.lstDispersion[0].fec_inicial_total,
      id_semana_final: this.lstDispersion[0].id_semana_final,
      num_anio_final: this.lstDispersion[0].num_anio_fin,
      fec_final: this.lstDispersion[0].fec_final_total,
      imp_total: this.dataFormCalc.get('imp_total')?.value,
      num_pagos: this.dataFormCalc.get('num_pagos')?.value,
      fec_registro: '',
      cod_usuario: this.user.cod != null ? this.user.cod : '0999',
      lstDispersion: this.lstDispersion,
      desc_prestamo: this.dataFormCalc.get('desc_prestamo')?.value

    };

    this.confirmationService.confirm({
      message: '¿Está seguro de guardar la <b>DISPERSION</b> del Colaborador <b>' + nombre + '</b>?',
      header: 'Confirmación antes de guardar dispersion',
      icon: 'pi pi-info-circle',
      accept: () => {

    this.echamelamanoService.postDescuento(data_enviar).subscribe((resp) => {
      if (resp.clv_estatus != -2) {
        setTimeout(() => {
          this.cancelarModal();
          this.cargarDescuentosVarios();
          this.messageService.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se almaceno de forma correcta' });
        }, 800);
      } else {
        setTimeout(() => {
          this.messageService.add({ severity: 'error', summary: 'Error en <Agregar Descuento>', detail: resp.messageDetails });
          this.cancelarModal();
          this.cargarDescuentosVarios();
        }, 800);
      }
    },
    (error) => {
      this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Cargar Listado Colaboradores>', detail: 'Contacte al Administrador del Sitio' });
    });  }});
  }

  cargarDescuentosVarios() {

    this.loading = true;
    if (this.selectedSemana == null|| this.selectedCiudad == null || this.anio_filtro == null ) {
      this.mostrarDialogoInformativoSincontacteadmi('<b>Informativo</b> Debe seleccionar una semana y ciudad para poder filtrar');
      this.loading = false;
    } else   {
    this.echamelamanoService.getListaprestamo(
      this.selectedSemana.id_semana,this.selectedSemana.anio, this.id_ciudad_hm,this.user.cod != null ? this.user.cod.toString():'0999'
    ).subscribe((resp) => {
      this.lstEchamelaMano = resp;
      if (this.lstEchamelaMano.length === 0) {
        this.loading = false;
        this.mostrarDialogoInformativoSincontacteadmi('<b>No Existe</b> Registros para la Fecha seleccionada');
      }
      setTimeout(() => {
        this.loading = false;
      }, 500);

    },
      (error) => {
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Cargar Listado Descuentos Varios>', detail: 'Contacte al Administrador del Sitio' });
      });}
  }

  cancelarModal() {
    this.dspDeduccion = false;
    this.reseteaCalculo();
    this.reseteaDataChofer();
   // this.reseteaLista();
    this.texto_operacion = '';
  }
  reseteaCalculo()
  {
    this.reactiveforprestamo.reset();
    this.lstDispersion = [];
  }
  reseteaDataChofer()
  {
    this.dataFormChofer.reset();
  }

  mostrarDialogoDispersion(data: IDescuentosVarios)
  {
    this.dspDialogoDispercion = true;
    this.texto_operacion = 'Dispersion Guardada'
    this.lstTableMostrarDispersion = data;
    this.imp_restante =0;
    this.lstTableMostrarDispersion.jsnDescDisp.forEach((item) => {
      if (item.clv_pago === 0) {
        this.imp_restante += item.imp_periodo;
      }
    });

    this.nombre_completo = data.nombre_completo
    this.cod_chofer = data.cod_chofer
    this.imp_total = data.imp_total
  }

  updateDialogCambioEstatus(id: number)
  {

    setTimeout(() => {
      let data = this.lstEchamelaMano.find(x => id == x.id_descuento);

      this.dspDialogoDispercion = true;
      this.loading_Disp = true;

      if(typeof data !== 'undefined')
      {
        this.mostrarDialogoDispersion(data);
        this.loading_Disp = false;
        // setTimeout(() => {
        //   this.loading_Disp = false;
        // },
        //   600);
      }
    }
    ,
       600);
  }

  cambioEstatus(data: IDescuentosVarios,id_periodo: number )
  {
    this.confirmationService.confirm({
      message: '¿Está seguro de pagar la <b>DISPERSION</b>  del Colaborador <b>' + data.nombre_completo + ' </b>?',
      header: 'Confirmación antes de pagar dispersion',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.echamelamanoService.cambioEstatus(data.id_descuento,id_periodo,this.user.cod != null ? this.user.cod:'0999').subscribe((resp)=> {
          if (resp.clv_estatus != -2) {
            this.dspDialogoDispercion = false;
            this.messageService.add({ key: 'msj', severity: 'success', summary: 'Exito', detail: 'Se registro correctamente' });
            setTimeout(() => {
              this.cancelarModalEstatus();
              this.cargarDescuentosVarios();
              this.updateDialogCambioEstatus(data.id_descuento);
              //
            },1000);
          }
          else if (resp.clv_estatus == -2) {
            this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: resp.messageDetails });
          }
        });
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
        }
      }
    });
  }

  cancelarModalEstatus()
  {
    this.dspDeduccion = false;
    this.dspDialogoDispercion = false;
    this.lstDispersion = [];
  }
}
