import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { NavigationExtras, Router } from "@angular/router";

//primeng services
import { MessageService } from 'primeng/api';
//own services
import { AuthService } from './auth.service';
//models
import Module from '../models/Module';
import { groupBy, permissionMapper } from '../utils/utils';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private apiUrl: string = "";

  constructor(private http: HttpClient, private auth: AuthService, private message: MessageService, private router: Router) {
    this.apiUrl = environment.sUrlAuth + 'Auth';
   }



  public async post(method: string, payload: any = {}): Promise<any> {
    if (!this.auth.validateToken()) this.router.navigate(['/unauthorized']);
    return new Promise<any>((resolve, reject) => {
      const headers = new HttpHeaders();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", `Bearer ${this.auth.token}`);
      this.http.post(`${this.apiUrl}${method}`, payload, { headers }).pipe(
        take(1),
      )
        .subscribe(res => {
          resolve(res); //PENDIENTE
        },
          error => {
            console.log(error);
            this.message.add({ severity: "error", summary: "Error al consultar", detail: error.message, life: 5000 });
            reject(error); //PENDIENTE
          });
    });
  }

  public async get(method: string): Promise<any> {
    if (!this.auth.validateToken()) this.router.navigate(['/unauthorized']);
    return new Promise<any>((resolve, reject) => {
      this.http.get(`${this.apiUrl}${method}`).pipe(
        take(1),
      )
        .subscribe(res => {
          resolve(res); //PENDIENTE
        },
          error => {
            console.log(error);
            this.message.add({ severity: "error", summary: "Error al consultar", detail: error.message, life: 5000 });
            reject(error); //PENDIENTE
          });
    });
  }

  public async login(cookie: string): Promise<boolean> {
    this.auth.clearCredentials();
    return new Promise<boolean>((resolve, reject) => {
      this.http.post(`${this.apiUrl}/LoginUrl`, { cookie }).pipe(take(1)).subscribe(
        async (res: any) => {
          if (res['token']) {
            this.auth.setToken(res['token']);
            await this.getPermissions().then((permissions: Module[]) => {
              this.auth.permissions = permissions;
            });
            resolve(true);
          } else {
            this.auth.setToken(null);
            resolve(false);
          }
        },
        err => {
          this.message.add({ severity: "error", summary: "Error al iniciar sesión", detail: "No cuentas con permisos de acceso para este sistema o tu sesión ha caducado", life: 10000 });
          this.auth.setToken(null);
          resolve(false);
        }
      )
    });
  }

  public async loginWithEmailNPassword(user: any): Promise<boolean> {
    this.auth.clearCredentials();
    return new Promise<boolean>((resolve, reject) => {
      this.http.post(`${this.apiUrl}/Login`, user).pipe(take(1)).subscribe(
        async (res: any) => {
          if (res['token']) {

            this.auth.setToken(res['token']);
            this.router.navigateByUrl("/");
            await this.getPermissions().then((permissions: Module[]) => {
              this.auth.permissions = permissions;
            });
            resolve(true);
          } else {
            this.auth.setToken(null);
            resolve(false);
          }
        },
        err => {
          this.message.add({ severity: "error", summary: "Error al iniciar sesión", detail: "No cuentas con permisos de acceso para este sistema o tu sesión ha caducado", life: 10000 });
          this.auth.setToken(null);
          resolve(false);
        }
      )
    });
  }

  public async getPermissions(): Promise<Array<Module>> {
    return new Promise<Array<Module>>((resolve, reject) => {
      const httpHeaders: HttpHeaders = new HttpHeaders({
        'Authorization': `Bearer ${this.auth.token}`
      });
      this.http.post(`${this.apiUrl}/AccesoModulos`, { Clave_Sistema: 'NOMIN' }, { headers: httpHeaders }).pipe(take(1)).subscribe(
        (data: any) => {

          resolve(groupBy(data as Array<any>, 'nom_Modulo', permissionMapper));
        },
        err => {
          this.message.add({ severity: "error", summary: "Error al obtener los permisos de usuario", detail: "No cuentas con permisos de acceso para este sistema o tu sesión ha caducado", life: 10000 });
          //this.auth.setToken(null);
          resolve([]);
        }
      )
    });
  }

  public async hasPermission(optionId: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const httpHeaders: HttpHeaders = new HttpHeaders({
        'Authorization': `Bearer ${this.auth.token}`
      });
      this.http.post(`${this.apiUrl}/PermisoModulos`, { Cod_Opcion: optionId }, { headers: httpHeaders }).pipe(take(1)).subscribe(
        (data: any) => {
          resolve(data);
        },
        err => {
          this.message.add({ severity: "error", summary: "Error al obtener los permisos de usuario", detail: "No cuentas con permisos de acceso para este sistema o tu sesión ha caducado", life: 10000 });
          //this.auth.setToken(null);
          resolve(false);
        }
      )
    });
  }

}
