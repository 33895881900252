import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { CiudadService } from './ciudad.service';
import { ICiudadHM } from '../models/ciudadhm';
import { CiudadhmService } from './ciudadhm.service';
import { ITipoDescuentos } from '../models/tipodescuentos';

import { IResultadoGeneral } from '../models/resultadogeneral';
import { ISubTipoDescuento } from '../models/subtipodescuento';
import { IDescuentosVariosChoferes } from '../models/descVariosChof';
import { IDescuentosVarios } from '../models/DescuentosVariosModel';
import { IDescuentosVariosCalculoDispersion } from '../models/descuentosVariosCalculoDispersion';
import { IPostDescuentosVarios } from '../models/postDescuentosVariosModel';

@Injectable({
  providedIn: 'root'
})
export class DescuentosVariosService {
    private apiUrl: string = environment.sUrlNomina + 'DescuentosVarios';

    constructor(private http: HttpClient, private ciudadService: CiudadhmService) { }

    getListadoTipoDecuento(): Observable<ITipoDescuentos[]> {
        return this.http.get<ITipoDescuentos[]>(this.apiUrl + '/ObtenTipoDescuento')
            .pipe(retry(1), catchError(this.handleError));
    }

    getListadoSubTipoDescuento(id_descuento: number): Observable<ISubTipoDescuento[]> {
        const valores = {
            params: new HttpParams()
                .set('id_descuento', id_descuento)
        };

        return this.http.get<ISubTipoDescuento[]>(this.apiUrl + '/ObtenSubTipoDescuento', valores)
            .pipe(retry(1), catchError(this.handleError));
    }

    getListadoDescuentoChoferes(id_semana: number, num_anio: number, id_ciudad_hm: number): Observable<IDescuentosVariosChoferes[]> {
      const valores = {
        params: new HttpParams()
            .set('id_semana', id_semana)
            .set('num_anio', num_anio)
            .set('id_ciudad_hm', id_ciudad_hm)
    };

      return this.http.get<IDescuentosVariosChoferes[]>(this.apiUrl + '/ObtenChoferesDescuentosVarios', valores)
          .pipe(retry(1), catchError(this.handleError));
  }

    getListadoDescuentos(id_semana: number, num_anio: number, id_ciudad_hm: number,cod_usuario: string): Observable<IDescuentosVarios[]> {
        const valores = {
            params: new HttpParams()
                .set('id_semana', id_semana)
                .set('num_anio', num_anio)
                .set('id_ciudad_hm', id_ciudad_hm)
                .set('cod_usuario', cod_usuario)
        };

        return this.http.get<IDescuentosVarios[]>(this.apiUrl + '/ObtenListDescuentosVarios', valores)
            .pipe(retry(1), catchError(this.handleError));
    }

    getListadoCiudades(): Observable<ICiudadHM[]> {
        // Uso del método del servicio importado
        return this.ciudadService.getListadoCiudades();
    }

    getListadoDescuentosDispersion(imp_total: number, num_pagos: number
        , fec_registro: string, id_ciudad_hm: number, cod_usuario: string)
        : Observable<IDescuentosVariosCalculoDispersion[]> {
        const valores = {
            params: new HttpParams()
                .set('imp_total', imp_total)
                .set('num_pagos', num_pagos)
                .set('fec_registro', fec_registro)
                .set('id_ciudad_hm', id_ciudad_hm)
                .set('cod_usuario', cod_usuario)
        };
        return this.http.get<IDescuentosVariosCalculoDispersion[]>(this.apiUrl + '/CalculaDispersionDescuentos', valores)
            .pipe(retry(1), catchError(this.handleError));
    }

    postDescuento(data: IPostDescuentosVarios):Observable<any>{
        const headers = { 'content-type': 'application/json'}
        const body = JSON.stringify(data);
        return this.http.post<any>(this.apiUrl + '/AgregaDescuentosVarios',body,{'headers':headers});
    }
    cambioEstatus(id_descuento:number,id_sub_descuento: number,cod_usuario: string): Observable<IResultadoGeneral> {
        let mdl = { id_descuento: id_descuento,id_sub_descuento: id_sub_descuento,cod_usuario:cod_usuario};
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        let data_enviar=JSON.stringify(mdl);

        return this.http.put<IResultadoGeneral>(this.apiUrl +'/FinalizarEvento', data_enviar , { headers: headers })
          .pipe(catchError(this.handleError));
    }
    deleteRecursos(data: number): Observable<IResultadoGeneral> {
        //let mdl = { id_descuento: data};
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        //let data_enviar={headers:headers, body : JSON.stringify(mdl)};
        let data_enviar={headers:headers};
        const valores = {
          params: new HttpParams()
              .set('id_descuento', data)
      };

        return this.http.delete<IResultadoGeneral>(this.apiUrl +'/EliminarDescuentosVarios', valores)
          .pipe(catchError(this.handleError));
    }

  private handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(() => new Error(errorMessage));
  }
}
