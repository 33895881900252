import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, catchError, retry, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { INomina } from '../models/nomina';
@Injectable({
  providedIn: 'root'
})
export class NominaService {
  private apiUrl: string ='';
  private apiUrl_Recibo: string ='';
  private apiUrl_activities: string ='';
  constructor(private _http: HttpClient) {
    this.apiUrl = environment.sUrlNomina + 'Nomina';
    this.apiUrl_Recibo = environment.sUrlRecibo + 'nomina';
    this.apiUrl_activities = environment.sUrlRecibo + 'activities';
  }

  getNominaPorSemanayCiudad(id_semana:number,anio:number,id_ciudad_principal_hm:number): Observable<any> {
    const valores = {
      params: new HttpParams().
        set('id_semana', id_semana).
        set('anio', anio).
        set('id_ciudad_principal_hm', id_ciudad_principal_hm)
    };
    return this._http
      .get<any>(this.apiUrl, valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  getHeaders(id_semana:number,anio:number,id_ciudad_principal_hm:number): Observable<any> {
    const valores = {
      params: new HttpParams().
        set('id_semana', id_semana).
        set('anio', anio).
        set('id_ciudad_principal_hm', id_ciudad_principal_hm)
    };
    return this._http
      .get<any>(this.apiUrl+'/getHeaders', valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  handleError(error:any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
        return errorMessage;
    });
  }

  getRptNominaPorSemanayCiudad(id_semana:number,anio:number,id_ciudad_principal_hm:number): Observable<any> {
    const valores = {
      params: new HttpParams().
        set('id_semana', id_semana).
        set('anio', anio).
        set('id_ciudad_principal_hm', id_ciudad_principal_hm)
    };
    return this._http
      .get<any>(this.apiUrl+'/getRptNomina', valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  getRecibo(num_anio: number, id_semana: number, cod_chofer:number): Observable<Blob> {
    const params={
      anio:num_anio,
      week:id_semana,
      employee:cod_chofer
    }

    return this._http.get(this.apiUrl_Recibo, {
      responseType: 'blob',
      params
    }).pipe(
      catchError(this.handleError)
    );
  }
  getactivities(num_anio: number, id_semana: number, cod_chofer:number): Observable<Blob> {
    const params={
      anio:num_anio,
      week:id_semana,
      employee:cod_chofer
    }

    return this._http.get(this.apiUrl_activities, {
      responseType: 'blob',
      params
    }).pipe(
      catchError(this.handleError)
    );
  }



}
