import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IDiasFestivos } from '../models/diasfestivos';
import { ISalarioDiario } from '../models/salariodiario';

@Injectable({
  providedIn: 'root'
})
export class SalariodiarioService {
  private apiUrl: string ='';
  constructor(private _http: HttpClient) { 
    this.apiUrl = environment.sUrlNomina + 'SalarioDiario';
  }

  public listar(anio:number):Observable<any>{
    return this._http.get(this.apiUrl+'/'+anio);
   }

   public agregar(data: ISalarioDiario ):Observable<any>{
    const headers = { 'content-type': 'application/json'}  
    const body = JSON.stringify(data); 
    return this._http.post<any>(this.apiUrl,body,{'headers':headers});
   }

   public editar(data: ISalarioDiario ):Observable<any>{
    const headers = { 'content-type': 'application/json'}  
    const body = JSON.stringify(data); 
    return this._http.put<any>(this.apiUrl,body,{'headers':headers});
   }

   public estatusSalarioDiario(data: ISalarioDiario ):Observable<any>{
    const headers = { 'content-type': 'application/json'}  
    const body = JSON.stringify(data); 
    return this._http.put<any>(this.apiUrl+'/editarStatus',body,{'headers':headers});
   }

}
