import { IResultadoGeneral } from './../models/resultadogeneral';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { ClienteService } from '../services/cliente.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { saveAs } from 'file-saver';
import ICredencialUsuario from '../models/credencial';
import { AuthService } from '../services/auth.service';
import { CiudadService } from '../services/ciudad.service';
import { ICiudad } from '../models/ciudad';
import { InombreCompletoChofer } from '../models/nombreCompletoChofer';
import { IMes } from '../models/mes';
import { IrptEstatusForaneo } from '../models/rptEstatusForaneo';

import { IVacaciones, IPeriodos } from '../models/vacaciones';
import { VacacionesService } from '../services/vacaciones.service';
import { IVacacionesPost } from '../models/vacacionesPost';
import { IputVacaciones } from '../models/putVacaciones';


@Component({
  selector: 'app-vacaciones',
  templateUrl: './vacaciones.component.html',
  styleUrls: ['./vacaciones.component.scss']
})
export class VacacionesComponent {

  @ViewChild('dt1') dt!: Table;
  lstCiudad: ICiudad[] = [];
  lstChoferes: InombreCompletoChofer[] = [];
  lstchofCopy: InombreCompletoChofer[] = [];
  lstCiudades: ICiudad[] = [];
  lstCiudadesForanea: ICiudad[] = [];
  mssgListaCiudades: string = '';
  lstvacacionesMain: IVacaciones[] = [];
  lstPostVacaciones: IVacacionesPost[] = [];
  lstrptDescansoFaltas: IrptEstatusForaneo[] = [];
  selectedCiudad: ICiudad = {
    id: 0,
    nombre: '',
    clave_Estado: '',
    id_ciudad_principal: 0
  };
  lstPeriodos :IPeriodos[] = [];
  num_anio: number= 0;
  loading: boolean = false;
  diasRestantes:number = 0;
  choferIDnumber = 0;


  //variables usadas para la fecha, fueron casteadas ya que presentaban errorees
  fecha_hoy: any = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
  dia: any = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
  fecha_filtrada: any = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
  fecha_dia: any = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substring(0, 10);

  fecha_fin: any = null;
  id_ciudad_hm: number = 0;
  dspCliente: boolean = false;
  texto_operacion: string = '';
  num_operacion: number = 0;
  clv_operacion: number = 0;
  habilitar_agregar: boolean = false;
  loadingOperaciones: boolean = false;


  resultado: IResultadoGeneral = {
    clv_estatus: 0,
    messageDetails: ''
  };

  dsblstCiudades: boolean = true;
  dsblstColaboradores: boolean = true;
  dsblstCiudadesAdscritas: boolean = true;
  dspOperacionesVacaciones: boolean = false;

  // variables de reportes--------------------------
  dbsReportes: boolean = false;
  years: number[] = [];
  fecha_fin_filtro: any = null;
  ButtonExportExcel: boolean = false;
  //Fecha de Dias Atras
  fecha_dos_dias_anterior!: Date;
  fecha_del_dia: any = null;
  btnHabilitar: boolean = false;
  mostrar_texto_fechas: number = 0;
  fecha_final_dia!: Date;
  //Fecha para restar 3 dias
  fecha_minus3 !: Date;
  habilitar_tres_dias: boolean = true;
  fecha_dia_real: any = null;
  checkboxValue: boolean = false;
  checked: boolean = false;
  selectedMesReporte1: IMes = {
    num_mes: -1,
    nombre: ""
  }
  nombreCompleto: string = '';


  anioReporte1: any;
  loading_reporte1: boolean = false;
  meses: IMes[] = [
    { num_mes: 1, nombre: "Enero" },
    { num_mes: 2, nombre: "Febrero" },
    { num_mes: 3, nombre: "Marzo" },
    { num_mes: 4, nombre: "Abril" },
    { num_mes: 5, nombre: "Mayo" },
    { num_mes: 6, nombre: "Junio" },
    { num_mes: 7, nombre: "Julio" },
    { num_mes: 8, nombre: "Agosto" },
    { num_mes: 9, nombre: "Septiembre" },
    { num_mes: 10, nombre: "Octubre" },
    { num_mes: 11, nombre: "Noviembre" },
    { num_mes: 12, nombre: "Diciembre" }];

  reactiveVacacionesForm: FormGroup = new FormGroup({
    idx: new FormControl(0, [Validators.required]),
    fecha: new FormControl(this.fecha_dia, [Validators.required]),
    fechaFin: new FormControl(this.fecha_fin, [Validators.required]),
    chofer: new FormControl(0, [Validators.required]),
    ciudad: new FormControl(0, [Validators.required]),
    nom_ult_mod: new FormControl(''),
    clv_estatus: new FormControl(1),
    num_anio: new FormControl(0),
    cod_usuario: new FormControl(''),
    usuarioMod: new FormControl(''),

  });



  //Permisos
  user: ICredencialUsuario = {};
  constructor(private router: Router,
    private vacacionesService: VacacionesService,

    private ciudadService: CiudadService,
    private confirmationService: ConfirmationService, private messageService: MessageService,

    private auth: AuthService) {
    this.cargarInformacionUsuario();
    this.initializeYears();
    this.num_anio = new Date().getFullYear();

  }

  ngOnInit(): void {
    this.cargaInicial();
  }

  initializeYears(): void {
    const currentYear = new Date().getFullYear();
    for (let year = currentYear; year >= currentYear - 5; year--) {
      this.years.push(year);
    }
  }
  cargarInformacionUsuario() {
    this.user = this.auth.user;
  }

  limpiarfiltrosTabla() {
    this.dt.clear();
  }

  //carga la lista de colaboradores

  // cargaListaChoferes() {
  //   this.dsblstColaboradores = true;
  //   this.lstChoferes = [];
  //   this.lstchofCopy = [];
  //   this.vacacionesService.getListadoVacaciones(this.fecha_filtrada, this.id_ciudad_hm).subscribe((resp) => {
  //     this.lstChoferes = resp;
  //     this.lstchofCopy = resp;

  //     setTimeout(() => {
  //       this.dsblstColaboradores = false;
  //     }, 1000);
  //   },
  //     (error) => {
  //       this.dsblstColaboradores = false;
  //       this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Colaboradores');
  //     });
  // }


  // cargar lista inicial de las ciudades para los dropdown  get
  cargaInicial() {
    this.dsblstCiudades = true;
    this.loading = true;
    this.ciudadService.getListadoCiudades().subscribe((resp) => {
      this.lstCiudad = resp;
      this.lstCiudadesForanea = resp;
      this.lstCiudad.push({ id: 0, nombre: 'TODAS LAS CIUDADES', clave_Estado: '', id_ciudad_principal: 0, clave_tipoTransporte: 0 })
      setTimeout(() => {
        this.loading = false;
        this.dsblstCiudades = false;
      }, 1000);
    },
      (error) => {
        this.loading = false;
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Ciudades');
      });
  }



  // -----------filtra y carga la informacion boton del filtro ----
  FiltrarFechaDiariaRuta() {
    this.loading = true;
    // this.dia = this.fecha_filtrada;
    // this.fecha_dia = this.fecha_filtrada;
    if (this.id_ciudad_hm == null || typeof this.id_ciudad_hm === 'undefined') {
      this.mostrarDialogoInformativoSinInformacion('<b>Informativo</b> La fecha y Ciudad de Operación o Todas las Ciudades deben seleccionarse');
      this.loading = false;
    } else {
      //this.cargaListaChoferes();
      //let cambiar la fecha dia para que permita agregar la fecha inicio

      this.vacacionesService.getListadoVacaciones( this.id_ciudad_hm, this.num_anio, this.user.cod! ).subscribe((resp) => {
        this.lstvacacionesMain = [];
        this.lstvacacionesMain = resp;


        this.clv_operacion = this.clv_operacion /*!= 0 ? this.clv_operacion : this.lstvacaciones.length == 0 ? 0 : this.lstvacaciones[0].clv_operacion*/ ;
//-        this.habilitar_agregar = true;
        if (this.lstvacacionesMain.length == 0) {
          this.loading = false;
          this.mostrarDialogoInformativoSinInformacion('<b>No Existe</b> información de Rutas para la Fecha ' + this.fecha_filtrada + ' seleccionada');
        }
        setTimeout(() => {
          this.loading = false;
        }, 800);
      },
        (error) => {
          this.mostrarDialogoInformativoSinInformacion('<b>ERROR</b> en Cargar Movimientos.');
          this.loading = false;
        });
    }

  }


  getStatusDescription(clv_estatus: number): string {
    switch (clv_estatus) {
      case -1 :
        return 'SIN REGISTROS'
      case 0:
        return 'PENDIENTE AUTORIZAR';
      case 1:
        return 'AUTORIZADO';
      case 2:
        return 'ACTIVO';
      case 3:
        return 'FINALIZADO';
      // case 4:
      //   return 'AUTORIZADO NO TOMAR VACACIONES';
      default:
        return 'Estatus Desconocido';
    }
  }
  getStatusColor(clv_estatus: number): string {
    switch (clv_estatus) {
      case 0:
        return 'orange'; //
      case 1:
        return 'green'; // Verde
      case 2:
        return 'blue'; // Azul
      case 3:
        return 'black'; // Negro
      // case 4:
      //   return 'purple'; // Púrpura
      default:
        return 'gray'; // Color predeterminado en caso de valor desconocido
    }
  }



  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }


//itzael
  //   guarda la informacion del colaborador en el p-dialog
  guardarPeriodoVacacional() {
   //
    let data_enviar = {
      idx: this.reactiveVacacionesForm.get('idx')?.value,
      cod_chofer: this.reactiveVacacionesForm.get('chofer')?.value,
      fec_inicio: this.reactiveVacacionesForm.get('fecha')?.value,
      fec_fin: this.reactiveVacacionesForm.get('fechaFin')?.value,
      num_anio: this.num_anio,
      cod_usuario: this.user.cod == undefined ? '0999' : this.user.cod
    };
    if (data_enviar.fec_inicio > data_enviar.fec_fin) {
      this.messageService.add({ severity: 'warn', summary: 'Error en "Agregar Periodo"', detail: 'No se puede ingresar una fecha fin menor a la fecha inicio, favor de verificar.' });
    } else {
      this.vacacionesService.postVacaciones(data_enviar).subscribe((resp) => {
        var resultado = resp;
        if (resultado.clv_estatus == 1) {
          //guardar
          setTimeout(() => {
            this.messageService.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se almaceno de forma correcta' });
            this.reactiveVacacionesForm.reset();
            this.cancelarVentanaModal();
            this.FiltrarFechaDiariaRuta();
          }, 800);
        } else if (resultado.clv_estatus == -2) {
          setTimeout(() => {
            this.messageService.add({ severity: 'error', summary: 'Error en <Agregar Cliente>', detail: resultado.messageDetails });
            this.reactiveVacacionesForm.reset();
            this.cancelarVentanaModal();
            this.FiltrarFechaDiariaRuta();
          }, 800);
        }
      },
        (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error en Agregar Periodo', detail: 'Favor de validar la informacion' });
        });
    }
  }



  //botones de agregar
  mostrarModalAgregar(diasRestantes:number, nom: string, chofer : number) {
    this.nombreCompleto = nom;
    this.diasRestantes =diasRestantes
      this.dspCliente = true;
      this.texto_operacion = 'Agregar Periodo Vacacional';
      this.reactiveVacacionesForm.controls['chofer'].setValue(chofer);
      this.reactiveVacacionesForm.controls['idx'].setValue(0);
      this.reactiveVacacionesForm.controls['fecha'].setValue(this.fecha_hoy);
      this.reactiveVacacionesForm.controls['fechaFin'].setValue('');


    }

// itzael
  mostrarModalEditar(vacaciones: IPeriodos) {

      this.reactiveVacacionesForm.controls['usuarioMod'].setValue(vacaciones.nom_ult_registro);

      this.lstPostVacaciones.push({
        idx: vacaciones.idx,
        cod_chofer: vacaciones.cod_chofer,
        fec_inicio: vacaciones.fec_inicio,
        fec_fin: vacaciones.fec_fin,
        num_anio:vacaciones.num_anio,
        cod_usuario:this.user.cod == undefined ? '0999' : this.user.cod

      })

      this.reactiveVacacionesForm.controls['idx'].setValue(vacaciones.idx);
    //  this.reactiveVacacionesForm.controls['ciudad'].setValue(vacaciones.id_ciudad_hm);
      this.reactiveVacacionesForm.controls['fecha'].setValue(vacaciones.fec_inicio.toString().substring(0,10));
      this.reactiveVacacionesForm.controls['chofer'].setValue(vacaciones.cod_chofer);
    //  this.reactiveVacacionesForm.controls['nom_ult_mod'].setValue(vacaciones.nom_ult_mod);
      this.reactiveVacacionesForm.controls['fechaFin'].setValue(vacaciones.fec_fin.toString().substring(0,10));
      this.reactiveVacacionesForm.controls['num_anio'].setValue(vacaciones.num_anio);
      this.reactiveVacacionesForm.controls['cod_usuario'].setValue(this.user.cod == undefined ? '0999' : this.user.cod);
      //chrcar esta validacion

      if(this.lstvacacionesMain.length>0 && typeof  this.lstvacacionesMain !== 'undefined' ){
        if(this.lstvacacionesMain[0].clv_operacion == 1 ){
         this.reactiveVacacionesForm.controls['fecha'].disable();
     }else if(this.lstvacacionesMain[0].clv_operacion == 3){
       this.reactiveVacacionesForm.controls['fecha'].enable();
     }
     }

      if (this.clv_operacion == 1) {
        if (vacaciones.fec_inicio == this.fecha_hoy) {

          this.reactiveVacacionesForm.controls['fechaFin'].enable();
          this.reactiveVacacionesForm.controls['fecha'].disable();
        } else {

          this.reactiveVacacionesForm.controls['fechaFin'].disable();
          this.reactiveVacacionesForm.controls['fecha'].disable();
        }

      } else if (this.clv_operacion == 3) {

        this.reactiveVacacionesForm.controls['ciudadForanea'].enable();
        this.reactiveVacacionesForm.controls['fechaFin'].enable();

      }
      //this.num_operacion = 1;
      this.dspCliente = true;
      this.texto_operacion = 'Editar Periodo Vacacional';

  }


  operacionPeriodoVacacional( nom: string, periodos : IPeriodos[] , diasRestantes: number, chofer : number) {
    this.diasRestantes = diasRestantes;
    this.lstPeriodos = [];
    this.nombreCompleto = '';
    this.lstPeriodos = periodos;
    this.dspOperacionesVacaciones = true;
    this.nombreCompleto = nom;
    this.reactiveVacacionesForm.controls['chofer'].setValue(chofer);
  }

  btn_cancelar_mostrar(fec_inicio: string,clv_estatus: number): boolean
  {
    let opc = this.lstvacacionesMain != undefined ? this.lstvacacionesMain[0]?.clv_operacion : 0;

    if (opc == 3) {
      return true;
    }

    if (fec_inicio.toString().substring(0,10) == this.fecha_hoy && opc == 1 && clv_estatus <= 2) {
      return true;
    }

    return false;
  }

  cancelarVentanaModal() {
    this.num_operacion = 0;
    this.dspCliente = false;
    this.texto_operacion = '';
    this.dspOperacionesVacaciones= false;
    this.reactiveVacacionesForm.reset();
    this.lstPeriodos = [];
    //7this.lstvacacionesMain = [];
    this.dbsReportes = false;
    this.anioReporte1 = null;
    this.fecha_fin = null;

    this.resultado = {
      clv_estatus: 0,
      messageDetails: ''
    };
    // Inicio Reporte 1
    this.selectedMesReporte1 = {
      num_mes: 0,
      nombre: ''
    }
    this.selectedCiudad = {
      id: -1,
      nombre: '',
      clave_Estado: '',
      id_ciudad_principal: 0
    };
    this.diasRestantes= 0;
    this.nombreCompleto = '';
  }
  cancelarVentanaModalPdialog() {
    this.num_operacion = 0;
    this.dspCliente = false;
    this.texto_operacion = '';
    // this.dspOperacionesVacaciones= false;
    this.reactiveVacacionesForm.reset();
    // this.lstPeriodos = [];
    //7this.lstvacacionesMain = [];
    this.dbsReportes = false;
    this.anioReporte1 = null;
    this.fecha_fin = null;

    this.resultado = {
      clv_estatus: 0,
      messageDetails: ''
    };
    // Inicio Reporte 1
    this.selectedMesReporte1 = {
      num_mes: 0,
      nombre: ''
    }
    this.selectedCiudad = {
      id: -1,
      nombre: '',
      clave_Estado: '',
      id_ciudad_principal: 0
    };
    this.diasRestantes= 0;
    this.nombreCompleto = '';
  }

  PagarVacaciones( data: IPeriodos) {
    let resultado: IResultadoGeneral;
    let data_enviar: IVacacionesPost = {
      idx: data.idx,
      cod_chofer: data.cod_chofer,
      fec_inicio: data.fec_inicio,
      num_anio: data.num_anio,
      fec_fin: data.fec_fin,
     cod_usuario: this.user.cod == undefined ? '0999' : this.user.cod
    };
    this.confirmationService.confirm({
      message: '¿Está seguro que desea cubrir el monto del periodo vacacional de <b>' + data.fec_inicio  + '</b> al <b>'+ data.fec_fin  +'</b> del Colaborador <b> ' + this.nombreCompleto  +'</b>?',
      header: 'Confirmación de edicion de estatus',
      icon: 'pi pi-info-circle',
      accept: () => {

        this.vacacionesService.autNoVacaciones(data_enviar).subscribe((resp) => {
          resultado = resp;
          if (resultado.clv_estatus == 1) {
            this.messageService.add({ key: 'msj', severity: 'success', summary: 'Editado', detail: 'Se edito correctamente el servicio' });
            this.FiltrarFechaDiariaRuta();
            this.cancelarVentanaModal();
          } else if (resultado.clv_estatus == -2) {
            this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: resultado.messageDetails });
          }
        });
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
        }
      }
    });

  }



 FinalizarVacaciones( data: IPeriodos) {
      if(data.clv_estatus == 3){
      this.messageService.add({
      key: 'msj',
      severity: 'warn',
      summary: 'Cancelado',
      detail: 'El periodo vacacional ya fue finalizado' });
    }   else{
    let resultado: IResultadoGeneral;
    let data_enviar: IputVacaciones = {
    idx: data.idx,
     cod_usuario: this.user.cod == undefined ? '0999' : this.user.cod
    };
    this.confirmationService.confirm({
      message: '¿Está seguro que desea finalizar el periodo vacacional de <b>' + data.fec_inicio  + '</b> al <b>'+ data.fec_fin  +'</b> del Colaborador <b> ' + this.nombreCompleto  +'</b>?',
      header: 'Confirmación de edicion de estatus',
      icon: 'pi pi-info-circle',
      accept: () => {

        this.vacacionesService.finalizarPeriodo(data_enviar).subscribe((resp) => {
          resultado = resp;
          if (resultado.clv_estatus == 1) {
            this.messageService.add({ key: 'msj', severity: 'success', summary: 'Editado', detail: 'Se edito correctamente el servicio' });
            this.FiltrarFechaDiariaRuta();
            this.cancelarVentanaModal();
          } else if (resultado.clv_estatus == -2) {
            this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: resultado.messageDetails });
          }
        });
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
        }
      }
    });}

  }


  confirmarVacaciones(data: IPeriodos) {
        if(data.clv_estatus == 2 ||data.clv_estatus == 3 ){
      this.messageService.add({
      key: 'msj',
      severity: 'warn',
      summary: 'Cancelado',
      detail: 'El periodo vacacional ya fue aprobado o finalizado' });
    }   else{
    let resultado: IResultadoGeneral;

   // let textoFechas = data.fec_inicio == data.fec_fin ? '</b> para el dia <b>' + data.fec_inicio:  '</b> registrado del dia <b> ' + data.fec_inicio + '</b> al dia  <b>' + data.fec_fin

    let data_enviar: IVacacionesPost = {
      idx: data.idx,
      cod_chofer: data.cod_chofer,
      fec_inicio: data.fec_inicio,
      num_anio: data.num_anio,
      fec_fin: data.fec_fin,
     cod_usuario: this.user.cod == undefined ? '0999' : this.user.cod
    };
    this.confirmationService.confirm({
      message: '¿Está seguro que desea Aprobar el periodo vacacional de <b>' + data.fec_inicio  + '</b> al <b>'+ data.fec_fin  +'</b> del Colaborador <b> ' + this.nombreCompleto  +'</b>?',
      header: 'Autorizar vacaciones',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.vacacionesService.AprobarVacaciones(data_enviar).subscribe((resp) => {
          resultado = resp;
          if (resultado.clv_estatus == 1) {
            this.messageService.add({ key: 'msj', severity: 'success', summary: 'Editado', detail: 'Se edito correctamente el servicio' });
            this.FiltrarFechaDiariaRuta();
            this.cancelarVentanaModal();
          } else if (resultado.clv_estatus == -2) {
            this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: resultado.messageDetails });
          }
        });
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
        }
      }
    });}

  }
  // case -1 :
  //   return 'SIN REGISTROS'
  // case 0:
  //   return 'PENDIENTE AUTORIZAR';
  // case 1:
  //   return 'AUTORIZADO';
  // case 2:
  //   return 'ACTIVO';
  // case 3:
  //   return 'FINALIZADO';
  confirmarEliminar(data: IPeriodos) {

    let resultado: IResultadoGeneral;
    let data_enviar: IVacacionesPost = {
      idx: data.idx,
      cod_chofer: data.cod_chofer,
      fec_inicio: data.fec_inicio,
      num_anio: data.num_anio,
      fec_fin: data.fec_fin,
     cod_usuario: this.user.cod == undefined ? '0999' : this.user.cod
    };

    this.confirmationService.confirm({
      message: '¿Está seguro que desea eliminar el perido vacional  registrado de <b> ' + data.fec_inicio  + '</b> al <b>'+ data.fec_fin  +'</b> del Colaborador <b> ' + this.nombreCompleto  +'</b>?',
      header: 'Confirmación de Eliminación',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.vacacionesService.DeletePeriodoVacaciones(data_enviar).subscribe((resp) => {
          resultado = resp;
          if (resultado.clv_estatus == 1) {
            this.messageService.add({ key: 'msj', severity: 'success', summary: 'Eliminado', detail: 'Se eliminó correctamente el servicio' });
            this.FiltrarFechaDiariaRuta();
            this.cancelarVentanaModal();
          } else if(resultado.clv_estatus == 0) {
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Warning', detail: resultado.messageDetails });
          }
          else if (resultado.clv_estatus == -2) {
            this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: resultado.messageDetails });
          }
        });
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
        }
      }
    });
  }


  saveAsExcelFile(buffer: any, fileName: string): void {
    //var FileSaver = require('file-saver');
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + "_export_" + dateFormat + EXCEL_EXTENSION);
  }


  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '. <b>Contacte al Administrador.</b>';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }


  // ----------------reportes  ------------
  abrirModalReportes() {
    this.dbsReportes = true;
    this.inicializarFechasFormateadas();
  }



  // cancelarModalReportes() {
  //   this.dbsReportes = false;
  //   // Inicio Reporte 1
  //   this.selectedMesReporte1 = {
  //     num_mes: 0,
  //     nombre: ''
  //   }
  //   this.anioReporte1 = null;
  //   this.selectedCiudad = {
  //     id: -1,
  //     nombre: '',
  //     clave_Estado: '',
  //     id_ciudad_principal: 0
  //   };
  //   this.fecha_fin = null;
  //   this.dspOperacionesVacaciones = false;
  //   this.dspCliente = false
  //   this.lstPeriodos = [];
  //   this.reactiveVacacionesForm.reset();

  // }



  inicializarFechasFormateadas() {
    //hacer globales
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;

    this.fecha_dia = dateFormat;
    this.fecha_del_dia = new Date(dateFormat + 'T00:00:00');
    //Fecha que no se modifica
    this.fecha_final_dia = new Date(dateFormat + 'T00:00:00');
    //Fecha para restar los 3 dias
    let minus3days = new Date(this.fecha_final_dia);
    this.fecha_minus3 = new Date(minus3days.setDate(new Date(minus3days).getDate() - 4));
    //Fecha del dia real, no se mueve
    this.fecha_dia_real = dateFormat;
    //Reportes
    let num_mes: number = +getMonth;
    this.meses.map((mes) => {
      if (mes.num_mes == num_mes) {
        this.selectedMesReporte1.nombre = mes.nombre;
        this.selectedMesReporte1.num_mes = mes.num_mes;
      }
    });
    this.anioReporte1 = getYear;
  }



  // metodo para exportar a excel
  exportarExcel(num_anio: number, id_ciudad_principal : number ) {
    // this.num_anio = this.fecha_filtrada;
     this.loading_reporte1 = true;
     if ( num_anio == null || id_ciudad_principal == null) {
       this.mostrarDialogoInformativo('<b>Aviso</b> Es necesario seleccionar <b>Fecha</b> de Operación a Filtrar');
     } else {
       this.vacacionesService.rptVacaciones(this.num_anio, this.id_ciudad_hm)
         .subscribe((resp: IVacaciones[]) => {
           if (resp.length === 0 || typeof resp === 'undefined') {
             this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion del Catalogo para exportar(Excel)');
             this.loading_reporte1 = false;
           } else {
             import("xlsx").then(xlsx => {
               const worksheet = xlsx.utils.json_to_sheet(resp);
               const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
               const excelBuffer: any = xlsx.write(workbook, {
                 bookType: "xlsx",
                 type: "array"
               });

               this.saveAsExcelFile(excelBuffer, "ReporteVacaciones" + '_' + num_anio );
             });
             setTimeout(() => {
               this.ButtonExportExcel = false;
               this.loading_reporte1 = false;
             }, 1000);
           }
         },
           (error) => {
             this.ButtonExportExcel = false;
             this.loading_reporte1 = false;
             this.fecha_fin = null;
             this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: 'Error en emitir el reporte de Estatus de Facturación.' });
           });
     }
   }

  mostrarDialogoInformativo(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '.';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
        this.messageService.clear();
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
        this.messageService.clear();
      },
      key: "sinInformacionDialog"
    });
    this.loading = false;
    this.messageService.clear();
  }


  //metodo para ahcer las validaciones referentes a clv_operacion que es donde se autoriza o no al usuario a modificar
  //validacion generico
  validarClvOperacion(): boolean {
    if (this.clv_operacion == 1) {
      return this.fecha_hoy == this.fecha_filtrada ? true : false
    } else if (this.clv_operacion == 3) {
      return true;
    }
    return false;
  }

  //botones para editar estatus
  validarClvOperacionbtnEstatus(pass: IVacaciones) {
    if (pass.clv_btn_aut == 1) {
      return true;
    } else {
      return false;
    }
  }

  // validarClvOperacionbtnFin(pass: IVacaciones) {
  //   if (pass.clv_btn_fin == 1) {
  //     return true;
  //   } else {
  //     return true;
  //   }
  // }

  // //botones para editar registros

  // validarClvOperacionbtnEdit(pass: IVacaciones) {
  //   if (pass.clv_btn_edit == 1) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // validarClvOperacionbtnDel(pass: IVacaciones) {
  //   if (pass.clv_btn_del == 1) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }




}

