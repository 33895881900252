import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IInasistencias } from '../models/inasistencias';

@Injectable({
  providedIn: 'root'
})
export class InasistenciasService {
  private apiUrl: string ='';
  constructor(private _http: HttpClient) { 
    this.apiUrl = environment.sUrlNomina + 'Inasistencias';
  }

  public listar(anio:number):Observable<any>{
    return this._http.get(this.apiUrl+'/'+anio);
   }

   public agregar(data: IInasistencias ):Observable<any>{
    const headers = { 'content-type': 'application/json'}  
    const body = JSON.stringify(data); 
    return this._http.post<any>(this.apiUrl,body,{'headers':headers});
   }

   public editar(data: IInasistencias ):Observable<any>{
    const headers = { 'content-type': 'application/json'}  
    const body = JSON.stringify(data); 
    return this._http.put<any>(this.apiUrl,body,{'headers':headers});
   }

   public estatusSalarioDiario(data: IInasistencias ):Observable<any>{
    const headers = { 'content-type': 'application/json'}  
    const body = JSON.stringify(data); 
    return this._http.put<any>(this.apiUrl+'/editarStatus',body,{'headers':headers});
   }
}
