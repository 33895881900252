import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ISemanasHappyMile } from '../models/semanas';

@Injectable({
  providedIn: 'root'
})
export class SemanaService {
  private apiUrl: string ='';
  constructor(private _http: HttpClient) {
    this.apiUrl = environment.sUrlNomina + 'SemanasHM';
  }

  public listarTodasSemanas():Observable<any>{
    return this._http.get(this.apiUrl);
   }

   public listarSemanas(anio:number):Observable<any>{
    return this._http.get(this.apiUrl+'/'+anio);
   }

   public agregarSemana(data: ISemanasHappyMile ):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this._http.post<any>(this.apiUrl,body,{'headers':headers});
   }

   public editarSemana(data: ISemanasHappyMile ):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this._http.put<any>(this.apiUrl,body,{'headers':headers});
   }

   public estatusSemana(data: ISemanasHappyMile ):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this._http.put<any>(this.apiUrl+'/editarStatus',body,{'headers':headers});
   }


}
