import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CiudadesprincipalesService } from './ciudadesprincipales.service';  // Importado
import { ICiudadPrincipalHM } from '../models/ciudadprincipal';
import { IPostInfonavit } from '../models/postinfonavit';
import { IEchamelaMano } from '../models/echamelamano';
import { IPostEchameLaMano } from '../models/postechamelamano';
import { IDescuentosVariosCalculoDispersion } from '../models/descuentosVariosCalculoDispersion';
import { IPostDescuentosVarios } from '../models/postDescuentosVariosModel';
import { IResultadoGeneral } from '../models/resultadogeneral';
import { IDescuentosVarios } from '../models/DescuentosVariosModel';
import { IPostprestamos } from '../models/IPostprestamos';
import { IPrestamos } from '../models/prestamosModel';

@Injectable({
  providedIn: 'root'
})
export class EchamelamanoService {
  private apiUrl: string = environment.sUrlNomina + 'EchameLaMano';

  constructor(private http: HttpClient, private ciudadesPrincipalesService: CiudadesprincipalesService) { }  // Inyección de CiudadesprincipalesService

  getListaprestamo(id_semana: number,num_anio: number, id_ciudad_hm: number, cod_usuario:string): Observable<IPrestamos[]> {
    const valores = {
      params: new HttpParams()
        .set('id_semana', id_semana)
        .set('num_anio', num_anio)
        .set('id_ciudad_hm', id_ciudad_hm)
        .set('cod_usuario',cod_usuario)
    };
    return this.http.get<IPrestamos[]>(this.apiUrl, valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  getListadoCiudadesPrincipales(): Observable<ICiudadPrincipalHM[]> {
    // Uso del método del servicio importado
    return this.ciudadesPrincipalesService.getListadoCiudadesPrincipales();
  }


  getListadoDescuentosDispersion(imp_total: number, num_pagos: number
    , id_semana: number, num_anio: number)
    : Observable<IDescuentosVariosCalculoDispersion[]> {
    const valores = {
        params: new HttpParams()
            .set('imp_total', imp_total)
            .set('num_pagos', num_pagos)
            .set('id_semana', id_semana)
            .set('num_anio', num_anio)
    };
    return this.http.get<IDescuentosVariosCalculoDispersion[]>(this.apiUrl + '/CalculaDispersion', valores)
        .pipe(retry(1), catchError(this.handleError));
  }

  postDescuento(data: IPostprestamos):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this.http.post<any>(this.apiUrl + '/AgregaEchameLaMano',body,{'headers':headers});
  }

  cambioEstatus(id_descuento:number,id_sub_descuento: number,cod_usuario: string): Observable<IResultadoGeneral> {
    let mdl = { id_descuento: id_descuento,id_sub_descuento: id_sub_descuento,cod_usuario:cod_usuario};
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let data_enviar=JSON.stringify(mdl);

    return this.http.put<IResultadoGeneral>(this.apiUrl +'/FinalizarEvento', data_enviar , { headers: headers })
      .pipe(catchError(this.handleError));
  }

  deleteRecursos(data: number): Observable<IResultadoGeneral> {
    //let mdl = { id_descuento: data};
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    //let data_enviar={headers:headers, body : JSON.stringify(mdl)};
    let data_enviar={headers:headers};
    const valores = {
      params: new HttpParams()
          .set('id_descuento', data)
  }

    return this.http.delete<IResultadoGeneral>(this.apiUrl +'/EliminarEchameLaMano', valores)
      .pipe(catchError(this.handleError));
}

  // public postprestamo(data: IPostEchameLaMano):Observable<any>{
  //   const headers = { 'content-type': 'application/json'}
  //   const body = JSON.stringify(data);
  //   return this.http.post<any>(this.apiUrl + '/postEchameLaMano/',body,{'headers':headers});
  // }

   rpteEchamelaMano(num_anio_rpt: number, id_semana_rpt: number,id_ciudad_hm_rpt: number): Observable<any> {
    const valores = {
      params: new HttpParams()
        .set('num_anio', num_anio_rpt)
        .set('id_semana', id_semana_rpt)
        .set('id_ciudad_hm', id_ciudad_hm_rpt)
    };
    return this.http.get<any>(this.apiUrl + '/rptechamelaMano', valores)
      .pipe(retry(1), catchError(this.handleError));
  }


  private handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(() => new Error(errorMessage));
  }
}
