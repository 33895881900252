<p-toast position="top-right" key="msj"></p-toast>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="Aceptar" rejectLabel="Cancelar"
    rejectButtonStyleClass="p-button-text">
</p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
    rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar"
    [rejectVisible]="false"></p-confirmDialog>
<div class="d-flex justify-content-center">
    <div class="container-fluid" style="width: 98%;">
        <div class="row mt-5">
            <div class="col-md-12">
                <div class="card card-shadow sinborde">
                    <div class="card-header sinborde" style="background: none; ">
                        <div class="cardicono rounded">
                            <i class="pi pi-calendar-plus mt-3" style="font-size: 2rem; color: #fff;"></i>
                        </div>
                        <header style="left: 11%; top: 5%;">
                            <div style="display:inline">Gestión de</div>
                            <div style="color:rgb(226, 101, 43);display:inline;" class="ml-3">Movimientos Dias Festivos
                            </div>
                            <div *ngIf="dia.length > 0" style="color:rgb(22, 48, 220);display:inline;" class="ml-3"> -
                                {{dia|date:
                                'dd/MM/yyyy'}} </div>
                        </header>
                        <div class="row mt-6 ml-6 d-flex justify-content-between">
                            <!-- <div class="col-md-12 "> -->
                            <!-- -------------fecha del filtro---------------- -->
                            <div class="form-group col-md-7 d-flex">
                                <!-- <div class="col-md-1"></div> -->
                                <!-- filtro de semanas -->
                                <!-- <div class="col-md-4">
                  <p-dropdown [options]="lstSemanas" autoWidth="false" [style]="{'width':'100%'}"
                    placeholder="Seleccione Semana*" [(ngModel)]="selectedSemana" optionLabel="nom_semana"
                    [showClear]="false" [disabled]="lstSemanas.length == 0">
                    <ng-template let-target pTemplate="item">
                      <div class="ui-helper-clearfix" style="position: relative;height:25px;"
                        pTooltip="{{getTargetDescription(target)}}" tooltipPosition="top">
                        <span style="font-size:14px;float:left;margin-top:4px">{{target.nom_semana}}</span>
                      </div>
                    </ng-template>
                  </p-dropdown>
                  <div *ngIf="selectedSemana!=null">
                    <div class="row">
                      <div style="color: green;" class="col-sm-6 text-left ">
                        <b>Inicio:
                          {{selectedSemana.fec_inicio | date: 'dd/MM/yyyy' }}</b>
                      </div>
                      <div style="color: green;" class="col-sm-6 text-right "><b>Fin:
                          {{selectedSemana.fec_fin | date: 'dd/MM/yyyy' }}</b>
                      </div>
                    </div>
                  </div>
                </div> -->

                                <!-- filtro de ciudades -->
                                <div class="col-md-3 mr-3">
                                    <p-dropdown [(ngModel)]="id_ciudad_hm" [options]="lstCiudad" optionLabel="nombre"
                                        optionValue="id" placeholder="Filtrar por Ciudad" [showClear]="true"
                                        [disabled]="dsblstCiudades">
                                    </p-dropdown>
                                </div>

                                <div class="col-md-2 ml-5">
                                    <p-button label="Filtrar" icon="pi pi-list" iconPos="right"
                                        (click)="cargaMovDiasFestivos()">
                                    </p-button>
                                </div>

                                <div class="col-md-3">

                                    <!-- <button pButton pRipple label="Registro" class="p-button-warning" icon="pi pi-plus"
                    (click)="registrarDescuento();" pTooltip="Registro Descuento" tooltipPosition="right"
                    iconPos="right" [disabled]="lstSemanas.length == 0"></button> -->
                                </div>
                                <!-- <div class="col-md-1 "></div> -->

                                <!-- ----------boton principal de los reportes en excel------------------- -->
                                <!-- <div class="  col-md-1 ml-4 ">
                                            <button type="button" pButton pRipple class="p-button-success "
                                                pTooltip="Exportar Catalogo a EXCEL" tooltipPosition="right"
                                                (click)="abrirModalReportes()">
                                                <i class="pi pi-file-excel"></i> Exportar
                                            </button>
                                        </div> -->
                            </div>
                            <div class="form-group col-md-4">
                                <!-- <div class=" ml-4 col-md-3 d-flex justify-content-between">
                                            <div> -->
                                <div class="row d-flex justify-content-start">
                                    <div class="row d-flex justify-content-start">
                                        <div class="col-md-3 "></div>
                                        <div class="col-md-4 ml-4">
                                            <p-calendar [(ngModel)]="anio_filtro" view="year" [showIcon]="true"
                                                dateFormat="yy" inputId="yearpicker"
                                                [style]="{'width':'100%','height':'80%'}"
                                                pTooltip="Año de Semanas a Consultar"
                                                tooltipPosition="top"></p-calendar>
                                        </div>
                                        <div class="col-md-3">
                                            <button pButton pRipple label="Cargar" class="p-button-help"
                                                icon="pi pi-filter"
                                                (click)="cargarListadoDeSemanasAnio_cast(anio_filtro);"
                                                pTooltip="Cargar Semanas del Año" tooltipPosition="top"></button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-1"></div>

                            <!-- </div> -->
                        </div>
                        <div class="card-content">
                            <div class="row mt-3">
                                <p-table #dt1 [value]="lstMovDiaFestivoColab" [tableStyle]="{'min-width': '50rem'}"
                                    styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true"
                                    scrollHeight="480px" responsiveLayout="scroll"
                                    [globalFilterFields]="['cod_chofer','nombre_completo','desc_ciudad','desc_diafestivo','fecha']"
                                    [paginator]="true" [rows]="30" [rowsPerPageOptions]="[10,15,20,30,50,100]"
                                    [showCurrentPageReport]="true" [resizableColumns]="false"
                                    currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros"
                                    [loading]="loading">
                                    <ng-template pTemplate="caption">
                                        <div class="flex justify-content-between">
                                            <div class="col-md-3">
                                                <button pButton pRipple label="Agregar nuevo registro"
                                                    class="btn btn-success" icon="pi pi-plus"
                                                    (click)="agregaMovimiento();" pTooltip="Registro Movimiento"
                                                    tooltipPosition="right" iconPos="right"
                                                    [disabled]="loading"></button>
                                            </div>

                                            <span class="p-input-icon-left p-ml-auto">
                                                <i class="pi pi-search"></i>
                                                <input pInputText type="text" size="40"
                                                    (input)="applyFilterGlobal($event, 'contains')"
                                                    placeholder="Buscar Codigo Colaborador,Nombre Colaborador,Ciudad de Operación, Dia Festivo, Fecha" />
                                            </span>
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th class="text-center" pSortableColumn="cod_chofer">#<p-sortIcon
                                                    field="cod_chofer"></p-sortIcon></th>
                                            <th class="text-center" pSortableColumn="nombre_completo">
                                                Colaborador<p-sortIcon field="nombre_completo"></p-sortIcon></th>
                                            <th class="text-center" pSortableColumn="desc_ruta">Ruta<p-sortIcon
                                                    field="desc_ruta"></p-sortIcon></th>
                                            <th class="text-center" pSortableColumn="desc_ciudad">Ciudad<p-sortIcon
                                                    field="desc_ciudad"></p-sortIcon></th>
                                            <th class="text-center" pSortableColumn="desc_diafestivo">Desc Dia
                                                Festivo<p-sortIcon field="desc_diafestivo"></p-sortIcon></th>
                                            <th class="text-center" pSortableColumn="fecha_diafestivo">Fecha<p-sortIcon
                                                    field="fecha_diafestivo"></p-sortIcon></th>
                                            <th class="text-center" pSortableColumn="id_semana_diafestivo">
                                                Semana<p-sortIcon field="id_semana_diafestivo"></p-sortIcon></th>
                                            <th class="text-center" *ngIf="this.clv_operacion == 3">Accion</th>

                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-data let-index>
                                        <tr>
                                            <td class="text-center" style="color: gray;"> <b>{{data.cod_chofer}}</b></td>
                                            <td class="text-center" style="color: blue;"><b>{{data.nombre_completo }}</b> </td>
                                            <td class="text-center" style="color: black;"><b>{{
                                                    data.desc_ruta}}</b> </td>
                                            <td class="text-center" style="color: brown;"><b>{{data.desc_ciudad}}</b></td>
                                            <td class="text-center" style="color: rgb(35, 202, 71);">
                                                <b>{{data.desc_diafestivo}}</b>
                                            </td>
                                            <td class="text-center " style="color: #f0ad38;">
                                                <b>{{data.fecha_diafestivo}}</b>
                                            </td>
                                            <td class="text-center" style="color: #2ed249;">
                                                <b>{{data.id_semana_diafestivo}}</b>
                                            </td>
                                            <td class="text-center texto_principal" *ngIf="clv_operacion == 3">
                                                <button class=" ml-2 btn btn-light" tooltipPosition="top"
                                                    (click)="confirmarEliminar(data)" pTooltip="Eliminar Movimiento"
                                                    [disabled]="loading || clv_operacion != 3">
                                                    <i class="pi pi-times color-icon-red"
                                                        style="font-size: 1.5rem; color: red;">
                                                    </i>
                                                </button>
                                            </td>
                                        </tr>
                                    </ng-template>
                                    <!-- <ng-template pTemplate="summary">
                                        <div class="flex align-items-center justify-content-center mb-2 mt-2">
                                            <div class="col-md-3">
                                                <button class="btn btn-success w-100"
                                                    pTooltip="Guardar Asignación de Bono" (click)="guardarTodo()">
                                                    <span class="text-white">Guardar Todo</span>
                                                    <i class="pi pi-save text-white"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </ng-template> -->
                                </p-table>

                                <div class=" mt-3 d-flex justify-content-center">
                                    <p class="text-left informativo"
                                        style="display: inline; padding-left: 10px; border-left: 5px solid rgb(28, 163, 35); font-weight: normal; font-size: 0.8rem;">
                                        <b>ACTIVO</b> la deuda de la dispersion continua activa.
                                    </p>
                                    <p class="text-left informativo"
                                        style="display: inline; padding-left: 10px; border-left: 5px solid rgb(224, 82, 60); font-weight: normal; font-size: 0.8rem;">
                                        <b>FINALIZADO</b> la dedua de la dispersion ya fue saldada.
                                    </p>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--AGREGAR / EDITAR PRESTAMO-->
            <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dgAddMovimiento"
                [style]="{width: '75vw','height':'70vh'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
                (onHide)="cancelarVentanaModal()">
                <ng-template pTemplate="header">
                    {{ texto_operacion }}
                    <!-- <div *ngIf="" style="color:black;">
                        {{ nombre_registro }}
                    </div> -->
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="mt-2">
                        <form [formGroup]="reactiveForm" (ngSubmit)="guardarInformacionDeDiaFestivoColaborador()">
                            <!-- <span class="ml-4 title-light mt-3">Información General<i class="pi pi-cog"
                                    style="size: 2rem;"></i>
                            </span> -->
                            <div class="row mt-3">
                                <div class="form-group">
                                    <div class="row g-0 mt-3 ml-4">
                                        <!-- Primer Dropdown -->
                                        <div class="col-sm-2 mx-0 mb-4 mt-2">
                                            <div class="input-group">
                                                <i class="pi pi-calendar color-icon" style="font-size: 1.5rem"></i>
                                                <span class="ml-3 align-self-start"><b>Dia Festivo</b></span>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 mb-4">
                                            <p-dropdown [options]="lstDiasFestivos" autoWidth="false"
                                                [style]="{'width':'90%'}" placeholder="Seleccione Dia Festivo*"
                                                optionLabel="nombre" [showClear]="true" [filter]="true"
                                                filterBy="nombre" formControlName="id_dia_festivo" optionValue="idx" tooltip="">
                                                <ng-template let-item pTemplate="selectedItem">
                                                    <span style="vertical-align:middle; word-wrap: break-word;"
                                                        class="texto_responsable">{{item.nombre}}</span>
                                                </ng-template>
                                                <ng-template let-target pTemplate="item">
                                                    <div class="ui-helper-clearfix"
                                                        style="position: relative;height:25px;"
                                                        pTooltip="{{target.fecha_diafestivo}}" tooltipPosition="top">
                                                        <span
                                                            style="font-size:14px;float:left;margin-top:4px;word-wrap: break-word;">
                                                            {{target.nombre}}</span>
                                                    </div>
                                                </ng-template>
                                            </p-dropdown>
                                        </div>
                                        <!-- Segundo Dropdown -->
                                        <div class="col-sm-2 mx-0 mb-4 mt-2">
                                            <div class="input-group">
                                                <i class="pi pi-user color-icon" style="font-size: 1.5rem"></i>
                                                <span class="ml-3 align-self-start"><b>Colaborador</b></span>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 mb-4">
                                            <p-dropdown [options]="lstChoferes" autoWidth="false"
                                                [style]="{'width':'90%'}" placeholder="Seleccione Colaborador*"
                                                optionLabel="nombre_completo" [showClear]="true" [filter]="true"
                                                filterBy="nombre_completo" formControlName="chofer"
                                                optionValue="cod_chofer" tooltip="">
                                                <!-- <ng-template let-item pTemplate="selectedItem">
                                                    <span style="vertical-align:middle; word-wrap: break-word;"
                                                        class="texto_responsable">{{item.nombre_completo}}</span>
                                                </ng-template>
                                                <ng-template let-target pTemplate="item">
                                                    <div class="ui-helper-clearfix"
                                                        style="position: relative;height:25px;"
                                                        pTooltip="{{target.desc_ciudad_hm}}" tooltipPosition="top">
                                                        <span
                                                            style="font-size:14px;float:left;margin-top:4px;word-wrap: break-word;">{{target.nombre_completo}}</span>
                                                    </div>
                                                </ng-template> -->
                                            </p-dropdown>
                                        </div>
                                        <!--Tercer Dropdown-->
                                        <div class="row mt-2"> <!--Ruta-->
                                            <div class="form-group col-md-3">
                                                <div class="row">
                                                    <div class="col-sm-10">
                                                        <!-- <button type="submit" class="btn btn-success w-100"
                                                        [disabled]="!reactiveForm.valid"
                                                            pTooltip="Guardar Movimiento" tooltipPosition="left">
                                                            <span class="text-white">Guardar</span>
                                                            <i class="pi pi-save text-white"></i>
                                                        </button> -->
                                                        <!-- <button class="btn btn-raised btn btn-button-success w-100"
                                                            (click)="mostrarDiaologoRutas()"
                                                            pTooltip="Mostrar catalogo de Rutas"
                                                            [disabled]="editarRuta">
                                                            <span class="text-green">Buscar Servicio</span>
                                                            <i class="pi pi-search text-green"></i>
                                                        </button> -->
                                                        <button pButton type="button" 
                                                            class="p-button-success p-button-raised p-button-text w-100"
                                                            (click)="mostrarDiaologoRutas()"
                                                            pTooltip="Buscar Ultimos Servicios"
                                                            icon="pi pi-search" label="Buscar Servicio"
                                                            [disabled]="!editarRuta">
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group col-md-9 mt-3">
                                                <div class="row g-0">
                                                    <div class="col-sm-12 color-operacion d-flex align-items-center">
                                                        <p>RUTA: {{ desc_ruta }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!--Cuarto Dropdown -->
                                        <div class="col-sm-2 mx-0 ml-2 mt-6">
                                            <div class="input-group">
                                                <i class="pi pi-building color-icon" style="font-size: 1.5rem"></i>
                                                <span class="ml-3 align-self-start"><b>Ciudad</b></span>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 mt-6">
                                            <input value="{{desc_ciudad}}" type="text" class="form-control sinborde"
                                                pTooltip="Ciudad Operacion" tooltipPosition="left" 
                                                placeholder="Ciudad Operacion*"
                                                [style]="{'width':'80%' }" disabled="true">
                                        </div>
                                        <!-- Tercer Dropdown -->
                                        <div class="col-sm-2 mx-0 mt-6">
                                            <div class="input-group">
                                                <i class="pi pi-calendar color-icon" style="font-size: 1.5rem"></i>
                                                <span class="ml-3 align-self-start"><b>Fecha Dia Festivo</b></span>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 mt-6">
                                            <input value="{{this.reactiveForm.get('fecha_dia_festivo')?.value| date: 'dd/MM/yyyy' }}" type="text" class="form-control sinborde"
                                                pTooltip="Fecha Dia Festivo" tooltipPosition="left" 
                                                placeholder="DD/MM/YYYY"
                                                [style]="{'width':'80%' }" disabled="true">
                                        </div>
                                        <!-- Cuarto Dropdown -->
                                    </div>
                                    <div class="row justify-content-center mt-6">
                                        <div class="col-2 ">
                                            <button type="submit" class="btn btn-success w-100"
                                            [disabled]="!reactiveForm.valid"
                                                pTooltip="Guardar Movimiento" tooltipPosition="left">
                                                <span class="text-white">Guardar</span>
                                                <i class="pi pi-save text-white"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </ng-template>
                <ng-template pTemplate="footer">
                    <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                        (click)="cancelarVentanaModal();"></button>
                </ng-template>
            </p-dialog>
            <!--CATALOGO DE RUTAS-->
            <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dgRutas"
                [style]="{width: '55vw','height':'550px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
                (onHide)="cancelarDialogoRutas()">
                <ng-template pTemplate="header">
                    Catalogo de Rutas a Tabular
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="mt-1">
                        <p-table #dt2 dataKey="code" [value]="lstRutasDisp" [tableStyle]="{ 'min-width': '30rem' }"
                            styleClass="p-datatable-sm" [scrollable]="true" scrollHeight="280px"
                            [globalFilterFields]="['id_ruta','desc_ruta','desc_ciudad','fecha']">
                            <ng-template pTemplate="caption">
                                <div class="flex justify-content-end">
                                    <span class="p-input-icon-left p-ml-auto">
                                        <i class="pi pi-search"></i>
                                        <input pInputText type="text" size="60"
                                            (input)="applyFilterGlobal2($event, 'contains')"
                                            placeholder="Buscar Clave Ruta,Nombre Ruta,Ciudad,Fecha Servicio" />
                                    </span>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Ruta</th>
                                    <th>Ciudad</th>
                                    <th>Fecha</th>
                                    <th></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-data>
                                <tr>
                                    <td>{{ data.desc_ruta }}</td>
                                    <td>{{ data.desc_ciudad }}</td>
                                    <td>{{ data.fecha }}</td>
                                    <td><p-button label="seleccionar" icon="pi pi-check" styleClass="p-button-sm"
                                            (click)="onRowSelectRuta(data)"></p-button></td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </ng-template>
                <ng-template pTemplate="footer">
                    <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                        (click)="cancelarDialogoRutas();"></button>
                </ng-template>
            </p-dialog>
        </div>
    </div>