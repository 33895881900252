import { IResultadoGeneral } from './../models/resultadogeneral';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { ClienteService } from '../services/cliente.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as FileSaver from 'file-saver';
import { saveAs } from 'file-saver';
import ICredencialUsuario from '../models/credencial';
import { AuthService } from '../services/auth.service';
import { CiudadService } from '../services/ciudad.service';
import { ICiudad } from '../models/ciudad';
import { InombreCompletoChofer } from '../models/nombreCompletoChofer';
import { IpostEstatusForaneo } from '../models/postEstatusForaneo';
import { IdeleteEstatusForaneo } from '../models/deleteEstatusForaneo';
import { IMes } from '../models/mes';
import { IrptEstatusForaneo } from '../models/rptEstatusForaneo';
import { ApoyoestatusforaneoService } from '../services/apoyoestatusforaneo.service';
import { IEstatusForaneo } from '../models/estatusforaneo';



@Component({
  selector: 'app-apoyoestatusforaneo',
  templateUrl: './apoyoestatusforaneo.component.html',
  styleUrls: ['./apoyoestatusforaneo.component.scss']
})
export class ApoyoestatusforaneoComponent {

  @ViewChild('dt1') dt!: Table;
  lstCiudad: ICiudad[] = [];
  lstChoferes: InombreCompletoChofer[] = [];
  lstchofCopy: InombreCompletoChofer[] = [];
  lstEstatusForaneo: IEstatusForaneo[] = [];
  lstCiudades: ICiudad[] = [];
  lstCiudadesForanea: ICiudad[] = [];
  mssgListaCiudades: string = '';


  lstrptDescansoFaltas: IrptEstatusForaneo[] = [];
  selectedCiudad: ICiudad = {
    id: 0,
    nombre: '',
    clave_Estado: '',
    id_ciudad_principal: 0
  };


  loading: boolean = false;
  //variables usadas para la fecha, fueron casteadas ya que presentaban errorees
  fecha_hoy: any = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
  dia: any = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
  fecha_filtrada: any = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
  fecha_dia: any = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substring(0, 10);

  fecha_fin: any = null;
  id_ciudad_hm: number = 0;
  dspCliente: boolean = false;
  texto_operacion: string = '';
  num_operacion: number = 0;
  clv_operacion: number = 0;
  habilitar_agregar: boolean = false;



  resultado: IResultadoGeneral = {
    clv_estatus: 0,
    messageDetails: ''
  };

  dsblstCiudades: boolean = true;
  dsblstColaboradores: boolean = true;
  dsblstCiudadesAdscritas: boolean = true;

  // variables de reportes--------------------------
  dbsReportes: boolean = false;

  fecha_fin_filtro: any = null;
  ButtonExportExcel: boolean = false;
  //Fecha de Dias Atras
  fecha_dos_dias_anterior!: Date;
  fecha_del_dia: any = null;
  btnHabilitar: boolean = false;
  mostrar_texto_fechas: number = 0;
  fecha_final_dia!: Date;
  //Fecha para restar 3 dias
  fecha_minus3 !: Date;
  habilitar_tres_dias: boolean = true;
  fecha_dia_real: any = null;
  checkboxValue: boolean = false;
  checked: boolean = false;
  selectedMesReporte1: IMes = {
    num_mes: -1,
    nombre: ""
  }

  anioReporte1: any;
  loading_reporte1: boolean = false;
  meses: IMes[] = [
    { num_mes: 1, nombre: "Enero" },
    { num_mes: 2, nombre: "Febrero" },
    { num_mes: 3, nombre: "Marzo" },
    { num_mes: 4, nombre: "Abril" },
    { num_mes: 5, nombre: "Mayo" },
    { num_mes: 6, nombre: "Junio" },
    { num_mes: 7, nombre: "Julio" },
    { num_mes: 8, nombre: "Agosto" },
    { num_mes: 9, nombre: "Septiembre" },
    { num_mes: 10, nombre: "Octubre" },
    { num_mes: 11, nombre: "Noviembre" },
    { num_mes: 12, nombre: "Diciembre" }];

  reactiveEstatusForaneo: FormGroup = new FormGroup({
    idx: new FormControl(0, [Validators.required]),
    fecha: new FormControl(this.fecha_dia, [Validators.required]),
    fechaFin: new FormControl(this.fecha_fin, [Validators.required]),
    chofer: new FormControl(0, [Validators.required]),
    ciudad: new FormControl(0, [Validators.required]),
    ciudadForanea: new FormControl(0, [Validators.required]),
    importe: new FormControl(0, [Validators.required]),
    nom_ult_mod: new FormControl(''),
    clv_estatus: new FormControl(1)

  });



  //Permisos
  user: ICredencialUsuario = {};
  constructor(private router: Router,
    private apoyoestatusforaneoService: ApoyoestatusforaneoService,

    private ciudadService: CiudadService,
    private confirmationService: ConfirmationService, private messageService: MessageService,
    private clienteService: ClienteService,
    private auth: AuthService) {
    this.cargarInformacionUsuario();
  }

  ngOnInit(): void {
    this.cargaInicial();
  }

  cargarInformacionUsuario() {
    this.user = this.auth.user;
  }

  limpiarfiltrosTabla() {
    this.dt.clear();
  }

  cargaListaChoferes() {
    this.dsblstColaboradores = true;
    this.lstChoferes = [];
    this.lstchofCopy = [];
    this.apoyoestatusforaneoService.getListadoChoferesGeneral(this.fecha_filtrada, this.id_ciudad_hm).subscribe((resp) => {
      this.lstChoferes = resp;
      this.lstchofCopy = resp;
      setTimeout(() => {
        this.dsblstColaboradores = false;
      }, 1000);
    },
      (error) => {
        this.dsblstColaboradores = false;
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Colaboradores');
      });
  }




  // -----------filtra y carga la informacion ----
  FiltrarFechaDiariaRuta() {

    this.loading = true;
    this.dia = this.fecha_filtrada;

    this.fecha_dia = this.fecha_filtrada;
    if (this.id_ciudad_hm == null || typeof this.id_ciudad_hm === 'undefined') {
      this.mostrarDialogoInformativoSinInformacion('<b>Informativo</b> La fecha y Ciudad de Operación o Todas las Ciudades deben seleccionarse');
      this.loading = false;
    } else {
      this.cargaListaChoferes();


      //let cambiar la fecha dia para que permita agregar la fecha inicio
      let fecha_fin_enviar = this.fecha_fin_filtro == null || typeof this.fecha_fin_filtro === 'undefined' ? '1900-01-01' : this.fecha_fin_filtro;
      this.apoyoestatusforaneoService.getListadoEstatusForaneo(this.fecha_dia, fecha_fin_enviar, this.id_ciudad_hm, this.user.cod == undefined ? '0999' : this.user.cod).subscribe((resp) => {
        this.lstEstatusForaneo = [];
        this.lstEstatusForaneo = resp;
        this.clv_operacion = this.clv_operacion != 0 ? this.clv_operacion : this.lstEstatusForaneo.length == 0 ? 0 : this.lstEstatusForaneo[0].clv_operacion;

        this.habilitar_agregar = true;
        if (this.lstEstatusForaneo.length == 0) {
          this.loading = false;
          this.mostrarDialogoInformativoSinInformacion('<b>No Existe</b> información de Rutas para la Fecha ' + this.fecha_filtrada + ' seleccionada');
        }
        setTimeout(() => {
          this.loading = false;
        }, 800);
      },
        (error) => {
          this.mostrarDialogoInformativoSinInformacion('<b>ERROR</b> en Cargar Movimientos del Dia(Gestion de Descanso y Faltas)');
          this.loading = false;
        });
    }

  }




  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  //   guarda la informacion del colaborador
  guardarInformacionDeColaborador() {
    this.fecha_dia = this.fecha_filtrada;
    let data_enviar: IpostEstatusForaneo = {
      idx: this.reactiveEstatusForaneo.get('idx')?.value,
      cod_chofer: this.reactiveEstatusForaneo.get('chofer')?.value,
      id_ciudad_hm_destino: this.reactiveEstatusForaneo.get('ciudadForanea')?.value,
      imp_dia: this.reactiveEstatusForaneo.get('importe')?.value,
      fec_inicio: this.reactiveEstatusForaneo.get('fecha')?.value,
      fec_fin: this.reactiveEstatusForaneo.get('fechaFin')?.value,
      cod_usuario: this.user.cod == undefined ? '0999' : this.user.cod
    };
    if (data_enviar.fec_inicio > data_enviar.fec_fin) {
      this.messageService.add({ severity: 'warn', summary: 'Error en <Agregar Cliente>', detail: 'No se puede ingresar una fecha fin menor a la fecha inicio, favor de verificar.' });

    } else {

      this.apoyoestatusforaneoService.postRecursos(data_enviar).subscribe((resp) => {
        var resultado = resp;
        if (resultado.clv_estatus == 1) {
          //guardar
          setTimeout(() => {
            this.messageService.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se almaceno de forma correcta' });
            this.reactiveEstatusForaneo.reset();
            this.cancelarVentanaModal();
            this.FiltrarFechaDiariaRuta();
          }, 800);
        } else if (resultado.clv_estatus == -2) {
          setTimeout(() => {
            this.messageService.add({ severity: 'error', summary: 'Error en <Agregar Cliente>', detail: resultado.messageDetails });
            this.reactiveEstatusForaneo.reset();
            this.cancelarVentanaModal();
            this.FiltrarFechaDiariaRuta();
          }, 800);
        }
      },
        (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error en <Agregar Cliente>', detail: 'Contacte al Administrador del Sitio' });
        });

    }
  }




  changedp(event: any) {
    let eve = event.value;
    this.lstCiudades = [];

    this.reactiveEstatusForaneo.controls['ciudad'].setValue(0);
    this.reactiveEstatusForaneo.controls['ciudad'].enable();

    if (this.lstchofCopy.length > 0) {
      this.lstchofCopy.map((resp) => {
        if (resp.cod_chofer == eve) {
          if (resp.id_ciudad_hm != 0) {
            this.lstCiudades.push({
              id: resp.id_ciudad_hm,
              nombre: resp.desc_ciudad_hm,
              clave_Estado: '',
              id_ciudad_principal: 0,
              clave_tipoTransporte: 0
            });
          }


        }
      });
    }

    if (this.lstCiudades.length == 1) {
      this.reactiveEstatusForaneo.controls['ciudad'].setValue(this.lstCiudades[0].id);
      this.reactiveEstatusForaneo.controls['ciudad'].disable();
    }

    switch (this.lstCiudades.length) {
      case 0:
        this.mssgListaCiudades = 'El colaborador no cuenta con ciudad favor de verificar';
        break;
      case 1:
        this.mssgListaCiudades = 'El colaborador solo cuenta con ciudad Origen';
        break;
      case 2:
      default:
        this.mssgListaCiudades = 'Seleccione ciudad a registrar';
        break;
    }
  }




  // cargar lista inicial de las ciudades para los dropdown  get
  cargaInicial() {
    this.dsblstCiudades = true;
    this.loading = true;
    this.ciudadService.getListadoCiudades().subscribe((resp) => {
      this.lstCiudad = resp;
      this.lstCiudadesForanea = resp;
      this.lstCiudad.push({ id: 0, nombre: 'TODAS LAS CIUDADES', clave_Estado: '', id_ciudad_principal: 0, clave_tipoTransporte: 0 })

      setTimeout(() => {
        this.loading = false;
        this.dsblstCiudades = false;
      }, 1000);
    },
      (error) => {
        this.loading = false;
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Ciudades');
      });

  }

  //botones de agregar
  mostrarModalAgregar() {
    this.cancelarVentanaModal();
    this.num_operacion = 1;
    this.dspCliente = true;
    this.texto_operacion = 'Agregar Nuevo Apoyo Foraneo';
    this.reactiveEstatusForaneo.controls['idx'].setValue(0);
    this.reactiveEstatusForaneo.controls['fecha'].setValue(this.fecha_hoy);
    this.reactiveEstatusForaneo.controls['ciudad'].disable();
    this.reactiveEstatusForaneo.controls['chofer'].enable();
    this.reactiveEstatusForaneo.controls['ciudadForanea'].enable();
    if (this.lstEstatusForaneo.length > 0 && typeof this.lstEstatusForaneo !== 'undefined') {
      if (this.lstEstatusForaneo[0].clv_operacion == 1) {
        this.reactiveEstatusForaneo.controls['fecha'].disable();
      } else if (this.lstEstatusForaneo[0].clv_operacion == 3) {
        this.reactiveEstatusForaneo.controls['fecha'].enable();
      }
    }

  }



  cancelarVentanaModal() {
    this.num_operacion = 0;
    this.dspCliente = false;
    this.texto_operacion = '';
    this.reactiveEstatusForaneo.reset();
    this.resultado = {
      clv_estatus: 0,
      messageDetails: ''
    };
  }


  mostrarModalEditar(foraneo: IEstatusForaneo) {

    this.lstChoferes.push({
      cod_chofer: foraneo.cod_chofer,
      nombre_completo: foraneo.nom_completo,
      id_ciudad_hm: foraneo.id_ciudad_hm,
      desc_ciudad_hm: foraneo.desc_ciudad_hm,

    })
    //agregar los demas
    this.reactiveEstatusForaneo.controls['idx'].setValue(foraneo.idx);
    this.reactiveEstatusForaneo.controls['ciudad'].setValue(foraneo.id_ciudad_hm);
    this.reactiveEstatusForaneo.controls['fecha'].setValue(foraneo.fec_inicio.toString().substring(0, 10));
    this.reactiveEstatusForaneo.controls['chofer'].setValue(foraneo.cod_chofer);
    this.reactiveEstatusForaneo.controls['nom_ult_mod'].setValue(foraneo.nom_ult_mod);
    this.reactiveEstatusForaneo.controls['fechaFin'].setValue(foraneo.fec_fin.toString().substring(0, 10));
    this.reactiveEstatusForaneo.controls['importe'].setValue(foraneo.imp_dia);
    this.reactiveEstatusForaneo.controls['clv_estatus'].setValue(foraneo.clv_estatus);
    this.reactiveEstatusForaneo.controls['ciudadForanea'].setValue(foraneo.id_ciudad_hm_prestada);

    if (this.lstEstatusForaneo.length > 0 && typeof this.lstEstatusForaneo !== 'undefined') {
      if (this.lstEstatusForaneo[0].clv_operacion == 1) {
        this.reactiveEstatusForaneo.controls['fecha'].disable();
      } else if (this.lstEstatusForaneo[0].clv_operacion == 3) {
        this.reactiveEstatusForaneo.controls['fecha'].enable();
      }
    }
    this.lstCiudades = [];
    if (foraneo.id_ciudad_hm != 0) {
      this.lstCiudades.push({
        id: foraneo.id_ciudad_hm,
        nombre: foraneo.desc_ciudad_hm,
        clave_Estado: '',
        id_ciudad_principal: 0,
        clave_tipoTransporte: 0
      });
    }

    if (foraneo.id_ciudad_hm_prestada != 0 && foraneo.id_ciudad_hm != foraneo.id_ciudad_hm_prestada) {
      this.lstCiudadesForanea.push({
        id: foraneo.id_ciudad_hm_prestada,
        nombre: foraneo.desc_ciudad_hm_prestada,
        clave_Estado: '',
        id_ciudad_principal: 0,
        clave_tipoTransporte: 0
      });
    }

    if (this.clv_operacion == 1) {
      if (foraneo.fec_inicio == this.fecha_hoy) {
        this.reactiveEstatusForaneo.controls['chofer'].disable();
        this.reactiveEstatusForaneo.controls['ciudad'].disable();
        this.reactiveEstatusForaneo.controls['ciudadForanea'].disable();
        this.reactiveEstatusForaneo.controls['fechaFin'].enable();
        this.reactiveEstatusForaneo.controls['fecha'].enable();
      } else {
        this.reactiveEstatusForaneo.controls['chofer'].disable();
        this.reactiveEstatusForaneo.controls['ciudad'].disable();
        this.reactiveEstatusForaneo.controls['ciudadForanea'].disable();
        this.reactiveEstatusForaneo.controls['fechaFin'].enable();
        this.reactiveEstatusForaneo.controls['fecha'].disable();
      }

    } else if (this.clv_operacion == 3) {
      this.reactiveEstatusForaneo.controls['fecha'].enable();
      this.reactiveEstatusForaneo.controls['chofer'].disable();
      this.reactiveEstatusForaneo.controls['ciudad'].disable();
      this.reactiveEstatusForaneo.controls['ciudadForanea'].enable();
      this.reactiveEstatusForaneo.controls['fechaFin'].enable();

    }
    this.num_operacion = 2;
    this.dspCliente = true;
    this.texto_operacion = 'Editar Registro';

  }



  editarEstatus(data: IEstatusForaneo) {
    let resultado: IResultadoGeneral;
    let texto_desactivar: number = data.desc_estatus;
    let descripcionEstatus = data.clv_estatus == 1 ? 'INACTIVO ' : 'ACTIVO'
    let textoFechas = data.fec_inicio == data.fec_fin ? '</b> para el dia <b>' + data.fec_inicio : '</b> registrado del dia <b> ' + data.fec_inicio + '</b> al dia  <b>' + data.fec_fin

    let data_enviar: IdeleteEstatusForaneo = {
      idx: data.idx,
      cod_chofer: data.cod_chofer,
      id_ciudad_hm_destino: data.id_ciudad_hm_prestada,
      imp_dia: data.imp_dia,
      fec_inicio: data.fec_inicio,
      fec_fin: data.fec_fin,
      cod_usuario: this.user.cod == undefined ? '0999' : this.user.cod
    };
    this.confirmationService.confirm({
      message: '¿Está seguro que desea cambiar el estaus a <b>' + descripcionEstatus + '</b> del Colaborador <b> ' + data.nom_completo + textoFechas + '</b>?',
      header: 'Confirmación de edicion de estatus',
      icon: 'pi pi-info-circle',
      accept: () => {

        this.apoyoestatusforaneoService.cambioEstatus(data_enviar).subscribe((resp) => {
          resultado = resp;
          if (resultado.clv_estatus == 1) {
            this.messageService.add({ key: 'msj', severity: 'success', summary: 'Editado', detail: 'Se edito correctamente el servicio' });
            this.FiltrarFechaDiariaRuta();
          } else if (resultado.clv_estatus == -2) {
            this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: resultado.messageDetails });
          }
        });
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
        }
      }
    });

  }


  finalizarEstatus(data: IEstatusForaneo) {
    let resultado: IResultadoGeneral;
    let texto_desactivar: number = data.desc_estatus;
    let descripcionEstatus = data.clv_finalizado == 1 ? 'ACTIVO' : 'FINALIZADA'
    //let descripcionEstatus = data.clv_finalizado == 1? 'ACTIVO':  data.clv_finalizado == 2 ?'INACTIVO' :' FINALIZADA'
    let textoFechas = data.fec_inicio == data.fec_fin ? '</b> para el dia <b>' + data.fec_inicio : '</b> registrado del dia <b> ' + data.fec_inicio + '</b> al dia  <b>' + data.fec_fin

    let data_enviar: IdeleteEstatusForaneo = {
      idx: data.idx,
      cod_chofer: data.cod_chofer,
      id_ciudad_hm_destino: data.id_ciudad_hm_prestada,
      imp_dia: data.imp_dia,
      fec_inicio: data.fec_inicio,
      fec_fin: data.fec_fin,
      cod_usuario: this.user.cod == undefined ? '0999' : this.user.cod
    };
    this.confirmationService.confirm({
      message: '¿Está seguro que desea cambiar el estaus a <b>' + descripcionEstatus + '</b>  del Colaborador <b> ' + data.nom_completo + textoFechas + '</b>?',
      header: 'Confirmación Para Finalizar Apoyo Foraneo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.apoyoestatusforaneoService.editaApoyoFinForaneo(data_enviar).subscribe((resp) => {
          resultado = resp;
          if (resultado.clv_estatus == 1) {
            this.messageService.add({ key: 'msj', severity: 'success', summary: 'Editado', detail: 'Se edito correctamente el servicio' });
            this.FiltrarFechaDiariaRuta();
          } else if (resultado.clv_estatus == -2) {
            this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: resultado.messageDetails });
          }
        });
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
        }
      }
    });

  }

  confirmarEliminar(data: IEstatusForaneo) {
    let resultado: IResultadoGeneral;
    let texto_desactivar: number = data.desc_estatus;
    //que mas ocupo mandar para eliminar rango de fechas?

    let data_enviar: IdeleteEstatusForaneo = {
      idx: data.idx,
      cod_chofer: data.cod_chofer,
      id_ciudad_hm_destino: data.id_ciudad_hm_prestada,
      imp_dia: data.imp_dia,
      fec_inicio: data.fec_inicio,
      fec_fin: data.fec_fin,
      cod_usuario: this.user.cod == undefined ? '0999' : this.user.cod
    };

    this.confirmationService.confirm({
      message: '¿Está seguro que desea eliminar el registro  ' + 'del Colaborador<b> ' + data.nom_completo + ' </b> con estatus <b>' + texto_desactivar + '</b> ?',
      header: 'Confirmación de Eliminación',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.apoyoestatusforaneoService.deleteRecursos(data_enviar).subscribe((resp) => {
          resultado = resp;
          if (resultado.clv_estatus == 1) {
            this.messageService.add({ key: 'msj', severity: 'success', summary: 'Eliminado', detail: 'Se eliminó correctamente el servicio' });
            this.FiltrarFechaDiariaRuta();
          } else if (resultado.clv_estatus == -2) {
            this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: resultado.messageDetails });
          }
        });
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
        }
      }
    });
  }


  saveAsExcelFile(buffer: any, fileName: string): void {
    //var FileSaver = require('file-saver');
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + "_export_" + dateFormat + EXCEL_EXTENSION);
  }

  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '. <b>Contacte al Administrador.</b>';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }


  // ----------------reportes  ------------
  abrirModalReportes() {
    this.dbsReportes = true;
    this.inicializarFechasFormateadas();
  }

  cancelarModalReportes() {
    this.dbsReportes = false;
    // Inicio Reporte 1
    this.selectedMesReporte1 = {
      num_mes: 0,
      nombre: ''
    }
    this.anioReporte1 = null;
    this.selectedCiudad = {
      id: -1,
      nombre: '',
      clave_Estado: '',
      id_ciudad_principal: 0
    };
    this.fecha_fin = null;
  }

  inicializarFechasFormateadas() {
    //hacer globales
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;

    this.fecha_dia = dateFormat;
    this.fecha_del_dia = new Date(dateFormat + 'T00:00:00');
    //Fecha que no se modifica
    this.fecha_final_dia = new Date(dateFormat + 'T00:00:00');
    //Fecha para restar los 3 dias
    let minus3days = new Date(this.fecha_final_dia);
    this.fecha_minus3 = new Date(minus3days.setDate(new Date(minus3days).getDate() - 4));

    //Fecha del dia real, no se mueve
    this.fecha_dia_real = dateFormat;

    //Reportes
    let num_mes: number = +getMonth;
    this.meses.map((mes) => {
      if (mes.num_mes == num_mes) {
        this.selectedMesReporte1.nombre = mes.nombre;
        this.selectedMesReporte1.num_mes = mes.num_mes;
      }
    });
    this.anioReporte1 = getYear;
  }

  // metodo para exportar a excel
  exportarExcel(fecha_dia: string, fecha_fin: string) {
    let fecha_fin_enviar = this.fecha_fin_filtro == null || typeof this.fecha_fin_filtro === 'undefined' ? '1900-01-01' : this.fecha_fin_filtro;
    this.fecha_dia = this.fecha_filtrada;
    this.loading_reporte1 = true;
    if (fecha_dia == null) {
      this.mostrarDialogoInformativo('<b>Aviso</b> Es necesario seleccionar <b>Fecha</b> de Operación a Filtrar');
    } else {
      this.apoyoestatusforaneoService.rptApoyoEstatusForaneo(this.fecha_dia, fecha_fin_enviar, this.id_ciudad_hm)
        .subscribe((resp: IEstatusForaneo[]) => {
          if (resp.length === 0 || typeof resp === 'undefined') {
            this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion del Catalogo para exportar(Excel)');
            this.loading_reporte1 = false;
          } else {

            import("xlsx").then(xlsx => {
              const worksheet = xlsx.utils.json_to_sheet(resp);
              const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
              const excelBuffer: any = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array"
              });
              let texto: string = fecha_fin_enviar == '1900-01-01' || fecha_fin_enviar == null ? '' : '_Al_' + fecha_fin_enviar;
              this.saveAsExcelFile(excelBuffer, "ApoyoEstatusForaneo" + '_' + fecha_dia + texto);
            });
            setTimeout(() => {
              this.ButtonExportExcel = false;
              this.loading_reporte1 = false;
            }, 1000);
          }
        },
          (error) => {
            this.ButtonExportExcel = false;
            this.loading_reporte1 = false;
            this.fecha_fin = null;
            this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: 'Error en emitir el reporte de Estatus de Facturación.' });
          });
    }
  }


  mostrarDialogoInformativo(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '.';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
        this.messageService.clear();
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
        this.messageService.clear();
      },
      key: "sinInformacionDialog"
    });
    this.loading = false;
    this.messageService.clear();
  }


  //metodo para ahcer las validaciones referentes a clv_operacion que es donde se autoriza o no al usuario a modificar
  //validacion generico
  validarClvOperacion(): boolean {

    if (this.clv_operacion == 1) {
      return this.fecha_hoy == this.fecha_filtrada ? true : false
    } else if (this.clv_operacion == 3) {
      return true;
    }
    return false;
  }

  //botones para editar estatus
  validarClvOperacionbtnEstatus(pass: IEstatusForaneo) {
    if (pass.clv_btn_estatus == 1) {
      return true;
    } else {
      return false;
    }
  }

  validarClvOperacionbtnFin(pass: IEstatusForaneo) {
    if (pass.clv_btn_fin == 1) {
      return true;
    } else {
      return false;
    }
  }

  //botones para editar registros

  validarClvOperacionbtnEdit(pass: IEstatusForaneo) {
    if (pass.clv_btn_edit == 1) {
      return true;
    } else {
      return false;
    }
  }

  validarClvOperacionbtnDel(pass: IEstatusForaneo) {
    if (pass.clv_btn_del == 1) {
      return true;
    } else {
      return false;
    }
  }




}
