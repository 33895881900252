<p-toast position="top-right" key="msj"></p-toast>
<!--<p-messages></p-messages>-->
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="Aceptar" rejectLabel="Cancelar"
    rejectButtonStyleClass="p-button-text">
</p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
    rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar"
    [rejectVisible]="false"></p-confirmDialog>

<div class="d-flex justify-content-center">
    <div class="container-fluid" style="width: 85%;">
        <div class="row mt-5">
            <div class="col-md-12">
                <div class="card card-shadow sinborde">
                    <div class="card-header sinborde" style="background: none; ">
                        <div class="cardicono rounded">
                            <i class="pi pi-calendar-plus mt-3" style="font-size: 2rem; color: #fff;"></i>
                        </div>
                        <header style="left: 11%; top: 5%;">
                            <div style="display:inline">Listado de</div>
                            <div style="color:blueviolet;display:inline;" class="ml-3">Salario Diario</div>
                        </header>
                        <div class="row d-flex justify-content-end">
                            <div class="col-2">
                                <p-button label="Agregar" icon="pi pi-plus-circle" iconPos="right"
                                    (click)="mostrarModalAgregar();" pTooltip="Registrar Salario">
                                </p-button>
                            </div>
                        </div>
                    </div>
                    <div class="card-content">
                        <div class="row mt-3">
                            <p-table #dt1 [value]="lstSalarioDiario" [tableStyle]="{'min-width': '50rem'}"
                                styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true" scrollHeight="480px"
                                responsiveLayout="scroll" [globalFilterFields]="['id_anio','monto']"
                                [paginator]="true" [rows]="30" [rowsPerPageOptions]="[10,15,20,30,50]"
                                [showCurrentPageReport]="true" [resizableColumns]="false"
                                currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros"
                                [loading]="loading">
                                <ng-template pTemplate="caption">
                                    <div class="flex justify-content-end">
                                        <span class="p-input-icon-left p-ml-auto">
                                            <i class="pi pi-search"></i>
                                            <input pInputText type="text" size="40"
                                                (input)="applyFilterGlobal($event, 'contains')" placeholder="Buscar Año, Monto" />
                                        </span>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="idx" class="text-center">#<p-sortIcon
                                            field="idx"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="id_anio" class="text-center">Año<p-sortIcon
                                                field="id_anio"></p-sortIcon>
                                        </th>
                                        <th class="text-center">Monto
                                        </th>
                                        <th>Estatus</th>
                                        <th></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-data let-index>
                                    <tr>
                                        <td>
                                            <p class="font-weight-bold text-center">{{data.id_salario_diario}}</p>
                                        </td>
                                        <td>
                                            <p class="font-weight-bold text-center">{{data.anio}}</p>
                                        </td>
                                        <td class="text-center texto_principal">{{data.imp_monto | currency }}</td>
                                        <td><p-tag *ngIf="data.clv_activo==1" severity="success" value="Activo"></p-tag>
                                            <p-tag *ngIf="data.clv_activo==0" [style]="{ 'background': '#D9D9D9'}"
                                                value="Inactivo"></p-tag>
                                        </td>
                                        <td>
                                            <div class="d-flex justify-content-around">
                                                <button class="btn btn-warning" pTooltip="Editar Informacion de Salario"
                                                    tooltipPosition="left" (click)="mostrarModalEditar(data);">
                                                    <i class="pi pi-pencil"></i>
                                                </button>
                                                <button class="btn btn-info" pTooltip="Estatus de Salario"
                                                    (click)="confirmarCambiarEstatus(data.id_anio,data);">
                                                    <i class="pi pi-delete-left"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspDialogo"
            [style]="{width: '60vw','height':'580px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
            (onHide)="cancelarModal()">
            <ng-template pTemplate="header">
                {{ texto_operacion }}
                <div *ngIf="" style="color:black;">
                    {{nombre_registro }}
                </div>
            </ng-template>
            <ng-template pTemplate="content">
                <div class="mt-5">
                    <form [formGroup]="dataForm" (ngSubmit)="guardarInformacion()">
                        <span class="title-light mt-3">Información de Salario Diario <i class="pi pi-cog"
                            style="size: 2rem;"></i>
                        </span>
                        <div class="row mt-3">
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-1 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-history color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                             <!--<p-calendar formControlName="anio" [showIcon]="true" view="year" dateFormat="yy"
                                             [style]="{'width':'100%','height':'80%'}"
                                             placeholder="Seleccione un año*"></p-calendar>-->
                                             <p-calendar formControlName="anio" view="year" [showIcon]="true" dateFormat="yy" inputId="yearpicker"
                                             [style]="{'width':'100%','height':'80%'}"></p-calendar>
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-1 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                        <input type="number" class="form-control sinborde"
                                            pTooltip="Importe de Salario"
                                            formControlName="imp_monto">
                                        <hr class="my-0 mt-1">
                                    </div>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-center mt-5">
                                <div class="col-2">
                                    <button  type="submit" class="btn btn-success w-100"
                                        [disabled]="!dataForm.valid">
                                        <span class="text-white">Guardar</span>
                                        <i class="pi pi-save text-white"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                    (click)="cancelarModal();"></button>
            </ng-template>
        </p-dialog>
    </div>
</div>
