import { CiudadhmService } from './../services/ciudadhm.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { INomina } from '../models/nomina';
import { ICiudadHM } from '../models/ciudadhm';
import { ICiudadPrincipalHM } from '../models/ciudadprincipal';
import { ISemanasHappyMile } from '../models/semanas';
import { Table } from 'primeng/table';
import { CiudadesprincipalesService } from '../services/ciudadesprincipales.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SemanaService } from '../services/semana.service';
import { AuthService } from '../services/auth.service';
import { IColumnas } from '../models/columnas';
import { NominaService } from '../services/nomina.service';
import { IJsonDia } from '../models/json_dia';
import { IJsonOtros } from '../models/json_otros';
import { saveAs } from 'file-saver';
import { IJsonBeneficios } from '../models/jsonbeneficios';
import { IJsonPrestaciones } from '../models/jsonPrestaciones';
import { IjsonApoyos } from '../models/jsonApoyos';
import { IjsonDescVarios } from '../models/jsonDescVarios';
import { IjsonDiaFestivo } from '../models/jsonDiaFestivo';

@Component({
  selector: 'app-consultanomina',
  templateUrl: './consultanomina.component.html',
  styleUrls: ['./consultanomina.component.scss']
})
export class ConsultanominaComponent implements OnInit {
  @ViewChild('dt1') dt!: Table;
  lstNominaPorCiudad: INomina[] = [];
  lstRptNominaPorCiudad: any = [];
  loading_reporteNomina1:boolean=false;
  loading: boolean = false;
  lstSemanas: ISemanasHappyMile[] = [];
  selectedSemana: ISemanasHappyMile = {
    idx: 0,
    id_semana: 0,
    nom_semana: '',
    anio: 0,
    fec_inicio: '',
    fec_fin: '',
    clv_activo: 0
  };
  num_anio_semana: number = 0;
  lstCiudadesPrincipales: ICiudadPrincipalHM[] = [];
  selectedCiudad: ICiudadPrincipalHM = {
    id: 0,
    nombre: '',
    clave_estado: ''
  };
  lstCiudad: ICiudadHM[] = [];
  anio: any;
  anio_filtro:any;
  /*Columnas Dinamicas*/
  cols: IColumnas[] = [{
    field: '',
    header: '',
    dia: 1,
    mes: 0
  },
  {
    field: '',
    header: '',
    dia: 2,
    mes: 0
  },
  {
    field: '',
    header: '',
    dia: 3,
    mes: 0
  },
  {
    field: '',
    header: '',
    dia: 4,
    mes: 0
  },
  {
    field: '',
    header: '',
    dia: 5,
    mes: 0
  },
  {
    field: '',
    header: '',
    dia: 6,
    mes: 0
  },
  {
    field: '',
    header: '',
    dia: 7,
    mes: 0
  }];
  //Totales
  total_dispersion: number=0;
  total_viaticos: number=0;
  dspDialogoServicios: boolean=false;
  dspDialogoOtros: boolean=false;
  dspDialogoBeneficios: boolean=false;
  dspDialogoPrestaciones: boolean=false;
  dspDialogoDiasFestivos:boolean=false
  texto_operacion:string='';
  nombre_registro:string='';
  dspDialogoApoyo:boolean=false;
  dspDialogodesc:boolean=false;
  lstJsonPorDia: IJsonDia[]=[];
  lstJsonOtrosIngresos: IJsonOtros[]=[];
  lstJsonBeneficios: IJsonBeneficios[]=[];
  lstJsonPrestaciones: IJsonPrestaciones[]=[];
  lstJsonDiaFestivo: IjsonDiaFestivo[]=[];
  lstJsonApoyo:IjsonApoyos[]=[];
  lstJsonDesc: IjsonDescVarios[]=[];
  nombre_colaborador:string='';
  fecha_servicio_dia:string='';
  //diario y quincenal
  clv_sueldo_diario_general:number=0;
  clv_sueldo_quincenal_general:number=0;
  constructor(private auth: AuthService, private confirmationService: ConfirmationService, private messageService: MessageService,
    private semanaService: SemanaService, private ciudadesprincipalesService: CiudadesprincipalesService,
    private nominaService:NominaService,private CiudadhmService: CiudadhmService) {
  }
  ngOnInit(): void {
    this.inicializarFechas();
    this.cargarListadoDeCiudadesPrincipales();
    this.cargarListadoDeCiudadesHM();
    this.cargarListadoDeSemanasAnio();
  }


  inicializarFechas() {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.anio = getYear;
    this.anio_filtro = getYear;
  }

  cancelarModal(){
    this.dspDialogoServicios=false;
    this.nombre_colaborador='';
    this.texto_operacion = 'Desgloce de Rutas';
    this.lstJsonPorDia = [];
    this.clv_sueldo_diario_general =0;
    this.clv_sueldo_quincenal_general =0;
  }

  mostrarServicios(dia: IJsonDia[],nombre:string,data: INomina){

    this.nombre_colaborador=nombre;
    this.lstJsonPorDia = dia;
    this.fecha_servicio_dia = dia[0].fec_servicio.substring(0,10);
    this.texto_operacion = 'Desgloce de Rutas';
    //diario y quincenal
    this.clv_sueldo_diario_general =data.clv_sueldo_diario;
    this.clv_sueldo_quincenal_general =data.clv_sueldo_quincenal;
    //
    this.dspDialogoServicios=true;
  }

  mostrarOtrosIngresos(json:IJsonOtros[],nombre:string,data: INomina){
    this.lstJsonOtrosIngresos = json;
    this.nombre_colaborador=nombre;
    this.texto_operacion = 'Desgloce de Otros Ingresos';
    this.dspDialogoOtros=true;
  }

  cancelarOtrosServicios(){
    this.lstJsonOtrosIngresos=[];
    this.nombre_colaborador='';
    this.texto_operacion = '';
    this.dspDialogoOtros=false;
  }

  mostrarBeneficioss(json:IJsonBeneficios[],nombre:string,data: INomina){
    this.lstJsonBeneficios = json;
    this.nombre_colaborador=nombre;
    this.texto_operacion = 'Desgloce de Beneficios';
    this.dspDialogoBeneficios=true;
  }
  cancelarBeneficiosss(){
    this.lstJsonBeneficios=[];
    this.nombre_colaborador='';
    this.texto_operacion = '';
    this.dspDialogoBeneficios=false;
  }

  mostrarPrestaciones(json:IJsonPrestaciones[],nombre:string,data: INomina){
    this.lstJsonPrestaciones = json;
    this.nombre_colaborador=nombre;
    this.texto_operacion = 'Desgloce de Prestaciones';
    this.dspDialogoPrestaciones=true;
  }


  mostrarApoyos(json:IjsonApoyos[],nombre:string,data: INomina){
    this.lstJsonApoyo = json;
    this.nombre_colaborador=nombre;
    this.texto_operacion = 'Desgloce de Apoyos';
    this.dspDialogoApoyo=true;
  }


  cancelarApoyos(){
    this.lstJsonApoyo=[];
    this.nombre_colaborador='';
    this.texto_operacion = '';
    this.dspDialogoApoyo=false;
  }



  mostrarDiasFestivos(json:IjsonDiaFestivo[],nombre:string,data: INomina){
    this.lstJsonDiaFestivo = json;
    this.nombre_colaborador=nombre;
    this.texto_operacion = 'Desgloce de Dias Festivos';
    this.dspDialogoDiasFestivos=true;
  }


  cancelarDiasFestivos(){
    this.lstJsonDiaFestivo=[];
    this.nombre_colaborador='';
    this.texto_operacion = '';
    this.dspDialogoDiasFestivos=false;
  }


  mostrardesc(json:IjsonDescVarios[],nombre:string,data: INomina){
    this.lstJsonDesc = json;
    this.nombre_colaborador=nombre;
    this.texto_operacion = 'Desgloce de Descuentos';
    this.dspDialogodesc=true;
  }


  cancelardesc(){
    this.lstJsonDesc=[];
    this.nombre_colaborador='';
    this.texto_operacion = '';
    this.dspDialogodesc=false;
  }


  cancelarPrestaciones(){
    this.lstJsonPrestaciones=[];
    this.nombre_colaborador='';
    this.texto_operacion = '';
    this.dspDialogoPrestaciones=false;
  }

  reciboPDF(clave_chofer: number, nom_chofer: string) {
    let id_semana = this.selectedSemana ? this.selectedSemana.id_semana : 0;
    let num_anio_semana = this.selectedSemana ? this.selectedSemana.anio : 0;

    this.nominaService.getRecibo(this.num_anio_semana, id_semana, clave_chofer).subscribe(
      (archivo: Blob) => {
        const archivoUrl = URL.createObjectURL(archivo);

        // Reemplazar espacios por guiones bajos en el nombre del chofer
        const nombreArchivo = `ReciboDeNomina_semana_${this.selectedSemana.id_semana}_${this.selectedSemana.anio}_${nom_chofer.replace(/\s+/g, '_')}.pdf`;

        // Crear un enlace <a> para descargar el archivo
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', archivoUrl);
        link.setAttribute('download', nombreArchivo);

        // Agregar el enlace al cuerpo del documento y activar el clic
        document.body.appendChild(link);
        link.click();

        // Limpiar el enlace del DOM después de la descarga
        document.body.removeChild(link);

        // Liberar recursos de la URL del archivo
        URL.revokeObjectURL(archivoUrl);
      },
      error => {
        console.error('Error al descargar el archivo:', error);
        // Manejar el error según tus necesidades
      }
    );
  }
  reciboactivities(clave_chofer: number, nom_chofer: string) {
    let id_semana = this.selectedSemana ? this.selectedSemana.id_semana : 0;
    let num_anio_semana = this.selectedSemana ? this.selectedSemana.anio : 0;

    this.nominaService.getactivities(this.num_anio_semana, id_semana, clave_chofer).subscribe(
      (archivo: Blob) => {
        const archivoUrl = URL.createObjectURL(archivo);

        // Reemplazar espacios por guiones bajos en el nombre del chofer
        const nombreArchivo = `DetalleDeActividades_semana_${this.selectedSemana.id_semana}_${this.selectedSemana.anio}_${nom_chofer.replace(/\s+/g, '_')}.pdf`;

        // Crear un enlace <a> para descargar el archivo
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', archivoUrl);
        link.setAttribute('download', nombreArchivo);

        // Agregar el enlace al cuerpo del documento y activar el clic
        document.body.appendChild(link);
        link.click();

        // Limpiar el enlace del DOM después de la descarga
        document.body.removeChild(link);

        // Liberar recursos de la URL del archivo
        URL.revokeObjectURL(archivoUrl);
      },
      error => {
        console.error('Error al descargar el archivo:', error);
        // Manejar el error según tus necesidades
      }
    );
  }

  consultaSemanas() {
    alert('Historico');
  }

  cargarInformacionNominaPorSemana() {
    this.total_dispersion = 0;
    this.total_viaticos = 0;
    let id_semana = this.selectedSemana == null ? 0 : this.selectedSemana.id_semana;
    let id_ciudad_principal = this.selectedCiudad == null ? 0 : this.selectedCiudad.id;
    this.num_anio_semana = this.selectedSemana == null  ? 0 : this.selectedSemana.anio;
    if(id_semana!=0 && id_ciudad_principal!=0 && this.num_anio_semana!=0){
      this.loading=true;
      this.nominaService.getHeaders(id_semana,this.num_anio_semana,id_ciudad_principal).subscribe((resp)=> {
        this.cols = resp;
      },
      (error) => {
        this.loading=false;
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> al cargar Información. Contacte al Administrador.');
      });
      this.nominaService.getNominaPorSemanayCiudad(id_semana,this.num_anio_semana,id_ciudad_principal).subscribe((resp) => {
        this.lstNominaPorCiudad = resp;
        if(this.lstNominaPorCiudad.length>0){
          this.sumarTotalDispersion();
          this.sumarTotalViaticos();
        }
        setTimeout(() => {
          this.loading=false;
        },
          900);
      },
        (error) => {
          this.loading=false;
          this.mostrarDialogoInformativoSinInformacion('<b>Error</b> al cargar Información. Contacte al Administrador.');
        });
    } else {
      this. mostrarMensajeDeAlerta();
    }
  }

  sumarTotalDispersion(){
    this.lstNominaPorCiudad.map((valor)=> {
      if(valor.imp_total_dispersion>0){
        this.total_dispersion += valor.imp_total_dispersion;
      }
    });
  }

  sumarTotalViaticos(){
    this.lstNominaPorCiudad.map((valor)=> {
      if(valor.imp_viaticos>0){
        //Colaborador de baja en la semana, se descartan los viaticos para el total.
        this.total_viaticos += (valor.clv_activo==1 ? valor.imp_viaticos : 0);
      }
    });
  }

  mostrarMensajeDeAlerta(){
    this.loading=false;
    this.messageService.add({
      key: 'confirm',
      sticky: true,
      severity: 'info',
      summary: 'Falta Semana y/o Ciudad',
      detail: 'Semana y Ciudad deben seleccionarse para consultar información.'
    });
  }

  cancelarMensajeConfirmacion(){
    this.messageService.clear('confirm');
    this.messageService.add({key: 'msj',severity:'info', summary:'Confirmación', detail:'Usuario informado de la situación.'});

  }

  confirmarMensaje(){
    this.messageService.clear('confirm');
    this.messageService.add({key: 'msj',severity:'info', summary:'Confirmación', detail:'Usuario informado de la situación.'});
  }


  guardarTodo() {

  }

  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  getTargetDescription(ruta: ISemanasHappyMile) {
    let fec_inicio = new Date(ruta.fec_inicio);
    let fec_fin = new Date(ruta.fec_fin);
    //return ruta.fec_inicio.substring(0,10)+' | '+ruta.fec_fin.substring(0,10)+'';
    return this.formatearFechas(fec_inicio) + ' | ' + this.formatearFechas(fec_fin);
  }

  formatearFechas(fecha: Date): string {
    let fecha_Cast = '';
    var getYear = new Date(fecha).toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date(fecha).toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date(fecha).toLocaleDateString('es-MX', { day: '2-digit' });
    fecha_Cast = getDay + '/' + getMonth + '/' + getYear;
    return fecha_Cast;
  }

  cargarListadoDeSemanasAnio() {
    this.lstSemanas = [];
    this.semanaService.listarSemanas(this.anio).subscribe((resp) => {
      this.lstSemanas = resp;
      setTimeout(() => {
      },
        600);
    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>Error al cargar Semanas HM</b>. Contacte al Administrador.');
      });
  }

  cargarListadoDeSemanasAnio_cast(num_anio:any) {
    let anio_cast: any = 0;
    if (num_anio instanceof Date) {
      // will execute
      anio_cast = num_anio.getFullYear();
    } else {
      anio_cast = num_anio;
    }
    this.lstSemanas = [];
    this.semanaService.listarSemanas(anio_cast).subscribe((resp) => {
      this.lstSemanas = resp;
      //reiniciar
      this.lstNominaPorCiudad = [];
      this.lstJsonPorDia=[];
      setTimeout(() => {
      },
        600);
    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>Error al cargar Semanas HM</b>. Contacte al Administrador.');
      });
  }

  cargarListadoDeCiudadesPrincipales() {
    this.lstCiudadesPrincipales = [];
    this.ciudadesprincipalesService.getListadoCiudadesPrincipales().subscribe((resp) => {
      this.lstCiudadesPrincipales = resp;
      setTimeout(() => {
      },
        600);
    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> al cargar Información de Ciudades Principales. Contacte al Administrador.');
      });
  }

  cargarListadoDeCiudadesHM() {
    this.lstCiudad = [];
    this.CiudadhmService.getListadoCiudades().subscribe((resp) => {
      this.lstCiudad = resp;
      setTimeout(() => {
      },
        600);
    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> al cargar Información de Ciudades HM. Contacte al Administrador.');
      });
  }

  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario;
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }

  generarReporte1() {
    this.loading_reporteNomina1 = true;
    this.lstRptNominaPorCiudad = [];
    if ((this.selectedSemana == null || this.selectedSemana.id_semana == -1) || (this.anio === 'undefined' || this.anio == null)
    || (this.selectedCiudad == null || this.selectedCiudad.id == -1)) {
      this.mostrarMensajeDeAlerta();
      this.loading_reporteNomina1 = true;
    } else {
      let semana = this.selectedSemana.id_semana;
      let anio_cast: any = 0;
      if (this.anio instanceof Date) {
        // will execute
        anio_cast = this.anio.getFullYear();
      } else {
        anio_cast = this.anio;
      }
      this.cargarReporteNomina(this.selectedCiudad.id, semana, anio_cast);
    }
  }

  cargarReporteNomina(id_ciudad_hm: number, semana: number, num_anio: number){
    let id_semana = this.selectedSemana == null ? 0 : this.selectedSemana.id_semana;
    let id_ciudad_principal = this.selectedCiudad == null ? 0 : this.selectedCiudad.id;
    this.num_anio_semana = this.selectedSemana == null  ? 0 : this.selectedSemana.anio;
    if(id_semana!=0 && id_ciudad_principal!=0 && this.num_anio_semana!=0){
      this.nominaService.getRptNominaPorSemanayCiudad(id_semana,this.num_anio_semana,id_ciudad_principal).subscribe((resp) => {
        this.lstRptNominaPorCiudad = resp;
        if(this.lstRptNominaPorCiudad.length == 0 || typeof this.lstRptNominaPorCiudad === 'undefined'){
            this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe información para exportar(Excel)');
          } else {
            //Validar si tienen información
            let nombre:string='ReporteNominaSemanal_'+this.selectedCiudad.nombre.replace(' ','_')+'_'+this.selectedSemana.nom_semana.replace(' ','_')+'_'+this.num_anio_semana;
            this.asignarArregloParaExportarDinamico(this.lstRptNominaPorCiudad,nombre);
            setTimeout(() => {
              this.loading_reporteNomina1 = false;
            },
              800);
          }
      },
      (error) => {
        this.loading=false;
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> al cargar Información. Contacte al Administrador.');
      });
    } else {
      this. mostrarMensajeDeAlerta();
    }
  }

  asignarArregloParaExportarDinamico(data: any[],nombre_reporte:string) {
    let data_cast: any = [];
    let header: any = {};
    for (const dat of data) {
      //
      /*Aqui se debe inicializar el header*/
      //
      header = {};
      for (const [key, value] of Object.entries(dat)) {
        if (key === "nombrechofer") {
          header.NombreChofer = value;
        } else {
          header[key.charAt(0).toUpperCase() + key.slice(1)] = value;
        }
      }
      data_cast.push(header);
    }
    if (data_cast.length > 0) {
      this.generarReporteExcelCasteado(data_cast, nombre_reporte);
    } else {
      this.mostrarDialogoInformativo('No existe información para generar el Reporte.');
    }
  }

  generarReporteExcelCasteado(data: any, nombre_reporte: string) {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array"
      });
      this.saveAsExcelFile(excelBuffer, nombre_reporte);
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    //var FileSaver = require('file-saver');
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName /*+ "_export_" + dateFormat */ + EXCEL_EXTENSION);

  }

  mostrarDialogoInformativo(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '.';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
        this.messageService.clear();
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
        this.messageService.clear();
      },
      key: "sinInformacionDialog"
    });
    this.loading = false;
    this.messageService.clear();
  }
}
