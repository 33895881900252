import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Ibonoaguinaldo } from '../models/bonoaguinaldo';
import { ICiudadesPrincipales } from '../models/CiudadesPrincipales';
import { CiudadesprincipalesService } from './ciudadesprincipales.service';  // Importado
import { ICiudadPrincipalHM } from '../models/ciudadprincipal';

@Injectable({
  providedIn: 'root'
})
export class BonoaguinaldoService {
  private apiUrl: string = environment.sUrlNomina + 'BonoAguinaldo';

  constructor(private http: HttpClient, private ciudadesPrincipalesService: CiudadesprincipalesService) { }  // Inyección de CiudadesprincipalesService

  getListadoBonoAguinaldo(num_anio: number, id_ciudad_principal_hm: number): Observable<Ibonoaguinaldo[]> {
    const valores = {
      params: new HttpParams()
        .set('num_anio', num_anio)
        .set('id_ciudad_principal_hm', id_ciudad_principal_hm)
    };
    return this.http.get<Ibonoaguinaldo[]>(this.apiUrl, valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  getListadoCiudadesPrincipales(): Observable<ICiudadPrincipalHM[]> {
    // Uso del método del servicio importado
    return this.ciudadesPrincipalesService.getListadoCiudadesPrincipales();
  }

  rptBonoAguinaldo(num_anio: number, id_ciudad_principal_hm: number): Observable<any> {
    const valores = {
      params: new HttpParams()
        .set('num_anio', num_anio)
        .set('id_ciudad_principal_hm', id_ciudad_principal_hm)
    };
    return this.http.get<any>(this.apiUrl + '/rptAguinaldo', valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  private handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(() => new Error(errorMessage));
  }
}
