import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, catchError, retry, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ICajaAhorro } from '../models/caja_ahorro';
import { ICajaAhorroEnviar } from '../models/caja_ahorro_enviar';
import { IColaboradoresActivos } from '../models/colaboradores_activos';
import { Iahorro } from '../models/listaahorro';

@Injectable({
  providedIn: 'root'
})
export class CajaahorroService {
  private apiUrl: string ='';
  constructor(private _http: HttpClient) {
    this.apiUrl = environment.sUrlNomina + 'CajaAhorro';
  }

  cargarInformacionColaboradoresActivos(): Observable<IColaboradoresActivos[]>{
    return this._http
      .get<any>(this.apiUrl+'/ChoferesActivos')
      .pipe(retry(1), catchError(this.handleError));
  }


  gethisahorro(cod_chofer: number): Observable<Iahorro[]> {
    const valores = {
      params: new HttpParams()
        .set('cod_chofer', cod_chofer)
    };
    return this._http.get<Iahorro[]>(this.apiUrl + '/hisahorro', valores)
      .pipe(retry(1), catchError(this.handleError));
  }



  // postbajarahorro(id_semana: number,num_anio: number,cod_usuario: string,clv_his: number): Observable<any> {
  //   const valores = {
  //     params: new HttpParams()
  //       .set('id_semana', id_semana)
  //       .set('num_anio', num_anio)
  //       .set('cod_usuario', cod_usuario)
  //       .set('clv_his', clv_his)
  //   };
  //   return this._http.post<any>(this.apiUrl + '/bajarAhorro', valores)
  //     .pipe(retry(1), catchError(this.handleError));
  // }

  postbajarahorro(id_semana: number, num_anio: number, cod_usuario: string, clv_his: number,id_ciudad_principal:number): Observable<any> {
    const valores = {
      id_semana: id_semana,
      num_anio: num_anio,
      cod_usuario: cod_usuario,
      clv_his: clv_his,
      id_ciudad_principal:id_ciudad_principal
    };

    return this._http.post<any>(this.apiUrl + '/bajarAhorro', valores)
      .pipe(retry(1), catchError(this.handleError));
  }











  postpagarahorro(cod_chofer: number, clv_pagado: number, anio:number,cod_usuario:string,id_semana:number): Observable<any> {
    const params = new HttpParams()
      .set('cod_chofer', cod_chofer) // Convertir a cadena si es necesario
      .set('clv_pagado', clv_pagado)
      .set('num_anio', anio)
      .set('cod_usuario', cod_usuario)
      .set('id_semana', id_semana)
      ; // Convertir a cadena si es necesario

    return this._http.post<any>(`${this.apiUrl}/pagarahorro`, null, { params });
  }

  pagoanual( anio:number,cod_usuario:string, id_ciudad_principal: number): Observable<any> {
    const params = new HttpParams()

      .set('num_anio', anio)
      .set('cod_usuario', cod_usuario)
      .set('id_ciudad_principal', id_ciudad_principal)
      ; // Convertir a cadena si es necesario

    return this._http.post<any>(`${this.apiUrl}/pagoanual`, null, { params });
  }

  cargarInformacionCajaAhorro(num_anio:number): Observable<ICajaAhorro[]>{
    const valores = {
      params: new HttpParams().
        set('num_anio', num_anio)
    };
    return this._http
      .get<any>(this.apiUrl, valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  public agregar(data: ICajaAhorroEnviar ):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this._http.post<any>(this.apiUrl,body,{'headers':headers});
   }

   public editar(data: ICajaAhorroEnviar ):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this._http.put<any>(this.apiUrl,body,{'headers':headers});
   }

  public estatusAhorro(data: ICajaAhorroEnviar):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this._http.put<any>(this.apiUrl+'/editarStatus',body,{'headers':headers});
   }

   rptCaa(num_anio: number, id_semana: number,id_ciudad_principal_rpt: number): Observable<any> {
    const valores = {
      params: new HttpParams()
        .set('num_anio', num_anio)
        .set('id_semana', id_semana)
        .set('id_ciudad_principal', id_ciudad_principal_rpt)
    };
    return this._http.get<any>(this.apiUrl + '/rptCaa', valores)
      .pipe(retry(1), catchError(this.handleError));
  }
  // rptCaa(num_anio: number, id_semana: number,id_ciudad_principal_rpt: number): Observable<any> {
  //   const valores = {
  //     num_anio: num_anio,
  //     id_semana: id_semana,
  //     id_ciudad_principal: id_ciudad_principal_rpt,

  //   };

  //   return this._http.get<any>(this.apiUrl + '/bajarAhorro', valores)
  //     .pipe(retry(1), catchError(this.handleError));
  // }



  handleError(error:any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
        return errorMessage;
    });
  }

}
