import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { IDiasFestivos } from '../models/diasfestivos';

@Injectable({
  providedIn: 'root'
})
export class DiasService {
  private apiUrl: string ='';
  constructor(private _http: HttpClient) { 
    this.apiUrl = environment.sUrlNomina + 'DiasFestivos';
  }

  public listarDiasFestivos(anio:number):Observable<any>{
    return this._http.get(this.apiUrl+'/'+anio);
   }

   public agregarDiaFestivo(data: IDiasFestivos ):Observable<any>{
    const headers = { 'content-type': 'application/json'}  
    const body = JSON.stringify(data); 
    return this._http.post<any>(this.apiUrl,body,{'headers':headers});
   }

   public editarDiaFestivo(data: IDiasFestivos ):Observable<any>{
    const headers = { 'content-type': 'application/json'}  
    const body = JSON.stringify(data); 
    return this._http.put<any>(this.apiUrl,body,{'headers':headers});
   }

   public estatusDiaFestivo(data: IDiasFestivos ):Observable<any>{
    const headers = { 'content-type': 'application/json'}  
    const body = JSON.stringify(data); 
    return this._http.put<any>(this.apiUrl+'/editarStatus',body,{'headers':headers});
   }
}
