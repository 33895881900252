import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, catchError, retry, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ICajaAhorro } from '../models/caja_ahorro';


@Injectable({
  providedIn: 'root'
})
export class CajaahorroanualService {
  private apiUrl: string ='';
  constructor(private _http: HttpClient) {
    this.apiUrl = environment.sUrlNomina + 'CajaAhorroAnual';
  }


  getCajaAhorroanual(num_anio:number): Observable<ICajaAhorro[]>{
    const valores = {
      params: new HttpParams().
        set('num_anio', num_anio)
    };
    return this._http
      .get<any>(this.apiUrl, valores)
      .pipe(retry(1), catchError(this.handleError));
  }



  rptCaaanual(num_anio: number,id_ciudad_principal_rpt: number): Observable<any> {
    const valores = {
      params: new HttpParams()
        .set('num_anio', num_anio)

        .set('id_ciudad_principal', id_ciudad_principal_rpt)
    };
    return this._http.get<any>(this.apiUrl + '/rptCaaanual', valores)
      .pipe(retry(1), catchError(this.handleError));
  }




  handleError(error:any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
        return errorMessage;
    });
  }

}
