import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IIncapacidades } from '../models/incapacidades';
import { IpostIncapcidad } from '../models/postIncapacidades';
import { ICatIncapacidades } from '../models/catincapacidades';
import { ICatSubIncapacidades } from '../models/catsubincapacidades';

@Injectable({
  providedIn: 'root'
})
export class IncapacidadService{

  private apiUrl: string = environment.sUrlNomina + 'Incapacidades';


  constructor(private http: HttpClient) { }

 getListadoIncapacidad(num_anio: number,id_mes:number ,id_ciudad_hm: number, cod_usuario : string): Observable<IIncapacidades[]> {
  const valores = {
    params: new HttpParams().
       set('num_anio',num_anio).
       set('id_mes',id_mes).
       set('id_ciudad_hm',id_ciudad_hm).
       set('cod_usuario',cod_usuario)
  }
  return this.http
      .get<IIncapacidades[]>(this.apiUrl,valores)
      .pipe(retry(1), catchError(this.handleError));

 }

 public postIncapacidad(data: IpostIncapcidad):Observable<any>{
  const headers = { 'content-type': 'application/json'}
  const body = JSON.stringify(data);
  return this.http.post<any>(this.apiUrl + '/postIncapacidad/',body,{'headers':headers});
 }


 getCatIncapacidades(): Observable<ICatIncapacidades[]> {
  return this.http
      .get<ICatIncapacidades[]>(this.apiUrl + '/getcatin')
      .pipe(retry(1), catchError(this.handleError));

 }

 getCatSubIncapacidades(): Observable<ICatSubIncapacidades[]> {
  return this.http
      .get<ICatSubIncapacidades[]>(this.apiUrl + '/getcatsubin')
      .pipe(retry(1), catchError(this.handleError));

 }





//  getListadoChoferesGeneral( ciudad:number  ): Observable<InombreCompletoChofer[]> {
//   const valores = {
//     params: new HttpParams().
//        set('id_ciudad_hm', ciudad)
//       }
//   return this.http.get<InombreCompletoChofer[]>(this.apiUrl+'/getChoferesGenerales' , valores)
//   .pipe(retry(1), catchError(this.handleError));
// }

//   rptApBonoRecomendacion(fec_inicio: string, fecha_fin_filtro: string,id_ciudad_hm:number ): Observable<any> {
//   const valores = {
//     params: new HttpParams().
//        set('fec_inicio',fec_inicio).
//        set('fec_fin',fecha_fin_filtro).
//        set('id_ciudad_hm',id_ciudad_hm)
//       }
//   return this.http
//       .get<any>(this.apiUrl +'/rptApoyoEstatusForaneo',valores)
//       .pipe(retry(1), catchError(this.handleError));

//  }

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }

}
