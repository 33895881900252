import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CiudadesprincipalesService } from './ciudadesprincipales.service';
import { ICiudadPrincipalHM } from '../models/ciudadprincipal';
import { IDeducciones } from '../models/deducciones';


@Injectable({
  providedIn: 'root'
})
export class DeduccionesService {
  private apiUrl: string = environment.sUrlNomina + 'Deducciones';

  constructor(private http: HttpClient, private ciudadesPrincipalesService: CiudadesprincipalesService) { }
  getListadodeducciones(num_anio: number, id_ciudad_hm: number, id_semana: number): Observable<IDeducciones[]> {
    const valores = {
      params: new HttpParams()
        .set('num_anio', num_anio)
        .set('id_ciudad_hm', id_ciudad_hm)
        .set('id_semana', id_semana)

    };
    return this.http.get<[IDeducciones]>(this.apiUrl, valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  getListadoCiudadesPrincipales(): Observable<ICiudadPrincipalHM[]> {
    // Uso del método del servicio importado
    return this.ciudadesPrincipalesService.getListadoCiudadesPrincipales();
  }



  rptDeducciones(num_anio_rpt: number, id_semana_rpt: number,id_ciudad_hm_rpt: number): Observable<any> {
    const valores = {
      params: new HttpParams()
        .set('num_anio', num_anio_rpt)
        .set('id_semana', id_semana_rpt)
        .set('id_ciudad_hm', id_ciudad_hm_rpt)
    };
    return this.http.get<any>(this.apiUrl + '/rptdeduccion', valores)
      .pipe(retry(1), catchError(this.handleError));
  }


  private handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(() => new Error(errorMessage));
  }
}
