<p-toast position="top-right" key="msj"></p-toast>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="Aceptar" rejectLabel="Cancelar"
  rejectButtonStyleClass="p-button-text">
</p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
  rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar"
  [rejectVisible]="false"></p-confirmDialog>
<div class="d-flex justify-content-center">
  <div class="container-fluid" style="width: 98%;">
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="card card-shadow sinborde">
          <div class="card-header sinborde" style="background: none; ">
            <div class="cardicono rounded">
              <i class="pi pi-calendar-plus mt-3" style="font-size: 2rem; color: #fff;"></i>
            </div>
            <header style="left: 11%; top: 5%;">
              <div style="display:inline">Gestión de</div>
              <div style="color:rgb(226, 101, 43);display:inline;" class="ml-3">Descuentos Varios</div>
            </header>
            <div class="row mt-6 ml-6 d-flex justify-content-between">
              <!-- <div class="col-md-12 "> -->
              <!-- -------------fecha del filtro---------------- -->
              <div class="form-group col-md-7 d-flex">
                <!-- <div class="col-md-1"></div> -->
                <!-- filtro de semanas -->
                <div class="col-md-4">
                  <p-dropdown [options]="lstSemanas" autoWidth="false" [style]="{'width':'100%'}"
                    placeholder="Seleccione Semana*" [(ngModel)]="selectedSemana" optionLabel="nom_semana"
                    [showClear]="false" [disabled]="lstSemanas.length == 0">
                    <ng-template let-target pTemplate="item">
                      <div class="ui-helper-clearfix" style="position: relative;height:25px;"
                        pTooltip="{{getTargetDescription(target)}}" tooltipPosition="top">
                        <span style="font-size:14px;float:left;margin-top:4px">{{target.nom_semana}}</span>
                      </div>
                    </ng-template>
                  </p-dropdown>
                  <div *ngIf="selectedSemana!=null">
                    <div class="row">
                      <div style="color: green;" class="col-sm-6 text-left ">
                        <b>Inicio:
                          {{selectedSemana.fec_inicio | date: 'dd/MM/yyyy' }}</b>
                      </div>
                      <div style="color: green;" class="col-sm-6 text-right "><b>Fin:
                          {{selectedSemana.fec_fin | date: 'dd/MM/yyyy' }}</b>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- filtro de ciudades -->
                <div class="col-md-3 ml-4">
                  <p-dropdown [options]="lstCiudad_filtro" optionLabel="nombre" optionValue="id"
                    [(ngModel)]="selectedCiudad" placeholder="Filtrar por Ciudad" [showClear]="false"
                    [disabled]="lstSemanas.length == 0">
                  </p-dropdown>
                </div>

                <div class="col-md-2">
                  <p-button label="Filtrar" icon="pi pi-list" iconPos="right" (click)="cargarDescuentosVarios()"
                    [disabled]="lstSemanas.length == 0">
                  </p-button>
                </div>

                <div class="col-md-3">

                  <!-- <button pButton pRipple label="Registro" class="p-button-warning" icon="pi pi-plus"
                    (click)="registrarDescuento();" pTooltip="Registro Descuento" tooltipPosition="right"
                    iconPos="right" [disabled]="lstSemanas.length == 0"></button> -->
                </div>
                <!-- <div class="col-md-1 "></div> -->

                <!-- ----------boton principal de los reportes en excel------------------- -->
                <!-- <div class="  col-md-1 ml-4 ">
                                            <button type="button" pButton pRipple class="p-button-success "
                                                pTooltip="Exportar Catalogo a EXCEL" tooltipPosition="right"
                                                (click)="abrirModalReportes()">
                                                <i class="pi pi-file-excel"></i> Exportar
                                            </button>
                                        </div> -->
              </div>
              <div class="form-group col-md-4">
                <!-- <div class=" ml-4 col-md-3 d-flex justify-content-between">
                                            <div> -->
                <div class="row d-flex justify-content-start">
                  <div class="row d-flex justify-content-start">
                    <div class="col-md-3 "></div>
                    <div class="col-md-4 ml-4">
                      <p-calendar [(ngModel)]="anio_filtro" view="year" [showIcon]="true" dateFormat="yy"
                        inputId="yearpicker" [style]="{'width':'100%','height':'80%'}"
                        pTooltip="Año de Semanas a Consultar" tooltipPosition="top"></p-calendar>
                    </div>
                    <div class="col-md-3">
                      <button pButton pRipple label="Cargar" class="p-button-help" icon="pi pi-filter"
                        (click)="cargarListadoDeSemanasAnio_cast(anio_filtro);" pTooltip="Cargar Semanas del Año"
                        tooltipPosition="top"></button>
                    </div>
                  </div>
                </div>

              </div>
              <div class="col-md-1"></div>

              <!-- </div> -->
            </div>
            <div class="card-content">
              <div class="row mt-3">
                <p-table #dt1 [value]="lstDescuentosVarios" [tableStyle]="{'min-width': '50rem'}"
                  styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true" scrollHeight="480px"
                  responsiveLayout="scroll" [globalFilterFields]="['cod_chofer','nombre_completo','desc_ciudad']"
                  [paginator]="true" [rows]="30" [rowsPerPageOptions]="[10,15,20,30,50,100]"
                  [showCurrentPageReport]="true" [resizableColumns]="false"
                  currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros" [loading]="loading">
                  <ng-template pTemplate="caption">
                    <div class="flex justify-content-between">
                      <div class="col-md-3">
                        <button pButton pRipple label="Agregar nuevo registro" class="btn btn-success" icon="pi pi-plus"
                          (click)="registrarDescuento();" pTooltip="Registro Descuento" tooltipPosition="right"
                          iconPos="right" [disabled]="lstSemanas.length == 0 || dspCalculo || dspDialogoDispercion"></button>
                      </div>

                      <span class="p-input-icon-left p-ml-auto">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" size="40" (input)="applyFilterGlobal($event, 'contains')"
                          placeholder="Buscar Codigo Colaborador,Nombre Colaborador,Ciudad de Operación" />
                      </span>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="text-center" pSortableColumn="cod_chofer">#<p-sortIcon field="cod_chofer"></p-sortIcon>
                      </th>
                      <th class="text-center" pSortableColumn="nombre_completo">Colaborador<p-sortIcon
                          field="nombre_completo"></p-sortIcon></th>
                      <th class="text-center" pSortableColumn="desc_ciudad">Ciudad<p-sortIcon
                          field="desc_ciudad"></p-sortIcon></th>
                      <th class="text-center" pSortableColumn="desc_categoria">Categoria<p-sortIcon
                          field="desc_categoria"></p-sortIcon></th>
                      <th class="text-center" pSortableColumn="desc_sub_categoria">Sub Categoria<p-sortIcon
                          field="desc_sub_categoria"></p-sortIcon></th>
                      <th class="text-center" pSortableColumn="imp_total">Importe Total<p-sortIcon
                          field="imp_total"></p-sortIcon></th>
                      <th class="text-center" pSortableColumn="id_semana_inicial">Semana Inicial<p-sortIcon
                          field="id_semana_inicial"></p-sortIcon></th>
                      <th class="text-center" pSortableColumn="num_pagos">Pagos Acordados<p-sortIcon
                          field="num_pagos"></p-sortIcon></th>
                      <th class="text-center" pSortableColumn="desc_estatus">Estatus<p-sortIcon
                          field="desc_estatus"></p-sortIcon></th>
                      <th class="text-center">Dispersión</th>
                      <th class="text-center">Acciones</th>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-data let-index>
                    <tr>
                      <td class="text-center"> <b>{{data.cod_chofer}}</b></td>
                      <td><b>{{data.nombre_completo }}</b> </td>
                      <td class="text-center"><b>{{data.desc_ciudad}}</b></td>
                      <td class="text-center texto_principal">{{data.desc_categoria}}</td>
                      <td class="text-center" style="color: #2a99bb;"><b>{{ data.desc_sub_categoria}}</b> </td>
                      <td class="text-center" style="color: rgb(202, 35, 35);"><b>{{ data.imp_total | currency}}</b>
                      </td>
                      <td class="text-center " style="color: #f0ad38;"><b>{{ data.id_semana_inicial}}</b></td>
                      <td class="text-center" style="color: #2ed249;"><b>{{data.num_pagos}}</b></td>
                      <td class="text-center texto_principal"
                        [ngStyle]="{'color': data.desc_estatus==='ACTIVO' ? 'rgb(28, 163, 35)' : 'rgb(224, 82, 60)'}">
                        {{ data.desc_estatus}}</td>
                      <td class="text-center texto_principal">
                        <button class=" ml-2 btn btn-light" tooltipPosition="top" (click)="mostrarDialogoDispersion(data)"
                        pTooltip="Desgloce Otros Ingresos" [disabled]="dspCalculo || dspDialogoDispercion">
                        <i class="pi pi-info-circle color-icon" style="font-size: 1.5rem; color: #39a4e2;">
                        </i></button>

                        <!-- <span class="input-group-addon ml-2 my-0" (click)="mostrarDialogoDispersion(data)"
                          pTooltip="Desgloce Otros Ingresos">
                          <i class="pi pi-info-circle color-icon mr-3" style="font-size: 1.5rem; color: #3B82F6;">
                          </i>
                        </span> -->
                      </td>
                      <td class="texto_principal">
                        <button *ngIf="data.clv_btn_eliminar == 1" class="btn btn-danger w-100"
                          pTooltip="Guardar Asignación de Bono" [disabled]="dspCalculo || dspDialogoDispercion"
                          (click)="deleteDescuento(data.id_descuento,data.nombre_completo)">
                          <span class="text-white">Eliminar</span>
                          <i class="pi pi-save text-white"></i>
                        </button>
                      </td>
                      <td></td>
                    </tr>
                  </ng-template>
                  <!-- <ng-template pTemplate="summary">
                                        <div class="flex align-items-center justify-content-center mb-2 mt-2">
                                            <div class="col-md-3">
                                                <button class="btn btn-success w-100"
                                                    pTooltip="Guardar Asignación de Bono" (click)="guardarTodo()">
                                                    <span class="text-white">Guardar Todo</span>
                                                    <i class="pi pi-save text-white"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </ng-template> -->
                </p-table>

                <div class=" mt-3 d-flex justify-content-center">
                  <p class="text-left informativo"
                    style="display: inline; padding-left: 10px; border-left: 5px solid rgb(28, 163, 35); font-weight: normal; font-size: 0.8rem;">
                    <b>ACTIVO</b> la deuda de la dispersion continua activa.
                  </p>
                  <p class="text-left informativo"
                    style="display: inline; padding-left: 10px; border-left: 5px solid rgb(224, 82, 60); font-weight: normal; font-size: 0.8rem;">
                    <b>FINALIZADO</b> la dedua de la dispersion ya fue saldada.
                  </p>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--AGREGAR / EDITAR PRESTAMO-->
      <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspCalculo"
        [style]="{width: '75vw','height':'750px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
        (onHide)="cancelarModal()">
        <ng-template pTemplate="header">
          {{ texto_operacion }}
          <div *ngIf="" style="color:black;">
            {{ nombre_registro }}
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <div class="mt-2">
            <form [formGroup]="dataFormChofer">
              <span class="ml-4 title-light mt-3">Información General<i class="pi pi-cog" style="size: 2rem;"></i>
              </span>
              <div class="row mt-3">
                <div class="form-group">
                  <div class="row g-0 mt-3 ml-4">
                    <!-- Primer Dropdown -->
                    <div class="col-sm-2 mx-0 mb-4 mt-2">
                      <div class="input-group">
                        <i class="pi pi-user color-icon" style="font-size: 1.5rem"></i>
                        <span class="ml-3 align-self-start"><b>Colaborador</b></span>
                      </div>
                    </div>
                    <div class="col-sm-4 mb-4">
                      <p-dropdown [options]="lstDescuentosVariosChoferes" autoWidth="false" [style]="{'width':'90%'}"
                        placeholder="Seleccione Colaborador*" optionLabel="nombre_completo" [showClear]="true"
                        [filter]="true" filterBy="nombre_completo" formControlName="cod_chofer" optionValue="cod_chofer"
                        tooltip="">
                        <ng-template let-item pTemplate="selectedItem">
                          <span style="vertical-align:middle; word-wrap: break-word;"
                            class="texto_responsable">{{item.nombre_completo}}</span>
                        </ng-template>
                        <ng-template let-target pTemplate="item">
                          <div class="ui-helper-clearfix" style="position: relative;height:25px;"
                            pTooltip="{{target.desc_ciudad_hm}}" tooltipPosition="top">
                            <span
                              style="font-size:14px;float:left;margin-top:4px;word-wrap: break-word;">{{target.nombre_completo}}</span>
                          </div>
                        </ng-template>
                      </p-dropdown>
                    </div>
                    <!-- Segundo Dropdown -->
                    <div class="col-sm-2 mx-0 ml-2 mb-4 mt-2">
                      <div class="input-group">
                        <i class="pi pi-building color-icon" style="font-size: 1.5rem"></i>
                        <span class="ml-3 align-self-start"><b>Ciudad</b></span>
                      </div>
                    </div>
                    <div class="col-sm-4 mb-4">
                      <p-dropdown [options]="lstCiudad" autoWidth="false" [style]="{'width':'90%'}"
                        placeholder="Seleccione Ciudad Operación*" optionLabel="nombre" [showClear]="true"
                        [filter]="true" formControlName="id_ciudad_hm" optionValue="id" tooltip="">
                      </p-dropdown>
                    </div>
                    <!-- Tercer Dropdown -->
                    <div class="col-sm-2 mx-0 mb-4 mt-2">
                      <div class="input-group">
                        <i class="pi pi-percentage color-icon" style="font-size: 1.5rem"></i>
                        <span class="ml-3 align-self-start"><b>Descuento</b></span>
                      </div>
                    </div>
                    <div class="col-sm-4 mb-4">
                      <p-dropdown [options]="lstTipoDescuentos" autoWidth="false" [style]="{'width':'90%'}"
                        placeholder="Seleccione Tipo Descuento*" optionLabel="desc_descuento" [showClear]="true"
                        [filter]="true" formControlName="id_categoria_descuento" optionValue="id_descuento" tooltip="">
                      </p-dropdown>
                    </div>
                    <!-- Cuarto Dropdown -->
                    <div class="col-sm-2 mx-0 mb-4 mt-2">
                      <div class="input-group">
                        <i class="pi pi-percentage color-icon" style="font-size: 1.5rem"></i>
                        <span class="ml-3 align-self-start"><b>Sub-Descuento</b></span>
                      </div>
                    </div>
                    <div class="col-sm-4 mb-4">
                      <p-dropdown [options]="lstSubTipoDescuentos" autoWidth="false" [style]="{'width':'90%'}"
                        placeholder="Seleccione Tipo Sub Descuento*" optionLabel="desc_sub_descuento" [showClear]="true"
                        [filter]="true" formControlName="id_sub_categoria_descuento" optionValue="id_sub_descuento"
                        tooltip="">
                      </p-dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="mt-2">
            <form [formGroup]="dataFormCalc" (ngSubmit)="calcularDispersion()">
              <span class="ml-4 title-light mt-3">Calculo Dispersión<i class="pi pi-cog" style="size: 2rem;"></i>
              </span>
              <div class="row mt-3">
                <div class="form-group">
                  <div class="row g-0 mt-3 ml-4 mr-4">
                    <!-- Campo Importe Total Descuento -->
                    <div class="col-md-4 d-flex align-items-center mb-4">
                      <i class="pi pi-money-bill color-icon" style="font-size: 1.5rem"></i>
                      <span class="ml-2"><b>Total</b></span>
                      <input type="number" class="form-control sinborde ml-2" pTooltip="Importe Total Descuento"
                       min="1" placeholder="Importe Total Descuento*" formControlName="imp_total" [style]="{'width':'60%'}">
                    </div>

                    <!-- Campo Numero de Pagos -->
                    <div class="col-md-4 d-flex align-items-center mb-4">
                      <i class="pi pi-tags color-icon" style="font-size: 1.5rem"></i>
                      <span class="ml-2"><b>Num. Pagos*</b></span>
                      <input type="number" class="form-control sinborde ml-2" placeholder="Numero de Pagos*"
                       min="1"  pTooltip="Numero de Pagos" formControlName="num_pagos"  [style]="{'width':'60%'}">

                    </div>

                    <!-- Campo Fecha Descuento -->
                    <div class="col-md-4 d-flex justify-content-start ">
                      <i class=" mt-2 pi pi-calendar color-icon" style="font-size: 1.5rem"></i>
                      <span class="mt-2"><b>Inicio descuento*</b></span>
                      <p-calendar  formControlName="fec_registro" placeholder="Fecha descuento*" [showIcon]="true"
                        dateFormat="dd/mm/yy" inputId="fec_registro"
                        [style]="{'width':'100%','height':'100%','scale': '0.6'}" pTooltip="Fecha a descuento"
                        tooltipPosition="right"></p-calendar>
                    </div>
                  </div>
                </div>
              </div>


              <div class="row d-flex justify-content-center mt-3">
                <div class="col-2">
                  <button type="submit" class="btn btn-primary w-100"
                    [disabled]="!dataFormCalc.valid || !dataFormChofer.valid || !this.dataFormCalc.controls['fec_registro'].valid">
                    <span class="text-white">Calcular </span>
                    <i class="pi pi-percentage text-white"></i>
                  </button>
                </div>
                <!-- <div class="col-2">
                                    <button type="submit" class="btn btn-success w-100"
                                        [disabled]="!dataFormChofer.valid &&lstDispersion.length == 0">
                                        <span class="text-white">Guardar </span>
                                        <i class="pi pi-save text-white"></i>
                                    </button>
                                </div> -->
              </div>
            </form>
            <div *ngIf="lstDispersion.length>0">
              <p-table [value]="lstDispersion" styleClass="p-datatable-sm p-datatable-striped"
                [tableStyle]="{'min-width': '50rem'}">
                <ng-template pTemplate="caption">
                  <div class="flex justify-content-start">
                    <span class="p-input-icon-left p-ml-auto">
                      <p> Dispersión del Descuento</p>
                    </span>
                  </div>
                </ng-template>
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center">#Pago</th>
                    <th class="text-center">Semana de Abono</th>
                    <th class="text-center">Año</th>
                    <th class="text-center">Importe</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
                  <tr>
                    <td class="text-center" style="color: black;"><b>{{ data.id_periodo }}</b></td>
                    <td class="text-center " style="color: rgb(23, 212, 23);"><b>{{ data.id_semana }}</b></td>
                    <td class="text-center texto_principal">{{ data.num_anio}}</td>
                    <td class="text-center" style="color: rgb(198, 54, 54);"><b>{{data.imp_periodo | currency }}</b></td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                  <tr>
                    <td colspan="3" class="text-right">Total Dispersión</td>
                    <td>{{ total_dispersion | currency: 'USD'}}</td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                  <div *ngIf="lstDispersion.length>0"
                    class="flex align-items-center justify-content-center mb-2 mt-2 ml-4 mr-4">
                    <div class="col-md-3">
                      <button class="btn btn-success w-100" pTooltip="Guardar Dispersion Descuento"
                        [disabled]="!dataFormCalc.valid|| !dataFormChofer.valid &&lstDispersion.length == 0" (click)="guardarDispersion()">
                        <span class="text-white">Registrar Descuento </span>
                        <i class="pi pi-save text-white"></i>
                      </button>
                    </div>
                  </div>
                </ng-template>
              </p-table>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="footer">
          <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
            (click)="cancelarModal();"></button>
        </ng-template>
      </p-dialog>
      <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspDialogoDispercion"
        [style]="{width: '65vw','height':'650px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
        (onHide)="cancelarModalEstatus()">
        <ng-template pTemplate="header">
          {{ texto_operacion }}
          <div *ngIf="" style="color:black;">
            {{ nombre_registro }}
          </div>
        </ng-template>

        <span class="title-light mt-3">Información de Pagos <i class="pi pi-cog" style="size: 2rem;"></i>
        </span>

        <ng-template pTemplate="content">
          <div class="mt-2">

            <div>
              <p-table [value]="lstTableMostrarDispersion.jsnDescDisp" styleClass="p-datatable-sm p-datatable-striped"
                [tableStyle]="{'min-width': '50rem'}">
                <ng-template pTemplate="caption">


                  <div class="row mt-1 mb-1 d-flex justify-content-around ">
                    <div class="col-md-2 ">#Chofer : &nbsp;<label style="color: #344ba5;"><b>{{cod_chofer}}</b></label>
                    </div>
                    <div class=" col-md-5 ">Colaborador : &nbsp;<label
                        style="color:#344ba5;"><b>{{nombre_completo}}</b></label></div>
                    <div class="col-md-3 ">Deuda Restante: &nbsp;<label
                        style="color: #e69915;"><b>{{imp_restante | currency}}</b></label></div>
                    <div class="col-md-2">Deuda total: &nbsp;<label style="color: #e62929;"><b>{{imp_total | currency}}</b></label>
                    </div>


                  </div>
                </ng-template>
                <ng-template pTemplate="header">
                  <tr>
                    <th>#Num.periodo</th>

                    <th class="text-center">Categoria</th>
                    <th class="text-center">Sub-Categoria</th>
                    <th class="text-center">Importe de Abono</th>
                    <th class="text-center">Semana de Descuento</th>
                    <!--<th class="text-center">Importe Pagado</th>-->
                    <th class="text-center">Estatus de Pago</th>
                    <th class="text-center">Accion</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
                  <tr>
                    <td class="text-center" style="color: black;"><b>{{data.id_periodo}}</b></td>
                    <td class="text-center texto_principal">{{data.desc_descuento}}</td>
                    <td class="text-center" style="color: #45c8f0;"><b>{{data.desc_sub_descuento}}</b></td>
                    <td class="text-center" style="color: rgb(226, 42, 42);"><b>{{data.imp_periodo | currency}}</b></td>
                    <td class="text-center " style="color: black;"><b>{{data.id_semana}}</b></td>
                    <td class="text-center "  style="color: rgb(0, 0, 0);" *ngIf="data.clv_pago == 0"><b>{{data.desc_estatus}}</b></td>
                    <td class="text-center " style="color: rgb(40, 216, 40);" *ngIf="data.clv_pago == 1">
                      <b>{{data.desc_estatus}}</b>
                    </td>
                    <td class="text-center">
                      <div class="ml-2">
                        <button [disabled]="data.clv_pago === 1" class="btn"
                          [ngClass]="{'btn-success': data.clv_pago === 0, 'btn-link': data.clv_pago === 1}"
                          tooltipPosition="left" pTooltip="{{data.clv_activo === 0 ? 'ya fue pagada ' : 'Pagar' }}"
                          (click)="cambioEstatus(lstTableMostrarDispersion.id_descuento,data.id_periodo, data)">
                          <i class="pi pi-wallet"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="footer">
          <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
            (click)="cancelarModalEstatus();"></button>
        </ng-template>
      </p-dialog>
    </div>
  </div>
