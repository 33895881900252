<p-toast position="top-right" key="msj"></p-toast>
<!--<p-messages></p-messages>-->
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="Aceptar" rejectLabel="Cancelar"
  rejectButtonStyleClass="p-button-text">
</p-confirmDialog>

<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
  rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar" [rejectVisible]="false">
</p-confirmDialog>
<div class="d-flex justify-content-center">
  <div class="container-fluid" style="width: 95%;">
      <div class="row mt-5">
          <div class="col-md-12">
              <div class="card card-shadow sinborde">
                  <div class="card-header sinborde" style="background: none; ">
                      <div class="cardicono rounded">
              <i class="pi pi-id-card mt-3" style="font-size: 2rem; color: #fff;"></i>
            </div>
            <header style="left: 11%; top: 5%;">
              <div style="display:inline"> Gestion De Apoyo Estatus Foraneo</div>
              <div *ngIf="dia.length > 0" style="color:rgb(22, 48, 220);display:inline;" class="ml-3"> - {{dia|date:
                'dd/MM/yyyy'}} </div>
            </header>
            <div class="row d-flex justify-content-end">
              <div class="col-md-12">

                <!-- -------------fecha del filtro---------------- -->
                <div class="form-group row mt-5">
                  <div class="col-md-3">
                    <div class="row d-flex justify-content-start">
                      <div class="col-md-5">
                        <label class="col-form-label color-font text-right"
                          style="font-weight: 600; font-family: 'Poppins', sans-serif;">
                          <span class="input-group-addon ml-5"> <i class="pi pi-calendar color-icon mr-4"
                              style="font-size: 2rem; color: #3B82F6;"></i>
                          </span>
                          Fecha inicio
                        </label>
                      </div>
                      <div class="col-sm-7 mt-3">
                        <input type="date" class="form-control sinborde" pTooltip="Seleccione o teclee fecha"
                          tooltipPosition="top" [(ngModel)]="fecha_filtrada" >
                        <hr class="my-1">
                      </div>
                    </div>
                  </div>






                  <!-- -------------fecha final del filtro---------------- -->
                  <div class="col-md-3">
                    <div class="row d-flex justify-content-start">
                      <div class="col-md-5">
                        <label class="col-form-label color-font text-right"
                          style="font-weight: 600; font-family: 'Poppins', sans-serif;">
                          <span class="input-group-addon ml-5"> <i class="pi pi-calendar color-icon mr-3"
                              style="font-size: 2rem; color: #3B82F6;"></i>
                          </span>
                          Fecha&nbsp;Fin
                        </label>
                      </div>
                      <div class="col-sm-7 mt-3">
                        <input type="date" class="form-control sinborde" pTooltip="Seleccione o teclee fecha"
                          tooltipPosition="top" [(ngModel)]="fecha_fin_filtro" >
                        <hr class="my-1">
                      </div>
                    </div>
                  </div>
                  <!-- filtro de ciudades -->
                  <div class="col-md-3 mr-3 mt-2">
                    <p-dropdown [(ngModel)]="id_ciudad_hm" [options]="lstCiudad" optionLabel="nombre" optionValue="id"
                      placeholder="Filtrar por Ciudad" [showClear]="false" [disabled]="dsblstCiudades">
                    </p-dropdown>
                  </div>
                  <!-- ----------boton principal para el filtro  inicial------------------- -->
                  <div class="col-md-1 ">
                    <button pButton pRipple type="button" (click)="FiltrarFechaDiariaRuta();" label="Filtrar"
                      icon="pi pi-filter" iconPos="left"></button>
                  </div>
                  <!-- ----------boton principal de los reportes en excel------------------- -->
                  <div class="  col-md-1 ml-6 ">
                    <button type="button" pButton pRipple class="p-button-success " pTooltip="Exportar Catalogo a EXCEL"
                      tooltipPosition="right" (click)="abrirModalReportes()">
                      <i class="pi pi-file-excel"></i> Exportar
                    </button>
                  </div>
                </div>

              </div>
            </div>
            <div class="card-content">
              <div class="row mt-3">
                <!-- informacio  -->
                <p-table #dt1 [value]="lstEstatusForaneo" [tableStyle]="{'min-width': '50rem'}"
                  styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true" scrollHeight="480px"
                  responsiveLayout="scroll"
                  [globalFilterFields]="['nom_completo','desc_estatus','desc_ciudad_hm','desc_ciudad_hm_prestada']"
                  [paginator]="true" [rows]="30" [rowsPerPageOptions]="[10,15,20,30,50]" [showCurrentPageReport]="true"
                  [resizableColumns]="false" currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros"
                  [loading]="loading">
                  <ng-template pTemplate="caption">
                    <div class="row d-flex justify-content-end">

                      <div class="col-md-auto">
                        <span class="p-input-icon-left">
                          <i class="pi pi-search" style="margin-right: 5px;"></i>
                          <input pInputText pTooltip="Buscar por: Nombre del colaborador, Ciudad o Evento" type="text"
                            (input)="applyFilterGlobal($event, 'contains')" placeholder="Buscar"
                            style="margin-left: 5px;" />
                        </span>
                      </div>
                    </div>
                    <div class="row d-flex justify-content-end mt-2 mb-3">
                      <div class="col-md-6">
                      </div>
                      <div class="col-3">
                        <button *ngIf="habilitar_agregar && (validarClvOperacion() || fecha_filtrada == fecha_hoy)"
                          type="button" class="btn btn-primary form-control" (click)="mostrarModalAgregar();"
                          pTooltip="Agregar colaborador a apoyo foraneo" tooltipPosition="top">
                          Agregar apoyo foraneo <i class="pi pi-plus-circle"></i>
                        </button>
                      </div>

                    </div>
                  </ng-template>

                  <!-- tabulado -->
                  <ng-template pTemplate="header">
                    <tr>
                      <th pSortableColumn="nom_completo" class="text-center">Colaborador<p-sortIcon
                          field="nom_completo"></p-sortIcon></th>
                      <!-- <th pSortableColumn="desc_puesto" class="text-center">Puesto<p-sortIcon -->
                      <!-- field="desc_puesto"></p-sortIcon></th> -->
                      <th  class="text-center">Ciudad</th>
                      <th class="text-center">Apoyo</th>

                          <th pSortableColumn="fec_inicio" class="text-center">Inicio<p-sortIcon
                            field="fec_inicio"></p-sortIcon></th>
                        <th pSortableColumn="fec_fin" class="text-center">Fin<p-sortIcon
                            field="fec_fin"></p-sortIcon></th>
                            <th  class="text-center">D.apoyo</th>

                      <th pSortableColumn="imp_dia" class="text-center">Importe por dia <p-sortIcon field="imp_dia"></p-sortIcon>
                      </th>

                      <th pSortableColumn="imp_total_dias" class="text-center">Importe total <p-sortIcon field="imp_total_dias"></p-sortIcon>
                      </th>

                      <th pSortableColumn="clv_estatus" class="text-center">Estatus<p-sortIcon
                          field="clv_estatus"></p-sortIcon></th>
                          <th class="text-center">Estatus Fin</th>
                      <th class="text-center">Editar Estatus</th>
                      <th></th>
                      <th class="text-center"> Editar Registro</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-data let-index>
                    <tr [ngClass]="{'row-accessories': data.clv_activo ===0}">

                      <td class="text-left pl-7">{{data.nom_completo}}</td>
                      <!-- <td [ngStyle]="{'color': data.desc_puesto === 'DRIVER' ? 'rgba(60, 148, 224 )' : 'rgba(60, 224, 122  )'}">
                          <p class="font-weight-bold text-center"><b>{{data.desc_puesto}}</b></p>
                      </td> -->
                      <td class="text-center"> {{data.desc_ciudad_hm}}</td>
                      <td class="text-center"> {{data.desc_ciudad_hm_prestada}}</td>

                      <td class="text-center">{{ data.fec_inicio | date: 'dd/MM/yyyy' }}</td>
                      <td class="text-center">{{ data.fec_fin | date: 'dd/MM/yyyy' }}</td>

                      <td class="text-center"[ngStyle]="{
                        'color':  'rgb(33, 96, 168)' }"><b>{{data.num_dias}}</b></td>

                      <td class="text-center"> <b>{{data.imp_dia | currency:'USD'}}</b></td>

                      <td class="text-center"> <b>{{data.imp_total_dias | currency:'USD'}}</b></td>

                      <td class="text-center" [ngStyle]="{
                        'color': data.clv_estatus === 0 ? 'rgb(226, 189, 39)' :  'rgb(33, 96, 168)' }">
                        <p class="font-weight-bold text-center"><b>{{data.desc_estatus}}</b></p>
                      </td>

                      <td class="text-center" [ngStyle]="{
                        'color':  data.clv_finalizado === 1 ? 'rgb(28, 163, 35)' : 'rgb(224, 82, 60)' }">
                        <p class="font-weight-bold text-center"><b>{{data.desc_finalizado}}</b></p>
                      </td>


                      <!-- botones para editar estatus -->
                      <td>
                        <div class="d-flex justify-content-around ">
                          <button *ngIf="validarClvOperacionbtnEstatus(data)" class="btn btn-warning" pTooltip="Editar Estatus"
                            (click)="editarEstatus(data);">
                            <i class="pi pi-flag"></i>
                          </button>

                          <div class="ml-2">
                            <button *ngIf="validarClvOperacionbtnFin(data)" class="btn btn-success" pTooltip="Finalizar Estatus"
                              (click)="finalizarEstatus(data);">
                              <i class="pi pi-check"></i>
                            </button>
                          </div>
                        </div>

                      </td>
                      <td>|</td>
                      <!-- botones para editar registros -->
                      <td>
                        <div class="d-flex justify-content-around">
                          <button *ngIf="validarClvOperacionbtnEdit(data)" class="btn btn-primary" pTooltip="Editar Registro"
                            tooltipPosition="left" (click)="mostrarModalEditar(data);">
                            <i class="pi pi-pencil"></i>
                          </button>
                          <div class="ml-2">
                            <button *ngIf="validarClvOperacionbtnDel(data)" class="btn btn-danger" pTooltip="Eliminar Registro"
                              (click)="confirmarEliminar(data);">
                              <i class="pi pi-delete-left"></i>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>

                <!-- pie de pagina -->
              </div>
              <div class=" mt-3 d-flex justify-content-center">
                <p class="text-left informativo" style="display: inline; padding-left: 10px; border-left: 5px solid rgb(49, 98, 189); font-weight: normal; font-size: 0.8rem;"><b>ACTIVO</b> el apoyo foráneo se encuentra en proceso</p>
                <p class="text-left informativo"  style="display: inline; padding-left: 10px; border-left: 5px solid rgb(226, 189, 39); font-weight: normal; font-size: 0.8rem;"><b>INACTIVO</b> el apoyo foráneo se ha cancelado, y no se contempla para nomina</p>
                <p class="text-left informativo"  style="display: inline; padding-left: 10px; border-left: 5px solid rgb(28, 163, 35); font-weight: normal; font-size: 0.8rem;">  <b>FINALIZADA</b> el apoyo foráneo se ha finalizado de forma correcta</p>
                <p class="text-left informativo"  style="display: inline; padding-left: 10px; border-left: 5px solid rgb(224, 82, 60); font-weight: normal; font-size: 0.8rem;">  <b>SIN FINALIZAR</b> el apoyo foráneo no ah finalizado</p>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>


    <!---pdialog de agregar--->

    <p-dialog [resizable]="false" [draggable]="false" [scrollTop]="" [(visible)]="dspCliente"
      [style]="{width: '55vw','height':'620px'}" [transitionOptions]="'0ms'">
      <ng-template pTemplate="header">
        {{ texto_operacion}}
      </ng-template>
      <ng-template pTemplate="content">
        <div class="card-content ">
          <form class="form_cliente" [formGroup]="reactiveEstatusForaneo"
            (ngSubmit)="guardarInformacionDeColaborador()">
            <div class="ml-1">
              <div class="row mt-1 d-flex justify-content-start">
                <div class="col-sm-6">
                  <span class="title-light">Informacion del Colaborador
                    <i class="pi pi-cog" style="size: 2rem;">
                    </i>
                  </span>
                </div>
              </div>
              <div class="row justify-content-md-around">
                <div class="col-12">


                  <!-- colaboradores -->
                  <div class="row d-flex justify-content-around">
                    <div class="col-md-5">
                      <div class="row">
                        <div class="col-md-4 mt-2 text-center">
                          <label class="col-form-label color-font">
                            <span class="input-group-addon">
                              <i class="pi pi-user color-icon mr-3" style="font-size: 2rem"></i>
                            </span>
                            Colaborador
                          </label>
                        </div>
                        <div class="col-md-8">
                          <p-dropdown [options]="lstChoferes" (onChange)="changedp($event)"
                            optionLabel="nombre_completo" formControlName="chofer" autoWidth="false" [showClear]="false"
                            placeholder="Seleccione Colaborador" [style]="{'width':'80%' , 'margin-top': '20px'}"
                            optionValue="cod_chofer" [disabled]="dsblstColaboradores">
                          </p-dropdown>
                        </div>
                      </div>
                    </div>

                    <!-- importe  -->

                    <div class="col-md-5">
                      <div class="row">
                        <div class="col-md-4 text-center">
                          <label class="col-form-label color-font">
                            <span class="input-group-addon">
                              <i class="pi pi-money-bill color-icon mr-3" style="font-size: 2rem"></i>
                            </span>
                            Importe&nbsp;día
                          </label>
                        </div>
                        <div class="col-md-8 mt-3">
                          <input type="text" class="form-control" pTooltip="Ingrese el importe del día"
                            formControlName="importe" placeholder="$00.00">
                        </div>
                      </div>

                    </div>
                  </div>


                  <!-- CIUDADES------------------- -->
                  <div class="row d-flex justify-content-around">
                    <div class="col-md-5">

                      <div class="row mt-2">
                        <div class="col-md-4 mt-3 text-center">
                          <label class="col-form-label color-font ">
                            <span class="input-group-addon">
                              <i class="pi pi-globe color-icon mr-3" style="font-size: 2rem"></i>
                            </span>
                            Ciudad&nbsp;Origen
                          </label>
                        </div>
                        <div class="col-md-8">
                          <p-dropdown [options]="lstCiudades" optionLabel="nombre" formControlName="ciudad"
                            autoWidth="false" [showClear]="true" placeholder="Seleccione Ciudad"
                            [style]="{'width':'100%' , 'margin-top': '20px'}" optionValue="id"
                            pTooltip="{{mssgListaCiudades}}">
                          </p-dropdown>
                        </div>
                      </div>

                    </div>
                    <div class="col-md-5">
                      <div class="row mt-2">
                        <div class="col-md-4 mt-3 text-center">
                          <label class="col-form-label color-font ">
                            <span class="input-group-addon">
                              <i class="pi pi-globe color-icon mr-3" style="font-size: 2rem"></i>
                            </span>
                            Ciudad&nbsp;Foranea
                          </label>
                        </div>
                        <div class="col-md-8">
                          <p-dropdown [options]="lstCiudadesForanea" optionLabel="nombre"
                            formControlName="ciudadForanea" autoWidth="false" [showClear]="true"
                            placeholder="Seleccione Ciudad destino" [style]="{'width':'100%' , 'margin-top': '20px'}"
                            optionValue="id" pTooltip="{{mssgListaCiudades}}">
                          </p-dropdown>
                        </div>
                      </div>


                    </div>
                  </div>

                  <!-- fechas ---- -->
                  <div class="row d-flex justify-content-around">
                    <div class="col-md-5">
                      <div class="row mt-3">
                        <div class="col-md-4 text-center">
                          <label class="col-form-label color-font">
                            <span class="input-group-addon">
                              <i class="pi pi-calendar color-icon mr-3" style="font-size: 2rem"></i>
                            </span>
                            Fecha&nbsp;Inicio
                          </label>
                        </div>
                        <div class="col-md-8">
                          <input type="date" formControlName="fecha" class="form-control sinborde"
                            pTooltip="Seleccione o teclee fecha" tooltipPosition="top"
                            [style]="{'width':'80%' , 'margin-top': '20px'}">
                        </div>
                      </div>
                    </div>


                    <div class="col-md-5">
                      <div class="row mt-3">
                        <div class="col-md-4 text-center">
                          <label class="col-form-label color-font">
                            <span class="input-group-addon">
                              <i class="pi pi-calendar color-icon mr-3" style="font-size: 2rem"></i>
                            </span>
                            Fecha&nbsp;Fin
                          </label>
                        </div>
                        <div class="col-md-8">
                          <input type="date" formControlName="fechaFin" class="form-control sinborde"
                            pTooltip="Seleccione o teclee fecha" tooltipPosition="top"
                            [style]="{'width':'80%' , 'margin-top': '20px'}">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
              <!-- --------------fin cambios------------- -->
              <div class="row d-flex ">
                <div class="col-md-5  mt-6 justify-content-start">
                  <p><span style="font-weight: bold; ">Última&nbsp;Modificación:</span></p>
                  <p style="margin-top: 0px;  color: rgb(181, 17, 50);">
                    <b>{{reactiveEstatusForaneo.get('nom_ult_mod')?.value
                      }}</b>
                  </p>
                </div>

                <div class="col-md-3 mt-7 justify-content-center">
                  <button class="btn btn-primary form-control" type="submit"
                    [disabled]="!reactiveEstatusForaneo.valid || !reactiveEstatusForaneo.dirty"
                    pTooltip="Almacenar informacion" tooltipPosition="right">
                    <span class="text-white">Guardar</span>
                    <i class="pi pi-save text-white"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
          (click)="cancelarVentanaModal()"></button>
      </ng-template>
    </p-dialog>
    <!-- p-dialog de reportes excel ------------ -->
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dbsReportes"
      [style]="{'width': '60vw','height':'620px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
      (onHide)="cancelarModalReportes()">
      <ng-template pTemplate="header">
        Generar Reporte De Excel
      </ng-template>
      <ng-template pTemplate="content">
        <div class="mt-5">
          <hr class="style-two">
          <div class="row">
            <div class="col-md-5">
              <p class="texto-pesado">Reporte De ApoyoEstatusForaneo</p>
            </div>
            <div class="col-md-2">
              <input type="date" class="form-control sinborde" pTooltip="Seleccione o teclee fecha inicio"
                tooltipPosition="top" [(ngModel)]="fecha_dia">
              <hr class="my-0">
            </div>
            <div class="col-md-2">
              <input type="date" class="form-control sinborde" pTooltip="Seleccione o teclee fecha fin"
                tooltipPosition="top" [(ngModel)]="fecha_fin">
              <hr class="my-0">
            </div>
            <div class="col-md-3">
              <button type="button" class="btn btn-outline-success" style="width: 100%;"
                (click)="exportarExcel(fecha_dia,fecha_fin)" [disabled]="ButtonExportExcel">
                Exportar <i class="pi pi-file-export"></i>
              </button>
            </div>
          </div>
          <div class="row d-flex justify-content-center">
            <div class="col-2 text-center">
              <i class="pi pi-spin pi-spinner" *ngIf="loading_reporte1"></i>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
          (click)="cancelarModalReportes();"></button>
      </ng-template>
    </p-dialog>
  </div>
