import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, catchError, retry, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { IBonoEnviar } from '../models/bono_enviar';

@Injectable({
  providedIn: 'root'
})
export class BonopuntualidadService {
  private apiUrl: string ='';
  constructor(private _http: HttpClient) { 
    this.apiUrl = environment.sUrlNomina + 'BonosPuntualidad';
  }

  getBonoPorSemanayCiudad(id_semana:number,anio:number,id_ciudad_principal_hm:number): Observable<any> {
    const valores = {
      params: new HttpParams().
        set('id_semana', id_semana).
        set('anio', anio).
        set('id_ciudad_principal_hm', id_ciudad_principal_hm)
    };
    return this._http
      .get<any>(this.apiUrl, valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  postBono(data: IBonoEnviar[] ):Observable<any>{
    const headers = { 'content-type': 'application/json'}  
    const body = JSON.stringify(data); 
    return this._http.post<any>(this.apiUrl + '/',body,{'headers':headers});
   }

  handleError(error:any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
        return errorMessage;
    });
  }
}
