import { Component, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IErrorCode } from '../models/errorcode';
import { ConfirmEventType, ConfirmationService, MessageService } from 'primeng/api';
import { InasistenciasService } from '../services/inasistencias.service';
import { IInasistencias } from '../models/inasistencias';

@Component({
  selector: 'app-inasistencias',
  templateUrl: './inasistencias.component.html',
  styleUrls: ['./inasistencias.component.scss']
})
export class InasistenciasComponent {
  @ViewChild('dt1') dt!: Table;
  lstBonos: IInasistencias[]=[];
  loading:boolean=false;
  //anio:number=+new Date().toLocaleDateString('es-MX',{ year: 'numeric'});
  anio:string=new Date().toLocaleDateString('es-MX',{ year: 'numeric'});
  dataForm: FormGroup = new FormGroup({
    id_concepto: new FormControl(0),
    clv_concepto: new FormControl('',[Validators.required,Validators.minLength(1)]),
    desc_concepto: new FormControl('',[Validators.required,Validators.minLength(1)]),
    clv_activo: new FormControl(0),
    num_anio: new FormControl('',[Validators.required]),
  });
  dspDialogo:boolean=false;
  texto_operacion:string='';
  clv_operacion:number=0;
  error_captura!: IErrorCode;
  nombre_registro:string='';
  constructor(private inasistenciasService: InasistenciasService,private confirmationService: ConfirmationService, private messageService: MessageService) {

  }
  ngOnInit(): void {
    this.cargarListado();
  }

  cargarListado(){
    this.loading=true;
    this.inasistenciasService.listar(2023).subscribe((resp)=> {
      this.lstBonos=resp;
      setTimeout(() => {
        this.loading=false;
      },
        600);
    },
    (error)=>{
      this.loading=false;
      this.mostrarDialogoInformativoSinInformacion('<b>Error</b> al cargar Información. Contacte al Administrador.');
    });
  }

  mostrarModalAgregar(){
    this.clv_operacion=1;
    this.texto_operacion='Agregar Registro';
    this.dataForm.controls['clv_concepto'].enable();
    this.dspDialogo = true;
  }

  mostrarModalEditar(data: IInasistencias){
    this.clv_operacion=2;
    this.texto_operacion='Editar Registro';
    this.dataForm.controls['id_concepto'].setValue(data.id_concepto);
    this.dataForm.controls['clv_concepto'].setValue(data.clv_concepto);
    this.dataForm.controls['desc_concepto'].setValue(data.desc_concepto);
    this.dataForm.controls['num_anio'].setValue(data.num_anio.toString());
    this.dataForm.controls['clv_activo'].setValue(data.clv_activo);

    //Deshabilitar Clave
    this.dataForm.controls['clv_concepto'].disable();
    this.dspDialogo=true;
  }

  confirmarCambiarEstatus(id:number,data: IInasistencias){
    let clv_estatus_original: number = data.clv_activo;
    let texto_desactivar: string = data.clv_activo == 1 ? 'DESACTIVAR' : 'ACTIVAR';
    let texto_desactivar_header: string = data.clv_activo == 1 ? 'Desactivar' : 'Activar';
    this.confirmationService.confirm({
      message: 'Esta seguro que desea <b>' + texto_desactivar + '</b> el registro de Bono <b>'+data.clv_concepto+'</b> del Año:  <b>' + data.num_anio + ' ?',
      header: 'Confirmación de ' + texto_desactivar_header,
      icon: 'pi pi-info-circle',
      accept: () => {
        data.clv_activo = data.clv_activo == 1 ? 0 : 1;
        this.guardarInformacionDesactivar(data);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            data.clv_activo = clv_estatus_original;
            //this.messageService.add({ key: 'msj', severity: 'error', summary: 'Rechazado', detail: 'Se ha Rechazado la operación' });
            break;
          case ConfirmEventType.CANCEL:
            data.clv_activo = clv_estatus_original;
            //this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Se ha Cancelado la operación' });
            break;
        }
      }
    });
  }

  guardarInformacionDesactivar(data: IInasistencias){
    this.inasistenciasService.estatusSalarioDiario(data).subscribe((resp)=> {
      this.cargarListado();
      this.messageService.add({ key:'msj', severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se registro de forma correcta' });
    },
    (error)=> {
      this.messageService.add({key:'msj', severity: 'error', summary: 'Error en <Editar>', detail: 'Contacte al Administrador del Sitio' });
    });
  }

  guardarInformacion(){
    let data: IInasistencias={
      idx: 0,
      id_concepto: 0,
      clv_concepto: '',
      desc_concepto: '',
      clv_activo: 0,
      num_anio: 0
    };
    let anio_cast: any;
    if(this.clv_operacion==1){

      if(typeof(this.dataForm.get('num_anio')?.value)!=="string")
      anio_cast = new Date(this.dataForm.get('num_anio')?.value).getFullYear();
      else
      anio_cast = Number(this.dataForm.get('num_anio')?.value);


      data.num_anio=anio_cast;
      data.clv_concepto=this.dataForm.get('clv_concepto')?.value;
      data.desc_concepto=this.dataForm.get('desc_concepto')?.value;
      this.inasistenciasService.agregar(data).subscribe((resp)=> {
        this.cancelarModal();
        this.messageService.add({ key:'msj', severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se registro de forma correcta' });
        this.cargarListado();
      },
      (error)=> {
        console.log(error);
        console.log(error.error);
          if(error.status==409){
            this.error_captura = error.error;
            console.log(this.error_captura.message);
          }
        this.messageService.add({key:'msj', severity: 'error', summary: 'Error en <Agregar>', detail: this.error_captura.message});
      });
    } else if(this.clv_operacion==2){
      data.clv_activo=this.dataForm.get('clv_activo')?.value;
      data.clv_concepto=this.dataForm.get('clv_concepto')?.value;
      data.desc_concepto=this.dataForm.get('desc_concepto')?.value;
      data.id_concepto=this.dataForm.get('id_concepto')?.value;
      this.inasistenciasService.editar(data).subscribe((resp)=> {
        this.cancelarModal();
        this.messageService.add({ key:'msj', severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se registro de forma correcta' });
        this.cargarListado();
      },
      (error)=> {
        console.log(error);
        if(error.status==409){
          this.error_captura = error.error;
          console.log(this.error_captura.message);
        }
        this.messageService.add({key:'msj', severity: 'error', summary: 'Error en <Editar>', detail: 'Contacte al Administrador del Sitio' });
      });
    }
  }

  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  cancelarModal(){
    this.clv_operacion=0;
    this.texto_operacion='';
    this.dspDialogo = false;
    this.dataForm.reset();
  }

  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario;
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }


}
