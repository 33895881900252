import { Component, OnInit, ViewChild } from '@angular/core';
import { ISalarioDiario } from '../models/salariodiario';
import { Table } from 'primeng/table';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IErrorCode } from '../models/errorcode';
import { SalariodiarioService } from '../services/salariodiario.service';
import { ConfirmEventType, ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'app-salariodiario',
  templateUrl: './salariodiario.component.html',
  styleUrls: ['./salariodiario.component.scss']
})
export class SalariodiarioComponent implements OnInit {
  @ViewChild('dt1') dt!: Table;
  lstSalarioDiario: ISalarioDiario[]=[];
  loading:boolean=false;
  //anio:number=+new Date().toLocaleDateString('es-MX',{ year: 'numeric'}); 
  anio:any; 
  dataForm: FormGroup = new FormGroup({
    id_salario_diario: new FormControl(),
    anio: new FormControl(null,[Validators.required]),
    imp_monto: new FormControl(0, [Validators.required]),
    clv_activo: new FormControl(1, [])
  });
  dspDialogo:boolean=false;
  texto_operacion:string='';
  clv_operacion:number=0;
  error_captura!: IErrorCode;
  nombre_registro:string='';
  constructor(private salarioDiarioService: SalariodiarioService,private confirmationService: ConfirmationService, private messageService: MessageService) {

  }
  ngOnInit(): void {
    this.inicializarFechas();
    this.cargarListado();
  }

  inicializarFechas(){
    var getYear = new Date().toLocaleDateString('es-MX',{ year: 'numeric'});   
    var getMonth = new Date().toLocaleDateString('es-MX',{ month: '2-digit'});
    var getDay = new Date().toLocaleDateString('es-MX',{day: '2-digit'});
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.anio = getYear;
  }

  cargarListado(){
    this.loading=true;
    this.salarioDiarioService.listar(2023).subscribe((resp)=> {
      this.lstSalarioDiario=resp;
      setTimeout(() => {
        this.loading=false;
      },
        600);
    },
    (error)=>{
      this.loading=false;
      this.mostrarDialogoInformativoSinInformacion('<b>Error</b> al cargar Información. Contacte al Administrador.');
    });
  }

  mostrarModalAgregar(){
    this.dataForm.controls['anio'].setValue(this.anio);
    this.dataForm.controls['clv_activo'].setValue(1);
    this.clv_operacion=1;
    this.texto_operacion='Agregar Registro';
    this.dspDialogo = true;
  }

  mostrarModalEditar(data: ISalarioDiario){
    this.clv_operacion=2;
    this.texto_operacion='Editar Registro';
    this.dataForm.controls['id_salario_diario'].setValue(data.id_salario_diario);
    this.dataForm.controls['anio'].setValue(data.anio.toString());
    this.dataForm.controls['imp_monto'].setValue(data.imp_monto);
    this.dataForm.controls['clv_activo'].setValue(data.clv_activo);
    this.dspDialogo=true;
  }

  confirmarCambiarEstatus(id:number,data: ISalarioDiario){
    let clv_estatus_original: number = data.clv_activo;
    let texto_desactivar: string = data.clv_activo == 1 ? 'DESACTIVAR' : 'ACTIVAR';
    let texto_desactivar_header: string = data.clv_activo == 1 ? 'Desactivar' : 'Activar';
    this.confirmationService.confirm({
      message: 'Esta seguro que desea <b>' + texto_desactivar + '</b> el registro de Salario Diario del Año:  <b>' + data.anio + ' ?',
      header: 'Confirmación de ' + texto_desactivar_header,
      icon: 'pi pi-info-circle',
      accept: () => {
        data.clv_activo = data.clv_activo == 1 ? 0 : 1;
        this.guardarInformacionDesactivar(data);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            data.clv_activo = clv_estatus_original;
            //this.messageService.add({ key: 'msj', severity: 'error', summary: 'Rechazado', detail: 'Se ha Rechazado la operación' });
            break;
          case ConfirmEventType.CANCEL:
            data.clv_activo = clv_estatus_original;
            //this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Se ha Cancelado la operación' });
            break;
        }
      }
    });
  }

  guardarInformacionDesactivar(data: ISalarioDiario){
    this.salarioDiarioService.estatusSalarioDiario(data).subscribe((resp)=> {
      this.cargarListado();
      this.messageService.add({ key:'msj', severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se registro de forma correcta' });
    },
    (error)=> {
      this.messageService.add({key:'msj', severity: 'error', summary: 'Error en <Editar>', detail: 'Contacte al Administrador del Sitio' });
    });
  }

  guardarInformacion(){
    let data: ISalarioDiario={
      idx: 0,
      id_salario_diario: 0,
      anio: 0,
      imp_monto: 0,
      clv_activo: 0
    };
    if(this.clv_operacion==1){
      let anio_cast = new Date(this.dataForm.get('anio')?.value).getFullYear();
      data.anio=anio_cast;
      data.imp_monto=this.dataForm.get('imp_monto')?.value;
      data.clv_activo=this.dataForm.get('clv_activo')?.value;
      this.salarioDiarioService.agregar(data).subscribe((resp)=> {
        this.cancelarModal();
        this.messageService.add({ key:'msj', severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se registro de forma correcta' });
        this.cargarListado();
      },
      (error)=> {
          if(error.status==409){
            this.error_captura = error.error;
            this.mostrarDialogoInformativoSinInformacion(this.error_captura.message);
          } else {
            this.messageService.add({key:'msj', severity: 'error', summary: 'Error en <Agregar>', detail: this.error_captura.message});
          }
      });
    } else if(this.clv_operacion==2){
      data.anio=this.dataForm.get('anio')?.value;
      data.imp_monto=this.dataForm.get('imp_monto')?.value;
      data.id_salario_diario=this.dataForm.get('id_salario_diario')?.value;
      data.clv_activo=this.dataForm.get('clv_activo')?.value;
      this.salarioDiarioService.editar(data).subscribe((resp)=> {
        this.cancelarModal();
        this.messageService.add({ key:'msj', severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se registro de forma correcta' });
        this.cargarListado();
      },
      (error)=> {
        if(error.status==409){
          this.error_captura = error.error;
          this.mostrarDialogoInformativoSinInformacion(this.error_captura.message);
        } else {
          this.messageService.add({key:'msj', severity: 'error', summary: 'Error en <Editar>', detail: 'Contacte al Administrador del Sitio' });
        }
      });
    }
  }

  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  cancelarModal(){
    this.clv_operacion=0;
    this.texto_operacion='';
    this.dspDialogo = false;
    this.dataForm.reset();
  }

  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario;
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }
}
