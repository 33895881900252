<p-toast position="top-right" key="msj"></p-toast>
<!--<p-messages></p-messages>-->
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="Aceptar" rejectLabel="Cancelar"
  rejectButtonStyleClass="p-button-text">
</p-confirmDialog>

<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
  rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar" [rejectVisible]="false">
</p-confirmDialog>
<div class="d-flex justify-content-center">
  <div class="container-fluid" style="width: 95%;">
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="card card-shadow sinborde">
          <div class="card-header sinborde" style="background: none; ">
            <div class="cardicono rounded">
              <i class="pi pi-id-card mt-3" style="font-size: 2rem; color: #fff;"></i>
            </div>
            <header style="left: 11%; top: 5%;">
              <div style="display:inline"> Gestion de</div>

              <div *ngIf="dia.length > 0" style="color:rgb(231, 131, 38);display:inline;" class="ml-3">Deducciones de
                infonavit</div>
              <!-- - {{dia|date:'dd/MM/yyyy'}}
                 -->
            </header>

            <div class="row d-flex justify-content-between">
              <div class="col-md-12">
                <!-- -------------fecha del filtro---------------- -->
                <div class="form-group row mt-5">
                  <div class="col-md-1"></div>
                  <!-- filtro de semanas -->
                  <!-- <div class="col-md-2">
                    <p-dropdown [options]="lstSemanas" autoWidth="false" [style]="{'width':'100%'}"
                      placeholder="Seleccione Semana*" [(ngModel)]="selectedSemana" optionLabel="nom_semana"
                      [showClear]="false">
                      <ng-template let-target pTemplate="item">
                        <div class="ui-helper-clearfix" style="position: relative;height:25px;"
                          pTooltip="{{getTargetDescription(target)}}" tooltipPosition="top">
                          <span style="font-size:14px;float:left;margin-top:4px">{{target.nom_semana}}</span>
                        </div>
                      </ng-template>
                    </p-dropdown>
                    <div *ngIf="selectedSemana!=null">
                      <div class="row">
                        <div style="color: green;" class="col-sm-6 text-left "><b>Inicio:
                            {{selectedSemana.fec_inicio | date: 'dd/MM/yyyy' }}</b></div>
                        <div style="color: green;" class="col-sm-6 text-right "><b>Fin:
                            {{selectedSemana.fec_fin | date: 'dd/MM/yyyy' }}</b></div>
                      </div>
                    </div>
                  </div> -->

                  <!-- filtro de ciudades -->
                  <div class="col-md-2 mr-3 mt-2">
                    <p-dropdown [(ngModel)]="id_ciudad_hm" [options]="lstCiudad" optionLabel="nombre" optionValue="id"
                      placeholder="Filtrar por Ciudad" [showClear]="false" [disabled]="dsblstCiudades">
                    </p-dropdown>
                  </div>

                  <!-- ----------boton principal para el filtro  inicial------------------- -->
                  <div class="col-md-1 ">
                    <button pButton pRipple type="button" (click)="Filtrar();" label="Filtrar" icon="pi pi-filter"
                      iconPos="left"></button>
                  </div>
                  <div class="col-md-1 "></div>
                  <div class="col-md-2 d-flex align-items-center">
                    <div class="justify-content-end">
                      <p-button label="Generar Corte Semanal" icon="pi pi-list" iconPos="right"
                        styleClass="p-button-raised p-button-warning" (click)="corteSemanal();"
                        pTooltip="Consultar Historico" tooltipPosition="left">
                      </p-button>
                    </div>
                  </div>




                  <div class=" ml-4 col-md-3 d-flex justify-content-end">
                    <div>
                      <div class="col-md-6">
                        <div class="row d-flex justify-content-around">
                          <div class="col-md-9">
                            <p-calendar [(ngModel)]="anio_filtro" view="year" [showIcon]="true" dateFormat="yy"
                              inputId="yearpicker" [style]="{'width':'100%','height':'80%'}"
                              pTooltip="Año de Semanas a Consultar" tooltipPosition="top"></p-calendar>
                          </div>
                          <div class="col-md-3">
                            <button pButton pRipple label="Cargar" class="p-button-help" icon="pi pi-filter"
                              (click)="cargarListadoDeSemanasAnio_cast(anio_filtro);"
                              pTooltip="Cargar Semanas del Año"></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- ----------boton principal de los reportes en excel------------------- -->
                  <div class="  col-md-1 ml-4 ">
                    <button type="button" pButton pRipple class="p-button-success " pTooltip="Exportar Catalogo a EXCEL"
                      tooltipPosition="right" (click)="abrirModalReportes()">
                      <i class="pi pi-file-excel"></i> Exportar
                    </button>
                  </div>
                </div>
              </div>
            </div>



            <div class="card-content">
              <div class="row mt-3">
                <!-- informacio  -->
                <p-table #dt1 [value]="lstInfonavit" [tableStyle]="{'min-width': '50rem'}"
                  styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true" scrollHeight="480px"
                  scrollDirection="horizontal" responsiveLayout="scroll"
                  [globalFilterFields]="['nom_chofer','desc_puesto','desc_ciudad_hm','cod_chofer']" [paginator]="true"
                  [rows]="30" [rowsPerPageOptions]="[10,15,20,30,50]" [showCurrentPageReport]="true"
                  [resizableColumns]="false" currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros"
                  [loading]="loading">
                  <ng-template pTemplate="caption">
                    <div class="flex justify-content-between">
                      <div class="col-md-2" >
                        <div>
                          <button pButton pRipple type="button" (click)="crearNuevo();" *ngIf="habilitaragregar"
                          label="Nueva Deduccion" icon="pi pi-plus" [style]="{'width':'100%'}" iconPos="left"
                          class="info">
                        </button>
                        </div>

                      </div>
                      <div class="col-md-3">
                        <p-columnFilter field="clv_activo" matchMode="contains" [showMenu]="false">
                          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                            <p-dropdown [ngModel]="value" [options]="lstEstatus" optionLabel="nombre" optionValue="id"
                              (onChange)="filter($event.value)" placeholder="Filtrar por Estatus" [showClear]="true">
                            </p-dropdown>
                          </ng-template>
                        </p-columnFilter>
                      </div>
                      <div class="p-input-icon-left p-ml-auto" *ngIf="habilitaragregar">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" size="40" (input)="applyFilterGlobal($event, 'contains')"
                          placeholder="Buscar: Codigo chofer, Nombre y Puesto"
                          pTooltip="Buscar por nombre del colaborador, ciudad de operacion o tipo transporte"
                          tooltipPosition="top" />
                      </div>
                      <div class="p-input-icon-left p-ml-auto" *ngIf="!habilitaragregar">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" size="40" (input)="applyFilterGlobal($event, 'contains')"
                          placeholder="Buscar: Codigo chofer, Nombre y Puesto"
                          pTooltip="Buscar por nombre del colaborador, ciudad de operacion o tipo transporte"
                          tooltipPosition="top" />
                      </div>
                    </div>

                  </ng-template>

                  <!-- tabulado -->
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="text-center" pSortableColumn="cod_chofer">#Chofer<p-sortIcon
                          field="cod_chofer"></p-sortIcon></th>
                      <th class="text-center" pSortableColumn="nom_chofer">Colaborador<p-sortIcon
                          field="nom_chofer"></p-sortIcon></th>
                      <th class="text-center" pSortableColumn="id_ciudad_hm">Ciudad<p-sortIcon
                          field="id_ciudad_hm"></p-sortIcon></th>
                      <th class="text-center" pSortableColumn="desc_puesto">Puesto<p-sortIcon
                          field="desc_puesto"></p-sortIcon></th>
                      <th class="text-center">Año</th>
                      <th class="text-center">Fecha registro</th>
                      <!-- <th class="text-center">Fecha fin</th> -->
                      <th class="text-center">Imp.deduccion</th>
                      <th class="text-center">Estatus</th>
                      <th class="text-center">Opciones</th>
                    </tr>

                  </ng-template>
                  <ng-template pTemplate="body" let-data let-index>
                    <tr>
                      <td class="text-center"><b>{{ data.cod_chofer }}</b></td>
                      <td class="justify-content-left"><b>{{ data.nom_chofer }}</b></td>
                      <td class="text-center">{{data.desc_ciudad_hm}}</td>
                      <td class="text-left pl-3"
                        [ngStyle]="{'color': data.desc_puesto ==='DRIVER'? 'rgba(60,  148, 224)' : 'rgba(60, 224, 122  )'}">
                        <b>{{ data.desc_puesto }}</b>
                      </td>
                      <td class="text-center">{{ data.num_anio }}</td>
                      <td class="text-center"><b>{{ data.fec_registro | date: 'dd/MM/yyyy' }}</b></td>
                      <!-- <td class="text-center"><b>{{ data.fec_fin | date: 'dd/MM/yyyy' }}</b></td> -->
                      <td class="text-center" style="color: rgb(22, 48, 220)"><b>{{ data.imp_deduccion | currency: 'USD'
                          }}</b></td>

                      <td class="text-center">
                        <p-tag *ngIf="data.clv_activo==1" severity="success" value="activo"></p-tag>
                        <p-tag *ngIf="data.clv_activo==2" [style]="{ 'background': '#e61216'}" value="Inactivo"></p-tag>

                      </td>
                      <td>
                        <div class="d-flex justify-content-around">
                          <div class="ml-2">
                            <button class=" ml-2 btn btn-light" pTooltip="Mostrar historico de pagos"
                              tooltipPosition="top" (click)="mostrardesgloce(data);">
                              <i class="pi pi-book"></i>
                            </button>
                          </div>
                          <div class="ml-2">
                            <button class="btn btn-primary" pTooltip="Editar Deduccion"
                              (click)=" editarDeduccion(data)">
                              <i class="pi pi-pencil"></i>
                            </button>
                          </div>


                          <!-- <div class="ml-2">
                            <button class="btn btn-danger" pTooltip="Eliminar Deduccion"
                              (click)="EliminarRegistro(data)">
                              <i class="pi pi-trash"></i>
                            </button>
                          </div>-->
                          <div class="ml-2">
                            <button class="btn"
                              [ngClass]="{'btn-warning': data.clv_activo === 1, 'btn-link': data.clv_activo === 0}"
                              tooltipPosition="left"
                              pTooltip="{{data.clv_activo === 1 ? 'Desactivar ahorro' : 'Activar ahorro' }}"
                              (click)="confirmarcambiarestatus(data);">
                              <i class="pi pi-arrow-right-arrow-left"></i>
                            </button>
                          </div>

                        </div>

                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="footer">
                    <tr>
                      <td colspan="2" class="text-right"></td>
                      <td colspan="6" class="text-right" style="color: rgb(169, 12, 12);">Descuento total semanal de infonavit
                      </td>
                      <td style="color: rgb(13, 164, 13);">{{ imp_total_general | currency: 'USD'}}</td>
                    </tr>
                  </ng-template>
                </p-table>

                <!-- pie de pagina -->
              </div>

                <div class=" mt-3 d-flex justify-content-center">
                  <p class="text-left informativo"
                    style="display: inline; padding-left: 10px; border-left: 5px solid rgb(67, 192, 35); font-weight: normal; font-size: 0.8rem;">
                    <b>ACTIVO</b> El colaborador se considera en el corte de infonavit
                  </p>
                  <p class="text-left informativo"
                    style="display: inline; padding-left: 10px; border-left: 5px solid #e61216; font-weight: normal; font-size: 0.8rem;">
                    <b>INACTIVO</b> el colaborador no es considerado en el corte de infonavit
                  </p>


                </div>

            </div>
          </div>
        </div>
      </div>

    </div>



    <!-- pdialog de agregar  -->
    <p-dialog [resizable]="false" [draggable]="false" [scrollTop]="" (onHide)="cancelarAgregar()"
      [(visible)]="dspDeduccion" [style]="{width: '55vw','height':'600px' } " [transitionOptions]="'0ms'">
      <ng-template pTemplate="header">
        {{ texto_operacion}}
      </ng-template>
      <ng-template pTemplate="content">
        <div style="height: 700px; overflow-y: auto;">
          <div class="card-content ">
            <form class="form_cliente" [formGroup]="reactiveforminfonavit" (ngSubmit)="guardarDeduccion()">
              <div class="ml-2">
                <div class="row mt-1 d-flex justify-content-start">
                  <div class="col-sm-6">
                    <span class="title-light">Seleccione el colaborador a agregar
                      <i class="pi pi-cog" style="size: 2rem;">
                      </i>
                    </span>
                  </div>
                </div>


                <div class="row d-flex justify-content-start mt-3">


                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-3  text-center">
                        <label class="col-form-label color-font">
                          <span class="input-group-addon">
                            <i class="pi pi-user color-icon " style="font-size: 2rem"></i>
                          </span>
                          Acreedor
                        </label>
                      </div>
                      <div class="col-md-9">
                        <p-dropdown [options]="lstChoferes" optionLabel="nombre_completo" formControlName="cod_chofer"
                          autoWidth="false" [showClear]="false" [filter]="true" [filterBy]="'nombre_completo'"
                          placeholder="Seleccione Colaborador" [style]="{'width':'100%' , 'margin-top': '20px'}"
                          optionValue="cod_chofer" (onChange)="changedp($event)">

                        </p-dropdown>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 mt-3 ">
                    <div class="row">
                      <div class="col-md-3 text-center">
                        <label class="col-form-label color-font">
                          <span class="input-group-addon "> <i class="pi pi-calendar color-icon "
                              style="font-size: 2rem; color: #3B82F6;"></i>
                          </span>
                          &nbsp;&nbsp;Año&nbsp;&nbsp;
                        </label>
                      </div>
                      <div class="col-md-9">
                        <select class="form-control" formControlName="num_anio">
                          <option *ngFor="let year of years" [value]="year">{{ year }}</option>
                        </select>
                      </div>
                    </div>
                  </div>




                </div>


                  <!-- colaboradores  que recibira el apoyo-->
                  <div class="row  d-flex justify-content-start mt-3 ">
                    <div class=" col-md-6">
                      <div class="row">
                        <div class="col-md-3 mt-2 text-center">
                          <label class="col-form-label color-font">
                            <span class="input-group-addon">
                              <i class="pi pi-money-bill color-icon " style="font-size: 2rem"></i>
                            </span>
                            Importe
                          </label>
                        </div>
                        <div class="col-md-9 mt-2">
                          <div class="p-input-icon-left " style="display: flex; align-items: center;">
                            <i class="pi pi-dollar "></i>
                            <input type="number" formControlName="imp_deduccion" min="0" pInputText size="30"
                              placeholder="Agregar Deducción"
                              style="-webkit-appearance: none; margin: 0; flex: 1; width: 100%;" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row ">
                        <div class="col-md-3  text-center">
                          <label class="col-form-label color-font ">
                            <span class="input-group-addon">
                              <i class="pi pi-globe color-icon " style="font-size: 2rem"></i>
                            </span>
                            Ciudad
                          </label>
                        </div>
                        <div class="col-md-9">
                          <p-dropdown [options]="lstCiudad2" optionLabel="nombre" formControlName="id_ciudad_hm"
                            autoWidth="false" [showClear]="false"
                            placeholder="Seleccione Ciudad del colaborador acreedor"
                            [style]="{'width':'100%' , 'margin-top': '20px'}" optionValue="id"
                            pTooltip="{{mssgListaCiudades}}">
                          </p-dropdown>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="row">

                    <div class=" col-md-6"></div>
                    <div class="row"></div>
                  </div> -->

                  <div class="row  d-flex justify-content-start mt-3">

                  <!-- --------------fin drodowns------------- -->
                  <div class="row d-flex ">
                    <div class="col-md-5  mt-6 justify-content-start">
                      <!-- <p><span style="font-weight: bold; ">Última&nbsp;Modificación:</span></p>
                 <p style="margin-top: 0px;  color: rgb(181, 17, 50);"> -->
                      <!-- <b>{{reactiveVacacionesForm.get('usuarioMod')?.value}}</b>
                 </p>-->
                    </div>

                    <div class="col-md-3 mt-7 justify-content-center">
                      <button class="btn btn-primary form-control" type="submit" [disabled]="reactiveforminfonavit.controls['clv_operacion'].value === 2 ?  !reactiveforminfonavit.controls['imp_deduccion'].valid   :  !reactiveforminfonavit.controls['imp_deduccion'].valid || !reactiveforminfonavit.controls['cod_chofer'].valid
                              || !reactiveforminfonavit.controls['imp_deduccion'].valid  || !reactiveforminfonavit.controls['num_anio'].valid
                              " pTooltip="Almacenar información" tooltipPosition="right">
                        <span class="text-white">Guardar</span>
                        <i class="pi pi-save text-white"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </ng-template>

      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
          (click)="cancelarAgregar()"></button>
      </ng-template>
    </p-dialog>






    <!-- p-dialog de reportes excel ------------ -->
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dbsReportes"
      [style]="{'width': '60vw','height':'620px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
      (onHide)="cancelarModalReportes()">
      <ng-template pTemplate="header">
        Generar Reporte De Excel
      </ng-template>
      <ng-template pTemplate="content">
        <div class="mt-5">
          <hr class="style-two">
          <div class="row">
            <div class="col-md-3 mt-3">
              <p class="texto-pesado">Reporte de ducciones de infonavit</p>
            </div>
            <div class="col-md-3">
              <p-dropdown [options]="lstSemanas" autoWidth="false" [style]="{'width':'100%'}"
                placeholder="Seleccione Semana*" [(ngModel)]="selectedSemanaReporte" optionLabel="nom_semana"
                [showClear]="false">
                <ng-template let-target pTemplate="item">
                  <div class="ui-helper-clearfix" style="position: relative;height:25px;"
                    pTooltip="{{getTargetDescription(target)}}" tooltipPosition="top">
                    <span style="font-size:14px;float:left;margin-top:4px">{{target.nom_semana}}</span>
                  </div>
                </ng-template>
              </p-dropdown>
              <div *ngIf="selectedSemanaReporte!=null">
                <div class="row">
                  <div style="color: green;" class="col-sm-6 text-left "><b>Inicio:
                      {{selectedSemanaReporte.fec_inicio | date: 'dd/MM/yyyy' }}</b></div>
                  <div style="color: green;" class="col-sm-6 text-right "><b>Fin:
                      {{selectedSemanaReporte.fec_fin | date: 'dd/MM/yyyy' }}</b></div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <p-dropdown [(ngModel)]="id_ciudad_hm_rpt" [options]="lstCiudad" optionLabel="nombre" optionValue="id"
                placeholder="Seleccione una Ciudad" [showClear]="false" [disabled]="dsblstCiudades">
              </p-dropdown>
            </div>
            <div class="col-md-2 ml-6">
              <button type="button" [disabled]="deshabilitarbtnexportar" class="btn btn-outline-success"
                style="width: 100%;" (click)="exportarExcel()" [disabled]="ButtonExportExcel">
                Exportar <i class="pi pi-file-export"></i>
              </button>
            </div>
          </div>
          <div class="row d-flex justify-content-center">
            <div class="col-2 text-center">
              <i class="pi pi-spin pi-spinner" *ngIf="loading_reporte1"></i>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
          (click)="cancelarModalReportes();"></button>
      </ng-template>
    </p-dialog>

    <!-- pagar caja de ahorro boton -->
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspcortesemanal"
      [style]="{width: '65vw', height: '550px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
      (onHide)="cancelarcorte()">
      <ng-template pTemplate="header">
        {{ nombre_registro }}
      </ng-template>
      <ng-template pTemplate="content">
        <div class="mt-5">
          <span class="title-light mt-3">
            Información de Caja de Ahorro <i class="pi pi-cog" style="size: 2rem;"></i>
          </span>
          <div class="row mt-4 d-flex align-items-start">
            <!-- Caja de Ahorro Anual -->
            <div class="col-md-3">
              <div class="text-center">
                <label class="col-form-label color-font" style="font-weight: 600; font-family: 'Poppins', sans-serif;">
                  <span class="input-group-addon ">
                    <i class="pi pi-wallet color-icon" style="font-size: 2rem; color: #3B82F6;"></i>
                  </span>
                  Descuento semanal infonavit
                </label>
              </div>
            </div>
            <div class="col-md-2 mt-3">
              <select class="form-control" style="width: 100%;" [(ngModel)]="num_anio">
                <option *ngFor="let year of years" [value]="year">{{ year }}</option>
              </select>
            </div>
            <div class="col-md-4 mt-2">
              <p-dropdown [options]="lstSemanas" autoWidth="false" [style]="{'width':'100%'}"
                placeholder="Seleccione Semana*" [(ngModel)]="selectedSemana" name="selectedSemana"
                optionLabel="nom_semana" [showClear]="false">
                <ng-template let-target pTemplate="item">
                  <div class="ui-helper-clearfix" style="position: relative;height:25px;"
                    pTooltip="{{getTargetDescription(target)}}" tooltipPosition="top">
                    <span style="font-size:14px;float:left;margin-top:4px">{{target.nom_semana}}</span>
                  </div>
                </ng-template>
              </p-dropdown>
              <div *ngIf="selectedSemana.id_semana" class="mt-2">
                <div class="row">
                  <div style="color: green;" class="col-sm-6 text-left "><b>Inicio:
                      {{selectedSemana.fec_inicio | date: 'dd/MM/yyyy' }}</b></div>
                  <div style="color: green;" class="col-sm-6 text-right "><b>Fin:
                      {{selectedSemana.fec_fin | date: 'dd/MM/yyyy' }}</b></div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <p-dropdown [(ngModel)]="id_ciudad_hm" [options]="lstCiudad2" optionLabel="nombre" optionValue="id"
                placeholder="Selecione una Ciudad" [showClear]="false" [disabled]="dsblstCiudades">
              </p-dropdown>
            </div>

          </div>

          <div class="row mt-7"></div>
          <div class="row d-flex justify-content-center mt-5">
            <div class="col-2">
              <button type="button" class="btn btn-success w-100" (click)="generarcorte()">
                <span class="text-white">Guardar</span>
                <i class="pi pi-save text-white"></i>
              </button>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
          (click)="cancelarcorte();"></button>
      </ng-template>
    </p-dialog>



    <p-dialog [resizable]="false" [draggable]="false" (onClose)="cancelarVerHistorico()" [(visible)]="dsphistorico"
      [style]="{width: '55vw','height':'650px'}" [transitionOptions]="'0ms'" styleClass="card-shadow">
      <ng-template pTemplate="header">
        Historico de deducciones de infonavit por colaborador
        <div style="font-size: 18px;">
          <!--<p style="color: orange;display: inline;">{{nombre_data_Editable }}</p>&nbsp;-->
          <!-- <p style="color:black; display: inline; ">Fecha: {{dia | date: 'dd/MM/yyyy'}}</p> -->

        </div>
      </ng-template>

      <ng-template pTemplate="content">
        <div class="mt-5">
          <p-table #dt2 [value]="lsthisInfonavit" [tableStyle]="{'min-width': '50rem'}"
            styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true" scrollHeight="550px"
            responsiveLayout="scroll" [paginator]="true" [rows]="10" [rowsPerPageOptions]="[10,25,50,100]"
            [showCurrentPageReport]="true" [resizableColumns]="false"
            currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros" [loading]="loading">
            <ng-template pTemplate="caption">
              <form class="form_cliente">
                <div class="row  mt-1 ">
                  <div class=" ml-3 d-flex justify-content-start ">Colaborador : &nbsp;<label
                      style="color: #23c00e;"> {{nombre_completo}}</label> </div>
                </div>
                      <!--<div class="row mb-1 "> -->


                  <!-- <div class=" col-md-4 d-flex justify-content-center  mlf-5">Importe acumulado : &nbsp;<label
                      style="color: #23c00e;"> {{imp_total| currency}}</label> </div>
                </div>-->
                <div class="row  mt-1 mb-1">
                  <div class=" ml-3 d-flex justify-content-start  ">Codigo de chofer :&nbsp;<label
                      style="color: #23c00e;"> {{cod_chofer}}</label> </div>
                </div>
              </form>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <!-- <th class="text-center" pSortableColumn="cod_chofer">cod_chofer<p-sortIcon
              field="cod_chofer"></p-sortIcon></th> -->
                <th class="text-center" pSortableColumn="id_semana">Id semana<p-sortIcon field="id_semana"></p-sortIcon>
                </th>
                <th class="text-center" pSortableColumn="fec_inicio">Inicio<p-sortIcon field="fec_inicio"></p-sortIcon>
                </th>
                <th class="text-center" pSortableColumn="fec_fin">Fin<p-sortIcon field="fec_fin"></p-sortIcon></th>
                <th class="text-center">Año</th>
                <th class="text-center" pSortableColumn="imp_ahorro">Importe de deduccion<p-sortIcon
                    field="imp_ahorro"></p-sortIcon></th>
                <!-- <th class="text-center" pSortableColumn="clv_pagado">Estatus<p-sortIcon field="clv_pagado"></p-sortIcon>

          </th> -->
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data>
              <tr [ngClass]="{'row-accessories': data.clv_activo ===0}">

                <!-- <td class="text-center"><b>{{data.cod_chofer}}</b></td> -->
                <td class="text-center" style="color: rgb(17, 199, 17)"><b>{{data.id_semana}}</b></td>
                <td class="text-center" style="color: rgb(13,110,253);"><b>{{data.fec_inicio | date: 'dd/MM/yyyy' }}</b>
                </td>
                <td class="text-center" style="color: orange;"><b>{{data.fec_fin | date: 'dd/MM/yyyy' }}</b></td>
                <td class="text-center"> <b>{{data.num_anio}}</b></td>
                <td class="text-center" style="color: rgb(192, 39, 22)"> <b>-{{data.imp_deduccion |currency}}</b></td>
                <!-- <td class="text-center" style="color: rgb(255,193,7)" ><b>{{data.clv_pagado}}</b></td> -->
                <!-- <td class="text-center">
            <p-tag *ngIf="data.clv_estatus==1" severity="success" value="Ingreso"></p-tag>
            <p-tag *ngIf="data.clv_estatus==2" [style]="{ 'background': '#e61216'}" value="Retiro"></p-tag>
            <p-tag *ngIf="data.clv_estatus==3" [style]="{ 'background': '#adacac'}"
              value="Pagado al finalizar el año"></p-tag>
          </td> -->

              </tr>
            </ng-template>
          </p-table>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
          (click)="cancelarVerHistorico()"></button>
      </ng-template>
    </p-dialog>







  </div>
