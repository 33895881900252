import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, catchError, retry, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { IPrestamosViaticos } from '../models/prestamoviaticos';
import { IColaboradoresQuinceDias } from '../models/prest_viaticos';
import { IPrestamoDispersion } from '../models/prestamo_dispersion';
import { IResultadoGeneral } from '../models/resultadogeneral';
import { IPrestamoAutorizar } from '../models/prestamo_autorizar';
import { IViaticoPagadoEstatus } from '../models/prestamo_viatico_estatus';

@Injectable({
  providedIn: 'root'
})
export class PrestamoviaticosService {
  private apiUrl: string ='';
  constructor(private _http: HttpClient) { 
    this.apiUrl = environment.sUrlNomina + 'PrestamoViaticos';
  }

  cargarInformacion(num_anio:number,cod_usuario:string): Observable<IPrestamosViaticos[]>{
    const valores = {
      params: new HttpParams().
        set('num_anio', num_anio).
        set('cod_usuario', cod_usuario)
    };
    return this._http
      .get<any>(this.apiUrl+'/ObtenerPrestamoViaticosLista', valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  cargarInformacionPrestamoPagado(id_prestamo:number): Observable<IViaticoPagadoEstatus[]>{
    const valores = {
      params: new HttpParams().
        set('id_prestamo', id_prestamo)
    };
    return this._http
      .get<any>(this.apiUrl+'/GetDispersionPagada', valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  obtenerDispersionDePrestamo(imp_total:number,cod_chofer:number,fecha_registro:string,
    cod_usuario_registro:string): Observable<IPrestamoDispersion[]>{
    const valores = {
      params: new HttpParams().
        set('imp_total', imp_total).
        set('cod_chofer', cod_chofer).
        set('fecha_registro', fecha_registro).
        set('cod_usuario_registro', cod_usuario_registro)
    };
    return this._http
      .get<IPrestamoDispersion[]>(this.apiUrl+'/ObtenerDispersionViaticos', valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  cargarInformacionQuinceDias(): Observable<IColaboradoresQuinceDias[]>{
    return this._http
      .get<any>(this.apiUrl)
      .pipe(retry(1), catchError(this.handleError));
  }

  guardarAutorizacionPrestamo(id_prestamo:number,clv_autorizado:number,clv_finalizado:number,cod_usuario:string): Observable<IResultadoGeneral>{
    let data_enviar: IPrestamoAutorizar = {
      id_prestamo: id_prestamo,
      clv_autorizado: clv_autorizado,
      clv_finalizado: clv_finalizado,
      cod_usuario: cod_usuario
    };
    const headers = { 'content-type': 'application/json'}  
    const body = JSON.stringify(data_enviar); 
    return this._http.post<any>(this.apiUrl + '/ActualizaViaticosAuth',body,{'headers':headers});
  }

  guardarPrestamo(lista: IPrestamoDispersion[]):Observable<IResultadoGeneral>{
    const headers = { 'content-type': 'application/json'}  
    const body = JSON.stringify(lista); 
    return this._http.post<any>(this.apiUrl + '/AgregaPrestamoViaticos',body,{'headers':headers});
  }

  handleError(error:any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
        return errorMessage;
    });
  }
}
