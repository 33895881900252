import { Component, OnInit, ViewChild } from '@angular/core';
import { ISemanasHappyMile } from '../models/semanas';
import { Table } from 'primeng/table';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmEventType, ConfirmationService, MessageService } from 'primeng/api';
import * as moment from 'moment';
import { SemanaService } from '../services/semana.service';
import { IErrorCode } from '../models/errorcode';

@Component({
  selector: 'app-semanas',
  templateUrl: './semanas.component.html',
  styleUrls: ['./semanas.component.scss']
})
export class SemanasComponent implements OnInit {
  @ViewChild('dt1') dt!: Table;
  loading: boolean = false;
  lstSemanas: ISemanasHappyMile[] = [];
  dspDialogo: boolean = false;
  dataForm: FormGroup = new FormGroup({
    id_semana: new FormControl(null, [Validators.required]),
    nom_semana: new FormControl(''),
    anio: new FormControl(0),
    fec_inicio: new FormControl('', [Validators.required]),
    fec_fin: new FormControl('', [Validators.required])
  });
  nombre_semana: string = '';
  texto_operacion: string = '';
  clv_editar: boolean = false;
  fecha_dia:any=null;
  fecha_inicio_edicion:any='';
  clv_operacion:number=0;
  error_captura!: IErrorCode;
  constructor(private confirmationService: ConfirmationService, private messageService: MessageService,
    private semanaService:SemanaService) {
  }
  ngOnInit(): void {
    this.inicializarFechas();
    this.cargarListadoDeSemanasAnio();
  }

  cargarListadoDeSemanasAnio(){
    this.lstSemanas=[];
    this.loading=true;
    this.semanaService.listarTodasSemanas().subscribe((resp)=> {
      this.lstSemanas=resp;
      setTimeout(() => {
        this.loading=false;
      },
        600);
    },
    (error)=>{
      this.loading=false;
      this.mostrarDialogoInformativoSinInformacion('<b>Error</b> al cargar Información. Contacte al Administrador.');
    });
  }

  mostrarModalAgregar() {
    this.clv_operacion=1;
    this.dataForm.get('id_semana')!.enable();
    this.texto_operacion = 'Agregar Registro';
    this.dspDialogo = true;
  }

  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  mostrarModalEditar(data: ISemanasHappyMile) {
    this.clv_operacion=2;
    this.dataForm.get('id_semana')!.disable();
    this.texto_operacion = 'Editar Registro';
    this.dataForm.controls['id_semana'].setValue(data.id_semana);
    this.dataForm.controls['fec_inicio'].setValue(data.fec_inicio.substring(0,10));
    this.dataForm.controls['fec_fin'].setValue(data.fec_fin.substring(0,10));
    this.fecha_inicio_edicion=data.fec_inicio;
    this.nombre_semana=data.nom_semana;
    this.dspDialogo=true;
  }

  confirmarEliminar(id: number, data: ISemanasHappyMile) {
    let clv_estatus_original: number = data.clv_activo;
    let texto_desactivar: string = data.clv_activo == 1 ? 'DESACTIVAR' : 'ACTIVAR';
    let texto_desactivar_header: string = data.clv_activo == 1 ? 'Desactivar' : 'Activar';
    this.confirmationService.confirm({
      message: 'Esta seguro que desea <b>' + texto_desactivar + '</b> el registro de Semana <b>' + data.nom_semana + '</b>('+data.anio+') ?',
      header: 'Confirmación de ' + texto_desactivar_header,
      icon: 'pi pi-info-circle',
      accept: () => {
        data.clv_activo = data.clv_activo == 1 ? 0 : 1;
        this.guardarInformacionDesactivar(data);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            data.clv_activo = clv_estatus_original;
            //this.messageService.add({ key: 'msj', severity: 'error', summary: 'Rechazado', detail: 'Se ha Rechazado la operación' });
            break;
          case ConfirmEventType.CANCEL:
            data.clv_activo = clv_estatus_original;
            //this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Se ha Cancelado la operación' });
            break;
        }
      }
    });
  }

  cancelarModal() {
    this.dspDialogo = false;
    this.texto_operacion = '';
    this.clv_editar = false;
    this.fecha_inicio_edicion='';
    this.dataForm.reset();
    this.clv_operacion=0;
    this.nombre_semana='';
  }

  guardarInformacion() {
    let fecha1: any = this.dataForm.get('fec_inicio')?.value;
    let fecha2: any = this.dataForm.get('fec_fin')?.value;
    let rango_fechas_correcto = this.calcularDiferenciaDeDias(fecha1, fecha2);
    if (rango_fechas_correcto == 1) {
      let semana: ISemanasHappyMile = {
        idx: 0,
        id_semana: 0,
        nom_semana: '',
        anio: 2024,
        fec_inicio: '',
        fec_fin: '',
        clv_activo: 1
      };
      if(this.clv_operacion==1){
        semana.id_semana=this.dataForm.get('id_semana')?.value;
        semana.fec_inicio=this.dataForm.get('fec_inicio')?.value;
        semana.fec_fin=this.dataForm.get('fec_fin')?.value;
        this.semanaService.agregarSemana(semana).subscribe((resp)=> {
          this.cancelarModal();
          this.messageService.add({ key:'msj', severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se registro de forma correcta' });
          this.cargarListadoDeSemanasAnio();
        },
        (error)=> {
          console.log(error.error);
          if(error.status==409){
            this.error_captura = error.error;
            console.log(this.error_captura.message);
          }
          this.messageService.add({key:'msj', severity: 'error', summary: 'Error en <Agregar>', detail: this.error_captura.message });
        });
        
      } else if(this.clv_operacion==2){
        semana.id_semana=this.dataForm.get('id_semana')?.value;
        semana.fec_inicio=this.dataForm.get('fec_inicio')?.value;
        semana.fec_fin=this.dataForm.get('fec_fin')?.value;
        this.semanaService.editarSemana(semana).subscribe((resp)=> {
          this.cancelarModal();
          this.messageService.add({ key:'msj', severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se registro de forma correcta' });
          this.cargarListadoDeSemanasAnio();
        },
        (error)=> {
          console.log(error.error);
          if(error.status==409){
            this.error_captura = error.error;
            console.log(this.error_captura.message);
          }
          this.messageService.add({key:'msj', severity: 'error', summary: 'Error en <Editar>', detail: this.error_captura.message });
        });
      }
    } else {
      this.mostrarDialogoInformativoSinInformacion('Rango entre Fechas es Invalido. Deben ser 7 dias.');
    }
  }

  calcularDiferenciaDeDias(fecha1: any, fecha2: any): number {
    let respuesta:number=0;
    var fecha1:any = moment(fecha1);
    var fecha2:any = moment(fecha2);
    let diff_in_days:number = fecha2.diff(fecha1, 'days')+1;
    if (diff_in_days == 7) {
      respuesta = 1;
    } else {
      respuesta = 0;
    }
    return respuesta;
  }

  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario;
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }
  
  inicializarFechas(){
    var getYear = new Date().toLocaleDateString('es-MX',{ year: 'numeric'});   
    var getMonth = new Date().toLocaleDateString('es-MX',{ month: '2-digit'});
    var getDay = new Date().toLocaleDateString('es-MX',{day: '2-digit'});
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.fecha_dia = dateFormat;
  }

  get fec_inicio() { return this.dataForm.get('fec_inicio'); }

  confirmarCambiarEstatus(id:number,data:ISemanasHappyMile){
    
  }

  guardarInformacionDesactivar(data:ISemanasHappyMile){
    this.semanaService.estatusSemana(data).subscribe((resp)=> {
      this.cargarListadoDeSemanasAnio();
      this.messageService.add({ key:'msj', severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se registro de forma correcta' });
    },
    (error)=> {
      this.messageService.add({key:'msj', severity: 'error', summary: 'Error en <Editar>', detail: 'Contacte al Administrador del Sitio' });
    });
  }

}

