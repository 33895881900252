<p-toast position="top-right" key="msj"></p-toast>
<!--<p-messages></p-messages>-->
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="Aceptar" rejectLabel="Cancelar"
  rejectButtonStyleClass="p-button-text">
</p-confirmDialog>

<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
  rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar" [rejectVisible]="false">
</p-confirmDialog>
<div class="d-flex justify-content-center">
  <div class="container-fluid" style="width: 95%;">
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="card card-shadow sinborde">
          <div class="card-header sinborde" style="background: none; ">
            <div class="cardicono rounded">
              <i class="pi pi-dollar mt-3" style="font-size: 2rem; color: #fff;"></i>
            </div>
            <header style="left: 11%; top: 5%;">
              <div style="display:inline"> Gestion de descuentos</div>

              <div *ngIf="dia.length > 0" style="color:rgb(31, 153, 27);display:inline;" class="ml-3">Echame la mano
              </div>
              <!-- - {{dia|date:'dd/MM/yyyy'}}
                 -->
            </header>
            <div class="row d-flex justify-content-between">
              <div class="col-md-12">
                <!-- -------------fecha del filtro---------------- -->
                <div class="form-group row mt-5">
                  <div class="col-md-1"></div>
                  <!-- filtro de semanas -->
                  <div class="col-md-2">
                    <p-dropdown [options]="lstSemanas" autoWidth="false" [style]="{'width':'100%'}"
                      placeholder="Seleccione Semana*" [(ngModel)]="selectedSemana" optionLabel="nom_semana"
                      [showClear]="false">
                      <ng-template let-target pTemplate="item">
                        <div class="ui-helper-clearfix" style="position: relative;height:25px;"
                          pTooltip="{{getTargetDescription(target)}}" tooltipPosition="top">
                          <span style="font-size:14px;float:left;margin-top:4px">{{target.nom_semana}}</span>
                        </div>
                      </ng-template>
                    </p-dropdown>
                    <div *ngIf="selectedSemana!=null">
                      <div class="row">
                        <div style="color: green;" class="col-sm-6 text-left "><b>Inicio:
                            {{selectedSemana.fec_inicio | date: 'dd/MM/yyyy' }}</b></div>
                        <div style="color: green;" class="col-sm-6 text-right "><b>Fin:
                            {{selectedSemana.fec_fin | date: 'dd/MM/yyyy' }}</b></div>
                      </div>
                    </div>
                  </div>

                  <!-- filtro de ciudades -->
                  <div class="col-md-2 mr-3 mt-2">
                    <p-dropdown [(ngModel)]="id_ciudad_hm" [options]="lstCiudad" optionLabel="nombre" optionValue="id"
                      placeholder="Filtrar por Ciudad" [showClear]="false" [disabled]="dsblstCiudades">
                    </p-dropdown>
                  </div>

                  <!-- ----------boton principal para el filtro  inicial------------------- -->
                  <div class="col-md-1 ">
                    <button pButton pRipple type="button" (click)="Filtrar();" label="Filtrar" icon="pi pi-filter"
                      iconPos="left"></button>
                  </div>
                  <div class="col-md-1 "></div>
                  <div class=" ml-4 col-md-3 d-flex justify-content-end">
                    <div>
                      <div class="col-md-6">
                        <div class="row d-flex justify-content-around">
                          <div class="col-md-9">
                            <p-calendar [(ngModel)]="anio_filtro" view="year" [showIcon]="true" dateFormat="yy"
                              inputId="yearpicker" [style]="{'width':'100%','height':'80%'}"
                              pTooltip="Año de Semanas a Consultar" tooltipPosition="top"></p-calendar>
                          </div>
                          <div class="col-md-3">
                            <button pButton pRipple label="Cargar" class="p-button-help" icon="pi pi-filter"
                              (click)="cargarListadoDeSemanasAnio_cast(anio_filtro);"
                              pTooltip="Cargar Semanas del Año"></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- ----------boton principal de los reportes en excel------------------- -->
                  <div class="  col-md-1 ml-4 ">
                    <button type="button" pButton pRipple class="p-button-success " pTooltip="Exportar Catalogo a EXCEL"
                      tooltipPosition="right" (click)="abrirModalReportes()">
                      <i class="pi pi-file-excel"></i> Exportar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-content">
            <div class="row mt-3">
              <!-- informacio  -->
              <p-table #dt1 [value]="lstEchamelaMano" [tableStyle]="{'min-width': '50rem'}"
                styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true" scrollHeight="480px"
                responsiveLayout="scroll" [globalFilterFields]="['cod_chofer','nombre_completo','desc_ciudad']"
                [paginator]="true" [rows]="30" [rowsPerPageOptions]="[10,15,20,30,50,100]"
                [showCurrentPageReport]="true" [resizableColumns]="false"
                currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros" [loading]="loading">
                <ng-template pTemplate="caption">
                  <div class="flex justify-content-between">
                    <div class="col-md-3">
                      <button pButton pRipple label="Agregar nuevo registro" class="btn btn-success" icon="pi pi-plus"
                        (click)="crearNuevo();" pTooltip="Registro Descuento" tooltipPosition="right" iconPos="right"
                        [disabled]="lstSemanas.length == 0 || dspDeduccion || dspDialogoDispercion"></button>
                    </div>

                     <div class="col-md-3">
                      <p-columnFilter field="desc_estatus" matchMode="contains" [showMenu]="false">
                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                          <p-dropdown [ngModel]="value" [options]="lstEstatus" optionLabel="nombre" optionValue="nombre"
                            (onChange)="filter($event.value)" placeholder="Filtrar por Estatus" [showClear]="true">
                          </p-dropdown>
                        </ng-template>
                      </p-columnFilter>
                    </div>


                    <span class="p-input-icon-left p-ml-auto">
                      <i class="pi pi-search"></i>
                      <input pInputText type="text" size="40" (input)="applyFilterGlobal($event, 'contains')"
                        placeholder="Buscar Codigo Colaborador,Nombre Colaborador,Ciudad de Operación" />
                    </span>
                  </div>
                </ng-template>
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center" pSortableColumn="cod_chofer">#<p-sortIcon field="cod_chofer"></p-sortIcon>
                    </th>
                    <th class="text-center" pSortableColumn="nombre_completo">Colaborador<p-sortIcon
                        field="nombre_completo"></p-sortIcon></th>
                    <th class="text-center" pSortableColumn="desc_ciudad">Ciudad<p-sortIcon
                        field="desc_ciudad"></p-sortIcon></th>
                    <th class="text-center" pSortableColumn="imp_total">Importe Total<p-sortIcon
                        field="imp_total"></p-sortIcon></th>
                    <th class="text-center" pSortableColumn="id_semana_inicial">Semana Inicial<p-sortIcon
                        field="id_semana_inicial"></p-sortIcon></th>
                    <th class="text-center" pSortableColumn="num_pagos">Pagos Acordados<p-sortIcon
                        field="num_pagos"></p-sortIcon></th>

                        <th class="text-center" >Motivo</th>

                    <th class="text-center" pSortableColumn="desc_estatus">Estatus<p-sortIcon
                        field="desc_estatus"></p-sortIcon></th>
                    <th class="text-center">Dispersión</th>
                    <th class="text-center">Acciones</th>

                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data let-index>
                  <tr>
                    <td class="text-center"> <b>{{data.cod_chofer}}</b></td>
                    <td><b>{{data.nombre_completo }}</b> </td>
                    <td class="text-center"><b>{{data.desc_ciudad}}</b></td>
                    <td class="text-center" style="color: rgb(202, 35, 35);"><b>{{ data.imp_total | currency}}</b>
                    </td>
                    <td class="text-center " style="color: #f0ad38;"><b>{{ data.id_semana_inicial}}</b></td>
                    <td class="text-center" style="color: #2ed249;"><b>{{data.num_pagos}}</b></td>

                    <td class="text-justify" style="width: 200px; min-width: 200px; font-size:13px;">
                      {{data.desc_prestamo   | slice : 0:80 }}<button type="button" class="btn p-button-text" (click)="verMas(data);"
                        pTooltip="ver mas" tooltipPosition="top">
                        <i class="pi pi-comment color-icon mr-3" style="font-size: 1rem; color: #3B82F6;"></i>
                      </button></td>
                    <td class="text-center texto_principal"
                      [ngStyle]="{'color': data.desc_estatus== 'ACTIVO' ? 'rgb(28, 163, 35)' : 'rgb(224, 82, 60)',}">
                      <b>{{ data.desc_estatus}}</b>
                    </td>
                    <td class="text-center texto_principal">
                      <button class=" ml-2 btn btn-light" tooltipPosition="top" (click)="mostrarDialogoDispersion(data)"
                        pTooltip="Desgloce Otros Ingresos" [disabled]="dspDeduccion || dspDialogoDispercion">
                        <i class="pi pi-info-circle color-icon" style="font-size: 1.5rem; color: #39a4e2;">
                        </i></button>

                      <!-- <span class="input-group-addon ml-2 my-0" (click)="mostrarDialogoDispersion(data)"
                          pTooltip="Desgloce Otros Ingresos">
                          <i class="pi pi-info-circle color-icon mr-3" style="font-size: 1.5rem; color: #3B82F6;">
                          </i>
                        </span> -->
                    </td>
                    <td class="texto_principal">
                      <button *ngIf="data.clv_btn_eliminar == 1" class="btn btn-danger w-100"
                        pTooltip="Guardar Asignación de Bono" [disabled]="dspDeduccion || dspDialogoDispercion"
                        (click)="deleteDescuento(data.id_descuento,data.nombre_completo)">
                        <span class="text-white">Eliminar</span>
                        <i class="pi pi-save text-white"></i>
                      </button>
                    </td>
                    <td></td>
                  </tr>
                </ng-template>
                <!-- <ng-template pTemplate="summary">
                                        <div class="flex align-items-center justify-content-center mb-2 mt-2">
                                            <div class="col-md-3">
                                                <button class="btn btn-success w-100"
                                                    pTooltip="Guardar Asignación de Bono" (click)="guardarTodo()">
                                                    <span class="text-white">Guardar Todo</span>
                                                    <i class="pi pi-save text-white"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </ng-template> -->
              </p-table>

              <div class=" mt-3 d-flex justify-content-center">
                <p class="text-left informativo"
                  style="display: inline; padding-left: 10px; border-left: 5px solid rgb(28, 163, 35); font-weight: normal; font-size: 0.8rem;">
                  <b>ACTIVO</b> la deuda de la dispersion continua activa.
                </p>
                <p class="text-left informativo"
                  style="display: inline; padding-left: 10px; border-left: 5px solid rgb(224, 82, 60); font-weight: normal; font-size: 0.8rem;">
                  <b>FINALIZADO</b> la dedua de la dispersion ya fue saldada.
                </p>


              </div>

              <!-- pie de pagina -->
            </div>
            <div class=" mt-3 d-flex justify-content-center">
              <!-- <p class="text-left informativo" style="display: inline; padding-left: 10px; border-left: 5px solid rgb(49, 98, 189); font-weight: normal; font-size: 0.8rem;"><b>ACTIVO</b> el apoyo foráneo se encuentra en proceso</p>
                <p class="text-left informativo"  style="display: inline; padding-left: 10px; border-left: 5px solid rgb(226, 189, 39); font-weight: normal; font-size: 0.8rem;"><b>INACTIVO</b> el apoyo foráneo se ha cancelado, y no se contempla para nomina</p>
                <p class="text-left informativo"  style="display: inline; padding-left: 10px; border-left: 5px solid rgb(28, 163, 35); font-weight: normal; font-size: 0.8rem;">  <b>FINALIZADA</b> el apoyo foráneo se ha finalizado de forma correcta</p>
                <p class="text-left informativo"  style="display: inline; padding-left: 10px; border-left: 5px solid rgb(224, 82, 60); font-weight: normal; font-size: 0.8rem;">  <b>SIN FINALIZAR</b> el apoyo foráneo no ah finalizado</p> -->
              <!-- <p class="text-left informativo"  style="display: inline; padding-left: 10px; border-left: 5px solid rgb(224, 82, 60); font-weight: normal; font-size: 0.8rem;"><b>La pagina no cuenta con opciones de edicion</b> </p> -->

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <!-- pdialog de agregar  -->
  <p-dialog [resizable]="false" [draggable]="false" [scrollTop]="" (onHide)="cancelarAgregar()"
    [(visible)]="dspDeduccion" [style]="{width: '60vw','height':'700px' } " [transitionOptions]="'0ms'">
    <ng-template pTemplate="header">
      {{ texto_operacion }}
    </ng-template>
    <ng-template pTemplate="content">
      <div style="height: 700px; ">
        <div class="card-content ">
          <div class="mt-2">
            <form [formGroup]="dataFormChofer">
              <span class="title-light mt-3">Información General<i class="pi pi-cog" style="size: 2rem;"></i>
              </span>
              <div class="row mt-3">
                <div class="form-group">
                  <div class="row g-0 mt-3 ml-4">
                    <!-- Primer Dropdown -->
                    <div class="col-sm-2 mx-0 mb-4 mt-2">
                      <div class="input-group">
                        <i class="pi pi-user color-icon" style="font-size: 1.5rem"></i>
                        <span class="ml-3 align-self-start"><b>Colaborador</b></span>
                      </div>
                    </div>
                    <div class="col-sm-4 mb-4">
                      <p-dropdown [options]="lstChoferes" autoWidth="false" [style]="{'width':'90%'}"
                        placeholder="Seleccione Colaborador*" optionLabel="nombre_completo" [showClear]="true"
                        [filter]="true" filterBy="nombre_completo" formControlName="cod_chofer" optionValue="cod_chofer"
                        tooltip="">
                        <ng-template let-item pTemplate="selectedItem">
                          <span style="vertical-align:middle; word-wrap: break-word;"
                            class="texto_responsable">{{item.nombre_completo}}</span>
                        </ng-template>
                        <ng-template let-target pTemplate="item">
                          <div class="ui-helper-clearfix" style="position: relative;height:25px;"
                            pTooltip="{{target.desc_ciudad_hm}}" tooltipPosition="top">
                            <span
                              style="font-size:14px;float:left;margin-top:4px;word-wrap: break-word;">{{target.nombre_completo}}</span>
                          </div>
                        </ng-template>
                      </p-dropdown>
                    </div>
                    <!-- Segundo Dropdown -->
                    <div class="col-sm-2 mx-0 ml-2 mb-4 mt-2">
                      <div class="input-group">
                        <i class="pi pi-building color-icon" style="font-size: 1.5rem"></i>
                        <span class="ml-3 align-self-start"><b>Ciudad</b></span>
                      </div>
                    </div>
                    <div class="col-sm-4 mb-4">
                      <p-dropdown [options]="lstCiudad" autoWidth="false" [style]="{'width':'90%'}"
                        placeholder="Seleccione Ciudad Operación*" optionLabel="nombre" [showClear]="true"
                        [filter]="true" formControlName="id_ciudad_hm" optionValue="id" tooltip="">
                      </p-dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="mt-2">
            <form [formGroup]="dataFormCalc" (ngSubmit)="calcularDispersion()">
              <div class="row">
                <div style="display: inline-flex;">
                  <div class="col-md-6">
                    <span class="ml-4 title-light mt-1">Calculo Dispersión<i class="pi pi-cog" style="size: 2rem;"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="container mt-3">
                <div class="row">
                  <div class="row col-md-4 ">
                    <!-- Campo Importe Total Descuento -->
                    <div class="col-md-12 d-flex align-items-start ">
                      <div class="col-md-4">
                      <i class="pi pi-money-bill color-icon" style="font-size: 1.5rem"></i>
                      <span class="ml-2"><b>Total</b></span>
                    </div>
                      <div class="col-md-8">
                      <input type="number" class="form-control sinborde ml-2" pTooltip="Importe Total Descuento"
                        min="1" placeholder="Importe Total Descuento*" formControlName="imp_total"
                        [style]="{'width':'100%'}">
                    </div>
                  </div>
                  </div>
                  <div class="row col-md-4">
                    <!-- Campo Numero de Pagos -->
                    <div class="col-md-10 d-flex align-items-center">
                      <i class="pi pi-tags color-icon" style="font-size: 1.5rem"></i>
                      <span class="ml-2"><b>Num.&nbsp;Pagos*</b></span>
                      <input type="number" class="form-control sinborde ml-2" placeholder="Numero de Pagos*" min="1"
                        pTooltip="Numero de Pagos" formControlName="num_pagos" [style]="{'width':'60%'}">
                    </div>
                  </div>
                    <!-- Campo Interes -->
                    <div class="col-md-4 d-flex align-items-center">
                      <i class="pi pi-percentage color-icon" style="font-size: 1.5rem"></i>
                      <span class="ml-2"><b>Interes</b></span>
                      <input type="number" formControlName="imp_percent" class="form-control sinborde ml-2"
                        pTooltip="Interes" [style]="{'width':'80%'}">
                    </div>



                </div>
                <div class="row mt-5">
                  <!-- Columna Izquierda -->
                  <div class="col-md-6">


                    <div class="row mt-2">
                      <!-- Campo Fecha Descuento -->
                      <div class="col-md-12">
                        <div class="row align-items-center">
                          <div class="col-md-3 d-flex align-items-center">
                            <i class="pi pi-calendar color-icon" style="font-size: 1.5rem"></i>
                            <span class="ml-2"><b>Inicio descuento*</b></span>
                          </div>
                          <div class="col-md-9">
                            <p-dropdown [options]="lstSemanas" formControlName="selectedSemana" autoWidth="false"
                              [style]="{'width':'100%'}" placeholder="Semana Descuento*" optionLabel="nom_semana"
                              [showClear]="false" pTooltip="Semana Descuento">
                              <ng-template let-target pTemplate="item">
                                <div class="ui-helper-clearfix" style="position: relative;height:25px;"
                                  pTooltip="{{getTargetDescription(target)}}" tooltipPosition="top">
                                  <span style="font-size:14px;float:left;margin-top:4px">{{target.nom_semana}}</span>
                                </div>
                              </ng-template>
                            </p-dropdown>
                          <div class="row mt-2">
                            <div *ngIf="dataFormCalc.controls['selectedSemana'].value">
                              <div class="row">
                                <div style="color: green;" class="col-sm-6 text-left"><b>Inicio:
                                    {{ dataFormCalc.controls['fec_inicio'].value | date:'dd/MM/yyyy' }}</b></div>
                                <div style="color: green;" class="col-sm-6 text-right"><b>Fin:
                                    {{ dataFormCalc.controls['fec_fin'].value | date:'dd/MM/yyyy' }}</b></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        </div>
                      </div>
                    </div>


                  </div>

                  <!-- Columna Derecha -->
                  <div class="col-md-6">
                    <div class="row mb-4">
                      <!-- Campo Motivo de la Baja -->
                      <div class="col-md-12">
                        <label style="color: rgb(22, 22, 176); font-weight: bold;">Observacion</label>
                        <textarea rows="5" style="width: 100%;" pInputTextarea
                          formControlName="desc_prestamo"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>




              <div class="row d-flex justify-content-center mt-3">
                <div class="col-2">
                  <button type="submit" class="btn btn-primary w-100"
                    [disabled]="!dataFormCalc.valid || !dataFormChofer.valid">
                    <span class="text-white">Calcular </span>
                    <i class="pi pi-percentage text-white"></i>
                  </button>
                </div>
                <!-- <div class="col-2">
                                    <button type="submit" class="btn btn-success w-100"
                                        [disabled]="!dataFormChofer.valid &&lstDispersion.length == 0">
                                        <span class="text-white">Guardar </span>
                                        <i class="pi pi-save text-white"></i>
                                    </button>
                                </div> -->
              </div>
            </form>
          </div>
          <div class="mt-5" *ngIf="lstDispersion.length>0">
            <p-table [value]="lstDispersion" styleClass="p-datatable-sm p-datatable-striped"
              [tableStyle]="{'min-width': '50rem'}">
              <ng-template pTemplate="caption">
                <div class="flex justify-content-start">
                  <span class="p-input-icon-left p-ml-auto">
                    <p> Dispersión del Descuento</p>
                  </span>
                </div>
              </ng-template>
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center">#Pago</th>
                  <th class="text-center">Semana de Abono</th>
                  <th class="text-center">Año</th>
                  <th class="text-center">Importe</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
                <tr>
                  <td class="text-center" style="color: black;">{{ data.id_periodo }}</td>
                  <td class="text-center " style="color: rgb(23, 212, 23);">{{ data.id_semana }}</td>
                  <td class="text-center texto_chofer_lila">{{ data.num_anio}}</td>
                  <td class="text-center" style="color: rgb(202, 35, 35);">{{data.imp_periodo | currency }}</td>
                </tr>
              </ng-template>
              <ng-template pTemplate="footer">
                <tr>
                  <td colspan="3" class="text-right">Total Dispersión</td>
                  <td>{{ total_dispersion | currency: 'USD'}}</td>
                </tr>
              </ng-template>
              <ng-template pTemplate="summary">
                <div *ngIf="lstDispersion.length>0"
                  class="flex align-items-center justify-content-center mb-2 mt-2 ml-4 mr-4">
                  <div class="col-md-3">
                    <button class="btn btn-success w-100" pTooltip="Guardar Dispersion Descuento"
                      [disabled]="(!dataFormChofer.valid && !dataFormCalc.valid) && lstDispersion.length == 0" (click)="guardarDispersion()">
                      <span class="text-white">Registrar Descuento </span>
                      <i class="pi pi-save text-white"></i>
                    </button>
                  </div>
                </div>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="footer">
      <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
        (click)="cancelarAgregar()"></button>
    </ng-template>
  </p-dialog>


  <!-- p-dialog de reportes excel ------------ -->
  <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dbsReportes"
    [style]="{'width': '60vw','height':'620px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
    (onHide)="cancelarModalReportes()">
    <ng-template pTemplate="header">
      Generar Reporte De Excel
    </ng-template>
    <ng-template pTemplate="content">
      <div class="mt-5">
        <hr class="style-two">
        <div class="row">
          <div class="col-md-3">
            <p class="texto-pesado">Reporte de deducciones de descuentos</p>
          </div>
          <div class="col-md-3">
            <p-dropdown [options]="lstSemanas" autoWidth="false" [style]="{'width':'100%'}"
              placeholder="Seleccione Semana*" [(ngModel)]="selectedSemanaReporte" optionLabel="nom_semana"
              [showClear]="false">
              <ng-template let-target pTemplate="item">
                <div class="ui-helper-clearfix" style="position: relative;height:25px;"
                  pTooltip="{{getTargetDescription(target)}}" tooltipPosition="top">
                  <span style="font-size:14px;float:left;margin-top:4px">{{target.nom_semana}}</span>
                </div>
              </ng-template>
            </p-dropdown>
            <div *ngIf="selectedSemanaReporte!=null">
              <div class="row">
                <div style="color: green;" class="col-sm-6 text-left "><b>Inicio:
                    {{selectedSemanaReporte.fec_inicio | date: 'dd/MM/yyyy' }}</b></div>
                <div style="color: green;" class="col-sm-6 text-right "><b>Fin:
                    {{selectedSemanaReporte.fec_fin | date: 'dd/MM/yyyy' }}</b></div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <p-dropdown [(ngModel)]="id_ciudad_hm_rpt" [options]="lstCiudad" optionLabel="nombre" optionValue="id"
              placeholder="Filtrar por Ciudad" [showClear]="false" [disabled]="dsblstCiudades">
            </p-dropdown>
          </div>
          <div class="col-md-2 ml-6">
            <button type="button" [disabled]="deshabilitarbtnexportar" class="btn btn-outline-success"
              style="width: 100%;" (click)="exportarExcel()" [disabled]="ButtonExportExcel">
              Exportar <i class="pi pi-file-export"></i>
            </button>
          </div>
        </div>
        <div class="row d-flex justify-content-center">
          <div class="col-2 text-center">
            <i class="pi pi-spin pi-spinner" *ngIf="loading_reporte1"></i>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
        (click)="cancelarModalReportes();"></button>
    </ng-template>
  </p-dialog>

  <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspDialogoDispercion"
    [style]="{width: '65vw','height':'650px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
    (onHide)="cancelarModalEstatus()">
    <ng-template pTemplate="header">
      {{ texto_operacion }}
      <!-- <div *ngIf="" style="color:black;">
            {{ nombre_registro }}
          </div> -->
    </ng-template>

    <span class="title-light mt-3">Información de Pagos <i class="pi pi-cog" style="size: 2rem;"></i>
    </span>

    <ng-template pTemplate="content">
      <div class="mt-2">

        <div>
          <p-table [value]="lstTableMostrarDispersion.jsnDescDisp" styleClass="p-datatable-sm p-datatable-striped"
            [tableStyle]="{'min-width': '50rem'}" [loading]="loading_Disp">
            <ng-template pTemplate="caption">


              <div class="row mt-1 mb-1 d-flex justify-content-around ">
                <div class="col-md-2 ">#Chofer : &nbsp;<label style="color: #344ba5;"><b>{{cod_chofer}}</b></label>
                </div>
                <div class=" col-md-5 ">Colaborador : &nbsp;<label
                    style="color:#344ba5;"><b>{{nombre_completo}}</b></label></div>
                <div class="col-md-3 ">Deuda Restante: &nbsp;<label style="color: #e69915;"><b>{{imp_restante |
                      currency}}</b></label></div>
                <div class="col-md-2">Deuda total: &nbsp;<label style="color: #e62929;"><b>{{imp_total |
                      currency}}</b></label>
                </div>


              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th>#Num.periodo</th>
                <th class="text-center">Importe de Abono</th>
                <th class="text-center">Semana de Descuento</th>
                <!--<th class="text-center">Importe Pagado</th>-->
                <th class="text-center">Estatus de Pago</th>
                <th class="text-center">Accion</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
              <tr>
                <td class="text-center" style="color: black;"><b>{{data.id_periodo}}</b></td>
                <td class="text-center" style="color: rgb(226, 42, 42);"><b>{{data.imp_periodo | currency}}</b></td>
                <td class="text-center " style="color: black;"><b>{{data.id_semana}}</b></td>
                <td class="text-center " style="color: rgb(0, 0, 0);" *ngIf="data.clv_pago == 0">
                  <b>{{data.desc_estatus}}</b>
                </td>
                <td class="text-center " style="color: rgb(40, 216, 40);" *ngIf="data.clv_pago == 1">
                  <b>{{data.desc_estatus}}</b>
                </td>
                <td class="text-center">
                  <div class="ml-2">
                    <button class="btn"
                      [ngClass]="{'btn-success': data.clv_pago === 0, 'btn-link': data.clv_pago === 1}"
                      tooltipPosition="left" pTooltip="{{data.clv_activo === 0 ? 'ya fue pagada ' : 'Pagar' }}"
                      (click)="cambioEstatus(lstTableMostrarDispersion,data.id_periodo)">
                      <i class="pi pi-wallet"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
        (click)="cancelarModalEstatus()"></button>
    </ng-template>
  </p-dialog>
   <!-- pdialog de comentario  -->
   <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspVerMas" [style]="{width: '35vw','height':'430px'}"
   (onHide)="verMenos()" [transitionOptions]="'0ms'" styleClass="card-shadow">
   <ng-template pTemplate="header">
      Motivo de la renuncia o despido
   </ng-template>
   <ng-template pTemplate="content">
     <form class="form_cliente" [formGroup]="dataFormCalc" (ngSubmit)="verMenos()">
       <div class="row  pb-2">

         <!-- tipo de incapacuidad -->

         <div class="row d-flex mt-3">
           <label style=" font-weight: bold;" class="title-light">Colaborador: {{this.nombre_completo}}</label>
           <textarea rows="5" cols="50" pInputTextarea formControlName="desc_prestamo"></textarea>
         </div>
       </div>
       <div class="mt-2 d-flex justify-content-center">
         <button type="submit" class="btn btn-lg btn-success" pTooltip="Registrar finiquito">ACEPTAR</button>
       </div>
     </form>
   </ng-template>
   <ng-template pTemplate="footer">
     <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
       (click)="verMenos();"></button>
   </ng-template>
 </p-dialog>
</div>
