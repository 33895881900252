import { Component, OnInit, ViewChild } from '@angular/core';
import { ITipoDescuentos } from '../models/tipodescuentos';
import { IDescuentoNomina } from '../models/descuentosnomina';
import { Table } from 'primeng/table';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';

import ICredencialUsuario from '../models/credencial';
import { ISemanasHappyMile } from '../models/semanas';
import { ICiudad } from '../models/ciudad';
import { ICiudadHM } from '../models/ciudadhm';
import { SemanaService } from '../services/semana.service';
import { ISubTipoDescuento } from '../models/subtipodescuento';
import { IDescuentosVarios } from '../models/DescuentosVariosModel';
import { IDescuentosVariosChoferes } from '../models/descVariosChof';
import { IPostDescuentosVarios } from '../models/postDescuentosVariosModel';
import { IDescuentosVariosCalculoDispersion } from '../models/descuentosVariosCalculoDispersion';
import { IDescuentosVariosInfo } from '../models/DescuentosVariosInfo';
import { DescuentosVariosService } from '../services/descuentosvarios.service';



@Component({
  selector: 'app-descuentosvarios',
  templateUrl: './descuentosvarios.component.html',
  styleUrls: ['./descuentosvarios.component.scss']
})
export class DescuentosvariosComponent implements OnInit {
  loading: boolean=false;
  @ViewChild('dt1') dt!: Table;
  user: ICredencialUsuario = {};
  fecha_dia: any = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
  lstTipoDescuentos: ITipoDescuentos[]=[]
  lstSubTipoDescuentos: ISubTipoDescuento[]=[]
  lstDescuentosVarios : IDescuentosVarios[]=[];
  lstPostDescuentosVarios: IPostDescuentosVarios[]=[];
  lstDescuentosVariosChoferes: IDescuentosVariosChoferes[] = [];
  lstDispersion: IDescuentosVariosCalculoDispersion[] = [];
  lstTableMostrarDispersion: IDescuentosVarios = {
    id_descuento: 0,
    cod_chofer: 0,
    nombre_completo: '',
    id_categoria_descuento: 0,
    desc_categoria: '',
    id_sub_categoria_descuento: 0,
    desc_sub_categoria: '',
    imp_total: 0,
    id_semana_inicial: 0,
    id_semana_final: 0,
    id_ciudad_hm: 0,
    desc_ciudad: '',
    num_pagos: 0,
    desc_estatus: '',
    fec_registro: new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)),
    jsnDescDisp: [],
    clv_btn_eliminar: 0,
    clv_operacion: 0,
    cod_usuario: ''
  };

  lstSemanas:ISemanasHappyMile[] = [];
  lstCiudad:ICiudadHM[] =[];
  lstCiudad_filtro:ICiudadHM[]=[];
  id_ciudad_hm: ICiudadHM = {
    id: 0,
    nombre: '',
    clave_estado: '',
    id_ciudad_principal: 0,
    clave_tipoTransporte: 0
  }
  total_dispersion:number= 0;

  selectedSemana: ISemanasHappyMile = {
    idx: 0,
    id_semana: 0,
    nom_semana: '',
    anio: 0,
    fec_inicio: '',
    fec_fin: '',
    clv_activo: 0
  };

  selectedCiudad: number= 0;

  selectedDescuento: IDescuentosVariosInfo = {
    nom_colaborador: '',
    desc_categoria: '',
    desc_sub_categoria: '',
    id_semana: 0,
    imp_total: 0,
    num_pagos: 0,
    lstDispersion: []
  }
  REGEX_NAMES = /^[0-9]{1,}[,]{0,1}[0-9]{0,}$/; ;

  anio: number=0;
  years: number[] = [];
  anio_filtro:any;
  dataFormCalc: FormGroup = new FormGroup({
    imp_total: new FormControl(null, [Validators.required,Validators.min(1)]),
    num_pagos: new FormControl(null, [Validators.required,Validators.min(1), Validators.pattern(this.REGEX_NAMES)]),
    fec_registro: new FormControl('',[Validators.required]),
    cod_usuario: new FormControl('')
  });

  dataFormChofer: FormGroup = new FormGroup({
    cod_chofer: new FormControl(null, [Validators.required]),
    id_ciudad_hm: new FormControl(null, [Validators.required]),
    id_categoria_descuento: new FormControl(null, [Validators.required]),
    id_sub_categoria_descuento: new FormControl(null, [Validators.required]),
  });
  //Para metodos de Registros
  /*
  1-Agregar
  2-Editar
  */
  clv_operacion=0;
  dsbDescuento: boolean = false;
  texto_operacion:string='';
  nombre_registro:string='';
  dspCalculo: boolean= false;
  dspDialogoDispercion: boolean = false;

  constructor(
    private auth: AuthService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private descuentosVariosService: DescuentosVariosService,
    private semanasService: SemanaService
  ){
    this.cargarInformacionUsuario();
    this.dataFormChofer.get('id_categoria_descuento')?.valueChanges.subscribe(data =>{
      this.dataFormChofer.get('id_sub_categoria_descuento')?.clearValidators();
      if(data != 4)
      {
        this.dataFormChofer.get('id_sub_categoria_descuento')?.setValidators([Validators.required]);
        this.cargarListaSubTipoDescuento(data);
      }
      else
      {
        this.lstSubTipoDescuentos = [];
      }
    });
    this.dataFormChofer.get('cod_chofer')?.valueChanges.subscribe(data =>{
      let ciudad = this.lstDescuentosVariosChoferes.find(x => x.cod_chofer == data)

      if(ciudad != undefined)
      {
        this.dataFormChofer.get('id_ciudad_hm')?.setValue(ciudad.id_ciudad_hm);
      }

    });
  }

  ngOnInit(): void {
    this.inicializarFechas();
    this.cargarListaCiudades();
    this.cargarListaTipoDescuento();
    this.cargarlstChoferes();
  }

  inicializarFechas() {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.anio = Number(getYear);
    this.anio_filtro = getYear;
  }

  getTargetDescription(ruta: ISemanasHappyMile) {
    let fec_inicio = new Date(ruta.fec_inicio);
    let fec_fin = new Date(ruta.fec_fin);
    return this.formatearFechas(fec_inicio) + ' | ' + this.formatearFechas(fec_fin);
  }

  formatearFechas(fecha: Date): string {
    let fecha_Cast = '';
    var getYear = new Date(fecha).toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date(fecha).toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date(fecha).toLocaleDateString('es-MX', { day: '2-digit' });
    fecha_Cast = getDay + '/' + getMonth + '/' + getYear;
    return fecha_Cast;
  }
  formatearFechasCod(fecha: Date): string {
    let fecha_Cast = '';
    var getYear = new Date(fecha).toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date(fecha).toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date(fecha).toLocaleDateString('es-MX', { day: '2-digit' });
    fecha_Cast = getYear+'-'+getMonth+'-'+getDay;
    return fecha_Cast;
  }
  cargarListaCiudades()
  {
    this.descuentosVariosService.getListadoCiudades().subscribe((resp)=>
    {
      if(resp.length>0)
      {
        this.lstCiudad = resp;
        this.lstCiudad_filtro =
          [{
            id:0,
            nombre:'Todas las Ciudades',
            clave_estado:'0',
            id_ciudad_principal:0,
            clave_tipoTransporte:0
          }]
        this.lstCiudad.forEach( x => this.lstCiudad_filtro.push(x));
      }
      else
       this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Cargar Listado de Ciudades>', detail: 'Contacte al Administrador del Sitio' });

    },
      (error) => {
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Cargar Listado de Ciudades>', detail: 'Contacte al Administrador del Sitio' });
    });
  }
  cargarListaTipoDescuento()
  {
    this.descuentosVariosService.getListadoTipoDecuento().subscribe((resp) =>{
      if(resp.length>0)
        this.lstTipoDescuentos = resp;
      else
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Cargar Listado Tipo Descuentos>', detail: 'Contacte al Administrador del Sitio' });
    },
      (error) => {
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Cargar Listado Tipo Descuentos>', detail: 'Contacte al Administrador del Sitio' });
    });
  }

  cargarListaSubTipoDescuento(id_descuento:number)
  {
    if(id_descuento != null || id_descuento > 0)
    this.descuentosVariosService.getListadoSubTipoDescuento(id_descuento).subscribe((resp) =>{
      if(resp.length>0)
        this.lstSubTipoDescuentos = resp;
      else
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Cargar Listado Sub Tipo Descuentos>', detail: 'Contacte al Administrador del Sitio' });
    },
      (error) => {
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Cargar Listado Sub Tipo Descuentos>', detail: 'Contacte al Administrador del Sitio' });
    });
  }

  cargarListadoDeSemanasAnio_cast(num_anio:any) {
    let anio_cast: any = 0;
    if (num_anio instanceof Date) {
      // will execute
      anio_cast = num_anio.getFullYear();
    } else {
      anio_cast = num_anio;
    }
    this.lstSemanas = [];
    this.semanasService.listarSemanas(anio_cast).subscribe((resp) => {
      this.lstSemanas = resp;
      //reiniciar
      this.lstDescuentosVarios = [];
      this.lstPostDescuentosVarios=[];
      setTimeout(() => {
      },
        600);
    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>Error al cargar Semanas HM</b>. Contacte al Administrador.');
      });
  }
  cargarInformacionUsuario() {
    this.user = this.auth.user;
  }

  cargarlstChoferes() {
    if(this.selectedSemana != null && this.selectedCiudad != null && this.anio_filtro != null)
    this.descuentosVariosService.getListadoDescuentoChoferes(this.selectedSemana.id_semana,this.anio_filtro,this.selectedCiudad).subscribe((resp) => {
      this.lstDescuentosVariosChoferes = resp;
    },
      (error) => {
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Cargar Listado Descuentos Varios>', detail: 'Contacte al Administrador del Sitio' });
      });
  }


  cargarDescuentosVarios() {

      this.loading = true;
    if (this.selectedSemana == null|| this.selectedCiudad == null || this.anio_filtro == null ) {
      this.mostrarDialogoInformativoSincontacteadmi('<b>Informativo</b> Debe seleccionar una semana y ciudad para poder filtrar');
      this.loading = false;
    } else   {
    this.descuentosVariosService.getListadoDescuentos(
      this.selectedSemana.id_semana,this.anio_filtro,this.selectedCiudad,this.user.cod != null ? this.user.cod:'0999'
    ).subscribe((resp) => {
      this.lstDescuentosVarios = resp;
      if (this.lstDescuentosVarios.length === 0) {
        this.loading = false;
        this.mostrarDialogoInformativoSincontacteadmi('<b>No Existe</b> Registros para la Fecha seleccionada');
      }
      setTimeout(() => {
        this.loading = false;
      }, 500);

    },
      (error) => {
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Cargar Listado Descuentos Varios>', detail: 'Contacte al Administrador del Sitio' });
      });}
  }

  registrarDescuentoVario()
  {
    this.reseteaCalculo();
    this.dspCalculo = true;
    this.dataFormChofer.controls['id_ciudad_hm'].disable();
  }

  imp_restante:number=0;
  calcularDispersion()
  {
    let imp_total = this.dataFormCalc.get('imp_total')?.value;
    let num_pagos = this.dataFormCalc.get('num_pagos')?.value;
    let id_ciudad_hm = this.dataFormChofer.get('id_ciudad_hm')?.value;
    let fec_registro = this.formatearFechasCod(this.dataFormCalc.get('fec_registro')?.value);
    this.total_dispersion = 0;

    this.descuentosVariosService.getListadoDescuentosDispersion(imp_total,num_pagos,fec_registro,id_ciudad_hm,this.user.cod != null ? this.user.cod : '0999').subscribe((resp) => {
      this.lstDispersion = resp;

      this.total_dispersion = this.lstDispersion[0].imp_pago_total
      this.imp_restante


    },
    (error) => {
      this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Cargar Listado Colaboradores>', detail: 'Contacte al Administrador del Sitio' });
    });
  }

  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '. <b>Contacte al Administrador.</b>';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }

  guardarDispersion(){
    let nom_completo = this.lstDescuentosVariosChoferes.find(x => x.cod_chofer == this.dataFormChofer.get('cod_chofer')?.value)
    let desc_categoria = this.lstTipoDescuentos.find(x => this.dataFormChofer.get('id_categoria_descuento')?.value)
    let desc_sub_categoria = this.lstSubTipoDescuentos.find(x => this.dataFormChofer.get('id_sub_categoria_descuento')?.value)


    let data_enviar: IPostDescuentosVarios = {
      cod_chofer: this.dataFormChofer.get('cod_chofer')?.value,
      nombre_completo: nom_completo == undefined ? '':nom_completo?.nombre_completo,
      id_ciudad_hm: this.dataFormChofer.get('id_ciudad_hm')?.value,
      id_categoria_descuento: this.dataFormChofer.get('id_categoria_descuento')?.value,
      desc_descuento: desc_categoria == undefined ? '':desc_categoria?.desc_descuento,
      id_sub_categoria_descuento:this.dataFormChofer.get('id_sub_categoria_descuento')?.value,
      desc_sub_descuento: desc_sub_categoria == undefined ? '':desc_sub_categoria?.desc_sub_descuento,
      id_semana_inicial: this.lstDispersion[0].id_semana_inicial,
      num_anio_inicial: this.lstDispersion[0].num_anio_inicial,
      fec_inicial: this.lstDispersion[0].fec_inicial_total,
      id_semana_final: this.lstDispersion[0].id_semana_final,
      num_anio_final: this.lstDispersion[0].num_anio_fin,
      fec_final: this.lstDispersion[0].fec_final_total,
      imp_total: this.dataFormCalc.get('imp_total')?.value,
      num_pagos: this.dataFormCalc.get('num_pagos')?.value,
      fec_registro: this.formatearFechasCod(this.dataFormCalc.get('fec_registro')?.value),
      cod_usuario: this.user.cod != null ? this.user.cod : '0999',
      lstDispersion: this.lstDispersion
    };
    this.confirmationService.confirm({
      message: '¿Está seguro de guardar el <b>DESCUENTO</b> del Colaborador <b>' + nom_completo?.nombre_completo + '</b>?',
      header: 'Confirmación antes de guardar finiquito',
      icon: 'pi pi-info-circle',
      accept: () => {

    this.descuentosVariosService.postDescuento(data_enviar).subscribe((resp) => {
      if (resp.clv_estatus != -2) {
        setTimeout(() => {
          this.cancelarModal();
          this.cargarDescuentosVarios();
          this.messageService.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se almaceno de forma correcta' });
        }, 800);
      } else {
        setTimeout(() => {
          this.messageService.add({ severity: 'error', summary: 'Error en <Agregar Descuento>', detail: resp.messageDetails });
          this.cancelarModal();
          this.cargarDescuentosVarios();
        }, 800);
      }
    },
    (error) => {
      this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Cargar Listado Colaboradores>', detail: 'Contacte al Administrador del Sitio' });
    });  }});



  }
  reseteaPost()
  {
    this.lstPostDescuentosVarios = [];
  }

  reseteaLista()
  {
    this.lstDescuentosVarios = [];
  }

  reseteaCalculo()
  {
    this.dataFormCalc.reset();
    this.dataFormCalc.get('fec_registro')?.setValue('');
    this.lstDispersion = [];
  }
  reseteaDataChofer()
  {
    this.dataFormChofer.reset();
  }

  cancelarModal() {
    this.dspCalculo = false;
    this.reseteaCalculo();
    this.reseteaDataChofer();
    this.reseteaPost();
   // this.reseteaLista();
    this.texto_operacion = '';
    this.dataFormCalc.get('fec_registro')?.setValue('');
  }
  nombre_completo:string ='';
  cod_chofer:number=0;
  imp_total:number=0;
 mostrarDialogoDispersion(data: IDescuentosVarios)
  {
    this.dspDialogoDispercion = true;
    this.texto_operacion = 'Dispersion Guardada'
    this.lstTableMostrarDispersion = data;
    this.imp_restante =0;
    this.lstTableMostrarDispersion.jsnDescDisp.forEach((item) => {
      if (item.clv_pago === 0) {
        this.imp_restante += item.imp_periodo;
      }
    });

    this.nombre_completo = data.nombre_completo
    this.cod_chofer = data.cod_chofer
    this.imp_total = data.imp_total
  }

  cancelarModalEstatus()
  {
    this.dspDialogoDispercion = false;
    this.lstDispersion = [];
  }

  registrarDescuento(){
    this.texto_operacion='Registrar Descuento';
    this.dspCalculo =true;
    this.cargarlstChoferes()
    //this.cargarListaSubTipoDescuento()

    this.dataFormChofer.controls['id_ciudad_hm'].disable();
    this.clv_operacion=1;
  }

  reiniciar(){
    this.texto_operacion='';
    this.dspCalculo =false;
    this.clv_operacion=0;
  }

  deleteDescuento(id_descuento: number,nombre:string)
  {
    this.confirmationService.confirm({
      message: '¿Está seguro de eliminar el <b>DESCUENTO</b>  del Colaborador <b>' + nombre + ' </b>?',
      header: 'Confirmación antes de eliminar dispersion',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.descuentosVariosService.deleteRecursos(id_descuento).subscribe((resp)=>
        {
          if(resp.clv_estatus != -2)
            setTimeout(() => {
              this.cancelarModal();
              this.cargarDescuentosVarios();
              this.messageService.add({ severity: 'success', summary: 'Eliminacion Exitosa', detail: 'La informacion del descuento se elimino de manera correcta' });
            }, 800);
          else
          setTimeout(() => {
            this.cancelarModal();
            this.cargarDescuentosVarios();
            this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Eliminar Descuento>', detail: resp.messageDetails});
          }, 800);
        },
          (error) => {
            this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Eliminar Descuento>', detail: 'Contacte al Administrador del Sitio' });
        });
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
        }
      }
    });
  }

  cambioEstatus(id_descuento: number,id_sub_descuento: number, data: IDescuentosVarios)
  {
    this.confirmationService.confirm({
      message: '¿Está seguro de pagar el <b>DESCUENTO</b>  del Colaborador <b>' + data.nombre_completo + ' </b>?',
      header: 'Confirmación antes de pagar dispersion',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.descuentosVariosService.cambioEstatus(id_descuento,id_sub_descuento,this.user.cod != null ? this.user.cod:'0999').subscribe((resp)=> {
          if (resp.clv_estatus != -2) {
            this.messageService.add({ key: 'msj', severity: 'success', summary: 'Exito', detail: 'Se registro correctamente' });
            this.cancelarModalEstatus();
            this.cargarDescuentosVarios();
          }
          else if (resp.clv_estatus == -2) {
            this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: resp.messageDetails });
          }
        });
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
        }
      }
    });
  }




  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }
  mostrarDialogoInformativoSincontacteadmi(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario ;
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }
}
