import { Component, OnInit, ViewChild } from '@angular/core';
import { IIncapacidades } from '../models/incapacidades';

import { Table } from 'primeng/table';
import { ConfirmEventType, ConfirmationService, MessageService } from 'primeng/api';
import { IChoferDisponible } from '../models/choferdisponible';
import { IMes } from '../models/mes';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ICiudadHM } from '../models/ciudadhm';
import { CiudadhmService } from './../services/ciudadhm.service';
import { InombreCompletoChofer } from '../models/nombreCompletoChofer';
import { BonoRecomendacionService } from '../services/bonorecomendacion.service';
import { IncapacidadService } from '../services/incapacidades.service';
import ICredencialUsuario from '../models/credencial';
import { ITipoIncapacidad } from '../models/tipoincapacidad';
import { IpostIncapcidad } from '../models/postIncapacidades';
import { AuthService } from '../services/auth.service';
import { zip } from 'rxjs';
import { ISubTipoIncapacidad } from '../models/subcategoriaincapacidad';
import { ICatIncapacidades } from '../models/catincapacidades';
import { ICatSubIncapacidades } from '../models/catsubincapacidades';

@Component({
  selector: 'app-incapacidad',
  templateUrl: './incapacidad.component.html',
  styleUrls: ['./incapacidad.component.scss']
})
export class IncapacidadComponent{

  habilitaragregar :boolean=false;
  dspAgregar:boolean=false;
  loading:boolean=false;
  dspVerMas:boolean=false;

  num_anio: number= 0;
  years: number[] = [];
  id_ciudad_hm: number = 0;
  id_mes: number= 0;
  dsblstCiudades: boolean = true;
  imp_total: number=0;
  num_dias:number=0;
  des_puesto: string = '';

  nombre_chofer:string='';
  imp_salario_base :number=0;
  imp_salario_ims:number=0;
  imp_incapacidad_dia:number=0;
  porcentaje :number=0;
  meses: IMes[] = [
    { num_mes: 0, nombre: "TODOS LOS MESES" },
    { num_mes: 1, nombre: "Enero" },
    { num_mes: 2, nombre: "Febrero" },
    { num_mes: 3, nombre: "Marzo" },
    { num_mes: 4, nombre: "Abril" },
    { num_mes: 5, nombre: "Mayo" },
    { num_mes: 6, nombre: "Junio" },
    { num_mes: 7, nombre: "Julio" },
    { num_mes: 8, nombre: "Agosto" },
    { num_mes: 9, nombre: "Septiembre" },
    { num_mes: 10, nombre: "Octubre" },
    { num_mes: 11, nombre: "Noviembre" },
    { num_mes: 12, nombre: "Diciembre" }];

  //  listtipoIncapacidad: ITipoIncapacidad [] = [
  //     { id_incapacidad: 1, desc_tipo_incapacidad: 'Incapacidad Total Temporal (60%)' },
  //     { id_incapacidad: 2, desc_tipo_incapacidad: 'Incapacidad Parcial Permanente (variable)' },
  //     { id_incapacidad: 3, desc_tipo_incapacidad: 'Incapacidad Total Permanente (100%)' },
  //     { id_incapacidad: 4, desc_tipo_incapacidad: 'Incapacidad Permanente Total para una Ocupación Distinta (100%)' }

  //   ];

  //   listsubcategoria: ISubTipoIncapacidad [] = [
  //     { id_sub_incapacidad: 1, desc_tipo_sub_incapacidad: 'Enfermedad general' },
  //     { id_sub_incapacidad: 2, desc_tipo_sub_incapacidad: 'Riesgo de trabajo' },

  //   ];

    reactiveincapacidades: FormGroup = new FormGroup({
      idx : new FormControl(0),
      cod_chofer: new FormControl(0, [Validators.required]),
      id_incapacidad: new FormControl(0, [Validators.min(1),Validators.required]),
      desc_incapacidad: new FormControl('', [Validators.maxLength(500),Validators.required]),
      id_ciudad_hm : new FormControl(0),
      fec_inicio : new FormControl(null, [Validators.required]),
    //
      fec_fin : new FormControl(null, [Validators.required]),
      desc_ciudad_hm: new FormControl(''),
      //----
      num_duracion: new FormControl(0, [Validators.min(1),Validators.maxLength(3),Validators.required]),
      porcentaje_capturado: new FormControl(0),
      id_sub_incapacidad : new FormControl(0),
      desc_tipo_sub_incapacidad: new FormControl(''),
      clv_activo: new FormControl(0),
      // id_semana: new FormControl(0, [Validators.min(1),Validators.required]),

      // selectedSemana : new FormControl([],[Validators.min(1),Validators.required]),

    });


  mostrarbtnLimpiarFiltro:boolean=false;
  dsblstColaboradores: boolean = false;
  dspDesgloce: boolean = false;
  user: ICredencialUsuario = {};
  @ViewChild('dt1') dt!: Table;
  lstCiudad: ICiudadHM[] = [];
  lstCiudad2 : ICiudadHM[] = [];
  lstCiudades: ICiudadHM[] = [];
  lstChoferes: InombreCompletoChofer[] = [];
  lstIncapacidades:IIncapacidades[]=[];
  lstChoferesDisponibles: IChoferDisponible[]=[];

  lstIncapacidad : ICatIncapacidades []=[];
lstSubIncapacidad : ICatSubIncapacidades []=[];
  desc_incapacidad:string='';
  uploadedFiles: any[] = [];
  constructor(private messageService: MessageService,
    private CiudadhmService: CiudadhmService,
    private confirmationService: ConfirmationService,
    private bonoRecomendacionService:BonoRecomendacionService,
    private incapacidadService:IncapacidadService,
    private auth: AuthService

  ){
    this.initializeYears();
    this.num_anio = new Date().getFullYear();
    this.cargarInformacionUsuario();
  }
  ngOnInit(): void {
    this.cargarCiudades();
    this.cargaListaChoferes();
    this.cargarSubincapacidad();
    this. cargarincapacidad();

  }

  cargarInformacionUsuario() {
    this.user = this.auth.user;
  }

  //iniciliza los años para el filtro
  initializeYears(): void {
    const currentYear = new Date().getFullYear();
    for (let year = currentYear; year >= currentYear - 5; year--) {
      this.years.push(year);
    }
  }

  //carga la lista inicial de semanas
  cargarCiudades() {
    this.dsblstCiudades = true;
    this.loading = true;
    this.CiudadhmService.getListadoCiudades().subscribe((resp) => {
      this.lstCiudad = resp.slice(); // Crear una copia independiente
      this.lstCiudad2 = resp.slice(); // Crear una copia independiente
      const index = this.lstCiudad.findIndex(item => item.nombre === 'TODAS LAS CIUDADES');
      if (index !== -1) {
        this.lstCiudad.splice(index, 1);
      }
      this.lstCiudad.push({ id: 0, nombre: 'TODAS LAS CIUDADES', clave_estado: '', id_ciudad_principal: 0, clave_tipoTransporte: 0 });
      setTimeout(() => {
        this.loading = false;
        this.dsblstCiudades = false;
      }, 1000);
    },
    (error) => {
      this.loading = false;
      this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Ciudades');
    });
  }

  //carga la lista incial de choferes
  cargaListaChoferes() {
    this.dsblstColaboradores = true;
    this.lstChoferes = [];
    this.bonoRecomendacionService.getListadoChoferesGeneral(this.id_ciudad_hm).subscribe((resp) => {
      this.lstChoferes = resp;
      setTimeout(() => {
        this.dsblstColaboradores = false;
      }, 100);
    },
      (error) => {
        this.dsblstColaboradores = false;
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Colaboradores');
      });
  }


  cargarincapacidad(){
    this.incapacidadService.getCatIncapacidades().subscribe((resp)=>{
  this.lstIncapacidad = resp;
  setTimeout(() => {
  }, 100);
},
  (error) => {
    this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar catalogo de incapacidades');
    })
  }



  cargarSubincapacidad(){
    this.incapacidadService.getCatSubIncapacidades().subscribe((resp)=>{
  this.lstSubIncapacidad = resp;
  setTimeout(() => {
  }, 100);
},
  (error) => {
    this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar catalogo de incapacidades');
    })
  }
    // -----------filtra y carga la informacion ----
    FiltrarInformacion() {
      this.habilitaragregar = true;
      this.loading = true;
      if (this.id_ciudad_hm == null || typeof this.id_ciudad_hm === 'undefined' ) {
        this.mostrarDialogoInformativoSinInformacion('<b>Informativo</b> Debe de seleccionar una semana para poder filtrar');
        this.loading = false;
      } else {
       // this.mostraragregar= true;
        this.incapacidadService.getListadoIncapacidad(this.num_anio, this.id_mes, this.id_ciudad_hm, this.user.cod == undefined ? '0999' : this.user.cod).subscribe(
          (resp) => {
            this.lstIncapacidades = resp;
            if (this.lstIncapacidades.length === 0) {
              this.loading = false;
              this.mostrarDialogoInformativoSinInformacionsinadmi('<b>No Existe</b> Registros para la fecha seleccionado');
            } else {
              // this.imp_total_genral = 0;
              // // Iterar sobre la lista y sumar los valores de los campos
              // this.lstIncapacidades.forEach((item) => {
              //   this.imp_total_genral += item.bono_recomendacion;
              // });
              // Finalizar el loading después de 800 ms2
              setTimeout(() => {
                this.loading = false;
              }, 800);

            }
          },
          (error) => {
            this.mostrarDialogoInformativoSinInformacionsinadmi('<b>ERROR</b> en Cargar Movimientos ');
            this.loading = false;
          }
        );
      }
    }


  porcentaje_capturado:number =0;
  clv_activo: number =0;

  GuardarIncapacidad(){
    let choferSeleccionado = this.lstChoferes.find(chofer => chofer.cod_chofer === this.reactiveincapacidades.controls['cod_chofer'].value);
    let nombreChofer = choferSeleccionado ? choferSeleccionado.nombre_completo : ''
    let data_enviar: IpostIncapcidad = {
      idx:  this.reactiveincapacidades.controls['idx'].value,
      cod_chofer: this.reactiveincapacidades.controls['cod_chofer'].value,
      id_incapacidad: this.reactiveincapacidades.controls['id_incapacidad'].value,
      desc_incapacidad: this.reactiveincapacidades.controls['desc_incapacidad'].value,
      id_ciudad_hm: this.reactiveincapacidades.controls['id_ciudad_hm'].value,
      fec_inicio: this.reactiveincapacidades.controls['fec_inicio'].value,
      cod_usuario: this.user.cod == undefined ? '0999' : this.user.cod,
      clv_activo: this.reactiveincapacidades.controls['clv_activo'].value,
      num_duracion: this.reactiveincapacidades.controls['num_duracion'].value,
      porcentaje_capturado: this.reactiveincapacidades.controls['porcentaje_capturado'].value ,
      id_sub_incapacidad:this.reactiveincapacidades.controls['id_sub_incapacidad'].value,
  };
  this.confirmationService.confirm({
    message: '¿Está seguro de guardar la <b>INCAPACIDAD</b>  del Colaborador <b>' + nombreChofer + '</b>?',
    header: 'Confirmación antes de guardar incapacidad',
    icon: 'pi pi-info-circle',
    accept: () => {
      this.incapacidadService.postIncapacidad(data_enviar).subscribe((resp) => {
        //const resultado = Array.isArray(resp) ? resp[0] : resp;
        if (resp.clv_estatus == 1) {
          this.messageService.add({ key: 'msj', severity: 'success', summary: 'Exito', detail: 'Se registro correctamente' });
          this.dspAgregar = false;
          this.FiltrarInformacion();
          this.cancelarAgregar();
        }
        else if (resp.clv_estatus == -2) {
          this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: resp.messageDetails });
         // this.dspCliente = false;
        }
      });
    },
    reject: (type: any) => {
      switch (type) {
        case ConfirmEventType.REJECT:
          this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
          break;
        case ConfirmEventType.CANCEL:
          this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
          break;
      }
    }
  });
}


id_editar: number = 0;
EditarIncapacidad(data: IIncapacidades) {
  this.reactiveincapacidades.controls['desc_incapacidad'].enable();
  this.dspAgregar = true;
  this.id_editar = 1;
  let x  = new Date(data.fec_inicio);
  let y = x.toISOString()
  let z  = new Date(data.fec_fin);
  let w = z.toISOString()

  this.reactiveincapacidades.controls['clv_activo'].setValue(4);
  this.reactiveincapacidades.controls['num_duracion'].setValue(data.num_duracion);
  this.reactiveincapacidades.controls['porcentaje_capturado'].setValue(data.porcentaje_capturado );
  this.reactiveincapacidades.controls['id_sub_incapacidad'].setValue(data.id_sub_incapacidad);
  this.reactiveincapacidades.controls['desc_tipo_sub_incapacidad'].setValue(data.desc_tipo_sub_incapacidad);
  this.reactiveincapacidades.controls['idx'].setValue(data.idx);
  this.reactiveincapacidades.controls['cod_chofer'].setValue(data.cod_chofer);
  this.reactiveincapacidades.controls['id_incapacidad'].setValue(data.id_incapacidad);
  this.reactiveincapacidades.controls['desc_incapacidad'].setValue(data.desc_incapacidad);
  this.reactiveincapacidades.controls['id_ciudad_hm'].setValue(data.id_ciudad_hm);
  this.reactiveincapacidades.controls['fec_inicio'].setValue(y.substring(0,10));

  this.reactiveincapacidades.controls['fec_fin'].setValue(w.substring(0,10));

  this.reactiveincapacidades.controls['fec_fin'].disable();
  this.reactiveincapacidades.controls['id_ciudad_hm'].disable();
  this.reactiveincapacidades.controls['cod_chofer'].disable();



}


verMas(data: IIncapacidades){
  this.dspVerMas = true;
  this.nombre_chofer = data.nom_chofer;
  this.reactiveincapacidades.controls['desc_incapacidad'].setValue(data.desc_incapacidad);
  this.reactiveincapacidades.controls['desc_incapacidad'].disable();
}
verMenos(){
  this.dspVerMas = false;
  this.reactiveincapacidades.controls['desc_incapacidad'].setValue('');
}
verMenosdesgloce(){

  this.dspDesgloce=false
  this.nombre_chofer = '';
  this.imp_salario_base = 0;
	this.imp_salario_ims= 0;
	this.imp_incapacidad_dia= 0;
	this.porcentaje=0;
  this.imp_total= 0;
  this.num_dias = 0;
  this.des_puesto = '';

}

id_sub_incapacidad:number =0;
desc_tipo_sub_incapacidad:string=''
mostrardesgloce(data: IIncapacidades){
  this.nombre_chofer = data.nom_chofer;
  this.imp_salario_base = data.imp_salario_base;
	this.imp_salario_ims= data.imp_salario_ims			;
	this.imp_incapacidad_dia= data.imp_incapacidad_dia;
	this.porcentaje=data.porcentaje ;
  this.imp_total= data.imp_incapacidad
  this.num_dias = data.num_dias;
  this.des_puesto = data.desc_puesto
  this.dspDesgloce= true
  this.id_sub_incapacidad = data.id_sub_incapacidad
  this.desc_tipo_sub_incapacidad =data.desc_tipo_sub_incapacidad
}




  EliminarIncapacidad(data:IIncapacidades){

    let data_enviar: IpostIncapcidad = {
      idx:data.idx,
      cod_chofer: data.cod_chofer,
      id_incapacidad: data.id_incapacidad,
      desc_incapacidad: data.desc_incapacidad,
      id_ciudad_hm: data.id_ciudad_hm,
      fec_inicio: data.fec_inicio,
      //fec_fin: data.fec_fin,
      cod_usuario: this.user.cod == undefined ? '0999' : this.user.cod,
      clv_activo: this.clv_activo = 3,
      num_duracion : data.num_duracion,
      porcentaje_capturado: this.porcentaje_capturado = 0,
      id_sub_incapacidad: data.id_sub_incapacidad,
     // desc_tipo_sub_incapacidad : data.desc_tipo_sub_incapacidad,
  };
  this.confirmationService.confirm({
    message: '¿Está seguro que desea eliminar la <b>INCAPACIDAD</b>  del Colaborador <b>' + data.nom_chofer + '</b>?',
    header: 'Confirmación de eliminacion de incapacidad',
    icon: 'pi pi-info-circle',
    accept: () => {
      this.incapacidadService.postIncapacidad(data_enviar).subscribe((resp) => {
        //const resultado = Array.isArray(resp) ? resp[0] : resp;
        if (resp.clv_estatus == 1) {
          this.messageService.add({ key: 'msj', severity: 'success', summary: 'Exito', detail: 'Se elimino correctamente' });
          this.dspAgregar = false;
          this.FiltrarInformacion();
          this.cancelarAgregar();
        }
        else if (resp.clv_estatus == -2) {
          this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: resp.messageDetails });
         // this.dspCliente = false;
        }
      });
    },
    reject: (type: any) => {
      switch (type) {
        case ConfirmEventType.REJECT:
          this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
          break;
        case ConfirmEventType.CANCEL:
          this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
          break;
      }
    }
  });
  }

  AprobarIncapacidad(data:IIncapacidades){
    if(data.clv_activo == 2 ){
      this.messageService.add({
      key: 'msj',
      severity: 'warn',
      summary: 'Cancelado',
      detail: 'La incapacidad ya fue autorizada' });
    }   else{

    let data_enviar: IpostIncapcidad = {
      idx:data.idx,
      cod_chofer: data.cod_chofer,
      id_incapacidad: data.id_incapacidad,
      desc_incapacidad: data.desc_incapacidad,
      id_ciudad_hm: data.id_ciudad_hm,
      fec_inicio: data.fec_inicio,
     // fec_fin: data.fec_fin,
      cod_usuario: this.user.cod == undefined ? '0999' : this.user.cod,
      clv_activo: this.clv_activo = 2,
      num_duracion : data.num_duracion,
      porcentaje_capturado: this.porcentaje_capturado = 0,
      id_sub_incapacidad: data.id_sub_incapacidad,
     // desc_tipo_sub_incapacidad : data.desc_tipo_sub_incapacidad,
  };

  this.confirmationService.confirm({
    message: '¿Está seguro que desea aprobar la <b> INCAPACIDAD </b>  del Colaborador <b>' + data.nom_chofer + '</b>?',
    header: 'Confirmación de edición de estatus',
    icon: 'pi pi-info-circle',
    accept: () => {
      this.incapacidadService.postIncapacidad(data_enviar).subscribe((resp) => {
       // const resultado = Array.isArray(resp) ? resp[0] : resp;
        if (resp.clv_estatus == 1) {
          this.messageService.add({ key: 'msj', severity: 'success', summary: 'Exito', detail: 'Se edito correctamente' });
          this.dspAgregar = false;
          this.FiltrarInformacion();
          this.cancelarAgregar();
        }
        else if (resp.clv_estatus == -2) {
          this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: resp.messageDetails });

        }
      });
    },
    reject: (type: any) => {
      switch (type) {
        case ConfirmEventType.REJECT:
          this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
          break;
        case ConfirmEventType.CANCEL:
          this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
          break;
      }
    }
  });}
  }



  changedp(event: any) {
    let eve = event.value;
    this.lstCiudades = [];
    let choferSeleccionado = this.lstChoferes.find(chofer => chofer.cod_chofer === eve);
    if (choferSeleccionado) {
        this.reactiveincapacidades.controls['id_ciudad_hm'].setValue(choferSeleccionado.id_ciudad_hm);
        this.reactiveincapacidades.controls['desc_ciudad_hm'].setValue(choferSeleccionado.desc_ciudad_hm);
        //this.reactiveincapacidades.controls['nom_chofer'].setValue(choferSeleccionado.nombre_completo);
       //this.reactiveincapacidades.controls['nombreChofer'].setValue(choferSeleccionado.nombre_completo);
    }
}
  cancelarAgregar(){
    this.dspAgregar=false;
    this.id_editar=0;
    this.reactiveincapacidades.controls['num_duracion'].setValue(0);
    this.reactiveincapacidades.controls['porcentaje_capturado'].setValue(0);
    this.reactiveincapacidades.controls['id_sub_incapacidad'].setValue(0);
    this.reactiveincapacidades.controls['cod_chofer'].setValue(0);
    this.reactiveincapacidades.controls['id_incapacidad'].setValue(0);
    this.reactiveincapacidades.controls['desc_incapacidad'].setValue('');
    this.reactiveincapacidades.controls['id_ciudad_hm'].setValue(0);
    this.reactiveincapacidades.controls['fec_inicio'].setValue('');
    //this.reactiveincapacidades.controls['fec_fin'].setValue('');

  }

  crearNuevo(){
    this.dspAgregar=true;
    this.reactiveincapacidades.controls['clv_activo'].setValue(1);
    this.reactiveincapacidades.controls['desc_incapacidad'].enable();
    this.reactiveincapacidades.controls['cod_chofer'].enable();
    this.reactiveincapacidades.controls['id_ciudad_hm'].disable();
    this.reactiveincapacidades.controls['idx'].setValue(0);
  }


  mostrarDialogoInformativoSinInformacionsinadmi(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario ;
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key:"sinInformacionDialog"
    });
  }
  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '. <b>Contacte al Administrador.</b>';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key:"sinInformacionDialog"
    });
  }

  exportarExcel(){

  }

  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  onUpload(event: { files: any; }) {
    for(let file of event.files) {
        this.uploadedFiles.push(file);
    }

    this.messageService.add({severity: 'info', summary: 'File Uploaded', detail: ''});
}
}
