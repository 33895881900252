/*export const environment = {
    production: false,
    SUrlgApi: "https://localhost:44313/api/v1/",
    sUrlAuth: "http://localhost:5286/api/"
};*/


export const environment = {
    production: false,
    SUrlgApiLocal: "https://localhost:44313/api/v1/",
    //sUrlAuthLocal: "http://localhost:5286/api/",
    sUrlNominaLocal: "https://localhost:44351/api/",
    // sUrlAuth: "https://apiauth.erp-happymile.com/api/",
    SUrlgApi: "https://beta.apirestfulnetcore.sw-hmp.com/api/v1/",
    sUrlAuth: "https://beta.apiAuth.sw-hmp.com/api/",
    sUrlNomina: "https://beta.apinom.sw-hmp.com/api/",
    //sUrlNomina: "https://localhost:44351/api/",
    sUrlRecibo: "https://beta.recibonomina.sw-hmp.com/api/v1/"
};

