import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../services/auth.service';
import ICredencialUsuario from '../models/credencial';
import { ISemanasHappyMile } from '../models/semanas';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SemanaService } from '../services/semana.service';
import { ICiudadPrincipalHM } from '../models/ciudadprincipal';
import { IChoferBonoSemanal } from '../models/choferbonosemanal';
import { Table } from 'primeng/table';
import { ICiudadHM } from '../models/ciudadhm';
import { IChoferEnviarBono } from '../models/choferbonoenviar';
import { IChoferEnviar } from '../models/choferenviar';
import { CiudadesprincipalesService } from '../services/ciudadesprincipales.service';
import { BonopuntualidadService } from '../services/bonopuntualidad.service';
import { IBonoEnviar } from '../models/bono_enviar';

@Component({
  selector: 'app-bonopuntualidad',
  templateUrl: './bonopuntualidad.component.html',
  styleUrls: ['./bonopuntualidad.component.scss']
})
export class BonopuntualidadComponent implements OnInit{
  @ViewChild('dt1') dt!: Table;
  user: ICredencialUsuario = {};
  lstSemanas: ISemanasHappyMile[]=[];
  selectedSemana: ISemanasHappyMile = {
    idx: 0,
    id_semana: 0,
    nom_semana: '',
    anio: 0,
    fec_inicio: '',
    fec_fin: '',
    clv_activo: 0
  };
  num_anio_semana:number=0;
  lstCiudadesPrincipales: ICiudadPrincipalHM[]=[];
  selectedCiudad: ICiudadPrincipalHM ={
    id: 0,
    nombre: '',
    clave_estado: ''
  };
  lstCiudad: ICiudadHM[] = [];
  lstColaboradores: IChoferBonoSemanal[]=[];
  lstColaboradores_Original: IChoferBonoSemanal[]=[];
  lstColaboradores_Eliminar: IChoferBonoSemanal[]=[];
  anio:any= new Date().toLocaleDateString('es-MX',{ year: 'numeric'});
  fecha_dia:any=null; 
  loading: boolean = false;
  data_enviar_bono: IChoferEnviarBono = {
    data: [],
    cod_usuario_registro: '',
    id_semana: 0
  };
  lstChoferBonoEnviar:IChoferEnviar[]=[];
  lstBonoEnviar: IBonoEnviar[]=[];
  lstBonoEnviar_Eliminar: IBonoEnviar[]=[];
  dsbGuardarTodo:boolean=true;
  constructor(private auth: AuthService,private confirmationService: ConfirmationService, private messageService: MessageService,
    private semanaService:SemanaService, private ciudadesprincipalesService: CiudadesprincipalesService,
    private bonopuntualidadService:BonopuntualidadService){
    this.cargarInformacionUsuario();
  }
  ngOnInit(): void {
    this.cargarListadoDeSemanasAnio();
    this.cargarListadoDeCiudadesPrincipales();
  }

  cargarInformacionUsuario(){
    this.user = this.auth.user;
  }

  cargarListaSimulada(){
    this.actualizarEstatusBoolean(this.lstColaboradores);
  }

  actualizarEstatusBoolean(lista: IChoferBonoSemanal[]){
    if(lista.length>0){
      lista.map((valor)=>{
        valor.clv_bono_cast = valor.clv_bono==0 ? false: true;
      });
    }
  }

  actualizarEstatusRetroctivoBoolean(lista: IChoferBonoSemanal[]){
    if(lista.length>0){
      lista.map((valor)=>{
        valor.clv_retroactivo_cast = valor.clv_retroactivo==0 ? false: true;
      });
    }
  }

  guardarTodo(){
    if(this.lstColaboradores.length>0){
      this.lstBonoEnviar = this.filtrarOperacioneDeBono(this.lstColaboradores);
      this.lstColaboradores_Eliminar = this.filtrarAEliminarOriginal(this.lstColaboradores);
      if(this.lstColaboradores_Eliminar.length>0){
        this.lstBonoEnviar_Eliminar = this.filtrarOperacioneDeBonoEliminar(this.lstColaboradores_Eliminar);
        //Juntar Arreglos
        this.lstBonoEnviar_Eliminar.map((valor)=> {
          this.lstBonoEnviar.push(valor);
        });
      }
      //Enviar Información
      this.loading=true;
      this.bonopuntualidadService.postBono(this.lstBonoEnviar).subscribe((resp)=> {
        this.messageService.add({ key:'msj', severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se registro de forma correcta' });
        this. cargarInformacionPersonalPorSemana();
      },
      (error)=> {
        this.loading=false;
        this.messageService.add({key:'msj', severity: 'error', summary: 'Error en <Guardar Bono de Puntualidad>', detail: 'Contacte al Administrador del Sitio' });
      });
    }
  }

  filtrarOperacioneDeBono(lista: IChoferBonoSemanal[]):IBonoEnviar[]{
    let listado:IBonoEnviar[]=[];
    lista.map((valor)=> {
      if(valor.clv_bono_cast==true){
        listado.push({
          idx: valor.idx,
          cod_chofer: valor.cod_chofer,
          id_ciudad_HM: valor.id_ciudad_hm,
          id_ciudad_principal: valor.id_ciudad_principal,
          id_semana: this.selectedSemana.id_semana,
          num_anio: this.num_anio_semana,
          clv_bono: 1,
          clv_retroactivo: valor.clv_retroactivo_cast==true ? 1 : 0,
          cod_usuario_registro: this.user.cod!
        });
      }
    });
    return listado;
  }

  filtrarOperacioneDeBonoEliminar(lista: IChoferBonoSemanal[]):IBonoEnviar[]{
    let listado:IBonoEnviar[]=[];
    lista.map((valor)=> {
        listado.push({
          idx: valor.idx,
          cod_chofer: valor.cod_chofer,
          id_ciudad_HM: valor.id_ciudad_hm,
          id_ciudad_principal: valor.id_ciudad_principal,
          id_semana: this.selectedSemana.id_semana,
          num_anio: this.num_anio_semana,
          clv_bono: 3,
          clv_retroactivo:valor.clv_retroactivo_cast==true ? 1 : 0,
          cod_usuario_registro: this.user.cod!
        });
    });
    return listado;
  }

  filtrarAEliminarOriginal(lista: IChoferBonoSemanal[]):IChoferBonoSemanal[]{
    let listado: IChoferBonoSemanal[] =[];
    lista.map((valor)=> {
      let clv_bono_original = this.lstColaboradores_Original.find((data)=>{
        return data.idx == valor.idx && data.cod_chofer==valor.cod_chofer;
      });
      if(clv_bono_original?.clv_bono==1 && valor.clv_bono_cast==false){
        valor.clv_bono=3;
        listado.push(valor);
      }
    });
    return listado;
  }



  filtrarMerecedoresDeBono(lista: IChoferBonoSemanal[]):IChoferEnviar[]{
    let listado:IChoferEnviar[]=[];
    lista.map((valor)=> {
      if(valor.clv_bono_cast==true){
        listado.push({
          cod_Chofer: valor.cod_chofer,
          id_ciudad_hm: valor.id_ciudad_hm,
          id_ciudad_principal: valor.id_ciudad_principal
        });
      }
    });
    return listado;
  }

  consultaSemanas(){

  }

  cargarListadoDeSemanasAnio(){
    this.lstSemanas=[];
    this.semanaService.listarSemanas(this.anio).subscribe((resp)=> {
      this.lstSemanas=resp;
      setTimeout(() => {
      },
        600);
    },
    (error)=>{
      this.mostrarDialogoInformativoSinInformacion('<b>Error</b> al cargar Información. Contacte al Administrador.');
    });
  }

  cargarListadoDeCiudadesPrincipales(){
    this.lstCiudadesPrincipales=[];
    this.ciudadesprincipalesService.getListadoCiudadesPrincipales().subscribe((resp)=> {
      this.lstCiudadesPrincipales=resp;
      setTimeout(() => {
      },
        600);
    },
    (error)=>{
      this.mostrarDialogoInformativoSinInformacion('<b>Error</b> al cargar Información. Contacte al Administrador.');
    });
  }

  cargarInformacionPersonalPorSemana(){
  let id_semana = this.selectedSemana==null ? 0: this.selectedSemana.id_semana; 
  let id_ciudad_principal = this.selectedCiudad==null ? 0: this.selectedCiudad.id; 
  this.num_anio_semana = this.selectedSemana==null ? 0: this.selectedSemana.anio; 
  //this.cargarListaSimulada();
  if(id_semana!=0 && id_ciudad_principal!=0 && this.num_anio_semana!=0){
    this.loading=true;
    this.bonopuntualidadService.getBonoPorSemanayCiudad(id_semana,this.num_anio_semana,id_ciudad_principal).subscribe((resp) => {
      this.lstColaboradores = resp;
      this.lstColaboradores_Original = resp;
      if(this.lstColaboradores.length>0){
        this.actualizarEstatusBoolean(this.lstColaboradores);
        this.actualizarEstatusRetroctivoBoolean(this.lstColaboradores);
        this.dsbGuardarTodo = false;
      }
      setTimeout(() => {
        this.loading=false;
      },
        900);
    },
      (error) => {
        this.loading=false;
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> al cargar Información. Contacte al Administrador.');
      });
  } else {
    this. mostrarMensajeDeAlerta();
  }
  }

  mostrarMensajeDeAlerta(){
    this.loading=false;
    this.messageService.add({
      key: 'confirm',
      sticky: true,
      severity: 'info',
      summary: 'Falta Semana y/o Ciudad',
      detail: 'Semana y Ciudad deben seleccionarse para consultar información.'
    });
  }

  cancelarMensajeConfirmacion(){
    this.messageService.clear('confirm');
    this.messageService.add({key: 'msj',severity:'info', summary:'Confirmación', detail:'Usuario informado de la situación.'});
     
  }

  confirmarMensaje(){
    this.messageService.clear('confirm');
    this.messageService.add({key: 'msj',severity:'info', summary:'Confirmación', detail:'Usuario informado de la situación.'});
  }

  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario;
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }
  
  inicializarFechas(){
    var getYear = new Date().toLocaleDateString('es-MX',{ year: 'numeric'});   
    var getMonth = new Date().toLocaleDateString('es-MX',{ month: '2-digit'});
    var getDay = new Date().toLocaleDateString('es-MX',{day: '2-digit'});
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.fecha_dia = dateFormat;
  }

  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  getTargetDescription(ruta:ISemanasHappyMile){
    let fec_inicio = new Date(ruta.fec_inicio);
    let fec_fin = new Date(ruta.fec_fin);
    //return ruta.fec_inicio.substring(0,10)+' | '+ruta.fec_fin.substring(0,10)+'';
    return this.formatearFechas(fec_inicio) + ' | '+this.formatearFechas(fec_fin);
  }

  formatearFechas(fecha: Date): string{
    let fecha_Cast = '';
    var getYear = new Date(fecha).toLocaleDateString('es-MX',{ year: 'numeric'}); 
    var getMonth = new Date(fecha).toLocaleDateString('es-MX',{ month: '2-digit'});
    var getDay = new Date(fecha).toLocaleDateString('es-MX',{day: '2-digit'});
    fecha_Cast = getDay+'/'+getMonth+'/'+getYear;
    return fecha_Cast;
  }


}
