import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IIncapacidades } from '../models/incapacidades';
import { IpostIncapcidad } from '../models/postIncapacidades';
import { ICatIncapacidades } from '../models/catincapacidades';
import { ICatSubIncapacidades } from '../models/catsubincapacidades';
import { IApoyo } from '../models/apoyo';
import { ICiudadPrincipalHM } from '../models/ciudadprincipal';
import { CiudadesprincipalesService } from './ciudadesprincipales.service';
import { ICatApoyo } from '../models/catapoyos';
import { InombreCompletoChoferciudadprincipal } from '../models/nombrecompletociudadprincipal';
import { IPostApoyo } from '../models/postapoyo';

@Injectable({
  providedIn: 'root'
})
export class ApoyoService{

  private apiUrl: string = environment.sUrlNomina + 'Apoyo';

  constructor(private http: HttpClient, private ciudadesPrincipalesService: CiudadesprincipalesService) { }  // Inyección de CiudadesprincipalesService


 getListadoapoyo(num_anio: number,id_semana:number ,id_ciudad_principal: number, cod_usuario : string): Observable<IApoyo[]> {
  const valores = {
    params: new HttpParams().
       set('num_anio',num_anio).
       set('id_semana',id_semana).
       set('id_ciudad_principal',id_ciudad_principal).
       set('cod_usuario',cod_usuario)
  }
  return this.http
      .get<IApoyo[]>(this.apiUrl,valores)
      .pipe(retry(1), catchError(this.handleError));

 }


 getListadoCiudadesPrincipales(): Observable<ICiudadPrincipalHM[]> {
  // Uso del método del servicio importado
  return this.ciudadesPrincipalesService.getListadoCiudadesPrincipales();
}

//  public postIncapacidad(data: IpostIncapcidad):Observable<any>{
//   const headers = { 'content-type': 'application/json'}
//   const body = JSON.stringify(data);
//   return this.http.post<any>(this.apiUrl + '/postIncapacidad/',body,{'headers':headers});
//  }


 getCatApoyos(): Observable<ICatApoyo[]> {
  return this.http
      .get<ICatApoyo[]>(this.apiUrl + '/getcatapoyo')
      .pipe(retry(1), catchError(this.handleError));

 }


 getListadoChoferesGeneral( ): Observable<InombreCompletoChoferciudadprincipal[]> {

  return this.http
  .get<InombreCompletoChoferciudadprincipal[]>(this.apiUrl+'/getChoferesGenerales' )
  .pipe(retry(1), catchError(this.handleError));
}


public postApoyo(data: IPostApoyo):Observable<any>{
  const headers = { 'content-type': 'application/json'}
  const body = JSON.stringify(data);
  return this.http.post<any>(this.apiUrl + '/postApoyo/',body,{'headers':headers});
 }


//  getListadoChoferesGeneral( ciudad:number  ): Observable<InombreCompletoChofer[]> {
//   const valores = {
//     params: new HttpParams().
//        set('id_ciudad_hm', ciudad)
//       }
//   return this.http.get<InombreCompletoChofer[]>(this.apiUrl+'/getChoferesGenerales' , valores)
//   .pipe(retry(1), catchError(this.handleError));
// }

rptApoyo(num_anio: number, id_semana: number,id_ciudad_principalrpt : number): Observable<any> {
  const valores = {
    params: new HttpParams()
      .set('num_anio', num_anio)
      .set('id_semana', id_semana)
      .set('id_ciudad_hm', id_ciudad_principalrpt )
  };
  return this.http.get<any>(this.apiUrl + '/rptApoyo', valores)
    .pipe(retry(1), catchError(this.handleError));}

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }

}
