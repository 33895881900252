import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Ibonoaguinaldo } from '../models/bonoaguinaldo';

import { CiudadesprincipalesService } from './ciudadesprincipales.service';  // Importado
import { ICiudadPrincipalHM } from '../models/ciudadprincipal';
import { IPostPrimaVacacional } from '../models/postprimavacacional';
import { IPostInfonavit } from '../models/postinfonavit';
import { IInfonavit } from '../models/infonavit';
import { IFiniquito } from '../models/finiquito';
import { IChoferBaja } from '../models/choferesbaja';
import { IPostFiniquito } from '../models/postfiniquito';

@Injectable({
  providedIn: 'root'
})
export class FiniquitoService {
  private apiUrl: string = environment.sUrlNomina + 'Finiquito';

  constructor(private http: HttpClient, private ciudadesPrincipalesService: CiudadesprincipalesService) { }  // Inyección de CiudadesprincipalesService

  getListadoFiniquito(num_anio: number, id_ciudad_hm: number, id_semana: number, cod_usuario:string): Observable<IFiniquito[]> {
    const valores = {
      params: new HttpParams()
        .set('num_anio', num_anio)
        .set('id_ciudad_hm', id_ciudad_hm)
        .set('id_semana', id_semana)
        .set('cod_usuario',cod_usuario)
    };
    return this.http.get<[IFiniquito]>(this.apiUrl, valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  // getListadoCiudadesPrincipales(): Observable<ICiudadPrincipalHM[]> {
  //   // Uso del método del servicio importado
  //   return this.ciudadesPrincipalesService.getListadoCiudadesPrincipales();
  // }


  public postiFiniquito(data: IPostFiniquito):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this.http.post<any>(this.apiUrl + '/postfiniquito/',body,{'headers':headers});
   }

   getListadoChoferesBaja( ciudad:number  ): Observable<IChoferBaja[]> {
    const valores = {
      params: new HttpParams().
         set('id_ciudad_hm', ciudad)
        }
    return this.http.get<IChoferBaja[]>(this.apiUrl+'/getChoferesBaja' , valores)
    .pipe(retry(1), catchError(this.handleError));
  }
  // deletePrima(data: IPostPrimaVacacional): Observable<IResultadoGeneral> {
  //   const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  //   let data_enviar={headers:headers, body : JSON.stringify(data)};

  //   return this.http.delete<IResultadoGeneral>(this.apiUrl +'/deleteApoyoPrimaVacacional', data_enviar)
  //     .pipe(catchError(this.handleError));
  // }

  upestatus(cod_chofer: number, clv_estatus_opciones: number): Observable<any> {
    const params = new HttpParams()
      .set('cod_chofer', cod_chofer.toString()) // Convertir a cadena si es necesario
      .set('clv_estatus_opciones', clv_estatus_opciones.toString()); // Convertir a cadena si es necesario

    return this.http.post<any>(`${this.apiUrl}/upestatus`, null, { params });
  }

  rptFiniquito(num_anio_rpt: number, id_semana_rpt: number,id_ciudad_hm_rpt: number): Observable<any> {
    const valores = {
      params: new HttpParams()
        .set('num_anio', num_anio_rpt)
        .set('id_semana', id_semana_rpt)
        .set('id_ciudad_hm', id_ciudad_hm_rpt)
    };
    return this.http.get<any>(this.apiUrl + '/rptFiniquito', valores)
      .pipe(retry(1), catchError(this.handleError));
  }


  private handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(() => new Error(errorMessage));
  }
}
