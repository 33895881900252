import { BonopermanenciaService } from './../services/bonopermanencia.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../services/auth.service';
import ICredencialUsuario from '../models/credencial';
import { CiudadesprincipalesService } from '../services/ciudadesprincipales.service';
import { Table } from 'primeng/table';
import { ConfirmEventType, ConfirmationService, MessageService } from 'primeng/api';
import { SemanaService } from '../services/semana.service';
import { ISemanasHappyMile } from '../models/semanas';
import { ICiudadPrincipalHM } from '../models/ciudadprincipal';
import { ICiudadHM } from '../models/ciudadhm';
import { IChoferBonoPermanencia } from '../models/choferbonopermanencia';
import { IBonoPermanenciaEnviar } from '../models/bono_enviar_permanencia';
import { IErrorCode } from '../models/errorcode';
import { IResultadoGeneral } from '../models/resultadogeneral';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { InombreCompletoChofer } from '../models/nombreCompletoChofer';
import IBonoPermanenciaPost from '../models/postBonoPermanencia';
import IpostTexto from '../models/postTexto';
import { IrespondBonoPermanencia } from '../models/respondBonoPermanencia';
import { timeout } from 'rxjs';


@Component({
  selector: 'app-bonopermanencia',
  templateUrl: './bonopermanencia.component.html',
  styleUrls: ['./bonopermanencia.component.scss']
})
export class BonopermanenciaComponent implements OnInit {
  @ViewChild('dt1') dt!: Table;
  user: ICredencialUsuario = {};
  lstSemanas: ISemanasHappyMile[] = [];
  lstpostTexto: IpostTexto[] = [];
  selectedSemana: ISemanasHappyMile = {
    idx: 0,
    id_semana: 0,
    nom_semana: '',
    anio: 0,
    fec_inicio: '',
    fec_fin: '',
    clv_activo: 0
  };
  num_anio_semana: number = 0;
  lstCiudadesPrincipales: ICiudadPrincipalHM[] = [];
  selectedCiudad: ICiudadPrincipalHM = {
    id: 0,
    nombre: '',
    clave_estado: ''
  };
  lstCiudad: ICiudadHM[] = [];
  anio: any = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
  fecha_dia: any = null;

  //validar este cambio
  //data: any;
  loading: boolean = false;
  lstColaboradores: IChoferBonoPermanencia[] = [];
  lstColaboradores_Original: IChoferBonoPermanencia[] = [];
  lstColaboradores_SinCambios: IChoferBonoPermanencia[] = [];
  lstRespondColaboradores: IChoferBonoPermanencia[] = [];
  lstBonoEnviar: IBonoPermanenciaEnviar[] = [];
  lstBonoEnviar_Eliminar: IBonoPermanenciaEnviar[] = [];
  lstgetColaboradorCiudad: IrespondBonoPermanencia[] = [];

  lstBonoPermanenciaPost: IBonoPermanenciaPost[] = [];
  lstbono = [
    { id: 1, nombre: '6 Meses' },
    { id: 2, nombre: '12 Meses' },
    { id: 0, nombre: 'Ninguno' }
  ];
  dsbGuardarTodo: boolean = true;
  errorEstatus: IErrorCode = {
    clv_status: false,
    message: '',
    errorCode: ''
  };

  //variblaes itzael cambio
  ultimoIdBonoSeleccionado: number | null = null;
  dspCliente: boolean = false;
  dspVerComentario: boolean = false;
  dspComentario: boolean = false;
  texto_operacion: string = '';
  num_operacion: number = 0;
  clv_operacion: number = 0;
  habilitar_agregar: boolean = false;
  deshabilitarGuardarTodo : boolean = false;
  resultado: IResultadoGeneral = {
    clv_estatus: 0,
    messageDetails: ''
  };
  data: any;
  lstChoferes: InombreCompletoChofer[] = [];

  bonoPermaneciaForm: FormGroup = new FormGroup({

    chofer: new FormControl(0, [Validators.required]),
    id_bono: new FormControl(0),
    clv_retroactivo: new FormControl(0),

    desc_motivoCambio: new FormControl(''),
    clv_retrasaPago: new FormControl(0),
    clv_nuevoPorAdmn: new FormControl(0)


  });

  agregarBonoPermaneciaForm: FormGroup = new FormGroup({
    chofer: new FormControl(0, Validators.required),
    id_bono: new FormControl(0, [Validators.required, Validators.min(1)]),
  });

  agregarTextoForm: FormGroup = new FormGroup({
    chofer: new FormControl(0),
    respuesta: new FormControl('', [Validators.required, Validators.min(1)]),
    nom_ult_mod : new FormControl(''),
  });

  constructor(private auth: AuthService, private confirmationService: ConfirmationService, private messageService: MessageService,
    private semanaService: SemanaService, private ciudadesprincipalesService: CiudadesprincipalesService,
    private bonopermanenciaService: BonopermanenciaService) {
    this.cargarInformacionUsuario();
  }
  ngOnInit(): void {
    this.cargarListadoDeSemanasAnio();
    this.cargarListadoDeCiudadesPrincipales();
  }

  cargarInformacionUsuario() {
    this.user = this.auth.user;
  }


  actualizarEstatusBoolean(lista: any[]) {
    if (lista.length > 0) {
      lista.map((valor) => {
        valor.clv_bono_cast = valor.clv_bono == 0 ? false : true;
      });
    }
  }

  guardarTodo() {
    if (this.lstColaboradores.length > 0) {
      this.lstBonoEnviar = this.filtrarOperacionesNuevosDeBono(this.lstColaboradores);
      this.loading = true;
      if (this.lstBonoEnviar.length > 0) {
        this.bonopermanenciaService.postBono(this.lstBonoEnviar).subscribe((resp) => {
          this.messageService.add({ key: 'msj', severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se registro de forma correcta' });
         this.dsbGuardarTodo = true ;
         setTimeout(()=>{
          this.cargarInformacionPersonalPorSemana();
          this.dsbGuardarTodo = false ;
         },800);

        },
          (error) => {
            this.loading = false;
            this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en Guardar Bono de Permancencia', detail: 'Contacte al Administrador del Sitio' });
          });
      } else {
        this.loading = false;
      }
    }
  }

  filtrarOperacionesNuevosDeBono(lista: IChoferBonoPermanencia[]): IBonoPermanenciaEnviar[] {
    let listado: IBonoPermanenciaEnviar[] = [];
    lista.map((valor) => {
      listado.push({
        idx: valor.idx,
        id_permanencia: valor.id_permanencia,
        cod_chofer: valor.cod_chofer,
        id_ciudad_HM: valor.id_ciudad_HM,
        id_ciudad_principal: valor.id_ciudad_principal,
        id_semana: this.selectedSemana.id_semana,
        num_anio: this.selectedSemana.anio,
        clv_bono: 0,
        id_bono: Number(valor.id_bono),
        clv_retroactivo: 0,
        cod_usuario_registro: valor.clv_mod ==1?  this.user.cod! : this.user.cod!,
        clv_cambioManual: valor.clv_cambioManual,
        desc_motivoCambio: valor.desc_motivoCambio,
        clv_retrasaPago: valor.clv_retrasaPago,
        clv_nuevoPorAdmn: valor.clv_nuevoPorAdmn,


      });
    });
    return listado.filter((x) => x.cod_usuario_registro != '');
  }

  obtenerBonoDelArregloOriginal(cod_chofer: number): IChoferBonoPermanencia {
    let objeto: IChoferBonoPermanencia = {
      idx: 0,
      id_permanencia: 0,
      cod_chofer: 0,
      nombres: '',
      apellido_paterno: '',
      apellido_materno: '',
      id_ciudad_HM: 0,
      nombre_ciudad_hm: '',
      id_ciudad_principal: 0,
      nombre_ciudad_principal: '',
      id_puesto: 0,
      desc_puesto: '',
      clv_activo: 0,
      id_bono: 0,
      desc_bono: '',
      clv_retroactivo: 0,
      clv_retroactivo_cast: false,
      fec_baja: '',
      clv_operacion: 0,
      clv_cambioManual: 0,
      desc_motivoCambio: '',
      clv_retrasaPago: 0,
      clv_nuevoPorAdmn: 0,
      nom_ult_mod: '',
      fec_alta:'',
      clv_mod: 0,
      clv_pagado:0


    };
    this.lstColaboradores_SinCambios.map((valor) => {
      if (valor.cod_chofer == cod_chofer) {
        objeto = valor;
      }
    });
    return objeto;
  }

  filtrarOriginalesAnuevo(id_bono: number, objFiltrado: IChoferBonoPermanencia) {
    let clv_bono: number = 0;
    if (objFiltrado.id_bono != 0 && id_bono == 0) {
      //Se elimina si antes tenia bono y ahora va en 0
      clv_bono = 3;
    } else if (objFiltrado.id_bono == 0 && id_bono != 0) {
      //Se manda a agregar por que antes no tenia bono
      clv_bono = 1;
    } else if (objFiltrado.id_bono == 0 && id_bono == 0) {
      clv_bono = 0;
    }
    return clv_bono;
  }

  filtrarOriginalesAnuevoF(id_bono: number, objFiltrado: number) {
    let clv_bono: number = 0;
    if (objFiltrado != 0 && id_bono == 0) {
      //Se elimina si antes tenia bono y ahora va en 0
      clv_bono = 3;
    } else if (objFiltrado == 0 && id_bono != 0) {
      //Se manda a agregar por que antes no tenia bono
      clv_bono = 1;
    } else if (objFiltrado == 0 && id_bono == 0) {
      clv_bono = 0;
    }
    return clv_bono;
  }

  /*buscarExitenciaEnOriginal(cod_chofer: number, id_bono: number): number {
    let clv_bono: number = 0;
    this.lstColaboradores_Original.map((valor) => {
      if (valor.cod_chofer == cod_chofer && valor.id_bono != 0 && id_bono == 0) {
        //Se elimina si antes tenia bono y ahora va en 0
        clv_bono = 3;
      } else if (valor.cod_chofer == cod_chofer && valor.id_bono == 0 && id_bono != 0) {
        //Se manda a agregar por que antes no tenia bono
        clv_bono = 1;
      } else if(valor.cod_chofer == cod_chofer && valor.id_bono == 0 && id_bono==0){
        clv_bono=0;
      }
    });
    return clv_bono;
  }*/

  consultaSemanas() {

  }

  cargarListadoDeSemanasAnio() {
    this.lstSemanas = [];
    this.semanaService.listarSemanas(this.anio).subscribe((resp) => {
      this.lstSemanas = resp;
      setTimeout(() => {
      },
        600);
    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> al cargar Información. Contacte al Administrador.');
      });
  }

  cargarListadoDeCiudadesPrincipales() {
    this.lstCiudadesPrincipales = [];
    this.ciudadesprincipalesService.getListadoCiudadesPrincipales().subscribe((resp) => {
      this.lstCiudadesPrincipales = resp;
      setTimeout(() => {
      },
        600);
    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> al cargar Información. Contacte al Administrador.');
      });
  }

  cargarInformacionPersonalPorSemana() {
    this.lstColaboradores = [];
    this.lstColaboradores_Original = [];
    this.lstColaboradores_SinCambios = [];
    let id_semana = this.selectedSemana == null ? 0 : this.selectedSemana.id_semana;
    let id_ciudad_principal = this.selectedCiudad == null ? 0 : this.selectedCiudad.id;
    this.num_anio_semana = this.selectedSemana == null ? 0 : this.selectedSemana.anio;
    this.habilitar_agregar = true;
    //this.cargarListaSimulada();
    if (id_semana != 0 && id_ciudad_principal != 0 && this.num_anio_semana != 0) {
      this.loading = true;
      this.bonopermanenciaService.getBonoPorSemanayCiudad(id_semana, this.num_anio_semana, id_ciudad_principal, this.user.cod!).subscribe((resp) => {
        this.lstColaboradores = resp;
        this.lstColaboradores_Original = resp;


        this.lstColaboradores_SinCambios = this.lstColaboradores_Original;
        if (this.lstColaboradores.length > 0) {
          this.dsbGuardarTodo = false;
        }
        setTimeout(() => {
          this.loading = false;
        },
          900);
      },
        (error) => {
          this.loading = false;
          this.mostrarDialogoInformativoSinInformacion('<b>Error</b> al cargar Información. Contacte al Administrador.');
        });
    } else {
      this.mostrarMensajeDeAlerta();
    }


  }

  mostrarMensajeDeAlerta() {
    this.loading = false;
    this.messageService.add({
      key: 'confirm',
      sticky: true,
      severity: 'info',
      summary: 'Falta Semana y/o Ciudad',
      detail: 'Semana y Ciudad deben seleccionarse para consultar información.'
    });
  }

  cancelarMensajeConfirmacion() {
    this.messageService.clear('confirm');
    this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });

  }

  confirmarMensaje() {
    this.messageService.clear('confirm');
    this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
  }

  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario;
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }

  inicializarFechas() {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.fecha_dia = dateFormat;
  }

  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  getTargetDescription(ruta: ISemanasHappyMile) {
    let fec_inicio = new Date(ruta.fec_inicio);
    let fec_fin = new Date(ruta.fec_fin);
    //return ruta.fec_inicio.substring(0,10)+' | '+ruta.fec_fin.substring(0,10)+'';
    return this.formatearFechas(fec_inicio) + ' | ' + this.formatearFechas(fec_fin);
  }

  formatearFechas(fecha: Date): string {
    let fecha_Cast = '';
    var getYear = new Date(fecha).toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date(fecha).toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date(fecha).toLocaleDateString('es-MX', { day: '2-digit' });
    fecha_Cast = getDay + '/' + getMonth + '/' + getYear;
    return fecha_Cast;
  }

  //valida permisos
  validarClvOperacion() {
    this.clv_operacion = this.lstColaboradores.length == 0 || typeof this.lstColaboradores  == 'undefined' ? 0 : this.lstColaboradores[0].clv_operacion;
    if (this.clv_operacion == 3) {
      return true;
    }
    return false;

  }


  agregarColaborador() {
    this.lstgetColaboradorCiudad = [];
    this.texto_operacion = 'Agregar Bono De Permanencia';
    this.dspCliente = true;
    let id_semana = this.selectedSemana == null ? 0 : this.selectedSemana.id_semana;
    let id_ciudad_principal = this.selectedCiudad == null ? 0 : this.selectedCiudad.id;
    this.bonopermanenciaService.getListChoferesCiudad(id_semana, id_ciudad_principal).subscribe((resp) => {
      this.lstgetColaboradorCiudad = resp;

    });


  }
  agregarComentario(chofer: number) {
    this.agregarTextoForm.controls['chofer'].setValue(chofer);
    let resp = this.lstColaboradores.filter((x) => x.cod_chofer == chofer)[0];
    this.lstColaboradores.filter((x) => x.cod_chofer == chofer)[0].clv_mod = 1;
    this.agregarTextoForm.controls['respuesta'].setValue(resp.desc_motivoCambio);
    this.agregarTextoForm.controls['nom_ult_mod'].setValue(resp.nom_ult_mod);
    this.texto_operacion = 'Agregar comentario para asignar bono de permanencia';
    this.dspComentario = true;
  }

  cancelarVentanaModal() {

    this.dspCliente = false;
    this.dspVerComentario = false;
    this.dspComentario = false;
    this.texto_operacion = '';
    this.bonoPermaneciaForm.reset();
    this.agregarTextoForm.reset();
    this.agregarBonoPermaneciaForm.reset();
    this.lstgetColaboradorCiudad =[];

    this.resultado = {
      clv_estatus: 0,
      messageDetails: ''
    };
  }

  //sirve para selecionar el boton una sola vez no borrar
  seleccionarBono(idBono: number , cod_chofer : number) {
    this.agregarComentario(cod_chofer);
    if (idBono !== this.ultimoIdBonoSeleccionado ) {
      // Actualizar el último id seleccionado
      this.ultimoIdBonoSeleccionado = idBono;

    }

  }

  // seleccionarBono(idBono: number) {
  //   this.ultimoIdBonoSeleccionado = idBono;
  //   this.agregarComentario();
  // }

  //boton que mostarra el pdialog con la informacion
  verMasPost(desc_motivoCambio: string,nom_ult_mod: string) {

    this.agregarTextoForm.controls['respuesta'].setValue(desc_motivoCambio);
    this.agregarTextoForm.controls['nom_ult_mod'].setValue(nom_ult_mod);
    this.dspVerComentario = true;
  }


  guardartexto() {

    let text = this.agregarTextoForm.controls['respuesta'].value;
    let colaborador = this.agregarTextoForm.controls['chofer'].value;
    let respIndex = this.lstColaboradores.filter((x) => x.cod_chofer == colaborador)[0].desc_motivoCambio = text;
    this.cancelarVentanaModal();

  }


colaboradorDuplicadoMSJ(respIndex : IrespondBonoPermanencia  ){

  this.confirmationService.confirm({
    message: 'El Colaboradoe <b>'+ respIndex.nom_completo+ '</b> ya se encuentra asignado a la lista, favor de validar ',
    header: '<b>No</b> se permite Guardar',
    icon: 'pi pi-info-circle',
    accept: () => {

    }})
}


  //   guarda la informacion del colaborador
  guardarInformacionDeColaborador() {
    let valorBono = this.lstbono.filter((x) => x.id == this.agregarBonoPermaneciaForm.controls['id_bono'].value)[0];
    let listado = this.lstColaboradores;
    let chofer = this.agregarBonoPermaneciaForm.controls['chofer'].value;
    let respIndex = listado.filter((x) => x.cod_chofer == chofer);
    let addColaborador = this.lstgetColaboradorCiudad.filter((x) => x.cod_chofer == chofer)[0];
    if (respIndex.length == 0) {
        // Mostrar la ventana de confirmación
        this.confirmationService.confirm({
            message: '¿Está seguro que desea guardar la información del Colaborador <b>'+ addColaborador.nom_completo+ '</b> con el bono <b>'+valorBono.nombre+'</b>?',
            header: 'Confirmación de Guardado',
            icon: 'pi pi-info-circle',
            accept: () => {

                this.lstColaboradores.push({
                    idx: 0,
                    id_permanencia: 0,
                    cod_chofer: addColaborador.cod_chofer,
                    nombres: addColaborador.nombres,
                    apellido_paterno: addColaborador.apellido_paterno,
                    apellido_materno: addColaborador.apellido_materno,
                    id_ciudad_HM: addColaborador.id_ciudad_HM,
                    nombre_ciudad_hm: addColaborador.nombre_ciudad_hm,
                    id_ciudad_principal: addColaborador.id_ciudad_principal,
                    nombre_ciudad_principal: addColaborador.nombre_ciudad_principal,
                    id_puesto: addColaborador.id_puesto,
                    desc_puesto: addColaborador.desc_puesto,
                    clv_activo: addColaborador.clv_activo,
                    id_bono: valorBono.id,
                    desc_bono: valorBono.nombre,
                    clv_retroactivo: 0,
                    clv_retroactivo_cast: false,
                    fec_baja: addColaborador.fec_baja,
                    clv_operacion: 0,
                    clv_cambioManual: 0,
                    desc_motivoCambio: 'Agregado por el administrador del sitio',
                    clv_retrasaPago: 0,
                    clv_nuevoPorAdmn: 1,
                    nom_ult_mod: '',
                    fec_alta: addColaborador.fec_alta,
                    clv_mod : 1,
                    clv_pagado:0
                });
                this.cancelarVentanaModal();

                // let index = this.lstgetColaboradorCiudad.indexOf( respIndex);
                // let var1 = this.lstgetColaboradorCiudad.splice(index,1 );

                // this.lstgetColaboradorCiudad = var1;

                // Mensaje de éxito
                this.messageService.add({
                    key: 'msj',
                    severity: 'success',
                    summary: 'Confirmación',
                    detail: 'La información del colaborador ha sido guardada exitosamente.'
                });

              // }
            },
            reject: (type: any) => {
              switch (type) {
                case ConfirmEventType.REJECT:
                  this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
                  break;
                case ConfirmEventType.CANCEL:
                  this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
                  break;
              }
            }
        });
    } else {
        // Si falta información, puede simplemente enviar un mensaje genérico o manejarlo como creas conveniente
        this.messageService.add({
            key: 'msj',
            severity: 'error',
            summary: 'Error',
            detail: 'Favor  de validar que el colaborador no se encuentre en el listado original.'
        });
    }
}



}
