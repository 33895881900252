<p-toast position="top-center" key="confirm" (onClose)="cancelarMensajeConfirmacion()" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="flex flex-column" style="flex: 1">
            <div class="text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{message.summary}}</h4>
                <p>{{message.detail}}</p>
            </div>
            <div class="grid p-fluid">
                <div class="col-6">
                    <button type="button" pButton (click)="cancelarMensajeConfirmacion()" label="Cancelar"
                        class="p-button-secondary"></button>
                </div>
                <div class="col-6">
                    <button type="button" pButton (click)="confirmarMensaje()" label="Aceptar"
                        class="p-button-success"></button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>
<p-toast position="top-right" key="msj"></p-toast>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="Aceptar" rejectLabel="Cancelar"
    rejectButtonStyleClass="p-button-text">
</p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
    rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar"
    [rejectVisible]="false"></p-confirmDialog>
<div class="d-flex justify-content-center">
    <div class="container-fluid" style="width: 98%;">
        <div class="row mt-5">
            <div class="col-md-12">
                <div class="card card-shadow sinborde">
                    <div class="card-header sinborde" style="background: none; ">
                        <div class="cardicono rounded">
                            <i class="pi pi-calendar-plus mt-3" style="font-size: 2rem; color: #fff;"></i>
                        </div>
                        <header style="left: 11%; top: 5%;">
                            <div style="display:inline">Gestión de</div>
                            <div style="color:blueviolet;display:inline;" class="ml-3">Préstamo de Viáticos</div>
                        </header>
                        <div class="row d-flex justify-content-around mt-4">
                            <div class="row d-flex justify-content-around mt-4">
                                <div class="col-md-6">
                                    <div class="row d-flex justify-content-around">
                                        <div class="col-md-5">
                                            <p-calendar [(ngModel)]="anio" view="year" [showIcon]="true" dateFormat="yy"
                                                inputId="yearpicker" [style]="{'width':'100%','height':'80%'}"
                                                pTooltip="Seleccione Año"></p-calendar>
                                        </div>
                                        <div class="col-md-3">
                                            <button pButton pRipple label="Filtrar" class="p-button-success"
                                                icon="pi pi-filter" (click)="cargarInformacion(anio);"
                                                pTooltip="Cargar Información"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 d-flex justify-content-end">
                                    <div>
                                        <p-button label="Consultar Historico" icon="pi pi-list" iconPos="right"
                                            styleClass="p-button-raised p-button-warning" (click)="consultaHistorico();"
                                            pTooltip="Consultar Historico" tooltipPosition="left">
                                        </p-button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 d-flex justify-content-end">
                                <div>
                                    <p-button label="Registrar Prestamo" icon="pi pi-plus" iconPos="right"
                                        (click)="registrarAhorro(1);">
                                    </p-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-content">
                        <div class="row mt-3">
                            <p-table #dt1 [value]="lstColaboradores" [tableStyle]="{'min-width': '50rem'}"
                                styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true" scrollHeight="480px"
                                responsiveLayout="scroll"
                                [globalFilterFields]="['nombres','apellido_paterno','apellido_materno','desc_puesto']"
                                [paginator]="true" [rows]="10" [rowsPerPageOptions]="[10,15,20,30,50]"
                                [showCurrentPageReport]="true" [resizableColumns]="false"
                                currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros"
                                [loading]="loading">
                                <ng-template pTemplate="caption">
                                    <div class="flex justify-content-end">
                                        <span class="p-input-icon-left p-ml-auto">
                                            <i class="pi pi-search"></i>
                                            <input pInputText type="text" size="40"
                                                (input)="applyFilterGlobal($event, 'contains')"
                                                placeholder="Buscar Nombre,Puesto" />
                                        </span>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="idx" class="text-center"># Chofer<p-sortIcon
                                                field="idx"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="nombres">Nombres<p-sortIcon field="nombres"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="apellido_paterno">Paterno<p-sortIcon
                                                field="apellido_paterno"></p-sortIcon></th>
                                        <th pSortableColumn="apellido_materno">Materno<p-sortIcon
                                                field="apellido_materno"></p-sortIcon></th>
                                        <th pSortableColumn="desc_puesto">Puesto<p-sortIcon
                                                field="desc_puesto"></p-sortIcon></th>
                                        <th pSortableColumn="nombre_ciudad_hm">Ciudad<p-sortIcon
                                                field="nombre_ciudad_hm"></p-sortIcon></th>
                                        <th class="text-center">Fecha de Registro</th>
                                        <th class="text-center">Dispersión</th>
                                        <th class="text-center">Importe</th>
                                        <th class="text-center">Estatus</th>
                                        <th class="text-center" style="min-width: 215px; width: 215px;"></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-data let-index>
                                    <tr>
                                        <td class="text-center texto_chofer_lila">
                                            {{data.cod_chofer}}
                                        </td>
                                        <td class="texto_resaltado">
                                            {{data.nombres }}
                                        </td>
                                        <td class="texto_resaltado"> {{data.apellido_paterno}}</td>
                                        <td class="texto_resaltado"> {{data.apellido_materno}}</td>
                                        <td
                                            [ngClass]="{'texto_chofer': data.id_puesto==1,'texto_auxiliar':data.id_puesto==2,'texto_bk':data.id_puesto==3}">
                                            {{ data.desc_puesto}}
                                        </td>
                                        <td class=""> {{data.desc_ciudad}}</td>
                                        <td class="text-center">
                                            {{ data.fecha_registro | date: 'dd/MM/yyyy'}}
                                        </td>
                                        <td class="text-center">
                                            <p-button icon="pi pi-search" styleClass="p-button-rounded p-button-text"
                                            (click)="cargarInformacionPrestamo(data.id_prestamo)"
                                            ></p-button>
                                        </td>
                                        <td class="text-center texto_chofer_lila">
                                            {{ data.imp_prestamo_total | currency}}
                                        </td>
                                        <td class="text-center"><p-tag *ngIf="data.clv_finalizado==1" severity="success"
                                                value="FINALIZADO"></p-tag>
                                            <p-tag *ngIf="data.clv_finalizado==0" [style]="{ 'background': '#D9D9D9'}"
                                                value="NO FINALIZADO"></p-tag>
                                        </td>
                                        <td>
                                            <div *ngIf="data.clv_operacion==3">
                                                <div *ngIf="data.clv_autorizado==0" class="d-flex justify-content-around">
                                                    <p-button label="Rechazar" icon="pi pi-times"
                                                        (onClick)="rechazarPrestamo(data)"
                                                        styleClass="p-button-danger p-button-raised p-button-text">
                                                    </p-button>
                                                    <p-button label="Autorizar" icon="pi pi-check"
                                                        (onClick)="autorizarPrestamo(data)"
                                                        styleClass="p-button-success p-button-raised p-button-text">
                                                    </p-button>
                                                </div>
                                                <div *ngIf="data.clv_autorizado==1" class="d-flex justify-content-around">
                                                    <p *ngIf="data.clv_autorizado==1" class="texto_verde texto_responsable pt-2">
                                                        Autorizado por Administrador
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-around" *ngIf="data.clv_operacion==1">
                                                <p *ngIf="data.clv_autorizado==0" class="color-operacion">
                                                    Pendiente de Autorización
                                                </p>
                                                <p *ngIf="data.clv_autorizado==1" class="texto_verde texto_responsable pt-2">
                                                    Autorizado por Administrador
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--AGREGAR / EDITAR PRESTAMO-->
        <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspDialogo"
            [style]="{width: '70vw','height':'650px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
            (onHide)="cancelarModal()">
            <ng-template pTemplate="header">
                {{ texto_operacion }}
                <div *ngIf="" style="color:black;">
                    {{nombre_registro }}
                </div>
            </ng-template>
            <ng-template pTemplate="content">
                <div class="mt-5">
                    <form [formGroup]="dataForm" (ngSubmit)="calcularDispersionPrestamo()">
                        <span class="title-light mt-3">Información de Prestamo de Viatico <i class="pi pi-cog"
                                style="size: 2rem;"></i>
                        </span>
                        <div class="row mt-3">
                            <div class="form-group col-md-6">
                                <div class="row g-0 mt-3">
                                    <div class="col-sm-1 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-bars color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                        <p-dropdown [options]="lstTodosColaboradores" autoWidth="false"
                                            [style]="{'width':'100%'}" placeholder="Seleccione Colaborador*"
                                            optionLabel="nombre_completo" [showClear]="true" [filter]="true"
                                            filterBy="nombre_completo" formControlName="cod_chofer"
                                            optionValue="cod_chofer" tooltip="">
                                            <ng-template let-item pTemplate="selectedItem">
                                                <span style="vertical-align:middle; word-wrap: break-word;"
                                                    class="texto_responsable">{{item.nombre_completo}}</span>
                                            </ng-template>
                                            <ng-template let-target pTemplate="item">
                                                <div class="ui-helper-clearfix" style="position: relative;height:25px;"
                                                    pTooltip="{{target.desc_ciudad}}" tooltipPosition="top">
                                                    <span
                                                        style="font-size:14px;float:left;margin-top:4px;word-wrap: break-word;">{{target.nombre_completo}}</span>
                                                </div>
                                            </ng-template>
                                        </p-dropdown>
                                    </div>
                                </div>
                                <div class="row g-0 mt-3">
                                    <div class="col-sm-1 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-bars color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                        <input type="number" class="form-control sinborde"
                                            pTooltip="Importe Total de Prestamo" formControlName="imp_total">
                                        <hr class="my-0 mt-1">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-center mt-3">
                            <div class="col-2">
                                <button type="submit" class="btn btn-primary w-100" [disabled]="!dataForm.valid">
                                    <span class="text-white">Calcular</span>
                                    <i class="pi pi-save text-white"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                    <div *ngIf="lstDispersionPrestamo.length>0">
                        <p-table [value]="lstDispersionPrestamo" styleClass="p-datatable-sm p-datatable-striped"
                            [tableStyle]="{'min-width': '50rem'}">
                            <ng-template pTemplate="caption">
                                <div class="flex justify-content-start">
                                    <span class="p-input-icon-left p-ml-auto">
                                        <p> Dispersión del Prestamo y Plan de Pago</p>
                                    </span>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>#Pago</th>
                                    <th class="text-center">Semana de Abono</th>
                                    <th class="text-center">Año</th>
                                    <th class="text-center">Importe</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
                                <tr>
                                    <td>{{ rowIndex +1 }}</td>
                                    <td class="text-center">{{data.num_semana_abono}}</td>
                                    <td class="text-center">{{data.num_anio_abono}}</td>
                                    <td class="color-operacion text-center">{{data.imp_abono | currency }}</td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="summary">
                                <div *ngIf="lstDispersionPrestamo.length>0"
                                    class="flex align-items-center justify-content-center mb-2 mt-2">
                                    <div class="col-md-3">
                                        <button class="btn btn-success w-100" pTooltip="Guardar Prestamo de Viatico"
                                            (click)="guardarPrestamo()">
                                            <span class="text-white">Registrar Prestamo</span>
                                            <i class="pi pi-save text-white"></i>
                                        </button>
                                    </div>
                                </div>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                    (click)="cancelarModal();"></button>
            </ng-template>
        </p-dialog>
        <!--DESGLOCE PRESTAMO-->
        <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspDialogoPrestamoEstatus"
            [style]="{width: '70vw','height':'650px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
            (onHide)="cancelarModalEstatus()">
            <ng-template pTemplate="header">
                {{ texto_operacion }}
                <div *ngIf="" style="color:black;">
                    {{nombre_registro }}
                </div>
            </ng-template>
            <ng-template pTemplate="content">
                <div class="mt-5">
                    <span class="title-light mt-3">Información de Pagos <i class="pi pi-cog"
                            style="size: 2rem;"></i>
                    </span>
                    <div>
                        <p-table [value]="lstPagadoViatico" styleClass="p-datatable-sm p-datatable-striped"
                            [tableStyle]="{'min-width': '50rem'}">
                            <ng-template pTemplate="caption">
                                <div class="flex justify-content-start">
                                    <span class="p-input-icon-left p-ml-auto">
                                        <p></p>
                                    </span>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>#</th>
                                    <th class="text-center">Semana De Abono</th>
                                    <th class="text-center">Importe de Abono</th>
                                    <th class="text-center">Importe Original</th>
                                    <!--<th class="text-center">Importe Pagado</th>-->
                                    <th class="text-center">Descripción</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
                                <tr>
                                    <td>{{ rowIndex +1 }}</td>
                                    <td class="text-center">{{data.num_semana_abono}}</td>
                                    <td class="color-operacion text-center">{{data.imp_abono | currency}}</td>
                                    <td class="text-center texto_resaltado">{{data.imp_prestamo_total | currency }}</td>
                                    <!--<td class="text-center">{{data.tota_pagado}}</td>-->
                                    <td class="text-center texto_chofer_lila">
                                        {{data.desc_finalizado}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                    (click)="cancelarModalEstatus();"></button>
            </ng-template>
        </p-dialog>
    </div>
</div>