import { Component } from '@angular/core';

@Component({
  selector: 'app-historiconomina',
  templateUrl: './historiconomina.component.html',
  styleUrls: ['./historiconomina.component.scss']
})
export class HistoriconominaComponent {

}
